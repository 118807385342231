import api from "shared/utils/api";

class Profile {
  async SendOTP(data: any) {
    try {
      const response = await api.post(`/send-user-otp`, data);
      if (response.status) return response;
    } catch (error) {}
  }

  async ConfirmOTP(data: any) {
    try {
      const response = await api.post(`/confirm-otp`, data);
      if (response.status) return response;
    } catch (error) {
      console.log("user phone number confirm otp error", error);
      return error;
    }
  }
}
const profileOBJ = new Profile();
export default profileOBJ;
