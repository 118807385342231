import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import GridSection from "./GridSection";
import Layout from "Layout";
import Switch from "shared/components/Switch";
import useApi from "shared/hooks/api";
import {
  disputeTableHead,
  ServiceTransTableHead,
  CustomersServiceTableHead,
  ServiceSettlementHead,
  subAccountTableHead,
} from "../businessTableHeader";

import { TableActions, TransStatus, ViewAllBtn } from "./Styles";
import businessOBJ from "Business/businessClass";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import Table from "shared/clientcomponents/Table";
import { numberFormat } from "shared/utils/utility";
import {
  PaginationButtonsContainer,
  PaginationContainer,
  PaginationNext,
  PaginationPrev,
} from "Business/Single/Styles";
import { GradientLoader } from "shared/components";
import EmptyState from "shared/components/EmptyState";
import PageOverlay from "shared/clientcomponents/PageOverlay";
import Modal from "shared/quickComponents/modal";
import TransDetailsModal from "Business/TransDetailsModal/Index";

export default function TabSelected() {
  const [isloading, setIsloading] = useState(true);
  const [noRecord, setNoRecord] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [tableName, settableName] = useState("Transactions");
  const [serviceDetails, setServiceDetails] = useState<any>({});
  const [currentPage, setcurrentPage] = useState(1);
  const [tableLoading, settableLoading] = useState(true);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [selectedTransaction, setselectedTransaction] = useState<any>("");

  const [tableHeader, settableHeader] = useState<any[]>([]);
  const [tableBody, settableBody] = useState<any[]>([]);

  const history = useHistory();
  const match = useRouteMatch();
  const { location } = useHistory();

  const routeDetails: any = location?.state;
  // let businessname = routeDetails && routeDetails["businessName"];
  const serviceId = routeDetails && routeDetails["serviceId"];

  // const [{ data, error, setLocalData }, fetchData] = useApi.get(
  //   `/services/${serviceId}`
  // );

  //====== Get transactions =================
  const getTransactions = async () => {
    const response = await businessOBJ.getTransactions(
      "services",
      serviceId,
      currentPage
    );
    if (response?.data?.length) {
      // console.log("transactions onservice", response);
      settableLoading(false);
      setcurrentPage(response?.currentPage);
      setTotalPage(response?.totalPages);

      const transactions = response?.data?.map(
        (transaction: any, index: number) => ({
          id: index + 1,
          amount: numberFormat(transaction?.amount ? transaction?.amount : 0),
          fullname:
            transaction?.Customer?.fullName === "undefined undefined"
              ? ""
              : transaction?.Customer?.fullName,
          email: transaction?.Customer?.email,
          gateway: transaction?.gateway,
          gatewayChannel: transaction?.gatewayChannel,
          dateAndTime: transaction.createdAt,
          status: (
            <TransStatus
              status={
                transaction.status === "INIT"
                  ? "In Progress"
                  : transaction.status
              }
            >
              {transaction.status === "INIT"
                ? "In Progress"
                : transaction.status}
            </TransStatus>
          ),
          funcProps: (
            <TableActions
              onClick={() => {
                //setisModalOpen(true);
                //setselectedTransaction(transaction);
                history.push(`/gateway/report/details/transactions/${transaction?.uuid}`);
                //history.push(`/business/overview/${elm?.busId}`);
              }}
            >
              <ViewAllBtn>View</ViewAllBtn>
            </TableActions>
          ),
        })
      );
      settableHeader(ServiceTransTableHead); //set table header
      settableBody(transactions); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };

  //=== Get Dispute =====================
  const getDispute = async () => {
    const response = await businessOBJ.getDisputes(
      "services",
      serviceId,
      currentPage
    );
    if (response?.data?.length) {
      settableLoading(false);
      setcurrentPage(response?.currentPage);
      setTotalPage(response?.totalPages);

      const disputes = response?.data?.map((dispute: any, index: number) => ({
        id: index + 1,
        serviceID: dispute?.service?.serviceId,
        serviceName: dispute?.service?.name,
        amount: numberFormat(dispute.amount),
        merchant: dispute.merchant,
        email: dispute.email,
        dateandtime: dispute.createdAt,
        funcProps: <></>,
      }));
      settableHeader(disputeTableHead); //set table header
      settableBody(disputes); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  //=====================================

  //======= Get Customers ===================
  const getCustormers = async () => {
    const response = await businessOBJ.getCustomers("services", serviceId);
    // console.log("customers data:", response);
    if (response.length) {
      settableLoading(false);
      const customers = response?.map((customer: any, index: number) => ({
        id: index + 1,
        sn: customer?.Services?.serviceId,
        serviceName: customer?.Services?.name,
        fullName: customer?.name + " " + customer?.lastName,
        email: customer?.email,
        phoneNumber: customer?.phone,
      }));
      settableHeader(CustomersServiceTableHead); //set table header
      settableBody(customers); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };

  // ========================================

  //== Get Total settlement ====
  const getSettlements = async () => {
    const response = await businessOBJ.getSettlements("services", serviceId);
    console.log("settlement data", response);
    if (response.length) {
      settableLoading(false);
      const settlements = response?.map((obj: any, index: number) => ({
        id: index + 1,

        // accountName: obj.bankAccountName,
        // bankName: obj?.bank?.name,
        status: obj?.status,
        amountSettled: numberFormat(obj.amount),
        funcProps: <></>,
      }));
      settableHeader(ServiceSettlementHead); //set table header
      settableBody(settlements); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  // ==============================

  //=== Get third pary accounts ========
  const getSubAccounts = async () => {
    const response = await businessOBJ.getSubaccounts("services", serviceId);
    // console.log("subaccounts", response);
    if (response.length) {
      settableLoading(false);
      const subaccounts = response?.map((account: any, index: number) => ({
        id: index + 1,
        serviceID: account?.service?.serviceId,
        serviceName: account?.service?.name,
        accountName: account.bankAccountName,
        bankName: account.bankName,
        accountNumber: account.accountNumber,
        funcProps: <></>,
      }));
      settableHeader(subAccountTableHead); //set table header
      settableBody(subaccounts); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  //=====================================

  const getData = (tabname?: any) => {
    switch (tabname) {
      case "Transactions":
        settableLoading(true);
        setcurrentPage(1);
        settableName("Transactions");
        getTransactions();
        break;
      case "Customers":
        setTotalPage(0);
        settableLoading(true);
        setcurrentPage(1);
        settableName("Customers");
        getCustormers();
        break;

      case "Total Settlement":
        setTotalPage(0);
        settableLoading(true);
        setcurrentPage(1);
        settableName("Settlements");
        getSettlements();
        break;

      case "Third Party Account":
        setTotalPage(0);
        settableLoading(true);
        setcurrentPage(1);
        settableName(tabname);
        getSubAccounts();
        break;

      case "Account Details":
        settableHeader([]); //to be removed

        break;

      case "Dispute Created":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("  Disputes");
        getDispute();
        break;

      default:
        getTransactions(); //Get transactions
        break;
    }
  };

  const PagenationListener = () => {
    if (tableName === "Transactions") getTransactions();
    if (tableName === "Disputes") getDispute();
  };

  // const getService = async () => {
  //   const data = await businessOBJ.getService(serviceId);
  //   if (data && data.status) {
  //     console.log("services data", data.payload);
  //     setServiceDetails(data?.payload);
  //     setNoRecord(false);
  //     setIsloading(false);
  //   }
  // };

  useEffect(() => {
    PagenationListener();
  }, [currentPage]);

  useLayoutEffect(() => {
    if (serviceId) {
      // getService();
    } else history.push("/business");
  }, [serviceId]);

  useLayoutEffect(() => {
    getData();
  }, []);

  return (
    <Layout pageTitle="Business">
      <GridSection getData={getData} tabName={""} />
      <DashboardSubHeader
        // count={tableBody.length}
        title={tableName}
        btnTitle=""
        withSearch={
          tableName == "Settlements" || tableName === "Third Party Account"
            ? false
            : true
        }
        withFilter={false}
        widthActionBtn={false}
        filterOptions={["Name", "Date"]}
        setSearchData={() => ""}
        callBackFn={() => ""}
        placeholder={`${tableName} ID`}
      />
      {tableBody.length < 1 && !tableLoading ? (
        <div style={{ background: "white", width: "100%" }}>
          <EmptyState stateTitle="No Data" statePara="" stateBtnText="" />
        </div>
      ) : (
        <>
          {tableLoading ? (
            <GradientLoader />
          ) : (
            <Table
              headData={tableHeader}
              bodyData={tableBody}
              rowStyle={{
                background: "#ffffff",
              }}
              totalPages={totalPage}
              currentpage={currentPage}
              goToPage={(value: any) => {
                setcurrentPage(value);
                settableLoading(true);
                // getKYCList(value);
              }}
              // hoverLink={hoverLink}
            />
          )}

          <Modal
            isOpen={isModalOpen}
            closeButton={true}
            onClose={() => setisModalOpen(false)}
            width="45%"
            marginTop="2rem"
          >
            {selectedTransaction && (
              <TransDetailsModal selectedTransaction={selectedTransaction} />
            )}
          </Modal>
        </>
      )}
    </Layout>
  );
}
