import React, { useLayoutEffect, useState, useEffect } from "react";
import { useParams, useHistory, Link, useRouteMatch } from "react-router-dom";
import businessOBJ from "Business/businessClass";
import Table from "shared/clientcomponents/Table";
import GridSection from "./GridSection";
import api from "shared/utils/api";
import EmptyState from "../../shared/components/EmptyState";
import Modal from "shared/quickComponents/modal";
import {
  ContentKey,
  ContentRow,
  ContentValue,
  DetailsContainer,
  DetailsContent,
  DetailsHeader,
  PaginationButtonsContainer,
  PaginationContainer,
  PaginationNext,
  PaginationPrev,
  TableActions,
  ViewAllBtn,
} from "./Styles";
import { GradientLoader } from "shared/components";
import {
  ServiceTableHead,
  CustomersTableHead,
  TransactionTableHead,
  accDetailsTableHead,
  totalSettlementHead,
  subAccountTableHead,
  disputeTableHead,
} from "../businessTableHeader";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import logger from "shared/utils/logger";
import { getClientDetails } from "shared/utils/location";
import { getCurrentUser } from "shared/utils/authToken";
import { numberFormat } from "shared/utils/utility";
import consoleLog from "shared/utils/consoleLog";
import { TransStatus } from "Business/Tab/Styles";
import Layout from "Layout";
import TransDetailsModal from "../TransDetailsModal/Index";
import { Content } from "AuditTrail/Styles";
import { useBizTableSearch } from "shared/hooks/useBizTableSearch";
import { businessTableSearch } from "shared/utils/tableSearch";

type PropT = {
  [x: string]: any;
};

export default function Single() {
  const [tabSelected, settabSelected] = useState("");
  const [tableHeader, settableHeader] = useState<any[]>([]);
  const [tableBody, settableBody] = useState<any[]>([]);
  const [businessName, setbusinessName] = useState<string>("");
  const [isloading, setIsloading] = useState(true);
  const [noRecord, setNoRecord] = useState(true);
  const [businessData, setbusinessData] = useState<any>([]);
  const [isPaginate, setisPaginate] = useState(false);
  const [businessDetails, setbusinessDetails] = useState<any>({});
  const [searchContent, setSearchContent] = useState<string | undefined>();
  const [pageData, setpageData] = useState<any>([]);

  const [totalPage, setTotalPage] = useState(0);
  const [tableName, settableName] = useState("");

  const [currentPage, setcurrentPage] = useState(1);
  const [tableLoading, settableLoading] = useState(false);
  const [serviceList, setseriviceList] = useState([]);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [selectedTransaction, setselectedTransaction] = useState<any>("");

  const history = useHistory();
  const { id: busId } = useParams<{
    id: string;
  }>();
  const match = useRouteMatch();
  const currentUser = getCurrentUser();

  // const [{ data, error, setLocalData }, fetchData] = useApi.get(
  //   `/business/${busId}`
  // );

  const handleLog = async () => {
    // console.log("logging on bussiness");
    await logger.logActivities("remote", {
      date: new Date().toString(),
      author: currentUser.fullName,
      resource: "admin",
      action: "MERCHANT",
      payload: {
        userAgent: getClientDetails(),
        data: {
          detail: `${currentUser.fullName} views/opens ${businessName}for analysis`,
        },
      },
    });
  };
  //============ Get services ===========
  const getServices = () => {
    const handleNavigate = (businessid: any, serviceId: any) => {
      history.push(`/business/overview/business/service`, { serviceId });
    };

    const services = serviceList.map((service: any, index: number) => ({
      id: index + 1,
      sn: service.serviceId,
      serviceName: service.name,
      isActive: "is active",
      funcProps: (
        <TableActions>
          <ViewAllBtn onClick={() => handleNavigate(busId, service.id)}>
            View
          </ViewAllBtn>
        </TableActions>
      ),
    }));

    settableHeader(ServiceTableHead); //set table header
    settableBody(services); //set table body
  };
  // ==========================================

  //======= Get Customers ===================
  const getCustormers = async () => {
    const response = await businessOBJ.getCustomers("business", busId);
    console.log("customers data:", response);
    if (response.length) {
      settableLoading(false);
      setpageData(response);
      const customers = response?.map((customer: any, index: number) => ({
        id: index + 1,
        sn: customer?.Services?.serviceId,
        serviceName: customer?.Services?.name,
        fullName: customer?.fullName,
        email: customer?.email,
        phoneNumber: customer?.phone,
      }));
      settableHeader(CustomersTableHead); //set table header
      settableBody(customers); //set table body
      //here
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };

  // ========================================

  //====== Get transactions =================
  const getTransactions = async () => {
    const response = await businessOBJ.getTransactions(
      "business",
      busId,
      currentPage
    );
    if (response?.data?.length) {
      setpageData(response);
      settableLoading(false);
      setcurrentPage(response?.currentPage);
      setTotalPage(response?.totalPages);
      // consoleLog("Transactionss ===>", response?.data);
      const transactions = response?.data?.map(
        (transaction: any, index: number) => ({
          id: index + 1,
          sn: transaction?.Services?.serviceId,
          serviceName: transaction?.Services?.name,
          reference: transaction?.reference,
          email: transaction?.customer?.email,
          amount: numberFormat(
            transaction?.amount ? parseInt(transaction?.amount) : 0
          ),
          dateAndTime: transaction.createdAt,
          status: (
            <TransStatus
              status={
                transaction.status === "INIT"
                  ? "In Progress"
                  : transaction.status
              }
            >
              {transaction.status === "INIT"
                ? "In Progress"
                : transaction.status}
            </TransStatus>
          ),
          funcProps: (
            <TableActions>
              <ViewAllBtn
                onClick={() => {
                  // setisModalOpen(true);
                  // setselectedTransaction(transaction);
                  console.log("transaction data ====>", transaction);
                  history.push(
                    `/business/overview/transaction/${transaction?.uuid}`,
                    { data: transaction }
                  );
                }}
              >
                View
              </ViewAllBtn>
            </TableActions>
          ),
        })
      );
      settableHeader(TransactionTableHead); //set table header
      settableBody(transactions); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  // =======================================

  //====== Get account details ========

  const getAccountDetails = async () => {
    console.log("table status", tableLoading);
    const response = await businessOBJ.getAccountDetails(busId);
    if (response.length) {
      setpageData(response);
      settableLoading(false);
      const accDetails = response.map((account: any, index: number) => ({
        id: index + 1,
        serviceID: account?.service?.serviceId,
        serviceName: account?.service?.name,
        accountName: account.bankAccountName,
        bankName: account?.bank?.name,
        accountNumber: account.accountNumber,
        funcProps: <></>,
      }));
      settableHeader(accDetailsTableHead); //set table header
      settableBody(accDetails); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  // ==================================

  //== Get Total settlement ====
  const getSettlements = async () => {
    const response = await businessOBJ.getSettlements("business", busId);
    if (response.length) {
      setpageData(response);
      settableLoading(false);
      const settlements = response?.map((obj: any, index: number) => ({
        id: index + 1,
        serviceID: obj?.Service?.serviceId,
        serviceName: obj?.Service?.name,
        // accountName: obj.bankAccountName,
        // bankName: obj?.bank?.name,
        recepient: obj?.recipient[0],
        amountSettled: numberFormat(obj.amount),
        funcProps: <></>,
      }));
      settableHeader(totalSettlementHead); //set table header
      settableBody(settlements); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  // ==============================

  //=== Get third pary accounts ========
  const getSubAccounts = async () => {
    const response = await businessOBJ.getSubaccounts("business", busId);
    // console.log("subaccounts", response);
    if (response.length) {
      setpageData(response);
      settableLoading(false);
      const subaccounts = response?.map((account: any, index: number) => ({
        id: index + 1,
        serviceID: account?.service?.serviceId,
        serviceName: account?.service?.name,
        accountName: account.bankAccountName,
        bankName: account.bankName,
        accountNumber: account.accountNumber,
        funcProps: <></>,
      }));
      settableHeader(subAccountTableHead); //set table header
      settableBody(subaccounts); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  //=====================================

  //=== Get Dispute =====================
  const getDispute = async () => {
    const response = await businessOBJ.getDisputes(
      "business",
      busId,
      currentPage
    );
    // console.log("disputes data", response);
    if (response?.data?.length) {
      setpageData(response);
      settableLoading(false);
      setcurrentPage(response?.currentPage);
      setTotalPage(response?.totalPages);

      const disputes = response?.data?.map((dispute: any, index: number) => ({
        id: index + 1,
        serviceID: dispute?.service?.serviceId,
        serviceName: dispute?.service?.name,
        amount: numberFormat(dispute.amount),
        merchant: dispute.merchant,
        email: dispute.email,
        dateandtime: dispute.createdAt,
        funcProps: <></>,
      }));
      settableHeader(disputeTableHead); //set table header
      settableBody(disputes); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  //=====================================

  //=== Get Dispute =====================
  const getTeams = async () => {
    const response = await businessOBJ.getTeams(busId, currentPage);
    // console.log("disputes data", response);
    if (response?.data?.length) {
      setpageData(response);
      settableLoading(false);
      setcurrentPage(response?.currentPage);
      setTotalPage(response?.totalPages);

      const teams = response?.data?.map((team: any, index: number) => ({
        name: `${team?.User?.lastName ?? ""} ${team?.User?.firstName ??
          ""} ${team?.User?.otherName ?? ""} `,
        phone: team.phone,
        email: team.email,
        service: (() => {
          if (team?.userBusinessServices instanceof Array) {
            let newServiceArray = [];
            for (let el of team?.userBusinessServices) {
              if (el?.Service?.name) newServiceArray.push(el?.Service?.name);
            }
            return newServiceArray.join(", ");
          }
          return "";
        })(),
        role: team.role,
        status: team?.status,
        funcProps: <></>,
      }));
      settableHeader(disputeTableHead); //set table header
      settableBody(teams); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  //=====================================

  const getData = (tabname?: any) => {
    setTotalPage(0); //reset pagination indicatior
    settabSelected(tabname ? tabname : "Services");
    if (tabname === "Service Created") settabSelected("Service");

    switch (tabname) {
      case "Transactions":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Transactions");
        getTransactions();
        break;
      case "Customers":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Customers");
        getCustormers();
        break;

      case "Total Settlement":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Settlement");
        getSettlements();
        break;

      case "Third Party Account":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Sub Accounts");
        getSubAccounts();
        break;

      case "Account Details":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Account Details");
        getAccountDetails();
        break;

      case "Dispute Created":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Disputes");
        getDispute();
        break;

      case "Teams":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Teams");
        getTeams();
        break;

      default:
        settableName("Service");
        getServices(); //Get services
        break;
    }
  };

  //========= Get extra data ==========
  const getExtraData = async () => {
    const response = await businessOBJ.getExtraData(busId);
    if (response) setbusinessData(response);
  };

  const PagenationLister = () => {
    if (tableName === "Transactions") getTransactions();
    if (tableName === "Disputes") getDispute();
  };

  const handleFilter = (content: any) => {
    setSearchContent(content);
    switch (tableName) {
      case "Transactions":
        const tableBody: any = businessTableSearch(
          content,
          tableName,
          "reference",
          pageData?.data,
          //functions passed as props of an obj
          { setisModalOpen, setselectedTransaction }
        );
        settableHeader(TransactionTableHead);
        settableBody(tableBody ?? []);
        break;

      case "Customers":
        const customertableBody: any = businessTableSearch(
          content,
          tableName,
          "email",
          pageData,
          //functions passed as props of an obj
          { setisModalOpen, setselectedTransaction }
        );
        settableHeader(CustomersTableHead);
        settableBody(customertableBody ?? []);
        break;
      default:
        break;
    }
  };

  const getBusiness = async () => {
    const data = await businessOBJ.getBusiness(busId);
    console.log("Business detail: ", data)
    if (data && data.status && data.payload.servicesList?.length) {
      setpageData(data.payload);
      //console.log("business service details ==>", data.payload);
      setbusinessDetails(data.payload);
      setbusinessName(data.payload?.businessName);
      setseriviceList(data?.payload?.servicesList);
      setNoRecord(false);
      setIsloading(false);
      handleLog();
    }
  };

  const getBusinessKycStatus = () => {
    let type = "gateway";
    let findBusType: any =
      businessDetails?.BusinessKyc instanceof Array &&
      businessDetails?.BusinessKyc.find(
        (el: any) =>
          el?.type
            .toString()
            .trim()
            .toLowerCase() === type
      );

    consoleLog(findBusType, "Bustype", businessDetails?.BusinessKyc);
    return findBusType?.status ?? "";
  };

  useEffect(() => {
    PagenationLister();
  }, [currentPage]);

  useEffect(() => {
    if (!busId) {
      history.push("/business/all");
    }
  }, [busId]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getExtraData();
      getBusiness();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (serviceList?.length) {
      getData(); //prepare data for the table
    }
  }, [serviceList]);

  return (
    <Layout pageTitle="Business">
      <>
        {isloading ? (
          <GradientLoader />
        ) : (
          <>
            {noRecord ? (
              <>
                <br />
                <EmptyState stateTitle="No Data" statePara="" stateBtnText="" />
              </>
            ) : (
              <>
                <GridSection
                  getData={getData}
                  businessName={businessName}
                  businessData={businessData}
                  businessStatus={businessDetails?.status}
                  reloadData={getBusiness}
                  details={businessDetails}
                  typeStatus={getBusinessKycStatus()}
                />

                <br />
                <DashboardSubHeader
                  // count={tableBody.length}
                  title={tableName}
                  btnTitle=""
                  withSearch={true}
                  withFilter={false}
                  widthActionBtn={false}
                  filterOptions={["Name", "Date"]}
                  setSearchData={handleFilter}
                  callBackFn={() => ""}
                  placeholder={`${
                    tableName === "Transactions" ? "Reference" : tableName
                  } ID`}
                  searchContent={searchContent}
                />
                {tableBody.length < 1 ? (
                  <div style={{ background: "white", width: "100%" }}>
                    <EmptyState
                      stateTitle="No Data"
                      statePara=""
                      stateBtnText=""
                    />
                  </div>
                ) : (
                  <>
                    {tableLoading ? (
                      <GradientLoader />
                    ) : (
                      <Table
                        headData={tableHeader}
                        bodyData={tableBody}
                        rowStyle={{
                          background: "#ffffff",
                        }}
                        totalPages={totalPage}
                        currentpage={currentPage}
                        goToPage={(value: any) => {
                          setcurrentPage(value);
                          settableLoading(true);
                          // getKYCList(value);
                        }}

                        // hoverLink={hoverLink}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        <Modal
          isOpen={isModalOpen}
          closeButton={true}
          onClose={() => setisModalOpen(false)}
          width="45%"
          marginTop="2rem"
        >
          {/* {selectedTransaction && DetailsView()} */}
          {selectedTransaction && (
            <TransDetailsModal selectedTransaction={selectedTransaction} />
          )}
        </Modal>
      </>
    </Layout>
  );
}
