import history from "browserHistory";
import SecureLS from "secure-ls";
let ls = new SecureLS({ encodingType: "rc4", isCompression: true });

// save anything to secure ls
export const omniSaver = (title: string, data: any) => ls.set(`${title}`, data);
// get anything from secure ls
export const omniGetter = (title: string) => ls.get(`${title}`);
// remove anything from secure ls
export const omniRemover = (title: string) => ls.remove(`${title}`);

export const getTempId = () => ls.get("tempId");
export const storeTempId = (tempId: any) => ls.set("tempId", tempId);
export const getStoredAuthToken = () => ls.get("authToken");

export const storeAuthToken = (token: string) => ls.set("authToken", token);
export const storeRefreshToken = (token: string) =>
  ls.set("refreshToken", token);

export const removeStoredAuthToken = () => {
  ls.remove("authToken");
  ls.remove("currentUser");
};

export const storeCurrentBusiness = (business: object) =>
  ls.set("currentBusiness", JSON.stringify(business));

export const getCurrentBusiness = () => {
  // const data = ls.get("currentBusiness");
  // // console.log(data, "data");
  // return data ? JSON.parse(data) : [];
  return [];
};

export const removeAll = () => {
  ls.removeAll();
  history.push("/autheticate/login");
};

export const storeLogs = (data: any) => {
  const storeData = ls.get("logs" || "");
  var reservedData: any[] = storeData ? JSON.parse(storeData) : [];
  reservedData.push(data);
  ls.set("logs", JSON.stringify(reservedData));
};

export const getStoredLogs = () => {
  const storeData = ls.get("logs" || "");
  return storeData ? JSON.parse(storeData) : [];
};

export const storeCurrentUser = (user: object) =>
  ls.set("currentUser", JSON.stringify(user));

export const getCurrentUser = () => {
  const data = ls.get("currentUser");
  return data ? JSON.parse(data) : [];
};

export const storeUserCoord = (location: any) => {
  ls.set("userCoord", JSON.stringify(location));
};

export const getUserCoord = () => {
  const data = ls.get("userCoord");
  return data ? JSON.parse(data) : [];
};

//cachedData
export const getCachedData = () => {
  try {
    return JSON.parse(ls.get("cachedData"));
  } catch (err) {
    // consoleLog(err);
    return [];
  }
};
export const storeCachedData = (cachedData: any) => {
  try {
    ls.set("cachedData", JSON.stringify(cachedData));
  } catch {}
};
export const storeToLocalStorage = (key: string, value: any) => {
  ls.set(key, value);
};

export const getStoreLocalStorage = (key: string) => {
  return ls.get(key);
};

export const clearLocalStorage = () => ls.clear();
