import styled, { css } from "styled-components";

import { color, font } from "shared/utils/styles";
import Icon from "shared/components/Icon";

type PropT = {
  hasIcon?: boolean;
  invalid?: boolean;
};

export const StyledInput = styled.div`
  position: relative;
  display: inline-block;
  height: 54px;
  width: 100%;
`;

export const InputElement = styled.input<PropT>`
  height: 100%;
  width: 100%;
  padding: 0 7px;
  border-radius: 5px;
  border: 1px solid ${color.borderLightest};
  color: ${color.textDarkest};
  background: ${color.backgroundLightest};
  transition: background 0.1s;
  ${font.regular}
  ${font.size(15)}
  ${(props) => props.hasIcon && "padding-left: 32px;"}
  &:hover {
    background: ${color.backgroundLight};
  }
  &:focus {
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 0 0 1px ${color.borderInputFocus};
  }
  ${(props) =>
    props.invalid &&
    css`
      &,
      &:focus {
        border: 1px solid ${color.danger};
        box-shadow: none;
      }
    `}
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 8px;
  left: 8px;
  pointer-events: none;
  color: ${color.textMedium};
`;
