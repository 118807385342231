import { useEffect, useLayoutEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import useApi from "shared/hooks/api";
import api from "shared/utils/api";
import { toast } from "react-toastify";
import {
  AmountBox,
  AwaitingText,
  BoldText,
  Heading,
  ModalButton,
  ModalButtonsContainer,
  ModalContent,
  OtpContainer,
  PageContainer,
  PersonBox,
  PersonBoxLeft,
  PersonBoxRight,
  PersonLabel,
  Reason,
  ReasonBox,
  ReasonContainer,
  ReasonLabel,
  SingleDispute,
  StatusBox,
  StatusContainer,
  SubTitle,
  Title,
} from "./Styles";
import { GradientLoader, Spinner } from "shared/components";
import Modal from "shared/quickComponents/modal";
import logger from "shared/utils/logger";
import disputOBJ from "Dispute/disputeClass";
import { getCurrentUser } from "shared/utils/authToken";
import { getClientDetails } from "shared/utils/location";
import { useEffectOnce } from "shared/hooks/useEffectOnce";
import OTPInput from "shared/components/OTPInput";
import { numberFormat } from "shared/utils/utility";

const selectOptions = [
  {
    value: "open",
    name: "Open",
  },
];

export default function Single() {
  const [disputes, setdisputes] = useState<any>({});
  const [isLoading, setisLoading] = useState(false);
  const [NoRecord, setNoRecord] = useState(true);
  const [status, setStatus] = useState<any>(null);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [showIndicator, setshowIndicator] = useState(false);
  const [isopen, setisopen] = useState(false);
  const [isOTPError, setisOTPError] = useState(false);
  const [Otp, setOtp] = useState("");
  const [tempId, settempID] = useState<string>("");

  const currentUser = getCurrentUser();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const disputeStatus = ["CLOSE", "OPEN", "PENDING"];

  const [{ data, error, setLocalData }, fetchData] = useApi.get(
    `/dispute/${id}`
  );

  const openModal = () => {
    setisopen(!isopen);
  };

  const handleLog = async (action = "ACCESS") => {
    let message: any = "";
    if (action === "ACCESS") message = `access the "Dispute" with id:${id}`;
    if (action === "STATUS_CHANGED")
      message = `changed the "Dispute" status with id:${id} to ${status}`;
    console.log("logging on Dispute");
    await logger.logActivities("remote", {
      date: new Date().toString(),
      author: currentUser.fullName,
      resource: "admin",
      action: "REVIEW",
      payload: {
        userAgent: getClientDetails(),
        data: {
          detail: `${currentUser.fullName} ${message}`,
        },
      },
    });
  };

  //controlling confirmation modal
  const handleCloseModal = () => {
    setisModalOpen(false);
    setshowIndicator(false);
    // fetchData();
  };

  //Handling 'No' button on confrimation modal
  const handleNoModalButton = () => {
    setisModalOpen(false);
    setStatus(disputes.status);
    setshowIndicator(false);
  };

  //Handling 'Yes'  button on confrimation modal
  const handleYesModalButton = async () => {
    setshowIndicator(true);
    const data = {
      adminEmail: currentUser.email,
      disputeId: id,
      status,
    };
    console.log("payload to send otp on dispute ===>", data);
    try {
      const response = await api.post("/send-dispute-otp", data);
      if (response?.status) {
        console.log("response from otp", response);
        settempID(response?.payload.temp_id);
        toast.success(response?.message);
        setisopen(true);
        handleCloseModal();
        setshowIndicator(false);
      } else {
        toast.error(response?.message);
        handleCloseModal();
        setshowIndicator(false);
      }
    } catch (error) {
      toast.error(`${error}`);
      handleCloseModal();
      setshowIndicator(false);
    }
  };

  const handleChange = async (otp: string) => {
    setOtp(otp);
    if (otp.length === 6) {
      const payload = { tempId, otp, status }; //=====> payload
      console.log("payload to confirm otp on dispute ==>", payload);
      try {
        const response = await api.put(`/dispute/update/${id}`, payload);
        if (response?.status) {
          toast.success(response?.message);
          setisopen(false);
          await fetchData();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    }
  };

  useLayoutEffect(() => {
    if (!id) history.push("/dashboard/dispute/home");
    if (!data) setisLoading(true);
    if (error) {
      // console.log("disputes error", error);
      setisLoading(false);
      setNoRecord(true);
    }
    if (!data && !error) setNoRecord(true);
    if (data) {
      setdisputes(data.payload);
      setisLoading(false);
      // console.log("single dispute data ==>", disputes);
    }
  }, [data]);

  useEffect(() => {
    handleLog();
  }, []);

  return (
    <>
      {/* ==== Confirmation modal ====== */}
      <Modal isOpen={isModalOpen} closeButton={true} onClose={handleCloseModal}>
        <ModalContent>
          <p>Do you want to change the status of this dispute?</p>

          <ModalButtonsContainer>
            <ModalButton onClick={handleYesModalButton}>
              {showIndicator ? <Spinner color="#fff" /> : "Yes"}
            </ModalButton>
            <ModalButton onClick={handleNoModalButton} option={true}>
              No
            </ModalButton>
          </ModalButtonsContainer>
        </ModalContent>
      </Modal>
      <SingleDispute>
        {isLoading ? (
          <GradientLoader />
        ) : (
          <>
            <Heading>{disputes?.merchant}</Heading>
            <PageContainer>
              <ReasonContainer>
                <ReasonLabel>Reason for Dispute</ReasonLabel>
                <ReasonBox>
                  <Reason>{disputes?.reason}</Reason>
                </ReasonBox>
              </ReasonContainer>
              <StatusContainer>
                <span>Dispute Status</span>
                <StatusBox>
                  <select
                    value={status ? status : disputes?.status}
                    onChange={(event) => {
                      setStatus(event.target.value);
                      setisModalOpen(true); //Open modal
                    }}
                  >
                    {disputeStatus.map((opt) => (
                      <option key={opt} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </select>
                </StatusBox>
              </StatusContainer>
              <PersonLabel>
                Information of the Person who raised dispute
              </PersonLabel>
              <PersonBox>
                <PersonBoxLeft>
                  <p>
                    Account Name : <BoldText>{disputes?.accountName}</BoldText>
                  </p>
                  <p>
                    Bank Name : <BoldText>{disputes?.bankName}</BoldText>
                  </p>
                  <p>
                    Account Number:{" "}
                    <BoldText>{disputes?.accountNumber}</BoldText>
                  </p>
                </PersonBoxLeft>
                <PersonBoxRight>
                  <p>
                    Name : <BoldText>{disputes?.name}</BoldText>
                  </p>
                  <p>
                    Phone Number: <BoldText>{disputes?.phoneNumber}</BoldText>
                  </p>
                  <p>
                    Email: <BoldText>{disputes?.email}</BoldText>
                  </p>
                </PersonBoxRight>
              </PersonBox>
              <AmountBox>
                <p>
                  Amount Of the Transaction:{" "}
                  <BoldText>
                    {disputes?.amount ? numberFormat(disputes?.amount) : '0.00'}
                  </BoldText>
                </p>
                <p>
                  Merchant: <BoldText>{disputes?.merchant}</BoldText>
                </p>

                <p>
                  Response:
                  <AwaitingText>
                    {disputes.thread
                      ? disputes?.thread?.push?.type
                      : "Awaiting response"}
                  </AwaitingText>
                </p>
              </AmountBox>
              {disputes?.thread && (
                <ReasonContainer>
                  <ReasonLabel>Message:</ReasonLabel>
                  <ReasonBox>
                    <Reason>{disputes?.thread?.push?.message}</Reason>
                  </ReasonBox>
                </ReasonContainer>
              )}
            </PageContainer>
          </>
        )}
      </SingleDispute>

      {/* ======= OTP MODAL */}
      <Modal isOpen={isopen} closeButton={true} onClose={openModal} width="40%">
        <div style={{ paddingBottom: "2rem" }}>
          <Title>Check your email for OTP</Title>
          <SubTitle>
            We have sent a 6 - digit code to your email address. <br /> The code
            expires shortly, please enter it soon.
          </SubTitle>
          <OtpContainer>
            {" "}
            <OTPInput
              autoFocus
              isNumberInput
              length={6}
              hasError={isOTPError}
              errorStyle={{ borderColor: "red" }}
              inputStyle={{
                border: "1px solid #828282",
                width: "60px",
                height: "60px",
                fontSize: "30px",
                color: "#000",
                fontWeight: "400",
                caretColor: "#000",
                textAlign: "center",
                borderRight: "0px",
              }}
              focusStyle={
                {
                  //border: "1px solid #CFD3DB",
                }
              }
              firstIndexStyle={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderRight: "0px",
              }}
              lastIndexStyle={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderRight: "1px solid #828282",
                borderLeft: "1px solid #828282",
              }}
              className="otpContainer"
              inputClassName="otpInput"
              separator={
                <span
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "10%",
                    fontSize: "50px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  -
                </span>
              }
              onChangeOTP={handleChange}
            />
          </OtpContainer>
        </div>
      </Modal>
    </>
  );
}
