import { type } from "os";
import styled from "styled-components";

type EyeOpenPropT = {
  height: string;
};

type EyeClosednPropT = {
  size: string;
};

export const EyeOpen = styled.img<EyeOpenPropT>`
  height: ${(prop) => prop.height && prop.height};
  width: auto;
`;
export const EyeClosed = styled.img<EyeClosednPropT>`
  height: ${(prop) => prop.size && prop.size};
  width: ${(prop) => prop.size && prop.size};
`;
