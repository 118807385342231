import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  @media ${breakpoints.device.max.md} {
   flex-direction: column;
  }
`;
export const LineChartContainer = styled.div`
  width: 64%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media ${breakpoints.device.max.md} {
    width: 100%;  
  }
`;

export const DonoutchartContainer = styled.div`
  width: 34%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media ${breakpoints.device.max.md} {
    width: 100%; 
    margin-top: 50px; 
  }
`;
