import React, { useState } from "react";
import Layout from "Layout";
import { useHistory } from "react-router";
import { Container, Heading, GridContainer } from "../Styles";
import SingleBox from "../../../Dashboard/Overview/firstSection/Box";
import { boxData } from "./../datalist";

export default function Home() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const linkUrlPath = (urlPath: string) => {
    history.push(`${history?.location?.pathname}/${urlPath}`);
  };

  const boxLists = boxData.map((box) => {
    let check = ![1, 4].includes(box?.id);
    return (
      <SingleBox
        noPointer={false}
        key={box.id}
        boxdata={box}
        onClick={() => box?.urlPath && linkUrlPath(box?.urlPath)}
        isLoading={isLoading}
      />
    );
  });

  return (
    <Container>
      <Heading>Report based on Agency Banking</Heading>
      {/* <h4>hreee</h4> */}
      <GridContainer>{boxLists}</GridContainer>
    </Container>
  );
}
