import { useLayoutEffect, useState } from "react";

// import { useAppDispatch, useAppSelector } from "AppStore/hooks";
import Layout from "Layout";
import { Redirect, Route, useHistory, useRouteMatch } from "react-router-dom";
import { GradientLoader } from "shared/components";
// import Business from "./Business";
import Financial from "./Financial";
import Dispute from "../Dispute";
import InviteUsers from "../Users/InviteUsers";
import Overview from "./Overview";
import { DashboardPage } from "./Styles";
import SystemStatus from "./SystemStatus";
import { getCurrentUser } from "shared/utils/authToken";

import UserClass from "classes/user.class";
import consoleLog from "shared/utils/consoleLog";
import { plainArray } from "shared/utils/menurecords";

const gatewayMenus = [];
type DashboardDataPropT = {
  data: {}[];
};
const Dashboard = () => {
  const [isLoggedIn, setisLoggedIn] = useState(true);
  const [data, setdata] = useState<boolean>(true);
  const match = useRouteMatch();

  const currentUser = getCurrentUser(); //Get current login user
  consoleLog(currentUser, "current User");
  consoleLog(plainArray(), "plain Array");

  return (
    <Layout pageTitle="Overview">
      <DashboardPage>
        <Redirect exact from="/dashboard" to={`/dashboard/over-view`} />
        <Route path={`${match.path}/over-view`} render={() => <Overview />} />
        {match.isExact && <Redirect to={`${match.url}/over-view`} />}
      </DashboardPage>
    </Layout>
  );
};

export default Dashboard;
