import { PageLayout } from "shared/section_components/Styles";
import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color } from "../../../shared/utils/styles";

type BoxPropT = {
  isActive?: boolean;
};

type ContainerT = {
  isDetails?: boolean;
};

type StatusBtnT = {
  mode?: string;
};

type PriceTabT = {
  isActive: boolean;
};

export const BusinesPageLayout = styled(PageLayout)`
  background-color: transparent !important;
  padding-bottom: 20px;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 4rem;
`;

export const TopSection = styled.section`
  display: flex;
  gap: 2rem;
`;

export const DetailsContainer = styled.div`
  position: relative;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
`;
export const InfoContainer = styled.div<ContainerT>`
  position: relative;
  overflow: hidden;
  width: ${(prop) => (prop.isDetails ? "45%" : "50%")};
  min-height: 4rem;
  max-height: ${(prop) => prop.isDetails && "30rem"};
  padding: ${(prop) => (prop.isDetails ? "0.4rem 2rem" : "0.2rem")};
  background: #fff;
  border-radius: 0.4rem;
  h3 {
    padding: 1.5rem 0;
  }
`;
export const InfoGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  p {
    width: 58%;
  }
`;

export const StatusButton = styled.span<StatusBtnT>`
  background: ${color.fountain};
  background: ${(prop) => prop.mode === "TEST" && "orange"};
  background: ${(prop) => prop.mode === "LIVE" && "green"};
  color: bisque;
  color: ${(prop) => prop.mode === "TEST" && "#000"};
  color: ${(prop) => prop.mode === "LIVE" && "#fff"};
  padding: 0.2rem 1rem;
  border-radius: 4rem;
`;

export const Ribbon = styled.div<StatusBtnT>`
  background: ${color.fountain};
  background: ${(prop) => prop.mode === "TEST" && "orange"};
  background: ${(prop) => prop.mode === "LIVE" && "green"};
  height: 4rem;
  width: 8rem;
  position: absolute;
  bottom: -2rem;
  right: -4rem;
  transform: rotate(-45deg);
`;

export const TopGrid = styled.div`
  padding: 1.6rem 0;
  width: 100%;
  height: 50vh;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 1rem;
  margin-bottom: 2rem;
  @media ${breakpoints.device.max.xs} {
    padding-bottom: 25px;
  }
`;

export const Box = styled.div<BoxPropT>`
  width: 10rem;
  padding: 1.48rem 0.8rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;
  transition: all ease 0.1s;
  cursor: pointer;
  border-radius: 1rem;
  color: ${(prop) => prop.isActive && `#fff`};
  background-color: ${(prop) => (prop.isActive ? `${color.fountain}` : "#fff")};
  border: ${(prop) => prop.isActive && `2px solid ${color.fountain}`};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  :hover {
    /* margin-right: 0.4rem; */
    background: ${color.fountain};
    color: #fff;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const IconContainer = styled.div<BoxPropT>`
  margin-top: 0.4rem;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  background-color: ${(prop) => prop.isActive && `#fff`};
  :hover {
    background-color: #fff;
  }
  img {
    width: 25px;
    height: 25px;
  }
`;

export const BoxTitle = styled.span<BoxPropT>`
  display: block;
  font-size: 14px;
  margin-top: 0.4rem;
  text-align: center;
`;

export const BoxValue = styled.span`
  margin-top: 0.2rem;
  display: block;
  font-size: 1.5rem;
  font-weight: 900;
`;

export const AttachBtn = styled.button`
  background: ${color?.backgroundMedium};
  color: #fff;
  border-radius: 0.2rem;
  padding: 0.6rem 1rem;
  font-size: 12px;
  transition: all ease 0.8s;
  :hover {
    background: ${color.fountain};
    cursor: pointer;
  }
`;

// ========= pricing modal  header tabs =====
export const PricingTabsCont = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
`;

export const PricingTab = styled.div<PriceTabT>`
  background: ${(prop) => (prop.isActive ? color.fountain : "#333")};
  border-radius: 0.2rem;
  color: #fff;
  padding: 0.4rem 1rem;
  cursor: pointer;
  transition: all ease-in-out 0.6s;
`;
