import { useEffect, useRef, useState } from "react";

export const useEffectOnce = (effect: () => void | (() => void)) => {
    const effectFn = useRef(effect)
    const destroyFunc = useRef<void | (() => void)>();
    const effectCalled = useRef(false);
    const renderAfterCalled = useRef(false);
    const [val, setVal] = useState<number>(0);
    const [, refresh] = useState(0)
  
    if (effectCalled.current) {
      renderAfterCalled.current = true;
    }
  
    useEffect(() => {
      // only execute the effect first time around
      if (!effectCalled.current) {
        destroyFunc.current = effectFn.current();
        effectCalled.current = true;
      }
  
      // this forces one render after the effect is run
      //setVal((val: number) => val + 1);
      
      refresh(1)

      return () => {
        if (renderAfterCalled.current === false) return
        if (destroyFunc.current) destroyFunc.current()
      }
    }, []);
  };