import { Calendar, CalendarContainer } from "Report/cbn/styles";
import { useEffect, useState } from "react";

type Props = {
  handleSelection: Function;
  // setStartDate:Function;
  // setFromDate:Function
};

const today = new Date();
today.setDate(1);
// let startDate: any=new Date(today.getFullYear(), today.getMonth() +1, 1)
let days = String(today.getDate()).padStart(2, "0");
let months = String(today.getMonth() + 1).padStart(2, "0");
let years = today.getFullYear();
today.setDate(1);
const startDate = `${years}-${months}-${days}`;

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const day = String(currentDate.getDate()).padStart(2, "0");
const formattedDate = `${year}-${month}-${day}`;

const Daterange = (props: Props) => {
  const [fromDate, setfromDate] = useState<any>(startDate);
  const [toDate, settoDate] = useState<any>(formattedDate);

  const handleDateSelection = () => {
    props.handleSelection(fromDate, toDate);
  };

  useEffect(() => {
    handleDateSelection();
  }, [fromDate, toDate]);

  return (
    <CalendarContainer>
      <Calendar>
        From:
        <input
          value={fromDate}
          type="date"
          min={"2022-08-24"}
          max={formattedDate}
          onChange={(e: any) => {
            setfromDate(e.target.value);
            handleDateSelection();
          }}
        />
      </Calendar>

      <Calendar>
        To:
        <input
          value={toDate}
          max={formattedDate}
          type="date"
          onChange={(e: any) => {
            settoDate(e.target.value);
            handleDateSelection();
          }}
        />
      </Calendar>
    </CalendarContainer>
  );
};

export default Daterange;
