import { useState } from "react";
import { setDatasets } from "react-chartjs-2/dist/utils";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import {
  Heading,
  TableContainer,
  TableDetails,
  TableRootContainer,
  PaginationNext,
  PaginationPrev,
  PaginationButtonsContainer,
  PaginationContainer,
} from "./Styles";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import EmptyState from "shared/components/EmptyState";
// import consoleLog from "shared/utils/consoleLog";

const Table = ({
  headData,
  bodyData,
  hoverLink,
  clickFunction,
  serialNumber,
  currentpage = 0,
  totalPages = 0,
  goToPage,
}: any) => {
  // consoleLog("From", bodyData);
  // const [rowData, setRowData] = useState(bodyData);
  // const [columnOrder, setColumnHeader] = useState("ASC");

  // const sorting = (col: any) => {
  //   consoleLog(col)
  //   consoleLog(rowData)
  //   if (columnOrder === "ASC") {

  //     const sorted = [...rowData].sort((a, b) =>
  //       a[col] > b[col] ? 1 : -1
  //     );
  //     setRowData(sorted)
  //     setColumnHeader("DSC")
  //   }
  //   if (columnOrder === "DSC") {
  //     const sorted = [...rowData].sort((a, b) =>
  //       a[col] < b[col] ? 1 : -1
  //     );
  //     setRowData(sorted)
  //     setColumnHeader("ASC")
  //   }
  // };

  if (typeof currentpage === "string") {
    currentpage = Number(currentpage);
  }

  return (
    <>
      <TableRootContainer>
        <TableContainer>
          <thead>
            <Heading>
              <TableHead key="sn" item="#" />
              {headData.map((data: any) => {
                return (
                  <TableHead
                    key={data.id}
                    item={data.label}

                    // onClick={() => sorting(data.id)}
                  />
                );
              })}
            </Heading>
          </thead>
          {bodyData && bodyData instanceof Array && 
            <TableDetails>

              {bodyData.map((data: any, index: number) => {
                return (
                  <TableRow
                    serialNumber={index + 1}
                    index={index}
                    key={`${index}}a`}
                    data={data}
                    headData={headData}
                    hoverLink={hoverLink}
                    clickFunction={clickFunction}
                  />
                );
              })}
            </TableDetails>
          }
          
        </TableContainer>
      </TableRootContainer>
      {bodyData instanceof Array && bodyData.length > 0 && totalPages > 1 && (
        <PaginationContainer>
          <div></div>

          <PaginationButtonsContainer>
            {currentpage > 1 && (
              <PaginationPrev
                onClick={() => goToPage && goToPage(currentpage - 1)}
              >
                {"< "}Prev
              </PaginationPrev>
            )}
            <div>
              {currentpage} of {totalPages} pages
            </div>
            {totalPages > 1 && (
              <>
                {currentpage < totalPages && (
                  <PaginationNext
                    onClick={() => goToPage && goToPage(currentpage + 1)}
                  >
                    Next {` >`}
                  </PaginationNext>
                )}
              </>
            )}
          </PaginationButtonsContainer>
        </PaginationContainer>
      )}
    </>
  );
};

export default Table;
