import React from "react";
import { Box, BoxTitle, BoxIcon, BoxValue } from "./Styles";

type SingleBoxPropT = {
  boxdata: {
    id: number;
    title: string;
    value?: number;
    icon?: string;
    background: boolean;
    urlPath: string;
  };
  variant?: boolean;
};

export default function SingleBox({ boxdata, variant }: SingleBoxPropT) {
  return (
    <>
      <Box background={boxdata.background}>
        {boxdata.icon && <BoxIcon src={boxdata?.icon} />}
        <BoxTitle>{boxdata?.title}</BoxTitle>
        <BoxValue>{boxdata?.value}</BoxValue>
      </Box>
    </>
  );
}
