import BreakPoints from "shared/utils/Breakpoint";
import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color } from "../../../shared/utils/styles";

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;

  @media ${breakpoints.device.max.xs} {
    display: block;
  }
`;

export const Container = styled.div`
  margin-bottom: 4rem;
  margin-top: -4rem;
  padding: 2rem 5rem;
  height: 50%;
  width: auto;
  background: #fff;
  border-radius: 1rem;

  @media (${BreakPoints.xs}) {
    margin-top: 0.6rem;
    padding: 2rem 0.5rem;
    /* border: 5px solid red; */
  }
`;

export const PiechartContainer = styled.div`
  width: 34%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-bottom: 25px;
  }
`;
export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
  /* background-color: #fff; */
`;
export const ViewAllBtn = styled.button`
  padding: 1rem 4rem;
  color: white;
  background-color: ${color.fountain};
  border-radius: 0.4rem;

  &:hover {
    cursor: pointer;
  }
`;
