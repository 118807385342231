import { useState } from "react";
import { Modal } from "shared/components";
import Filter from "shared/components/Filter";
import { fieldSorter } from "shared/utils/url";
import { FinanceData } from "../Data";
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
} from "../SecondSection/TableSection/Styles";
import {
  ModalContentLeft,
  ModalContentRight,
  ModalContentRow,
  PageLayout,
  TableContainer,
  TableContent,
  TableContentMain,
  TableHeader,
  TableRow,
  TicketIDBadge,
  TransactionModal,
} from "./Styles";

const Finance = ({ finance, handleSwitch }: any) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <>
        <TableRow>
          <TableContentMain>
            <TicketIDBadge onClick={() => setShowModal(true)}>
              {finance.transactionId}
            </TicketIDBadge>
          </TableContentMain>
          <TableContentMain>{finance.description}</TableContentMain>
          <TableContentMain>{finance.type}</TableContentMain>
          <TableContentMain>{finance.amountPaid}</TableContentMain>
          <TableContentMain>{finance.timeDate}</TableContentMain>
          <TableContentMain>
            <CheckBoxWrapper>
              <CheckBox
                id={finance.id}
                type="checkbox"
                checked={finance.activated}
                onChange={() => handleSwitch(finance.transactionId)}
              />
              <CheckBoxLabel
                htmlFor="checkbox"
                onClick={() => handleSwitch(finance.transactionId)}
              />
              <span onClick={() => handleSwitch(finance.transactionId)}>
                Release
              </span>
            </CheckBoxWrapper>
          </TableContentMain>
        </TableRow>
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(!showModal)}
          withCloseIcon={true}
          renderContent={() => (
            <TransactionModal>
              <ModalContentRow>
                <ModalContentLeft>Transaction Id: </ModalContentLeft>
                <ModalContentRight>{finance.transactionId} </ModalContentRight>
              </ModalContentRow>
              <ModalContentRow>
                <ModalContentLeft>Email of Payee: </ModalContentLeft>
                <ModalContentRight>larrysnet2001@gmail.com</ModalContentRight>
              </ModalContentRow>
              <ModalContentRow>
                <ModalContentLeft>Amount Paid: </ModalContentLeft>
                <ModalContentRight>{finance.amountPaid} </ModalContentRight>
              </ModalContentRow>
              <ModalContentRow>
                <ModalContentLeft>Time: </ModalContentLeft>
                <ModalContentRight>{finance.timeDate} </ModalContentRight>
              </ModalContentRow>
              <ModalContentRow>
                <ModalContentLeft>Date: </ModalContentLeft>
                <ModalContentRight>{finance.timeDate} </ModalContentRight>
              </ModalContentRow>
              <ModalContentRow>
                <ModalContentLeft>Date: </ModalContentLeft>
                <ModalContentRight>{finance.type} </ModalContentRight>
              </ModalContentRow>
            </TransactionModal>
          )}
        />
      </>
    </div>
  );
};

const filterOptions = [
  {
    name: "amountPaid",
    label: "Amount Paid",
    checked: false,
  },
  {
    name: "activated",
    label: "Release",
    checked: false,
  },
  {
    name: "timeDate",
    label: "Date",
    checked: false,
  },
  {
    name: "type",
    label: "Payment Type",
    checked: false,
  },
];

export default function AllFinance() {
  const [filterBy, setFilterBy] = useState(filterOptions);
  // const [disputeList, setDisputeList] = useState(FinanceData);
  const [finances, setFinances] = useState(FinanceData);

  const handleSwitch = (id: any) => {
    let newData = FinanceData.map((finance) => {
      if (finance.transactionId == id) {
        finance.activated = !finance.activated;
      }
      return finance;
    });
    setFinances(newData);
  };

  const handleFilter = (data: any) => {
    const filterData = data.filter((option: any) => option.checked);
    const sortDataBy = filterData.map((option: any) => option.name);
    const unSortDataBy = filterOptions.map((option: any) => option.name);
    const newDisputeData =
      sortDataBy.length > 0
        ? finances.sort(fieldSorter(sortDataBy))
        : finances.sort(fieldSorter(unSortDataBy));
    setFinances(newDisputeData);
    setFilterBy(data);
  };

  return (
    <>
      <Filter
        onChange={handleFilter}
        options={filterBy}
        title="All Transations"
      />
      <PageLayout>
        <TableContainer>
          <TableHeader>
            <TableContent>Transaction ID</TableContent>
            <TableContent>Transaction Description</TableContent>
            <TableContent>Transaction Type</TableContent>
            <TableContent>Amount</TableContent>
            <TableContent>Time/Date</TableContent>
            <TableContent>Release or Delay Settlement</TableContent>
          </TableHeader>
          {finances.map((finance) => (
            <Finance
              key={finance.id}
              handleSwitch={handleSwitch}
              finance={finance}
            />
          ))}
        </TableContainer>
      </PageLayout>
    </>
  );
}
