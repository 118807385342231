import React, { useState } from "react";
import "chart.js/auto";
import colorMixer from "shared/utils/mixColors";
import { Line, Doughnut, Pie } from "react-chartjs-2";
import { color } from "../../../../shared/utils/styles";
import { Select, Option } from "shared/quickComponents/select";
import { Data } from "./Data";
import {
  Box,
  ChartDetails,
  ChartLabelContainer,
  GraphContent,
  GraphHeader,
} from "../Styles";

type ChartPropT = {
  id: number;
  month: string;
  transaction: number;
  userLost: number;
}[];

// const businessData = [
//   { id: 1, BusinessName: "Fashion", Value: 100 },
//   { id: 2, BusinessName: "Technology", Value: 200 },
//   { id: 3, BusinessName: "Agriculture", Value: 190 },
//   { id: 4, BusinessName: "Others", Value: 590 },
// ];

// function shuffleArray(array: any) {
//   for (var i = array.length - 1; i > 0; i--) {
//     var j = Math.floor(Math.random() * (i + 1));
//     var temp = array[i];
//     array[i] = array[j];
//     array[j] = temp;
//   }
// }

// const colorsArr = ["rgb(255, 20, 86)","rgb(255, 100, 86)"];

// const businessDataSort = businessData.sort((a, b) => b.Value - a.Value);
// const chartValues = businessData.map((data) => data.Value);

export default function DonutChart({ data }: any) {
  if (!(data instanceof Array)) return null;
  let datasetsArr = [];
  let datalabel = [];
  let colorsArr = ["rgb(240, 20, 200)"];
  let ind = 0;
  for (let el of data) {
    datalabel.push(el.name);
    datasetsArr.push(el.total);
    if (ind > 0) {
      colorsArr.push(colorMixer(colorsArr[ind - 1], 30));
    }
    ind++;
  }

  const userData = {
    labels: datalabel,
    datasets: [
      {
        label: "My First Dataset",
        data: datasetsArr, //feeding the chart with business data values array
        backgroundColor: colorsArr,
        hoverOffset: 4,
      },
    ],
  };

  function kFormatter(num: any) {
    let exp = (Math.sign(num) * Math.abs(num)) / 1000;
    return Math.abs(num) > 999
      ? ` ${exp.toFixed(1)}k`
      : Math.sign(num) * Math.abs(num);
  }

  const options = {
    plugins: { legend: { display: false } },
  };

  const detailsList = datalabel.map((business, i) => (
    <ChartDetails key={business}>
      <div
        style={{
          display: "flex",
          width: "100%",
          // gap: "0.3rem",
          alignItems: "center",
          flexDirection: "row",
          marginRight: 10,
        }}
      >
        <Box bgColor={colorsArr[i]}></Box>
        <div style={{ width: "100%" }}>{business}</div>
      </div>
    </ChartDetails>
  ));
  // // console.log(userData, "userData");
  return (
    <>
      <GraphHeader>
        <h4>Business based on industry</h4>
      </GraphHeader>
      <GraphContent>
        <Doughnut data={userData} options={options} />
      </GraphContent>
      <ChartLabelContainer>{detailsList}</ChartLabelContainer>
    </>
  );
}
