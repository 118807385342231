import api from "shared/utils/api";

class AgencyBanking {
  // @Desciption: To get paginated list of agents
  getAllAgents = async (currentPage = 1) => {
    try {
      const response = await api.get(`/agents?page=${currentPage}`);
      console.log("all agents", response);
      if (response?.status) return response?.payload;
    } catch (error) {}
  };

  // @Desciption: To get data for chart
  getChartData = async (filter = "week") => {
    try {
      const response = await api.get(`/agents-line-chart?filter=${filter}`);
      console.log("chart data", response);
      if (response?.payload) return response?.payload;
    } catch (error) {}
  };
}

const AgencyBankingObj = new AgencyBanking();

export default AgencyBankingObj;
