import styled from "styled-components";

import { sizes } from "shared/utils/styles";

const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

export const DashboardPage = styled.div`
  padding: 0rem 32px 50px ${paddingLeft - sizes.secondarySideBarWidth - 110}px;
  /* margin-left: -38px; */
  margin-top: 10px;
  width: 100%;
  @media (max-width: 1100px) {
    padding: -25px 20px 50px ${paddingLeft - 20}px;
  }
  @media (max-width: 999px) {
    /* padding-left: ${paddingLeft - 20 - sizes.secondarySideBarWidth}px; */
		padding: 0px 15px;
  }
`;
