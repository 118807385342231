import React from "react";
import { FormSelect, InputCont } from "./Styles";

type SelectPropT = {
  label: string;
  value: any;
  disabled?: boolean;
  setValue: Function;
  data: {
    value: string;
    name?: string
    label: string;
  }[];
  //   value: any;
  //   handleChange: Function;
};

export default function SelectField({
  label,
  data,
  disabled,
  value,
  setValue,
}: SelectPropT) {
  return (
    <div>
      <InputCont>
        <label>{label}:</label>
        <FormSelect
          disabled={disabled}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            setValue(event.target.value)
          }
        >
          {data.length &&
            data.map((option, ind) => (
              <option value={option.value} key={ind}>{option.label?option.label:option.name}</option>
            ))}
        </FormSelect>
      </InputCont>
    </div>
  );
}
