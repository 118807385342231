import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import { toast } from "react-toastify";

class roleApiClass {
  async fetchUsers() {
    try {
      const response = await api.get("/users");
      consoleLog(response, "response");

      if (response.status && response.payload instanceof Array) {
        return response.payload;
      } else {
        toast.error("Unable to fetch Users");
        return false;
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      return false;
    }
  }

  async fetchDepts() {
    try {
      const response = await api.get("/units");
      consoleLog(response, "responseUnits");
      //   const response = await api.get("/units");
      //   const response = await api.get("/roles");
      // consoleLog("Departments", response);
      if (response.status && response.payload instanceof Array) {
        return response.payload;
      } else {
        toast.error("Unable to fetch departments");
        return false;
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      return false;
    }
  }

  async fetchDeptsChildren() {
    try {
      const response = await api.get(`/units/children`);
      consoleLog(response, "response");

      if (response.status && response.payload instanceof Array) {
        return response.payload;
      } else {
        toast.error("Unable to fetch departments Children");
        return false;
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      return false;
    }
  }

  findParentUnit(arr: any[], unitId: string) {
    for (let el of arr) {
      if (el.unitId === unitId) {
        let val = el?.Unit?.parentId ? el?.Unit?.parentId : unitId;

        return val;
      }
    }

    return "";
  }

  async fetchSingleUnit(id: string) {
    try {
      let response: any;
      response = await api.get(`/units/${id}`);
      consoleLog(response, "Resp");
      if (response.status && response.payload instanceof Object) {
        return response.payload;
      } else {
        toast.error("Unable to fetch  Item Details");
        return false;
      }

      consoleLog(response, "response");
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      return false;
    }
  }

  async addDept(obj: any, chgStatus = false) {
    try {
      let response: any;
      let reqObj: any = { name: obj.name };
      if (chgStatus) {
        reqObj = { id: obj?.id, status: obj?.status };
      } else {
        if (
          String(obj.name)
            .trim()
            .toLowerCase() === "main unit"
        ) {
          toast.error("Main unit is a reserved word");
          return false;
        }

        if (obj?.parentId && String(obj.parentId).trim() !== "") {
          reqObj.parentId = obj?.parentId;
        }
      }

      consoleLog(reqObj, "request object");
      if (obj?.id) {
        // reqObj.parentId = reqObj.parentId ?? "";
        response = await api.put(`/units/${obj?.id}`, reqObj);
        // inherit  roles from parent
        await this.inheritRoles(obj?.parentId, obj?.id, true);
      } else {
        response = await api.post(`/units`, reqObj);

        // inherit  roles from parent
        consoleLog(response, "POST"); //get the ID instead
        let fullRecords = await this.fetchDepts();
        if (fullRecords instanceof Array) {
          fullRecords.sort(
            (a: any, b: any) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
          let newRecord = fullRecords[0].id;
          await this.inheritRoles(obj?.parentId, newRecord);
        }
      }

      if (response?.status) {
        toast.success(response.message);
        return true;
      } else {
        toast.error(response.message);
        return false;
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      return false;
    }
  }

  async inheritRoles(parentId?: string, childId?: string, newrec = false) {
    try {
      let addObj: any = {};
      if (newrec) {
        let rec: any = await this.fetchSingleUnitRole(String(childId));
        addObj.id = rec.id;
      }
      consoleLog(parentId, childId, "Inherittt");
      if (!parentId) {
        ///very rare ... if there is no parent
        await this.addRoles({
          unitId: childId,
          permissions: { allOpen: true },
          menus: { allOpen: true },
          ...addObj,
        });
      } else {
        let {
          permissions: parentPermissions,
          menus: parentMenus,
        }: any = await this.fetchSingleUnitRole(String(parentId));

        let {
          permissions: childCurrentPermissions,
          menus: childCurrentMenus,
        }: any = await this.fetchSingleUnitRole(String(childId));
        consoleLog(
          childCurrentPermissions,
          parentPermissions,
          "childCurrentPermissions"
        );

        if (parentPermissions) {
          let childMenus = this.compareParentAndChild(
            parentMenus,
            childCurrentMenus
          );

          await this.addRoles({
            unitId: childId,
            permissions: childCurrentPermissions,
            menus: childMenus,
            ...addObj,
          });
        }
      }
      await this.updateChildrenRoles(String(childId));
    } catch {}
  }

  async recursiveChildrenPermissions(arr: any, parentId: string) {
    let children = arr.find((el: any) => el.id === parentId)?.children;
    consoleLog(children, "Childddreen", arr, parentId);
    // return;
    for (let el of children) {
      if (
        el.roles instanceof Array &&
        el.roles[0]?.permissions &&
        el.roles[0]?.permissions instanceof Array &&
        el.roles[0]?.permissions[0] instanceof Object
      ) {
        await this.inheritRoles(parentId, el.id, true);
        await this.recursiveChildrenPermissions(arr, el.id);
      }
    }
  }

  async updateChildrenRoles(parentId: string) {
    try {
      const response = await api.get(`/units/children`);
      consoleLog(response, "updatteeeChill ");
      if (response.payload instanceof Array) {
        await this.recursiveChildrenPermissions(response.payload, parentId);
      }
      consoleLog(response, "updateChildrenRoles");
    } catch {}
  }

  async addRoles(obj: any, updateChildren = false) {
    try {
      consoleLog("adding roles", obj);
      const reqObj = {
        // unitId: obj?.unit?.id ?? obj?.unitId,
        unitId: obj?.unitId,
        permissions: [obj?.permissions, obj?.menus],
        id: obj?.id,
      };
      const response = obj?.id
        ? await api.put(`/roles/${obj?.id}`, reqObj) //update children permissions
        : await api.post("/roles", reqObj);
      if (obj?.id && updateChildren) {
        await this.updateChildrenRoles(reqObj?.unitId);
      }
      consoleLog(response, "Response");
      if (response?.status) {
        toast.success(response.message);
        return true;
      }
      toast.error(response.message);
      return false;
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      return false;
    }
  }

  async fetchSingleUnitRole(id: string) {
    try {
      let response: any;
      response = await api.get(`/roles?unit_id=${id}`);
      consoleLog(response, "RespUnit");
      if (response.status && response.payload instanceof Object) {
        if (response.payload.permissions instanceof Array) {
          return {
            id: response.payload.id,
            permissions: response.payload.permissions[0],
            menus: response.payload.permissions[1],
          };
        } else {
          return false;
        }
      } else {
        toast.error("Unable to fetch  Item Details");
        return false;
      }

      consoleLog(response, "response");
    } catch (error) {
      // @ts-ignore
      // toast.error(error);
      return false;
    }
  }

  async fetchRoles() {
    try {
      const response = await api.get("/roles");
      if (response?.status) {
        return response?.payload;
      }
    } catch (error) {}
  }

  async fetchUser(id: string) {
    try {
      const response = await api.get(`/users/${id}`);
      if (response?.status) {
        return response?.payload;
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      return {};
    }
  }

  compareParentAndChild(parent: any, child?: any) {
    if (!(parent instanceof Object)) parent = {};
    if (!(child instanceof Object)) child = {};

    let newObj: any = {};

    for (let el in parent) {
      if (parent[el] === true) {
        if (child[el] !== false && child[el] !== true) {
          newObj[el] = true;
        } else {
          newObj[el] = child[el];
        }
      }
    }

    return newObj;
  }
}

const roleApi = new roleApiClass();
export default roleApi;
