import React, { useState } from "react";
import "chart.js/auto";
import { Line, Doughnut, Pie } from "react-chartjs-2";
import { color } from "../../../../shared/utils/styles";
import { Select, Option } from "shared/quickComponents/select";
import { Data } from "./Data";
import {
  Box,
  ChartDetails,
  ChartLabelContainer,
  GraphContainer,
  GraphContent,
  GraphHeader,
} from "../Styles";

type ChartPropT = {
  id: number;
  month: string;
  transaction: number;
  userLost: number;
}[];

// const businessData = [
//   { id: 1, StatusName: "Pending", Value: 400 },
//   { id: 2, StatusName: "Failed", Value: 200 },
//   { id: 3, StatusName: "Successful", Value: 90 },
// ];

// function shuffleArray(array: any) {
//   for (var i = array.length - 1; i > 0; i--) {
//     var j = Math.floor(Math.random() * (i + 1));
//     var temp = array[i];
//     array[i] = array[j];
//     array[j] = temp;
//   }
// }

// const colorsArr = ["#219653", "#FF2020", "#F4BE37"];

// const businessDataSort = businessData.sort((a, b) => b.Value - a.Value);
// const chartValues = businessDataSort.map((data) => data.Value);
// const chartLabel = businessDataSort.map((data) => data.StatusName);

export default function PieChart({ data }: any) {
  if (!(data instanceof Object)) return null;
  let colorsArr: any = []; //["#219653", "#FF2020", "#F4BE37"];
  let datasetsArr = [];
  let datalabel = [];
  const getRandVal = () => Math.floor(Math.random() * 255);
  for (let el of Object.keys(data)) {
    let str = el?.trim().toLowerCase();
    datalabel.push(`${str[0].toUpperCase()}${str.substring(1)}`);
    datasetsArr.push(data[el]);
    switch (str) {
      case "inactive":
        colorsArr.push("#FF2020");
        break;
      case "active":
        colorsArr.push("#219653");
        break;
      case "suspended":
        colorsArr.push("#F4BE37");
        break;
      default:
        colorsArr.push(`rgb(${getRandVal()},${getRandVal()},${getRandVal()}`);
    }
  }
  const userData = {
    labels: datalabel,
    datasets: [
      {
        label: "My First Dataset",
        data: datasetsArr, //feeding the chart with business data values array
        backgroundColor: colorsArr,
        hoverOffset: 4,
      },
    ],
  };

  function kFormatter(num: any) {
    let exp = (Math.sign(num) * Math.abs(num)) / 1000;
    return Math.abs(num) > 999
      ? ` ${exp.toFixed(1)}k`
      : Math.sign(num) * Math.abs(num);
  }

  const options = {
    plugins: { legend: { display: false } },
  };

  const detailsList = datalabel.map((business, i) => (
    <ChartDetails key={business}>
      <div style={{ display: "flex", gap: "0.3rem", alignItems: "center" }}>
        <Box bgColor={colorsArr[i]}></Box>
        <span>{business}</span>
      </div>
    </ChartDetails>
  ));
  return (
    <>
      <GraphHeader>
        <h4>Business based on status</h4>
      </GraphHeader>
      <GraphContent>
        <Pie data={userData} options={options} />
      </GraphContent>
      <ChartLabelContainer>{detailsList}</ChartLabelContainer>
    </>
  );
}
