import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import useApi from "shared/hooks/api";
import { toast } from "react-toastify";
import { getCurrentUser } from "shared/utils/authToken";
import Spinner from "shared/components/Spinner";
import {
  Container,
  PageContent,
  PageHeader,
  PageHeaderSearch,
  Pagetitle,
  TableActions,
  TotalRecord,
} from "Pricing/Styles";
import Table from "shared/clientcomponents/Table";
import Switch from "shared/components/Switch";
import EmptyState from "shared/components/EmptyState";
import Modal from "shared/quickComponents/modal";
import {
  ModalButton,
  ModalButtonsContainer,
  ModalContent,
  OtpContainer,
  SelectContainer,
  SubTitle,
  Title,
} from "./Styles";
import { pricingOBJ } from "Pricing/pricingClass";
import { GradientLoader } from "shared/components";
import OTPInput from "shared/components/OTPInput";
import { useEffectOnce } from "shared/hooks/useEffectOnce";
import { ViewBtn } from "Kyc/Styles";

type businessPropT = {
  id: any;
  sn: any;
  businessName: string;
  businessID: string;
  isActive: boolean;
}[];

type PropT = {
  [x: string]: any;
};

export default function Single() {
  const [planName, setplanName] = useState("");
  const [pricingPlans, setpricingPlans] = useState([]);
  const [isOTPError, setisOTPError] = useState(false);
  const [businessData, setbusinessData] = useState([]);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [seletedData, setseletedData] = useState("");
  const [searchData, setsearchData] = useState("");
  const [isOtpModalOpen, setisOtpModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [showIndicator, setshowIndicator] = useState(false);
  const [Otp, setOtp] = useState("");
  const [tempId, settempID] = useState("");
  const [action, setaction] = useState("");

  const { id } = useParams<{ id: string }>();
  const currentUser = getCurrentUser(); //Get current login user
  const history = useHistory();

  const [{ data, error, setLocalData }, fetchData] = useApi.get(
    `/pricing/${id}/service`
  );

  const tableHeader: any = [
    // { id: "sn", label: "#" },
    { id: "serviceID", label: "Service ID" },
    { id: "serviceName", label: "Service Name" },
    { id: "funcProps", label: "" },
  ];

  // =========================== actions ===========

  const filterBusinesses = () => {
    const newlist = businessData.filter((business: any) => {
      if (business.businessId.indexOf(searchData) > -1) return business;
    });
    if (newlist.length) setbusinessData(newlist);
    if (!searchData) setbusinessData(data?.payload);
  };

  // ===============================================
  const handleNavigate = (service: any) => {
    history.push(`/business/overview/business/service`, {
      serviceId: service?.uuid,
    });
  };
  const businessList = businessData?.map((business: any, index: number) => ({
    id: index + 1,
    uuid: business?.id,
    sn: index + 1,
    serviceName: business?.name,
    serviceID: business?.serviceId,
    // funcProps: <ViewBtn onClick={() => handleNavigate(business)}>view</ViewBtn>,
  }));

  const hoverLink = (elm: PropT) => {
    history.push(`/business/overview/${elm?.busId}`);
  };

  //controlling confirmation modal
  const handleCloseModal = () => {
    setisModalOpen(false);
    fetchData();
  };

  //Handling 'No' button on confrimation modal
  const handleNoModalButton = () => {
    setisModalOpen(false);
    setbusinessData(data?.payload);
  };

  //Controlling otp modal
  const openModal = () => {
    setisOtpModalOpen(!isOtpModalOpen);
  };

  //Handling 'Yes'  button on confrimation modal
  const handleYesModalButton = async () => {
    setshowIndicator(true);
    const selected = seletedData.split(",", 3);
    //send otp
    const adminEmail = currentUser.email;
    const businessId = selected[0];
    const pricingId = selected[2];
    const response = await pricingOBJ.sendOTP({
      adminEmail,
      businessId,
      pricingId,
    });
    if (response?.payload) {
      settempID(response?.payload?.tempId);
      setaction("UPDATE_BUSINESS_PRICING_PLAN");
      toast.success("OTP sent successfully!.");
      setisModalOpen(false);
      setshowIndicator(false);
      openModal();
    } else {
      toast.error("OTP not sent, try again later!.");
      setshowIndicator(false);
      setisModalOpen(false);
    }
  };

  //====== Handle OTP ======
  const handleChange = async (otp: string) => {
    setOtp(otp);
    if (otp.length === 6) {
      // // console.log(otp, action, tempID);
      const response = await pricingOBJ.confirmOTP({ otp, action, tempId });
      if (response?.status) {
        openModal();
        toast.success(response?.message);
        await fetchData();
      }
    }
  };

  //========= Get pricing plans ============
  const getPricingPlans = async () => {
    const response = await pricingOBJ.getAllPricing();
    setpricingPlans(response?.data);

    const currentPlan = response?.data?.filter((plan: any) => plan.id === id);
    // console.log("filtered plans on single biz", currentPlan, id);
    // console.log("plans on single biz", response?.data);
    if (currentPlan.length) setplanName(currentPlan[0]?.name);
  };

  //====== Get pricing plans effect on mount =====
  useEffectOnce(() => {
    getPricingPlans();
  });

  //======= Checking if data effect on mount ======
  useEffect(() => {
    if (!data) setisLoading(true);
    if (error) setisLoading(false);
    if (data) {
      setbusinessData(data?.payload);
      setisLoading(false);
      filterBusinesses();
      console.log("single plan", data);
    }
  }, [data, error, searchData]);

  return (
    <>
      {/* ==== Confirmation modal ====== */}
      <Modal isOpen={isModalOpen} closeButton={true} onClose={handleCloseModal}>
        <ModalContent>
          <p>Do you want to change the pricing plan on this business?</p>

          <ModalButtonsContainer>
            <ModalButton onClick={handleYesModalButton}>
              {showIndicator ? <Spinner color="#fff" /> : "Yes"}
            </ModalButton>
            <ModalButton onClick={handleNoModalButton} option={true}>
              No
            </ModalButton>
          </ModalButtonsContainer>
        </ModalContent>
      </Modal>
      {isLoading ? (
        <GradientLoader />
      ) : (
        <Container>
          {businessData.length > 0 && (
            <PageHeader>
              <div>
                <Pagetitle>{planName?.toLocaleUpperCase()}</Pagetitle>
                <TotalRecord>Total: {businessData.length}</TotalRecord>
              </div>
              <form>
                <PageHeaderSearch
                  placeholder="Business ID or Name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setsearchData(event.target.value);
                  }}
                />
              </form>
            </PageHeader>
          )}

          <PageContent>
            {businessData.length > 0 ? (
              <Table
                headData={tableHeader}
                bodyData={businessList}
                hoverLink={handleNavigate}
              />
            ) : (
              <EmptyState stateTitle="No Data" statePara="" stateBtnText="" />
            )}
          </PageContent>
        </Container>
      )}

      {/* =======OTP MODAL */}
      <Modal
        isOpen={isOtpModalOpen}
        closeButton={true}
        onClose={openModal}
        width="40%"
      >
        <div style={{ paddingBottom: "2rem" }}>
          <Title>Check your email for OTP</Title>
          <SubTitle>
            We have sent a 6 - digit code to your email address. <br /> The code
            expires shortly, please enter it soon.
          </SubTitle>
          <OtpContainer>
            {" "}
            <OTPInput
              autoFocus
              isNumberInput
              length={6}
              hasError={isOTPError}
              errorStyle={{
                borderColor: "red",
              }}
              inputStyle={{
                border: "1px solid #828282",
                width: "60px",
                height: "60px",
                fontSize: "30px",
                color: "#000",
                fontWeight: "400",
                caretColor: "#000",
                textAlign: "center",
                borderRight: "0px",
              }}
              focusStyle={
                {
                  //border: "1px solid #CFD3DB",
                }
              }
              firstIndexStyle={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderRight: "0px",
              }}
              lastIndexStyle={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderRight: "1px solid #828282",
                borderLeft: "1px solid #828282",
              }}
              className="otpContainer"
              inputClassName="otpInput"
              separator={
                <span
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "10%",
                    fontSize: "50px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  -
                </span>
              }
              onChangeOTP={handleChange}
            />
          </OtpContainer>
        </div>
      </Modal>
    </>
  );
}
