import React from "react";
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
  TableContent,
  TableRow,
} from "./Styles";

type AccountRowProp = {
  Account: {
    id: number;
    AccountName: string;
    phoneNumber: string;
    email: string;
    activated: boolean;
  };
  toogleswitch: Function;
};

export default function AccountRow({ Account, toogleswitch }: AccountRowProp) {
  return (
    <>
      <TableRow key={Account.id}>
        <TableContent>{Account.AccountName}</TableContent>
        <TableContent width="25%">{Account.phoneNumber}</TableContent>
        <TableContent>{Account.email}</TableContent>
        <TableContent width="12%" alignEnd={true}>
          <CheckBoxWrapper>
            <CheckBox
              id="checkbox"
              type="checkbox"
              checked={Account.activated}
              onChange={() => console.log("")}
            />
            <CheckBoxLabel
              htmlFor="checkbox"
              onClick={() => toogleswitch(Account.id)}
            />
          </CheckBoxWrapper>
        </TableContent>
      </TableRow>
    </>
  );
}
