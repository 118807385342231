import React from "react";
import { TableLinkTdwContainer } from "../TableRow/Style";

const TableHead = ({ item, border, maxwidth, ...props }: any) => {
  return (
    <TableLinkTdwContainer
      title={item}
      border={border}
      maxwidth={maxwidth}
      {...props}
    >
      {item}
    </TableLinkTdwContainer>
  );
};

export default TableHead;
