import { useEffect, useState } from "react";

import {
  CardFooter,
  ContentHeader,
  ContentRow,
  InnerPanelLayout,
  PanelTitle,
  ViewAll,
} from "./Style";
import { useSelector } from "react-redux";
import api from "shared/utils/api";
import reportObj from "Report/report-class";
import { useHistory } from "react-router-dom";
import { Spinner } from "shared/components";


const PendingSettlement = () => {
  const [loading, setLoading] = useState<any>([]);
  const [datas, setData] = useState<any>([]);
  const history=useHistory();

  const getSettlement = async () => {
    setLoading(true)
    try {
      const response = await reportObj.getSettlement();
      console.log("Settlements: ", response)
      
      if (
        response instanceof Array
      ) {
        setData(response);
      } else {
        setData([]);
      }

      //consoleLog(response, "responseServ");
    } catch (err) {
      //consoleLog(err, "error");
      setData([]);
      // @ts-ignore
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  const truncate=(item: string)=> {
    if(!item){
      return "";
    }

    const str: string=item.length > 15? item.substring(0, 15) + "...": item;

    return(
      <>
        {str}
      </>
    )
  }
  
  const linkUrlPath = () => {
    history.push(`${history?.location?.pathname}/details/settlements`);
  };

  const arrayDataItems = datas.map((item: any, id: number) => (
    <ContentRow key={id}>
      <span>{truncate(item?.Service?.name)}</span>
      <span>{item?.paymentCurrency}{parseFloat(item?.amount).toFixed(2)}</span>
    </ContentRow>
  ));
  
  useEffect(() => {
    getSettlement();
  }, []);
  
  return (
    <InnerPanelLayout>
      <PanelTitle>Settlements</PanelTitle>
      <ContentHeader>
        <span>Business</span>
        <span>Total volume</span>
      </ContentHeader>
      {loading && <Spinner size={30} />}
      {!loading && datas && arrayDataItems}
      
      <ViewAll onClick={()=>linkUrlPath()}>View all</ViewAll>
    </InnerPanelLayout>
  );
};

export default PendingSettlement;
