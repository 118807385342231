import React, { useLayoutEffect, useState, useEffect } from "react";
import { useParams, useHistory, Link, useRouteMatch } from "react-router-dom";
import businessOBJ from "Business/businessClass";
import Table from "shared/clientcomponents/Table";
import GridSection from "./GridSection";
import api from "shared/utils/api";
import EmptyState from "../../shared/components/EmptyState";
import useApi from "shared/hooks/api";
import {
  PaginationButtonsContainer,
  PaginationContainer,
  PaginationNext,
  PaginationPrev,
  TableActions,
  ViewAllBtn,
} from "./Styles";
import { GradientLoader } from "shared/components";
import {
  ServiceTableHead,
  CustomersTableHead,
  TransactionTableHead,
  accDetailsTableHead,
  totalSettlementHead,
  subAccountTableHead,
  disputeTableHead,
} from "../businessTableHeader";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import logger from "shared/utils/logger";
import { getClientDetails } from "shared/utils/location";
import { getCurrentUser } from "shared/utils/authToken";
import { numberFormat } from "shared/utils/utility";

type PropT = {
  [x: string]: any;
};

export default function Single() {
  const [tabSelected, settabSelected] = useState("");
  const [tableHeader, settableHeader] = useState<any[]>([]);
  const [tableBody, settableBody] = useState<any[]>([]);
  const [businessName, setbusinessName] = useState<string>("");
  const [isloading, setIsloading] = useState(false);
  const [noRecord, setNoRecord] = useState(true);
  const [businessData, setbusinessData] = useState<any>([]);
  const [isPaginate, setisPaginate] = useState(false);
  const [businessDetails, setbusinessDetails] = useState<any>({});

  const [totalPage, setTotalPage] = useState(0);
  const [tableName, settableName] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [tableLoading, settableLoading] = useState(false);
  const [serviceList, setseriviceList] = useState([]);

  const history = useHistory();
  const { id: busId } = useParams<{
    id: string;
  }>();
  const match = useRouteMatch();
  const currentUser = getCurrentUser();

  //@desc: These are to prevent api calls until endpoints are ready
  const data = { payload: { status: "" } };
  const error = "";
  const fetchData = () => "";
  // const [{ data, error, setLocalData }, fetchData] = useApi.get(
  //   `/business/${busId}`
  // );

  const handleLog = async () => {
    // console.log("logging on bussiness");
    await logger.logActivities("remote", {
      date: new Date().toString(),
      author: currentUser.fullName,
      resource: "admin",
      action: "MERCHANT",
      payload: {
        userAgent: getClientDetails(),
        data: {
          detail: `${currentUser.fullName} views/opens ${businessName}for analysis`,
        },
      },
    });
  };
  //============ Get services ===========
  const getServices = () => {
    // console.log("services called", serviceList);

    const handleNavigate = (businessid: any, serviceId: any) => {
      history.push(`/business/overview/business/service`, {
        businessName,
        businessid,
        serviceId,
      });
    };

    const services = serviceList.map((service: any, index: number) => ({
      id: index + 1,
      sn: service.serviceId,
      serviceName: service.name,
      isActive: "is active",
      funcProps: (
        <TableActions>
          <ViewAllBtn onClick={() => handleNavigate(busId, service.id)}>
            View
          </ViewAllBtn>
        </TableActions>
      ),
    }));

    settableHeader(ServiceTableHead); //set table header
    settableBody(services); //set table body
  };
  // ==========================================

  //======= Get Customers ===================
  const getCustormers = async () => {
    const response = await businessOBJ.getCustomers("business", busId);
    console.log("customers data:", response);
    if (response.length) {
      settableLoading(false);
      const customers = response?.map((customer: any, index: number) => ({
        id: index + 1,
        sn: customer?.Services?.serviceId,
        serviceName: customer?.Services?.name,
        fullName: customer?.customer?.fullname,
        email: customer?.customer?.email,
        phoneNumber: customer?.customer?.phone,
      }));
      settableHeader(CustomersTableHead); //set table header
      settableBody(customers); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };

  // ========================================

  //====== Get transactions =================
  const getTransactions = async () => {
    const response = await businessOBJ.getTransactions(
      "business",
      busId,
      currentPage
    );
    if (response?.data?.length) {
      settableLoading(false);
      setcurrentPage(response?.currentPage);
      setTotalPage(response?.totalPages);

      const transactions = response?.data?.map(
        (transaction: any, index: number) => ({
          id: index + 1,
          sn: transaction?.Services?.serviceId,
          serviceName: transaction?.Services?.name,
          amount: numberFormat(transaction?.amount ? transaction?.amount : 0),
          dateAndTime: transaction.createdAt,
          status: transaction.status,
          funcProps: (
            <TableActions>
              <ViewAllBtn>View</ViewAllBtn>
            </TableActions>
          ),
        })
      );
      settableHeader(TransactionTableHead); //set table header
      settableBody(transactions); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  // =======================================

  //====== Get account details ========

  const getAccountDetails = async () => {
    console.log("table status", tableLoading);
    const response = await businessOBJ.getAccountDetails(busId);
    if (response.length) {
      settableLoading(false);
      const accDetails = response.map((account: any, index: number) => ({
        id: index + 1,
        serviceID: account?.service?.serviceId,
        serviceName: account?.service?.name,
        accountName: account.bankAccountName,
        bankName: account?.bank?.name,
        accountNumber: account.accountNumber,
        funcProps: <></>,
      }));
      settableHeader(accDetailsTableHead); //set table header
      settableBody(accDetails); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  // ==================================

  //== Get Total settlement ====
  const getSettlements = async () => {
    const response = await businessOBJ.getSettlements("business", busId);
    if (response.length) {
      settableLoading(false);
      const settlements = response?.map((obj: any, index: number) => ({
        id: index + 1,
        serviceID: obj?.Service?.serviceId,
        serviceName: obj?.Service?.name,
        // accountName: obj.bankAccountName,
        // bankName: obj?.bank?.name,
        recepient: obj?.recipient[0],
        amountSettled: numberFormat(obj.amount),
        funcProps: <></>,
      }));
      settableHeader(totalSettlementHead); //set table header
      settableBody(settlements); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  // ==============================

  //=== Get third pary accounts ========
  const getSubAccounts = async () => {
    const response = await businessOBJ.getSubaccounts("business", busId);
    // console.log("subaccounts", response);
    if (response.length) {
      settableLoading(false);
      const subaccounts = response?.map((account: any, index: number) => ({
        id: index + 1,
        serviceID: account?.service?.serviceId,
        serviceName: account?.service?.name,
        accountName: account.bankAccountName,
        bankName: account.bankName,
        accountNumber: account.accountNumber,
        funcProps: <></>,
      }));
      settableHeader(subAccountTableHead); //set table header
      settableBody(subaccounts); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  //=====================================

  //=== Get Dispute =====================
  const getDispute = async () => {
    const response = await businessOBJ.getDisputes(
      "business",
      busId,
      currentPage
    );
    // console.log("disputes data", response);
    if (response?.data?.length) {
      settableLoading(false);
      setcurrentPage(response?.currentPage);
      setTotalPage(response?.totalPages);

      const disputes = response?.data?.map((dispute: any, index: number) => ({
        id: index + 1,
        serviceID: dispute?.service?.serviceId,
        serviceName: dispute?.service?.name,
        amount: numberFormat(dispute.amount),
        merchant: dispute.merchant,
        email: dispute.email,
        dateandtime: dispute.createdAt,
        funcProps: <></>,
      }));
      settableHeader(disputeTableHead); //set table header
      settableBody(disputes); //set table body
    } else {
      settableLoading(false);
      settableBody([]);
    }
  };
  //=====================================

  const getData = (tabname?: any) => {
    setTotalPage(0); //reset pagination indicatior
    settabSelected(tabname ? tabname : "Services");
    if (tabname === "Service Created") settabSelected("Service");

    switch (tabname) {
      case "Transactions":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Transactions");
        getTransactions();
        break;
      case "Customers":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Customers");
        getCustormers();
        break;

      case "Total Settlement":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Settlement");
        getSettlements();
        break;

      case "Third Party Account":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Sub Accounts");
        getSubAccounts();
        break;

      case "Account Details":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Account Details");
        getAccountDetails();
        break;

      case "Dispute Created":
        settableLoading(!tableLoading);
        setcurrentPage(1);
        settableName("Disputes");
        getDispute();
        break;

      default:
        settableName("Service");
        getServices(); //Get services
        break;
    }
  };

  //========= Get extra data ==========
  const getExtraData = async () => {
    const response = await businessOBJ.getExtraData(busId);
    if (response) setbusinessData(response);
  };

  const PagenationLister = () => {
    if (tableName === "Transactions") getTransactions();
    if (tableName === "Disputes") getDispute();
  };

  const nextPage = () => {
    if (currentPage < totalPage) {
      setisPaginate(true);
      setcurrentPage(currentPage + 1);
      settableLoading(true);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      settableLoading(true);
      setcurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    PagenationLister();
  }, [currentPage]);

  useEffect(() => {
    if (!busId) {
      history.push("/agencybanking");
    }
  }, [busId]);

  useLayoutEffect(() => {
    // getExtraData();
    // if (!data) setIsloading(true);
    // if (error) {
    //   setIsloading(false);
    //   setNoRecord(true);
    // }
    // if (!data && !error) setNoRecord(true);
    // if (data && data.status && data.payload.servicesList?.length) {
    //   // console.log("business details ==>", data.payload);
    //   setbusinessDetails(data.payload);
    //   setbusinessName(data.payload?.businessName);
    //   setseriviceList(data?.payload?.servicesList);
    //   setNoRecord(false);
    //   setIsloading(false);
    //   handleLog();
    // }
  }, [data, error]);

  useEffect(() => {
    if (serviceList?.length) {
      getData(); //prepare data for the table
    }
  }, [serviceList]);

  return (
    <>
      {isloading ? (
        <GradientLoader />
      ) : (
        <>
          {noRecord ? (
            <>
              <br />
              <EmptyState stateTitle="No Data" statePara="" stateBtnText="" />
            </>
          ) : (
            <>
              <GridSection
                getData={getData}
                businessName={businessName}
                businessData={businessData}
                businessStatus={data?.payload.status}
                reloadData={fetchData}
                details={businessDetails}
              />

              <br />
              <DashboardSubHeader
                // count={tableBody.length}
                title={tableName}
                btnTitle=""
                withSearch={true}
                withFilter={false}
                widthActionBtn={false}
                filterOptions={["Name", "Date"]}
                setSearchData={() => ""}
                callBackFn={() => ""}
                placeholder={`${tableName} ID`}
              />
              {tableBody.length < 1 ? (
                <EmptyState stateTitle="No Data" statePara="" stateBtnText="" />
              ) : (
                <>
                  {tableLoading ? (
                    <GradientLoader />
                  ) : (
                    <Table
                      headData={tableHeader}
                      bodyData={tableBody}
                      rowStyle={{
                        background: "#ffffff",
                      }}
                      // hoverLink={hoverLink}
                    />
                  )}
                </>
              )}
              {totalPage > 1 && (
                <PaginationContainer>
                  <div>
                    {currentPage} of {totalPage} pages
                  </div>

                  <PaginationButtonsContainer>
                    {currentPage > 1 && (
                      <PaginationPrev onClick={prevPage}>
                        {"< "}Prev
                      </PaginationPrev>
                    )}

                    {totalPage > currentPage && (
                      <PaginationNext onClick={nextPage}>
                        Next {` >`}
                      </PaginationNext>
                    )}
                  </PaginationButtonsContainer>
                </PaginationContainer>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
