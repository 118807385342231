import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color } from "../../../shared/utils/styles";

export const PageLayout = styled.div`
  width: 100%;
  margin-top: 2rem;
  background-color: #fff;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  @media ${breakpoints.device.max.md} {
    padding: 0.5rem;
  }
`;

export const TableContainer = styled.div`
  margin-top: 2rem;
  height: 60vh;
  overflow-y: scroll;
`;

export const TableHeader = styled.div`
  background-color: ${color.fountainLight};
  display: flex;
  width: 100%;
  padding: 1rem 0.2rem;
  justify-content: space-between;
  color: ${color.fountain};
  font-size: 14px;
`;

export const TableRow = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0.2rem;
  justify-content: space-between;
  color: ${color.fountain};
  font-size: 14px;
  border: 1px solid ${color.backgroundMedium};
  /* cursor: pointer; */

  &:hover {
    background-color: ${color.backgroundLightest};
  }

  @media ${breakpoints.device.max.md} { 
    padding: 1rem 0rem;
  }
`;

export const TableContent = styled.div`
  width: 15%;
  padding-left: 0.2rem;
  justify-content: center;
`;
export const TableContentMain = styled.div`
  width: 15%;
  padding-left: 0.2rem;
  justify-content: center;
  font-size: 13px;
`;

export const TicketIDBadge = styled.span`
  background: rgba(217, 217, 217, 0.42);
  border-radius: 30px;
  padding: 10px 15px;
  cursor: pointer;
`;

export const TransactionModal = styled.div`
  padding: 45px 35px;
`;

export const ModalContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const ModalContentLeft = styled.div`
  width: 30%;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`;

export const ModalContentRight = styled.div`
  width: 70%;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
`;
