import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import logo from "../Assets/logo.svg";
import banner from "./assets/banner.png";

import {
  BannerContainer,
  Container,
  FormContainer,
  InputContainer,
  InputLabel,
  CPBanner,
  CPButton,
  CPForm,
  CPInput,
  CPPageContainer,
  LoginPageSpan,
  LogoContainer,
  LogoImage,
  Theform,
  WelcomeText,
  InputBox,
  IconImage,
  PasswordError,
  SuccessImg,
  VaultImage,
  CoverWatermark,
  ModalContent,
} from "./Styles";

import keyicon from "./assets/Key.svg";
import vault from "./assets/vault.svg";
import { EyeClosed, EyeOpen } from "shared/quickComponents/EyeIcons";
import successfullImg from "./assets/successful.gif";
import successImg from "./assets/success.gif";
import { passwordStrength } from "shared/utils/javascript";
import Modal from "shared/quickComponents/modal";
import PasswordMeter from "Auth/pages/PasswordMeter";
import api from "shared/utils/api";
import { Message } from "../shared/components/ConfirmModal/Styles";
import { toast } from "react-toastify";
import passwordOBJ from "./createPasswordClass";
import { getCurrentUser, removeAll } from "../shared/utils/authToken";
import { Icon } from "shared/components";
import logger from "shared/utils/logger";
import { getClientDetails, getIpAddress } from "shared/utils/location";

export default function CreatePassword() {
  const [currentUserPassword, setCurrentUserPassword] = useState<string>("");
  const [userPassword, setuserPassword] = useState<string>("");
  const [confirmPassword, setconfirmPassword] = useState<string>("");
  const [passwordStatus, setpasswordStatus] = useState<boolean>(false);
  const [showPassword, setshowPassword] = useState<boolean>(false);
  const [error, seterror] = useState<string>("");
  const [isModalOpen, setisModalOpen] = useState<boolean>(false);
  const [btnText, setbtnText] = useState("CHANGE PASSWORD");
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const history = useHistory();
  const { id } = useParams<{ id: any }>();

  const currentUser = getCurrentUser();

  //====================== Page actions =======================
  const handleValidation = (e: React.KeyboardEvent): void => {
    if (userPassword !== confirmPassword) {
      seterror("Error: Your passwords don't match");
    } else {
      const pwdStrength = passwordStrength(userPassword); //Check password strength
      if (pwdStrength.status === false) {
        setpasswordStatus(false);
        seterror(pwdStrength.message);
      } else {
        setpasswordStatus(true);
        seterror("");
      }
    }
  };

  const handleCloseModal = () => {
    history.push("/autheticate");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (passwordStatus) {
      try {
        setbtnText("Processing...");

        const response = await passwordOBJ.changePassword({
          current_userPassword: currentUserPassword,
          password: userPassword,
          confirm_password: confirmPassword,
        });
        // console.log("ress", response);
        if (response) {
          await logger.logActivities("remote", {
            date: new Date().toString(),
            author: currentUser.email,
            resource: "admin",
            action: "LOGIN",
            payload: {
              userAgent: getClientDetails(),
              data: {
                detail: `${currentUser.email} changed password.`,
              },
            },
          });
          setTimeout(() => {
            setbtnText("CHANGE PASSWORD");
            setisModalOpen(!isModalOpen);
            removeAll();
          }, 2000);
        } else {
          setbtnText("CHANGE PASSWORD");
          await logger.logActivities("remote", {
            date: new Date().toString(),
            author: currentUser.email,
            resource: "admin",
            action: "LOGIN",
            payload: {
              userAgent: getClientDetails(),
              data: {
                detail: `${currentUser.email} attempts to changed password failed.`,
              },
            },
          });
        }
      } catch (error) {
        toast.error(`${error}`);
        setbtnText("CHANGE PASSWORD");
      }
    }
  };
  //==================================================================

  useEffect(() => {
    // console.log(`${isPasswordValid} && (${userPassword}===${confirmPassword})`)
    if (isPasswordValid && userPassword === confirmPassword) {
      setpasswordStatus(true);
    } else {
      setpasswordStatus(false);
    }
  }, [isPasswordValid, userPassword, confirmPassword]);

  return (
    <div>
      <Modal closeButton={true} isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <h2>Account successfully created !</h2>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <SuccessImg src={successImg} />
          </div>
          <CoverWatermark></CoverWatermark>
        </ModalContent>
      </Modal>
      <CPPageContainer>
        <Container>
          <FormContainer>
            <CPForm>
              <WelcomeText>
                <Icon
                  type="arrow-left-circle"
                  size={28}
                  onClick={() => history.goBack()}
                  style={{ cursor: "pointer" }}
                />
                Set your Password
              </WelcomeText>
              <Theform onSubmit={handleSubmit}>
                <InputContainer>
                  <InputLabel>Current Password</InputLabel>

                  <InputBox>
                    <IconImage src={keyicon} alt="" />
                    <CPInput
                      type={showPassword ? "text" : "password"}
                      placeholder="Current Password"
                      value={currentUserPassword}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setCurrentUserPassword(event.target.value);
                      }}
                      required
                    />
                    <span
                      onClick={() => setshowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <EyeOpen height="1rem" />
                      ) : (
                        <EyeClosed size="1.4rem" />
                      )}
                    </span>
                  </InputBox>
                </InputContainer>
                <hr />
                <InputContainer>
                  <InputLabel>Enter your new Password</InputLabel>

                  <InputBox>
                    <IconImage src={keyicon} alt="" />
                    <CPInput
                      type={showPassword ? "text" : "password"}
                      placeholder="New password"
                      value={userPassword}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setuserPassword(event.target.value);
                      }}
                      required
                    />
                    <span
                      onClick={() => setshowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <EyeOpen height="1rem" />
                      ) : (
                        <EyeClosed size="1.4rem" />
                      )}
                    </span>
                  </InputBox>
                </InputContainer>
                <InputContainer>
                  <InputLabel>Confirm your Password</InputLabel>
                  <InputBox>
                    <IconImage src={keyicon} alt="" />
                    <CPInput
                      type={showPassword ? "text" : "password"}
                      placeholder="Confirm password"
                      id="confirm_password"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setconfirmPassword(event.target.value);
                      }}
                      required
                    />
                    <span
                      onClick={() => setshowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <EyeOpen height="1rem" />
                      ) : (
                        <EyeClosed size="1.4rem" />
                      )}
                    </span>
                  </InputBox>
                </InputContainer>
                <PasswordMeter
                  password={userPassword}
                  setIsPasswordValid={setIsPasswordValid}
                />

                <div style={{ width: "100%", marginTop: "0.1rem" }}>
                  <CPButton type="submit" disabled={!passwordStatus}>
                    {btnText}
                  </CPButton>
                </div>
              </Theform>
            </CPForm>
          </FormContainer>

          <VaultImage src={vault} alt="" />
        </Container>
        <BannerContainer>
          <CPBanner src={banner} />
        </BannerContainer>
      </CPPageContainer>
    </div>
  );
}
