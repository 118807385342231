import api from "shared/utils/api";

class Settlement {
  async getSettlements() {
    try {
      const response = await api.get(`/settlements`);
      if (response?.status) return response;
      console.log("settlements", response);
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getTransactions(settlementId: any, page = 1) {
    console.log("herrrr", settlementId);
    try {
      const response = await api.get(
        `/settlements/${settlementId}/transactions?page=${page}`
      );
      if (response) return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getAccountDetails(settlementId: any, page = 1) {
    console.log("herrrr", settlementId);
    try {
      const response = await api.get(
        `/settlements/${settlementId}/account-details`
      );
      if (response) return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}




const settlementOBJ = new Settlement();
export default settlementOBJ;
