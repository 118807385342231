import { ActionTypes } from "../constants/actionType";

const initialState = {
  reportLink: "",
};

export const reportLinkReducer = (
  state: any = initialState.reportLink,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.REPORT_LINK:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
