import styled from "styled-components";

export const PageTitle = styled.h3`
  margin-top: 2rem;
`;

export const TableCont = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  margin-top: 4rem;
`;
