import React, { useState } from "react";
// import LineChart from "./LineChart/LineChart";
import { Container } from "./Styles";

import LineChart from "Dashboard/Overview/secondSection/LineChart";

export default function SectionSection({ data }: any) {
  return (
    <Container>
      <LineChart data={data} />
    </Container>
  );
}
