import React, { useEffect, useState } from "react";
import { BtnCont, BtnsContainer, BusinessForm, FormBtn, FormTitle, LoaderCont } from "./Styles";
import SelectField from "./SelectField";
import InputField from "./InputField";
import { RegTypeData } from "./StepsData";
import consoleLog from "shared/utils/consoleLog";
import api from "shared/utils/api";
import Loaders from 'shared/components/Loaders/index'
import { toast } from "react-toastify";
import { omniSaver } from "shared/utils/ls";
import BackBtn from "./BackBtn";

type PropT = {
  setcurrentStep: Function;
  setdoneSteps: Function;
  doneSteps: any[];
};

export default function StepOneComp({
  setcurrentStep,
  setdoneSteps,
  doneSteps,
}: PropT) {
  const [RegNo, setRegNo] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [RegType, setRegType] = useState("RC");
  const [industry, setindustry] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [categoryList, setCategoryList] = useState<any>([]);
  const [isValidating, setIsValidating] = useState(false)

  const handleStepOne = (e: any) => {
    e.preventDefault();
    setcurrentStep(3);
    setdoneSteps([...doneSteps, 2]);
  };

  const validateReg = async(value:string) =>{
    setRegNo(value)
    if(value.length >=6 && industry !== '') {
      consoleLog('called me')
      setIsValidating(true)
      try {
        const res = await api.post(
          "/kyc-verify",
          {
            rc_number: value,
            rc_type: RegType
          }
        );

        const businessKyc = {
          businessName: res.payload.companyName,
          categoryId: industry,
          registrationType: RegType,
          registrationNumber: RegNo,
          address: res.payload.address,
          businessKycType: 'GATEWAY',
          websiteAddress: res.payload.emailAddress || '',
        }

        toast.success(`${res.message}`);
        omniSaver('businessKyc', businessKyc)
        setbusinessName(res.payload.companyName)
      } catch(err:any) {
          console.log('get user err', err)
          toast.error(`${err.message}`);
      } finally{
        setIsValidating(false)
      }
    } else if (value.length >=6 && industry === '') {
      toast.error('Please select your industry type.')
    } else if (industry !== '' && value.length < 6) {
      toast.error(`Please enter a valid ${RegType} number.`)
    }
  }

  const onSubmit = async(e:any) => {
    e.preventDefault();
    setIsLoading(true)
    console.log({
      rcNumber: RegNo,
      rcType: RegType
    })
    try {
      const res:any = await api.post(
        '/kyc-verify',
        {
          data: {
            rc_number: RegNo,
            rc_type: RegType
          }
        }
      )
      consoleLog('business detail res ', res)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      consoleLog('business detail error ', error)
    }
  }

  const industryCatgory = (categoryList: any) =>
  categoryList.map((cat: any) => ({ value: cat?.id, label: cat?.name }));

  const getIndustryType = async () => {
    setIsLoading(true);
    try {
      const businessList: any = await api.get("/business-categories");
      if (!businessList) {
        toast.warning("No Business List");
        return;
      }
      const { payload } = businessList;
      setCategoryList(payload);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // consoleLog(error);
      // @ts-ignore
      toast.error(error);
    }
  };

  useEffect(() => {
    getIndustryType()
  }, [])

  return (
    <div>
      <FormTitle>Enter Business Details</FormTitle>
      <BusinessForm onSubmit={handleStepOne}>

        {
          <>
            <SelectField
              value={RegType}
              setValue={(value: any) => setRegType(value)}
              label="Registration Type"
              data={RegTypeData}
            />
            <InputField
              value={RegNo}
              label="Registration Number"
              inputType="Number"
              placeholder ={isValidating? 'Validating...': ''}
              handleChange={(value: any) => validateReg(value)}
            />
            <InputField
              value={businessName}
              label="Business Name"
              handleChange={(value: any) => setbusinessName(value)}
            />
            <SelectField
              value={industry}
              disabled={isLoading}
              setValue={(value: any) =>{setindustry(value); validateReg(RegNo)}}
              label="Industry Type"
              data={industryCatgory(categoryList)}
            />
          </>
        }

        <BtnsContainer>
          <BackBtn
            setcurrentStep={setcurrentStep}
            setdoneSteps={setdoneSteps}
            currentStep={2}
            doneSteps={doneSteps}
          />
          <FormBtn disabled={isValidating} type="submit">
            Save and Continue
          </FormBtn>
        </BtnsContainer>

        {/* <BtnCont>
          <FormBtn disabled={false} type="submit">
            Save and Continue
          </FormBtn>
        </BtnCont> */}
      </BusinessForm>
    </div>
  );
}
