import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { parentPort } from "worker_threads";
import { color } from "../../utils/styles";

type ContentProp = {
  visible?: boolean;
  variant?: string;
  bgColor?: string;
};

type ButtonPropT = {
  variant?: string;
  btn?: string;
};

export const SelectMainContainer = styled.div`
  width: 22%;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const StyledWrapper = styled.div`
  min-width: 100%;
  width: 100%;
  position: relative;
  height: inherit;
`;

export const StyledButton = styled.div<ButtonPropT>`
  min-width: 80%;
  height: inherit;
  cursor: pointer;
  padding: ${(prop) =>
    prop.variant === "outline" ? "0.6rem 0.9rem" : "0.9rem"};
  border: ${(prop) =>
    prop.variant === "outline" ? `1px solid ${color.fountain}` : "none"};
  border: ${(prop) => prop.btn === "primary" && `none`};
  border-radius: ${(prop) => (prop.variant === "outline" ? "0.4rem" : 0)};
  ${(prop) =>
    prop.variant === "primary" &&
    `background-color: ${color.backgroundLight} !important`};

  ${(prop) =>
    prop.variant === "outline"
      ? "background-color: #fff"
      : `  background-color: ${color.fountain}`};

  color: ${(prop) => (prop.variant === "outline" ? color.fountain : "#fff")};
  display: flex;
  justify-content: space-between;
  gap: 0.4rem;
  font-size: 12px;

  @media only screen and (min-width: 768px) {
    min-width: 100%;
    height: inherit;
    cursor: pointer;
    padding: ${(prop) =>
      prop.variant === "outline" ? "0.6rem 0.9rem" : "0.9rem"};
    border: ${(prop) =>
      prop.variant === "outline" ? `1px solid ${color.fountain}` : "none"};
    border-radius: ${(prop) => (prop.variant === "outline" ? "0.4rem" : 0)};

    ${(prop) =>
      prop.variant === "primary"
        ? `color: ${color.textDark} !important`
        : `color: #fff`};

    ${(prop) =>
      prop.variant === "outline" ? `color: ${color.fountain}` : `color: #fff`};

    display: flex;
    justify-content: space-between;
    gap: 0.4rem;
    font-size: 14px;
  }
`;

export const ButtonText = styled.span`
  margin-top: 0.2rem;
  display: block;
  @media only screen and (min-width: 768px) {
  }
`;

export const StyledContent = styled.div<ContentProp>`
  width: 100%;
  margin-top: 0.1rem;
  visibility: ${(prop) => (prop.visible ? "visible" : "hidden")};
  opacity: ${(prop) => (prop.visible ? 1 : 0)};
  position: absolute;
  background-color: ${(prop) => (prop.bgColor ? prop.bgColor : color.fountain)};
  z-index: 5;
  border: ${(prop) =>
    prop.variant === "outline" ? `1px solid ${color.fountain}` : "none"};

  color: ${(prop) =>
    prop.variant === "outline" ? `${color.fountain}!important` : "#fff"};
  color: ${(prop) =>
    prop.variant === "primary" ? `${color.textDark} !important` : "#fff"};
  max-height: 20.5rem;
  overflow: scroll;

  /* transition: all ease 0.2s; */
  &:last-child {
    border-bottom: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
`;

export const Item = styled.div<ContentProp>`
  border-bottom: 1px solid ${color.fountainAccent};
  padding: ${(prop) =>
    prop.variant === "outline" ? "0.6rem 0.9rem" : "0.5rem 0.9rem"};
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: ${(prop) =>
      prop.variant === "outline" ? color.backgroundLightest : color.fountain};
    background-color: ${(prop) =>
      prop.variant === "primary"
        ? `${color.backgroundLightest}!important`
        : color.fountain};
  }

  @media only screen and (min-width: 768px) {
    border-bottom: 1px solid ${color.fountainAccent};
    padding: ${(prop) =>
      prop.variant === "outline" ? "0.6rem 0.9rem" : "0.5rem 0.9rem"};
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: ${(prop) =>
        prop.variant === "outline" ? color.backgroundLightest : color.fountain};
    }
  }
`;
