import { color } from "shared/utils/styles";
import styled from "styled-components";

export const ContainerFinder = styled.div`
  padding: 0.2rem;
  margin-top: 1rem;
`;

export const Finders_pageheader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-size: 1.5rem;
  }
`;

export const Finders_headButton = styled.button`
  background: ${color.fountain};
  color: #fff;
  border-radius: 10rem;
  padding: 0.8rem 1.6rem;
  transition: all ease-in 0.8s;
  cursor: pointer;

  :hover {
    background-color: #333;
    transition: all ease-in 0.8s;
  }
`;

export const Finders_tablecont = styled.div`
  width: 100%;
  margin-top: 2rem;
  background-color: #fff;
  padding: 0.5rem;
`;
