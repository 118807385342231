import styled from "styled-components";
import { color } from "../../../shared/utils/styles";
import Button from "../../../shared/components/Button/index";
import breakpoints from "shared/utils/breakpoints";
// import { FormGroup } from "Settings/Business/Styles";

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 60%;
  margin-top: 1rem;
  @media ${breakpoints.device.max.sm} {
    width: 100%;
  }
`;
export const InviteFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 1.2rem 0.4rem;
  border: 1px solid ${color.fountain};
  border-radius: 0.5rem;
`;

export const InviteButton = styled(Button)`
  width: 20%;
  padding: 1.84rem 1.4rem;
  color: #fff;
  background-color: ${color.fountain};
  border-radius: 0.5rem;
  /* margin-top: -1.6rem; */

  &:hover {
    background-color: ${color.fountainAccent} !important;
  }
`;

export const DomianEmailErr = styled.p`
  color: ${color.fountain};
  margin-top: 0.5rem;
`;

export const ModalContent = styled.div`
  margin-top: -1rem;
  width: 80%;
  padding: 1rem 0;
`;
export const Modaltitle = styled.h1`
  color: ${color.fountain};
  text-align: center;
`;

export const ModalFormContainer = styled.div`
  margin-top: 1.8rem;
`;

export const ModalFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.4rem;
`;
export const InputLabel = styled.span`
  color: ${color.fountain};
`;
export const FormInput = styled.input`
  width: 100%;
  padding: 1rem 0.4rem;
  border: none;
  border-style: none;
  background: ${color.backgroundLight};
`;

export const InviteModalButton = styled.button`
  width: 100%;
  border-radius: 0.4rem;
  padding: 1rem 0.4rem;
  background: ${color.fountain};
  color: #fff;
  margin-top: 2rem;
  cursor: pointer;
`;
