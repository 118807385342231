import React, { useState } from "react";
import Layout from "Layout";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import Home from "./Home";
import Single from "./Single";
export default function Pricing() {
  const match = useRouteMatch();

  return (
    <>
      <Layout pageTitle="Pricing">
        <>
          <Route path={`${match.path}/home`} render={() => <Home />} />

          <Route
            path={`${match.path}/overview`}
            exact
            render={() => <Single />}
          />

          <Route
            path={`${match.path}/overview/:id`}
            render={() => <Single />}
          />
          {match.isExact && <Redirect to={`${match.url}/home`} />}
        </>
      </Layout>
    </>
  );
}
