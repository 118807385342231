import React from "react";
import { FormInput, InputCont } from "./Styles";

type InputPropT = {
  label: string;
  value: any;
  handleChange: Function;
  max?: number;
  inputType?: any;
  disabled?: boolean;
  placeholder?: string
};

export default function InputField({
  label,
  value,
  handleChange,
  max,
  inputType,
  disabled,
  placeholder
}: InputPropT) {
  return (
    <div>
      <InputCont>
        <label>{label}</label>
        <FormInput
          value={value}
          required
          maxLength={max}
          type={inputType}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // seterror("");
            handleChange(e.target.value);
          }}
        />
      </InputCont>
    </div>
  );
}
