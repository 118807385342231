import InviteUsers from "Users/InviteUsers";
import Layout from "Layout";
import { useRouteMatch, useHistory } from "react-router-dom";
import { GradientLoader, PageError } from "shared/components";
import { UserPage } from "./Styles";
import ResetRequest from "./ResetRequest";
import { UserList } from "./UserList";
import { ProtectedRoute } from "guards/ProtectedRoute";
import { SuperAdminGuard } from "guards/super-admin-guard";
import { useLayoutEffect } from "react";

const Users = () => {
  const match = useRouteMatch();
  const history = useHistory();
  // const dispatch = useAppDispatch();
  // const USER = useAppSelector((state) => state.USER);
  useLayoutEffect(() => {
    const { pathname } = window.location;
    if (pathname == "/users") {
      history.push("/users/list");
    }
  }, []);
  return (
    <Layout pageTitle="Users">
      <UserPage>
        {/* <Redirect exact from="/users" to='/users/list' /> */}
        <ProtectedRoute
          path={`${match.path}/list`}
          guards={[SuperAdminGuard]}
          component={UserList}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/invite-users`}
          guards={[SuperAdminGuard]}
          component={InviteUsers}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/reset-request`}
          guards={[SuperAdminGuard]}
          component={ResetRequest}
          fallback={() => <PageError />}
        />
      </UserPage>
    </Layout>
  );
};

export default Users;
