import React from "react";
import { BoxTitle } from "../firstSection/Styles";
import LineChart from "./LineChart";
import {
  GraphContainer,
  StatContainer,
  SectionContainer,
  GraphHeader,
  GraphContent,
  BoxesContainer,
  BoxBig,
  BoxSmall,
  BoxValue,
} from "./Styles";

export default function SecondSection({ data }: any) {
  return (
    <>
      <SectionContainer>
        <GraphContainer>
          {/* work in progress */}
          <LineChart data={data} />
        </GraphContainer>
        <StatContainer>
          <BoxesContainer>
            <BoxBig isToday={true}>
              <BoxTitle>Today</BoxTitle>
              <BoxValue>{data?.today ?? 0}</BoxValue>
            </BoxBig>
            <BoxBig>
              <BoxTitle>All Transactions</BoxTitle>
              <BoxValue>{data?.all ?? 0}</BoxValue>
            </BoxBig>
          </BoxesContainer>
          <BoxesContainer>
            <BoxSmall>
              <BoxTitle>This week</BoxTitle>
              <BoxValue>{data?.weekly ?? 0}</BoxValue>
            </BoxSmall>
            <BoxSmall>
              <BoxTitle>This month</BoxTitle>
              <BoxValue>{data?.monthly ?? 0}</BoxValue>
            </BoxSmall>
            <BoxSmall>
              <BoxTitle>This year</BoxTitle>
              <BoxValue>{data?.yearly ?? 0}</BoxValue>
            </BoxSmall>
          </BoxesContainer>
        </StatContainer>
      </SectionContainer>
    </>
  );
}
