import { useEffect } from "react";
import Layout from "Layout";
import { Route, useRouteMatch } from "react-router-dom";
import AllDispute from "./AllDispute/AllDispute";
import DisputeHome from "./Home";
import Single from "./Single";

export default function Dispute() {
  const match = useRouteMatch();

  return (
    <Layout pageTitle="Dispute">
      <>
        <Route path={`${match.path}`} exact render={() => <DisputeHome />} />
        <Route path={`${match.path}/single/:id`} render={() => <Single />} />
        <Route path={`${match.path}/single`} exact render={() => <Single />} />
        <Route path={`${match.path}/all`} exact render={() => <AllDispute />} />
      </>
    </Layout>
  );
}
