import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  @media print {
    display: none;
  }
`;

export const FilterTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #292929;
`;

export const FilterButtonContainer = styled.div`
  position: relative;
`;

export const FilterButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
  width: 120px;
  height: 42px;
  border: 1px solid #760000;
  border-radius: 26px;
  &:hover {
    background-color: #ffffff;
  }
`;

export const FilterBox = styled.div`
  position: absolute;
  width: 519px;
  min-height: 255px;
  right: 0;
  top: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  padding: 25px;
  text-align: left;
  z-index: 1;
  @media ${breakpoints.device.max.xs} {
    width: calc(100vw - 25px);
  }
`;

export const FilterBoxHeader = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  border-bottom: 1px solid rgba(138, 133, 133, 0.34);
  padding-bottom: 15px;
  text-align: left;
`;

export const FIlterBoxContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
`;


export const FilterDateContainer=styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  gap: 10px;
`;

export const FilterCheckBox = styled.div`
  width: 50%;
`;

export const FilterActionArea=styled.div`
  margin-top:5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SubActions=styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: 10%;
`;