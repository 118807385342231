import { color, font } from "shared/utils/styles";
import styled from "styled-components";

type modalButtonPropT = {
  option?: boolean;
};

export const FormContainer = styled.div`
  width: 100%;
  padding: 1.4rem;

  h2 {
    text-align: center;
    color: ${color.fountain};
  }
`;

export const AddBusinessForm = styled.form`
  margin-top: 0.5rem;
`;

export const SelectContainer = styled.div`
  width: 60%;
  position: relative;
  /* border: 1px solid orange; */

  & select {
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    /* width: 100%; */
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
  }
`;

export const ModalContent = styled.div`
  width: 90%;
  margin: auto;
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    padding: 0.4rem;
    text-align: center;
  }
`;

export const ModalButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
`;

export const ModalButton = styled.button<modalButtonPropT>`
  padding: 0rem 2rem;
  height: 40px;
  background-color: ${(prop) =>
    prop.option ? color.fountainLight : color.fountain};
  color: ${(prop) => (prop.option ? "#000" : "#fff")};
  border-radius: 0.4rem;
`;

export const ActionLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const OtpContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const Title = styled.h1`
  color: #292929;
  padding: 10px 0;
  ${font.size(32)}
`;

export const SubTitle = styled.p`
  color: #828282;
  ${font.size(16)}
`;
