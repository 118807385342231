import { Link, useRouteMatch } from "react-router-dom";
import icon5 from "../../../Assets/icon5.svg";
import SingleBox from "./Box";
import {
  BtnContainer,
  Container,
  Section,
  TableContainer,
  ViewAllBtn,
} from "./Styles";
import TableSection from "./TableSection/TableSection";

const box = {
  id: 1,
  title: "Total Transactions",
  value: 346,
  icon: icon5,
  background: false,
  urlPath: "/",
};

export default function SecondSection() {
  const match = useRouteMatch();
  return (
    <Section>
      <TableContainer
        style={{ padding: "25px 25px 0px 25px", textAlign: "center" }}>
        <Container>
          <TableSection />
        </Container>
        <BtnContainer>
          <Link to={`/agencybanking/financial/all`}>
            <ViewAllBtn>View All</ViewAllBtn>
          </Link>
        </BtnContainer>
      </TableContainer>
      <SingleBox boxdata={box} />
    </Section>
  );
}
