import React, { useState, useEffect } from "react";
import Layout from "Layout";
import { PageError } from "shared/components";
import { SuperAdminGuard } from "guards/super-admin-guard";
import { ProtectedRoute } from "guards/ProtectedRoute";
import ChannelActivation from "./Channel";
import PasswordReset from "./PasswordReset";
import EmailPolicy from "./EmailPolicy";
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { SettingsPageNav, Tab } from "./Styles";

export default function Index() {
  const location: any = useLocation();
  const defaultSelected = location?.state?.data ?? "Channel";
  // const [data] = useState(location?.state?.data);

  const [isSelected, setisSelected] = useState(defaultSelected);
  const match = useRouteMatch();
  const history = useHistory();

  const handleNav = (path: string, data: any) => {
    history.push(path, data);
  };

  return (
    <>
      <Layout pageTitle="Settings">
        <SettingsPageNav>
          <Tab
            isActive={isSelected === "Channel" ? true : false}
            onClick={() => {
              setisSelected("Channel");
              handleNav("/settings/channel-activation", { data: "Channel" });
            }}
          >
            Channel Activation
          </Tab>
          <Tab
            isActive={isSelected === "PasswordReset" ? true : false}
            onClick={() => {
              setisSelected("PasswordReset");
              handleNav("/settings/password-reset", { data: "PasswordReset" });
            }}
          >
            Password Reset
          </Tab>
          <Tab
            isActive={isSelected === "EmailPolicy" ? true : false}
            onClick={() => {
              setisSelected("EmailPolicy");
              handleNav("/settings/email-policy", { data: "EmailPolicy" });
            }}
          >
            Email Policy
          </Tab>
          <Tab isActive={false}>Others</Tab>
        </SettingsPageNav>
        <ProtectedRoute
          path={`${match.path}/channel-activation`}
          guards={[SuperAdminGuard]}
          component={ChannelActivation}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/password-reset`}
          guards={[SuperAdminGuard]}
          component={PasswordReset}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/email-policy`}
          guards={[SuperAdminGuard]}
          component={EmailPolicy}
          fallback={() => <PageError />}
        />
        {match.isExact && <Redirect to={`${match.url}/channel-activation`} />}
      </Layout>
    </>
  );
}
