import styled from "styled-components";
import { color, font } from "../utils/styles";
import { Button } from "shared/components";
import BreakPoints from "shared/utils/Breakpoint";

type ContentRowProp = {
  role?: string;
};

export const PageLayout = styled.div`
  width: 100%;
  //padding: 0 0.6rem 1.4rem 1.4rem;
  margin-top: 2.4rem;
  overflow-y: scroll;
  /* background-color: white; */

  @media (${BreakPoints.xs}) {
    margin-top: -2.6rem;
    /* border: 5px solid red; */
  }
`;

export const Sectionbutton = styled.button`
  background-color: ${color.fountain};
  color: white;
  padding: 1rem 2.2rem;
  border-radius: 0.2rem;

  &:hover {
    background-color: ${color.fountainAccent};
    transition: all ease 0.4s;
  }
  &:active {
    background-color: ${color.fountainAccent};
  }
`;

export const SectionHeader = styled.div`
  width: 100%;
  padding: 0.4rem 0.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const SectionHeaderLabel = styled.h3`
  color: ${color.textDark};
`;

export const ContentHeaderContainer = styled.div`
  display: flex;
  gap: 2rem;
  background-color: ${color.fountainLight};
`;

export const ContentHeader = styled.div`
  width: 18%;
  padding: 1rem 1.4rem;
  color: ${color.fountain};
  font-size: 18px;
  font-weight: ${font.bold};
`;

export const ContentRow = styled.div<ContentRowProp>`
  display: flex;
  gap: 2.5rem;
  padding: 1.2rem 1.4rem;
  margin-top: 0.4rem;
  margin-bottom: 0.2rem;
  border-bottom: 1px solid ${color.backgroundMedium};
  cursor: pointer;
  ${(prop) =>
    prop.role === "Business/Admin"
      ? `background-color:${color.backgroundLight}`
      : "background-color:#fff"};
  &:hover {
    background-color: ${color.backgroundLight};
  }
`;

export const Content = styled.div`
  width: 18%;
  color: ${color.textDark};
  font-size: 16px;
  display: flex;
`;

export const EditContainer = styled.div`
  display: flex;
  cursor: pointer;
  color: tomato;
`;

export const Divider = styled.div`
  margin-top: 17px;
  padding-top: 18px;
  border-top: 1px solid ${color.borderLight};
`;

export const GhostButton = styled(Button)`
  padding: 1.6rem 1.6rem !important;
  color: white;
  width: 100%;
  background: ${color.fountain};
  border-radius: 0.2rem;
`;
