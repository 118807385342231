import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import EmptyState from "../../../shared/components/EmptyState";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import Table from "../../../shared/clientcomponents/Table";
import useApi from "shared/hooks/api";
import logosample from "../../../App/assets/images/amazon.png";
import { BtnContainer, ViewAllBtn } from "./Styles";
import { useEffectOnce } from "shared/hooks/useEffectOnce";
import { GradientLoader } from "shared/components";
import { businessTableHeader } from "../../businessTableHeader";
import Switch from "shared/components/Switch";
import { numberFormat } from "shared/utils/utility";
import businessOBJ from "../../businessClass";
import consoleLog from "shared/utils/consoleLog";

type PropT = {
  [x: string]: any;
};

export default function ThirdSection() {
  const [businesses, setbusinesses] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [noRecord, setNoRecord] = useState(true);
  const [totalPages, settotalPages] = useState(0);

  const match = useRouteMatch();
  const history = useHistory();
  const businessDataRef = useRef<any>(null);

  const [{ data, error, setLocalData }, fetchData] = useApi.get(
    `/business?page=1`
  );

  const handleNavigate = (totalBusinesses: any) => {
    history.push(`/business/all`, {
      totalBusinesses,
    });
  };

  const businessData = businessDataRef.current?.map(
    (el: any, index: number) => {
      return {
        index: index + 1,
        busId: el?.id,
        businessID: el?.businessId,
        businessName: el?.businessName,
        amountSettled:
          el?.amountSettled === 0 ? "₦0.00" : numberFormat(el?.amountSettled),
        totalServices: el?.services?.length,
        totalTransactions: el?.numOfTransactions,
        logo: logosample && <img src={logosample} />,
      };
    }
  );

  const handleFilter = (searchData: any) => {
    const newlist = data.payload.filter((business: any) => {
      if (business.businessId.indexOf(searchData) > -1) {
        console.log(business);
        return business;
      }
    });
    if (newlist.length) setbusinesses(newlist);
    if (!searchData) setbusinesses(data?.payload);
  };

  const hoverLink = (elm: PropT) => {
    history.push(`/business/overview/${elm?.busId}`);
  };

  const getAllBusiness = async () => {
    const response = await businessOBJ.getAllBusiness();
    consoleLog("businesses fetched", response);
    if (response?.data) {
      settotalPages(response?.totalPages);
      setbusinesses(response?.data);
      businessDataRef.current = response?.data;
      setIsloading(false);
    }
  };

  useEffect(() => {
    getAllBusiness();
  }, []);

  // useEffectOnce(() => {
  //   console.log("Onceeffect ran");
  //   getAllBusiness();
  // });

  return (
    <>
      {isloading ? (
        <GradientLoader />
      ) : (
        <>
          {!noRecord ? (
            <EmptyState stateTitle="No Data" statePara="" stateBtnText="" />
          ) : (
            <>
              <DashboardSubHeader
                // count={businessData.length}
                title={`Businesses`}
                btnTitle="Create subaccount"
                withSearch={false}
                placeholder="Business ID"
                withFilter={false}
                widthActionBtn={false}
                filterOptions={["Name", "Date"]}
                setSearchData={handleFilter}
                callBackFn={() => ""}
              />

              <Table
                // serialNumber={true}
                headData={businessTableHeader}
                bodyData={businessData.slice(0, 3)}
                rowStyle={{ background: "#ffffff" }}
                hoverLink={hoverLink}
              />
              {businessData.length > 3 && (
                <BtnContainer>
                  <ViewAllBtn
                    onClick={() => handleNavigate(businessData.length)}
                  >
                    View All
                  </ViewAllBtn>
                </BtnContainer>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
