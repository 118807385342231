import React, { useState } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { ProtectedRoute } from "guards/ProtectedRoute";
import { SuperAdminGuard } from "guards/super-admin-guard";
import { AdminGuard } from "guards/admin-guard";
import { GradientLoader, PageError } from "shared/components";
import Home from "./Home/Index";
import ReportDetails from "./details";
import Layout from "Layout";
import CBNReport from "./cbn/cbn-report";
import TransactionDetails from "Transactions/TransactionDetails";
import TransactionOverview from "Transactions/Transaction-overview";

export default function Report() {
  const match = useRouteMatch();
  return (
    <>
      <Layout pageTitle="Report">
        <>
          <ProtectedRoute
            path={`${match.path}/`}
            guards={[SuperAdminGuard, AdminGuard]}
            component={Home}
            fallback={() => <PageError />}
          />

          <ProtectedRoute
            path={`${match.path}/details/:id`}
            guards={[SuperAdminGuard, AdminGuard]}
            component={ReportDetails}
            fallback={() => <PageError />}
          />
          <ProtectedRoute
            path={`${match.path}/transactions`}
            guards={[SuperAdminGuard, AdminGuard]}
            component={TransactionOverview}
            fallback={() => <PageError />}
          />

          <ProtectedRoute
            path={`${match.path}/details/transactions/:id`}
            guards={[SuperAdminGuard, AdminGuard]}
            component={TransactionDetails}
            fallback={() => <PageError />}
          />

          <ProtectedRoute
            path={`${match.path}/cbnreport`}
            guards={[SuperAdminGuard, AdminGuard]}
            component={CBNReport}
            fallback={() => <PageError />}
          />

          {/* {match.isExact && <Redirect to={`${match.url}/home`} />} */}
        </>
      </Layout>
    </>
  );
}
