import api from "shared/utils/api";

class ReportClass {
  async getChannelStat() {
    try {
      const response = await api.get("/report/transaction-channels");
      if (response?.status) {
        return response.payload;
      }
      console.log("transactions channels", response);
    } catch (error) {
      console.log("Channel Error: ", error)
    }
  }

  async getTransactionSummary() {
    try {
      const response = await api.get("/report/transaction-summary");
      if (response?.status) return response?.payload;
    } catch (error) {}
  }

  async getBusinessStat() {
    try {
      const response = await api.get("/report/business");
      if (response?.status) return response?.payload?.data;
    } catch (error) {}
  }

  async getTransactions(){
    try{
      const response=await api.get(`/report/transactions?status=SUCCESSFUL&limit=5`);
      if (response?.status) return response?.payload?.data;
    } catch (error) {}
  }

  async getSettlement(){
    try{
      const response=await api.get(`/report/settlements?status=SUCCESSFUL&limit=5`);
      if (response?.status) return response?.payload?.data;
    } catch (error) {}
  }

  async getPSSP(startDate: string, endDate: string) {
    //console.log(`Date params: /report/pssp?start_date=${startDate}&end_date=${endDate}`)
    try {
      const response = await api.get(
        `/report/pssp?start_date=${startDate}&end_date=${endDate}`
      );
      if (response?.status) return response;
      else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  }
}

const reportObj = new ReportClass();
export default reportObj;
