import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

type cardPropT = {
  isActive: boolean;
};

export const CardsContainer = styled.div`
  /* border: 1px solid orange; */
  padding: 0.5rem;
  display: flex;
  gap: 4rem;
  margin-top: 1rem;
  overflow-x: auto;
`;

export const Card = styled.div<cardPropT>`
  width: 260px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5rem;
  border-radius: 1rem;
  background: ${(prop) => (prop.isActive ? color.fountain : "#fff")};
  cursor: pointer;
  transition: all ease 0.8s;

  h2 {
    margin-top: 0.2rem;
    color: ${(prop) => (prop.isActive ? "#fff" : "#000")};
  }
`;

export const CardIconContainer = styled.div<cardPropT>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 20rem;
  background: ${(prop) => (prop.isActive ? "#fff" : color.fountainLight)};
  padding: 0.4rem;
`;

export const SwitchContainer = styled.div`
  width: 100%;
  margin-top: 8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8rem;
  padding-left: 10px;
  @media ${breakpoints.device.max.sm} {
    margin-top: 2rem;
    justify-content: space-between;
   flex-direction: column;
   align-items: flex-start;
   gap: 1rem;
  }
`;
