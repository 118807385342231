import React from "react";
import DonutChart from "./DonutChart/DonutChart";
import LineChart from "./LineChart/LineChart";
import { DonoutchartContainer, LineChartContainer, Section } from "./Styles";

export default function FirstSection() {
  return (
    <>
      <Section>
        <LineChartContainer>
          <LineChart />
        </LineChartContainer>
        <DonoutchartContainer>
          <DonutChart />
        </DonoutchartContainer>
      </Section>
    </>
  );
}
