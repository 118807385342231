import "chart.js/auto";
import { useState } from "react";
import { Pie } from "react-chartjs-2";
import {
  Box,
  ChartDetails,
  ChartLabelContainer,
  GraphContent,
  GraphHeader,
} from "./Styles";

type ChartPropT = {
  id: number;
  month: string;
  transaction: number;
  userLost: number;
}[];

const disputeData = [
  { id: 1, disputeName: "Dispute 1", Value: 789 },
  { id: 2, disputeName: "Dispute 2", Value: 890 },
  { id: 3, disputeName: "Dispute 3", Value: 456 },
  { id: 4, disputeName: "Dispute 4", Value: 568 },
];

function shuffleArray(array: any) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const colorsArr = [
  "#0D2535",
  "rgb(255, 99, 132)",
  "rgb(54, 162, 235)",
  "rgb(255, 205, 86)",
];

const disputeDataSort = disputeData.sort((a, b) => b.Value - a.Value);
const chartValues = disputeData.map((data) => data.Value);

export default function PieChart() {
  const [userData, setUserData] = useState({
    labels: ["A dispute", "B dispute", "C dispute", "D dispute"],
    datasets: [
      {
        label: "My First Dataset",
        data: chartValues, //feeding the chart with dispute data values array
        backgroundColor: colorsArr,
        hoverOffset: 4,
      },
    ],
  });

  function kFormatter(num: any) {
    let exp = (Math.sign(num) * Math.abs(num)) / 1000;
    return Math.abs(num) > 999
      ? ` ${exp.toFixed(1)}k`
      : Math.sign(num) * Math.abs(num);
  }

  const options = {
    plugins: { legend: { display: false } },
  };

  const detailsList = disputeData.map((dispute, i) => (
    <ChartDetails key={dispute.id}>
      <div style={{ display: "flex", gap: "0.4rem" }}>
        <Box bgColor={colorsArr[i]}></Box>
        <span>{dispute.disputeName}</span>
      </div>
      <span
        style={{
          display: "block",
          width: "100%",
          textAlign: "center",
          fontSize: "1rem",
        }}>
        {dispute.Value}
      </span>
    </ChartDetails>
  ));
  return (
    <>
      <GraphHeader>
        <h4>Five Best Agent</h4>
      </GraphHeader>
      <GraphContent>
        <Pie data={userData} options={options} />
      </GraphContent>
      <ChartLabelContainer>{detailsList}</ChartLabelContainer>
    </>
  );
}
