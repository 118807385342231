import React from "react";
import { useHistory } from "react-router-dom";
import DonutChart from "./DonutChart/DonutChart";
import PieChart from "./PieChart/PieChart";
// import LineChart from "./LineChart/LineChart";
import {
  AddBusinessBtn,
  AddBusinessCont,
  ChartContainer,
  ChartContainerImage,
  Section,
} from "./Styles";
import image1 from "../../../Assets/image1.png";

export default function FirstSection({ data }: any) {
  const history = useHistory();
  return (
    <>
      <AddBusinessCont>
        <AddBusinessBtn onClick={() => history.push("./create-business")}>
          Create Business
        </AddBusinessBtn>
      </AddBusinessCont>
      <Section>
        <ChartContainer>
          <DonutChart data={data?.businessCategories ?? []} />
        </ChartContainer>
        <ChartContainer>
          <PieChart data={data?.businessStatus ?? []} />
        </ChartContainer>
        <ChartContainerImage url={image1}></ChartContainerImage>
      </Section>
    </>
  );
}
