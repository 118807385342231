import React, { useState, useEffect } from "react";
import LineChart from "./LineChart/LineChart";
import BarChart from "./BarChart";
import PieChart from "./PieChart/PieChart";
import { DonoutchartContainer, LineChartContainer, Section } from "./Styles";
import disputOBJ from "Dispute/disputeClass";

export default function FirstSection() {
  const [linechartData, setlinechartData] = useState<any>([]);
  const [piechartData, setPiechartData] = useState<any>([]);

  const getLineChartData = async () => {
    const response = await disputOBJ.getLineChart();
    // console.log("line chart data", response);
    if (response) setlinechartData(response);
  };

  const getPieChartData = async () => {
    const response = await disputOBJ.getPieChart();
    // console.log("Pie chart data", response);
    if (response) setPiechartData(response);
  };

  useEffect(() => {
    getLineChartData();
    getPieChartData();
  }, []);

  return (
    <>
      <Section>
        <LineChartContainer>
          <LineChart data={linechartData ?? {}} />
        </LineChartContainer>
        <DonoutchartContainer>
          <PieChart data={piechartData ?? {}} />
        </DonoutchartContainer>
      </Section>
    </>
  );
}
