import React from "react";
import {
  ContentKey,
  ContentRow,
  ContentValue,
  DetailsContainer,
  DetailsContent,
  DetailsHeader,
} from "../Single/Styles";
import { numberFormat } from "shared/utils/utility";

type PropT = {
  selectedTransaction: any;
};
export default function TransDetailsModal({ selectedTransaction }: PropT) {
  const transDetailsArr = [
    { propKey: "Transaction ID", propVal: "reference" },
    { propKey: "Customer Name", propVal: "fullName" },
    { propKey: "Customer Email", propVal: "email" },
    { propKey: "Amount", propVal: "amount" },
    { propKey: "Channel", propVal: "gatewayChannel" },
    { propKey: "Charge", propVal: "charge" },
    { propKey: "Paid at", propVal: "paidAt" },
    { propKey: "Remark", propVal: "remark" },
  ];

  return (
    <DetailsContainer>
      <DetailsHeader>Transaction Details</DetailsHeader>
      <DetailsContent>
        {transDetailsArr?.map((obj, index) => (
          <ContentRow>
            <ContentKey>{obj?.propKey}:</ContentKey>
            <ContentValue>
              {["amount", "charge"].includes(obj.propVal)
                ? numberFormat(parseInt(selectedTransaction[obj.propVal]) / 100)
                : obj.propVal === "paidAt" &&
                  selectedTransaction?.status
                    .toString()
                    .trim()
                    .toLowerCase() === "successful"
                ? selectedTransaction["updatedAt"]
                : ["fullName", "email"].includes(obj.propVal)
                ? selectedTransaction.Customer[obj.propVal]
                : selectedTransaction[obj.propVal]}
            </ContentValue>
          </ContentRow>
        ))}
      </DetailsContent>
    </DetailsContainer>
  );
}
