import styled, { css } from "styled-components";
import { color, font } from "shared/utils/styles";

import { sizes } from "shared/utils/styles";

type ContentPropT = {
  alignEnd?: boolean;
  width?: string;
  isColor?: boolean;
};
const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

export const UserPage = styled.div`
  padding: 0rem 32px 50px ${paddingLeft - sizes.secondarySideBarWidth - 110}px;
  /* margin-left: -38px; */
  margin-top: 10px;
  width: 100%;
  @media (max-width: 1100px) {
    padding: -25px 20px 50px ${paddingLeft - 20}px;
  }
  @media (max-width: 999px) {
    /* padding-left: ${paddingLeft - 20 - sizes.secondarySideBarWidth}px; */
		padding: 0px 15px;
  }
`;

export const Section = styled.section`
  margin-top: 1rem;
  margin-bottom: 5rem;
  background: #fff;
  padding: 2.8rem 2rem 4rem 2rem;
  border-radius: 1rem;
  /* height: 50rem; */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

export const TableContainer = styled.div`
  width: 100%;
  border: 1px solid ${color.fountainLight};
  padding-bottom: 2rem;
  overflow-y: auto;
  min-height: 10rem;
`;

export const TableHeader = styled.div`
  display: flex;
  background-color: ${color.fountainLight};
  color: ${color.fountain};
  justify-content: space-between;
  padding: 0 1rem;
  gap: 2rem;
`;

export const TableRow = styled.div`
  display: flex;
  background-color: #fff;
  padding: 0 1rem;
  justify-content: space-between;
  gap: 2rem;
  border: 1px solid ${color.fountainLight};
`;
export const TableContent = styled.div<ContentPropT>`
  width: ${(prop) => (prop.width ? prop.width : "24%")};
  padding: 0.8rem 0;
  display: flex;
  justify-content: ${(prop) => (prop.alignEnd ? "end" : "start")};
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #760000;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const OtpContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const Title = styled.h1`
  color: #292929;
  padding: 10px 0;
  ${font.size(32)}
`;

export const SubTitle = styled.p`
  color: #828282;
  ${font.size(16)}
`;

export const ActionArea=styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media print {
    display: none;
  }
`;
export const ApprovedButton = styled.div<ContentPropT>`
  padding: 0.5rem 0rem;
  border: 1px solid ${color.fountain};
  color: ${color.fountain};
  border-radius: 0.5rem;
  text-align: center;
  transition: all ease 0.4s;
  ${props=> props.isColor && css`
    background-color: red;
    color: #fff;
    border: 0px;
  `};
  cursor: pointer;
  &:hover {
    background: #fff;
    color: ${color.fountain};
  }
`;


export const StateContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
