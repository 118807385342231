import Layout from "Layout";
import { ProtectedRoute } from "guards/ProtectedRoute";
import { AdminGuard } from "guards/admin-guard";
import { SuperAdminGuard } from "guards/super-admin-guard";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import FindersHome from "./Home/Index";
import { PageError } from "shared/components";
import SingleFinder from "./Single/Index";

export default function Finders() {
  const match = useRouteMatch();
  return (
    <Layout pageTitle="Finders">
      <ProtectedRoute
        path={`${match.path}`}
        guards={[SuperAdminGuard, AdminGuard]}
        component={FindersHome}
        fallback={() => <PageError />}
      />
      <ProtectedRoute
        path={`${match.path}/single`}
        guards={[SuperAdminGuard, AdminGuard]}
        component={SingleFinder}
        fallback={() => <PageError />}
      />
    </Layout>
  );
}
