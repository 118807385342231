import React, { useState, useEffect } from "react";
import path from "path";
import { NavLink, useRouteMatch } from "react-router-dom";
import { Icon, ProjectAvatar } from "shared/components";
import { MENUS, ROLES } from "shared/utils/sitemap";
import { useAppDispatch, useAppSelector } from "store/hook";
import { setPageTitle } from "../../store/actions/pageTitle";
import {
  BusinessCategory,
  BusinessInfo,
  BusinessName,
  BusinessTexts,
  ConsoleNameCont,
  Divider,
  LinkItem,
  LinkText,
  NotImplemented,
  SidebarContainer,
} from "./Styles";
import { getCurrentUser } from "shared/utils/authToken";
import { validateMenu } from "shared/utils/menurecords";
import consoleLog from "shared/utils/consoleLog";

type PropT = {
  [x: string]: any;
};

type menus = {
  id: number;
  title: string;
  url: string;
  icon: string;
  type: boolean;
};

const Sidebar = ({ toggleNav }: any) => {
  const match = useRouteMatch(); // custom hook with type
  // const dispatch = useAppDispatch(); // custom hook with type
  // const navbarIcon = useAppSelector((state) => state.NAVBARICON.isNavOpen); //Get menus from global state
  const sidebarList: any = useAppSelector((state) => state.sidebar); // get data from redux store
  const consoleCategory = useAppSelector((state) => state.consoleCategory);

  const [topMenuList, setTopMenuList] = useState<any>([]);
  const [sidebarMenus, setSidebarMenus] = useState<any>([]);

  const dispatch = useAppDispatch();
  const currentUser: any = getCurrentUser();

  const populateMenus = () => {
    // console.log("Populating", sidebarMenus);
    if (sidebarMenus && Object.keys(sidebarMenus).length) {
      // alert("y");
      let sidebarArray = Object.values(sidebarMenus);
      const topList = sidebarArray.map((el: any, index: number) => {
        if (el.system === false) {
          return (
            <div key={index}>
              {renderLinkItem(el.title, el.icon || "", el.path)}
            </div>
          );
        }
      });
      // console.log(topList, "topList");

      return topList;

      // setTopMenuList(topList);
    }
    return [];
  };

  //================================================================================

  const currentBusiness = {
    name: "",
    category: "",
  };

  //consoleLog(sidebarList, "sidebarList");
  // console.log(sidebarMenus, "sidebarMenus");
  //validate Parent Menu
  const getExactMenu = () => {
    let permissions = currentUser?.Role?.permissions;
    let arr: any[] = [];
    
    const menuToBeListed: any=MENUS.filter(menu=>menu.category==consoleCategory);
    // alert(JSON.stringify(permissions))
    if (
      permissions &&
      permissions[1] &&
      menuToBeListed &&
      menuToBeListed instanceof Object
    ) {
      
      let permittedMenus = permissions[1];
      
      let sidebarListArray: any = Object.values(menuToBeListed);
      consoleLog("Sidebar list: ", menuToBeListed, "Permitted menus: ", permittedMenus);
      for (let el of sidebarListArray) {
        consoleLog(el?.path, "sidebarParth", permittedMenus);
        if (
          permissions[0].allOpen ||
          (typeof el?.path === "string" &&
            validateMenu(el?.path, permittedMenus))
        ) {
          arr.push(el);
        }
      }
      consoleLog(arr, "arrsidebar");
      setSidebarMenus(arr);
    }
  };

  const renderLinkItem = (
    text: string,
    iconType: string,
    path?: string,
    match?: any
  ) => {
    const isImplemented = !!path;

    const linkItemProps: PropT = isImplemented
      ? {
          as: NavLink,
          exact: true,
          to: match ? `${match.path}` : path,
        }
      : { as: "div" };
    return (
      <LinkItem
        {...linkItemProps}
        onClick={() =>
          dispatch(setPageTitle(text === "Overview" ? "Dashboard" : text))
        }
      >
        <Icon type={iconType} />
        <LinkText>{text}</LinkText>
        {!isImplemented && <NotImplemented>Not implemented</NotImplemented>}
      </LinkItem>
    );
  };

  // =============== Create jsx menu tab list using data from redux store ==========

  let filteredMenus: any = [];
  if (MENUS.length && currentUser) {
    filteredMenus = MENUS.filter((menu) => {
      // let permissions = currentUser?.Role?.permissions;
      // let permittedMenus = permissions[1];
      return (
        menu.roles.includes(currentUser?.type) &&
        menu.main &&
        menu.system === true
        // (permissions[0].allOpen ||
        //   (typeof menu?.path === "string" &&
        //     validateMenu(menu?.path, permittedMenus)))
      );
    });
  }
  //bottom common menu
  const bottomMenus = filteredMenus.map((menu: any) => (
    <div key={menu.title}>
      {renderLinkItem(menu.title, menu.icon || "", menu.path)}
    </div>
  ));

  useEffect(() => {
    getExactMenu();
    // }, [currentUser]);
  }, []);

  useEffect(() => {
    setTopMenuList(populateMenus());
  }, [sidebarMenus]);

  return (
    <SidebarContainer onClick={toggleNav} isClicked={false}>
      <BusinessInfo>
        <ConsoleNameCont>
          <ProjectAvatar />
          {consoleCategory}
        </ConsoleNameCont>

        {/* <BusinessTexts>
          <BusinessName>{currentBusiness?.name || ""}</BusinessName>
          <BusinessCategory>{currentBusiness?.category}</BusinessCategory>
        </BusinessTexts> */}
      </BusinessInfo>
      {/* ======== set menu tabs ================== */}
      {topMenuList}
      {/* ======================================== */}
      <Divider />
      {bottomMenus}
      {/* {renderLinkItem("System settings", "settings", "/settings")} */}
      {/* {renderLinkItem("Issues and filters", "issues", "", match)}
      {renderLinkItem("Reports", "reports", "", match)} */}
    </SidebarContainer>
  );
};

export default Sidebar;
