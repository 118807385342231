import React, { useEffect, useState } from "react";
import EllipsisLink from "shared/components/EllipsisLink";
import ResetIcon from "../../Assets/ResetIcon.svg";
import DisableIcon from "../../Assets/DisableIcon.svg";
import { useHistory } from "react-router-dom";
import {
  DisableSpan,
  OptionItem,
  PageHeader,
  RoleUserPageCont,
  RowStatus,
  TableContainer,
} from "./Styles";
import Table from "shared/clientcomponents/Table";
import roleApi from "RoleManagement/apiClass";
import consoleLog from "shared/utils/consoleLog";
import { getCurrentUser } from "shared/utils/authToken";

export default function Users() {
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");
  const [selctedAction, setselctedAction] = useState("");
  const [selectedRow, setselectedRow] = useState<any>({});
  const [userData, setUserData] = useState<any>([]);
  const [deptList, setDeptList] = useState<any>([]);

  const history = useHistory();
  const currentUser = getCurrentUser();

  const tableHeader = [
    // { id: "index", label: "S/N", width: "2%" },
    { id: "userID", label: "USER ID", width: "14%" },
    { id: "department", label: "DEPARTMENT", width: "38%" },
    { id: "name", label: "NAME", width: "20%" },
    { id: "email", label: "EMAIL", width: "20%" },
    { id: "phoneNo", label: "PHONE NO", width: "20%" },
    { id: "status", label: "STATUS", width: "20%" },
    { id: "functionProp", label: "", width: "20%" },
  ];

  const getAllUsers = async () => {
    let data: any = await roleApi.fetchUsers();
    consoleLog(data, "UsersData");
    setUserData(data);
    data = await roleApi.fetchDepts();
    consoleLog(data, "UsersDataDepts");

    setDeptList(data);
  };

  const handleReset = (obj: any) => {
    console.log("handle reset", obj);
  };
  const handleDisable = (obj: any) => {
    console.log("handle disable", obj);
  };

  const sendOTP = (obj: any) => {
    console.log("send otp", obj);
  };

  const getOptions = (obj: any) => {
    return String(obj?.status)
      .toLowerCase()
      .trim() === "active"
      ? [
          {
            title: (
              <OptionItem>
                <img src={ResetIcon} alt="" />
                <span>Reset Password</span>
              </OptionItem>
            ),
            link: () => {
              setselectedRow(obj);
              setselctedAction("Reset");
              sendOTP(obj);
            },
          },
          {
            title: (
              <OptionItem>
                <img src={ResetIcon} alt="" />
                <span>Edit user</span>
              </OptionItem>
            ),
            link: () => {
              setselectedRow(obj);
              setselctedAction("Edit");
              sendOTP(obj);
              history.push(`/role-management/edit-user/${obj.id}`);
            },
          },

          {
            title: (
              <OptionItem>
                <img src={DisableIcon} alt="" />
                <DisableSpan>Disable</DisableSpan>
              </OptionItem>
            ),
            link: () => {
              setselectedRow(obj);
              setselctedAction("Disable");
              sendOTP(obj);
            },
          },
        ]
      : [
          {
            title: (
              <OptionItem>
                <img src={DisableIcon} alt="" />
                <DisableSpan>Disable</DisableSpan>
              </OptionItem>
            ),
            link: () => {
              setselectedRow(obj);
              setselctedAction("Disable");
              handleDisable(obj);
            },
          },
        ];
  };

  const getDepartmentName = (roleId = "") => {
    if (roleId) {
      console.log(deptList, "deptList");
      let findRecord = deptList.find(
        (dept: any) =>
          dept instanceof Object &&
          dept.roles instanceof Array &&
          dept.roles[0].id === roleId
      );
      return findRecord ? findRecord?.name ?? "" : null;
    }
    return null;
  };

  const tableData = userData
    .map((obj: any) => {
      return {
        userID: obj.staffId,
        department: getDepartmentName(obj.roleId),
        name: obj.fullName,
        email: obj.email,
        phoneNo: obj.phoneNumber,
        status: <RowStatus status={obj?.status}>{obj?.status}</RowStatus>,
        functionProp: <EllipsisLink content={getOptions(obj)} />,
      };
    })
    .filter((obj: any) => obj?.email !== currentUser?.email);

  console.log(tableData, "tableData");

  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <div>
      <RoleUserPageCont>
        <PageHeader>
          <h3>Users</h3>
          <button onClick={() => history.push("/role-management/add-user")}>
            Add User
          </button>
        </PageHeader>

        <TableContainer>
          <Table
            headData={tableHeader}
            bodyData={tableData}
            rowStyle={{ background: "#ffffff" }}
            totalPages={totalPages}
            currentpage={currentPage}
            goToPage={(value: any) => {
              setcurrentPage(value);
              // getKYCList(value);
            }}
            // hoverLink={hoverLink}
          />
        </TableContainer>
      </RoleUserPageCont>
    </div>
  );
}
