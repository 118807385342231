import React from "react";
import { BoxTitle } from "../../secondSection/Styles";
import LineChart from "./LineChart";
import {
  GraphContainer,
  StatContainer,
  SectionContainer,
  GraphHeader,
  GraphContent,
  BoxesContainer,
  BoxBig,
  BoxSmall,
  BoxValue,
} from "../Style";

export default () => {
  return (
    <>
      <SectionContainer>
        <GraphContainer>
          <LineChart />
        </GraphContainer>
      </SectionContainer>
    </>
  );
};
