import { any } from "cypress/types/bluebird";
import moment from "moment";
import { ROLES, MENUS } from "./sitemap";
import { getCurrentUser } from "shared/utils/authToken";

export const checkRoles = (role: any) => {
  // const filter=roles.filter(x:any => x.code===role);
  const filter = ROLES.filter((therole: any) => therole.code === role);
  return filter && filter.length > -1 ? true : false;
};

export const privilege = (role: any) => {
  const currentUser = getCurrentUser();
  if (!currentUser.permissions) return false;
  return currentUser.persistStore[role];
};

export const getRole = (role: any) => {
  const filter = ROLES.filter((therole: any) => therole.code === role);
  // console.log(filter);
  return filter && filter.length > -1 ? filter[0].name : false;
};

export const getUserMenus = (role: any) => {
  const filter = MENUS.filter((menu: any) => menu.roles.includes(role));
  if (filter.length > -1) {
    return filter;
  }
  return false;
};

export const formateDateString = (dateStr: string) => {
  var date = new Date(dateStr);
  var test = date.toISOString();
  return moment(test, "YYYYMMDD");
};

export const formateDateString2 = (dateStr: string) => {
  return new Date(dateStr).toLocaleDateString();
};

export const tableToExcel = () => {};

const convertToCSV = (objArray: any) => {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
};

export const exportCSVFile = async (
  headers: any,
  items: any,
  fileTitle: any
) => {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = await convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if ((window.navigator as any)?.msSaveBlob) {
    // IE 10+
    (window.navigator as any)?.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const getDates = () => {
  var today = new Date();
  var endDate =
    String(today.getFullYear()).padStart(2, "0") +
    "-" +
    String(today.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(today.getDate()).padStart(2, "0");

  var startDate =
    String(today.getFullYear()).padStart(2, "0") +
    "-" +
    String(today.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(today.getDate()).padStart(2, "0");

  return [startDate, endDate];
};

export function numFormatter(x: number) {
  if (isNaN(x)) return x;

  if (x < 9999) {
    return x;
  }

  if (x < 1000000) {
    return Math.round(x / 1000) + "K";
  }
  if (x < 10000000) {
    return (x / 1000000).toFixed(2) + "M";
  }

  if (x < 1000000000) {
    return Math.round(x / 1000000) + "M";
  }

  if (x < 1000000000000) {
    return Math.round(x / 1000000000) + "B";
  }

  return "1T+";
}
export function numberFormat(num: number, currencySymbol = "₦") {
  const newNumber = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${currencySymbol}${newNumber}`;
}
export function numberIntFormat(num: number, currencySymbol = "$") {
  const newNumber = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${currencySymbol}${newNumber}`;
}

export function currencyFormat(num: number) {
  const newNumber = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${numFormatter(num)}`;
}

export const currencyFormat100 = (amount: number, curr: string = "NGN") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: curr,
  });

  // return formatter.format(amount / 100);
  return formatter.format(amount);
};

//====== To remove duplicates objs in an array =====
export function removeDuplicateObjects(arr: []) {
  const uniqueSet = new Set();
  return arr.filter((obj) => {
    const objString = JSON.stringify(obj);
    const isUnique = !uniqueSet.has(objString);
    if (isUnique) {
      uniqueSet.add(objString);
    }
    return isUnique;
  });
}

export const currencyFormatter = (amount: any) => {
  const currFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });
  return currFormat.format(amount);
};

export function numFormat(num: number) {
  const newNumber = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${newNumber}`;
}


export function abbrNum(number: any, decPlaces: number) {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10,decPlaces);

  // Enumerate number abbreviations
  var abbrev = [ "K", "M", "B", "T" ];

  // Go through the array backwards, so we do the largest first
  for (var i=abbrev.length-1; i>=0; i--) {

      // Convert array index to "1000", "1000000", etc
      var size = Math.pow(10,(i+1)*3);

      // If the number is bigger or equal do the abbreviation
      if(size <= number) {
           // Here, we multiply by decPlaces, round, and then divide by decPlaces.
           // This gives us nice rounding to a particular decimal place.
           number = Math.round(number*decPlaces/size)/decPlaces;

           // Handle special case where we round up to the next abbreviation
           if((number == 1000) && (i < abbrev.length - 1)) {
               number = 1;
               i++;
           }

           // Add the letter for the abbreviation
           number += abbrev[i];

           // We are done... stop
           break;
      }
  }

  return number;
}