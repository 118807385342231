import Layout from "Layout";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { Container, Heading, GridContainer } from "./Styles";
import Table from "shared/clientcomponents/Table";
import { useEffect, useState } from "react";
import { GradientLoader, Header } from "shared/components";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "shared/utils/api";
import { boxData } from "./datalist";
import summaryData from "shared/utils/dataSummary";
import {
  currencyFormat100,
  formateDateString2,
  numberFormat,
} from "shared/utils/utility";
import { EmptyState } from "shared/components";
import { getStatusColor } from "shared/utils/statusColor";

const today = new Date();
today.setDate(1);
// let startDate: any=new Date(today.getFullYear(), today.getMonth() +1, 1)
let days = String(today.getDate()).padStart(2, "0");
let months = String(today.getMonth() + 1).padStart(2, "0");
let years = today.getFullYear();
today.setDate(1);
const startDate = `${years}-${months}-${days}`;

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const day = String(currentDate.getDate()).padStart(2, "0");
const formattedDate = `${year}-${month}-${day}`;

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, settotalPages] = useState<any>("");
  const [currentPage, setcurrentPage] = useState(1);
  const [fromDate, setfromDate] = useState<any>(startDate);
  const [toDate, settoDate] = useState<any>(formattedDate);
  const [type, setType] = useState("");
  const [specificItem, setSpecificItem] = useState({} as any);
  const [bodyData, setBodyData] = useState([] as any);
  const history = useHistory();

  const businessType: any = history?.location?.pathname
    .toString()
    .trim()
    .toLowerCase()
    .includes("agency")
    ? "agency"
    : "gateway";

  const reFormat = (arr: any) => {
    if (!(arr instanceof Array)) return [];
    return arr.map((el: any) => {
      if (!(el instanceof Object)) return {};
      let obj: any = {};
      if (el?.updatedAt) {
        obj.updatedAt = formateDateString2(el.updatedAt);
      }
      if (el?.amount) {
        obj.amount = currencyFormat100(
          Number.parseFloat(el.amount),
          el?.currency
        );
      }
      if (el?.charge) {
        obj.charge = currencyFormat100(
          Number.parseFloat(el.charge),
          el?.currency
        );
      }
      if (el?.remitedAmount) {
        obj.remitedAmount = currencyFormat100(
          Number.parseFloat(el.remitedAmount),
          el?.currency
        );
      }
      if (el?.gatewayChannel) {
        obj.gatewayChannel = el?.gatewayChannel.replace("_", " ");
      }
      if (el?.status) {
        obj.status =
          el.status &&
          (el.status.toLowerCase().includes("init") ? (
            <div style={{ color: getStatusColor("terminated") }}>
              terminated
            </div>
          ) : (
            <div style={{ color: getStatusColor(el?.status) }}>
              {el?.status?.toLowerCase()[0].toUpperCase() +
                el?.status?.toLowerCase().substring(1)}
            </div>
          ));
      }
      return { ...el, ...obj };
    });
  };

  const getActualParams = async () => {
    try {
      let pathStr = history?.location?.pathname
        .toString()
        .trim()
        .toLowerCase();
      setType(pathStr.includes("agency") ? "agency" : "gateway");
      let arr = pathStr.split("/");
      let srchStr = arr[arr.length - 1];
      let findRecord: any = boxData.find((el: any) => el?.urlPath === srchStr);
      //alert(JSON.stringify(findRecord))
      // console.log("record", findRecord);

      if (findRecord) {
        setIsLoading(true);
        setSpecificItem(findRecord);
        if (findRecord?.endpoints && findRecord?.endpoints[businessType]) {
          let obj: any = findRecord?.where ?? {};
          const response = await api.get(
            findRecord?.endpoints[businessType].toString(),
            {
              page: currentPage,
              ...obj,
            }
          );
          console.log(response.payload.data);
          // response?.payload?.data;
          settotalPages(
            response?.payload?.totalPages
              ? Number.parseInt(response?.payload?.totalPages)
              : 0
          );
          setBodyData(reFormat(response?.payload?.data));
        }
        setIsLoading(false);
      } else {
        history.goBack();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleFilter = async (fromDateStr: string, toDateStr: string) => {
    //make request using dates
    setfromDate(fromDateStr);
    settoDate(toDateStr);
    getActualParams();
  };

  const myFunction = (val: any) => {
    history.push(`${history?.location?.pathname}/${val?.id}`);
  };

  const hoverLink = (val: any) => {
    history.push(`${history?.location?.pathname}/${val?.id}`);
  };

  useEffect(() => {
    getActualParams();
  }, [history, currentPage]);

  //console.log(specificItem, "specificItem", bodyData, totalPages);
  // let bodyData: any = [];
  return (
    // <Layout pageTitle={specificItem?.title ?? "Report Details"}>
    <Container>
      <DashboardSubHeader
        title={specificItem?.title}
        btnTitle="Download"
        widthActionBtn={true}
        withSearch={false}
        withFilter={true}
        filterType={specificItem?.urlPath}
        callBackFn={handleFilter}
        excelExport
        excelExportData={summaryData(
          bodyData?.length ?? 0,
          specificItem?.tableHeader ?? [],
          bodyData
        )}
      />
      {isLoading || !specificItem?.tableHeader || !bodyData ? (
        <GradientLoader />
      ) : bodyData.length === 0 ? (
        <div style={{ width: "100%", backgroundColor: "#fff" }}>
          <EmptyState statePara="NO RECORD" />
        </div>
      ) : (
        <>
          <Table
            headData={specificItem?.tableHeader}
            bodyData={bodyData}
            rowStyle={{ background: "#ffffff" }}
            totalPages={totalPages}
            currentpage={currentPage}
            clickFunction={myFunction}
            goToPage={(value: any) => {
              setcurrentPage(value);
            }}
            hoverLink={hoverLink}
          />

          {/* <Table
            
            headData={columnHeaders}
            bodyData={data ?? []}
            rowStyle={{ background: "#ffffff" }}
            hoverLink={hoverLink}
          /> */}
        </>
      )}
    </Container>
    // </Layout>
  );
};
