import React, { useState, useLayoutEffect, useCallback } from "react";
import { getCurrentUser, getUserCoord } from "shared/utils/authToken";
import {
  getClientDetails,
  getIpAddress,
  getLocation,
} from "shared/utils/location";
import logger from "../utils/logger";

export default function useLogger() {
  const [clientLocation, setClientLocation] = useState<any>("");
  const [values, setvalues] = useState({
    destination: "",
    resource: "",
    action: "",
    detail: "",
    author:""
  });

  const currentUser = getCurrentUser();
  
  const handleLogger = () => {
    logger.logActivities(values.destination, {
      date: new Date().toString(),
      author: values.author,
      resource: values.resource,
      action: values.action,
      payload: {
        userAgent: getClientDetails(),
        data: {
          detail: values.detail,
        },
      },
    });
    // console.log("logger called");
  };

  useLayoutEffect(() => {
    setClientLocation(getLocation());
  }, []);

  useLayoutEffect(() => {
    
    if (values.author && values.resource && values.action && values.detail) {
      handleLogger();
      setClientLocation(getLocation());
    }
  }, [values]);
  return [setvalues];
}
