export const BusinessData = [
  {
    id: 1,
    binessName: "Tiamiyu Digitals",
    category: "Finance",
    amountProcessed: "N20,000",
    numOfServices: 10,
    amountSettled: "N20,000",
    numOfSubAcct: 15,
  },

  {
    id: 2,
    binessName: "Tiamiyu Digitals",
    category: "Finance",
    amountProcessed: "N20,000",
    numOfServices: 10,
    amountSettled: "N20,000",
    numOfSubAcct: 15,
  },

  {
    id: 3,
    binessName: "Tiamiyu Digitals",
    category: "Finance",
    amountProcessed: "N20,000",
    numOfServices: 10,
    amountSettled: "N20,000",
    numOfSubAcct: 15,
  },

  {
    id: 4,
    binessName: "Tiamiyu Digitals",
    category: "Finance",
    amountProcessed: "N20,000",
    numOfServices: 10,
    amountSettled: "N20,000",
    numOfSubAcct: 15,
  },

  {
    id: 5,
    binessName: "Tiamiyu Digitals",
    category: "Finance",
    amountProcessed: "N20,000",
    numOfServices: 10,
    amountSettled: "N20,000",
    numOfSubAcct: 15,
  },
  {
    id: 6,
    binessName: "Tiamiyu Digitals",
    category: "Finance",
    amountProcessed: "N20,000",
    numOfServices: 10,
    amountSettled: "N20,000",
    numOfSubAcct: 15,
  },
  {
    id: 7,
    binessName: "Tiamiyu Digitals",
    category: "Finance",
    amountProcessed: "N20,000",
    numOfServices: 10,
    amountSettled: "N20,000",
    numOfSubAcct: 15,
  },
  {
    id: 8,
    binessName: "Tiamiyu Digitals",
    category: "Finance",
    amountProcessed: "N20,000",
    numOfServices: 10,
    amountSettled: "N20,000",
    numOfSubAcct: 15,
  },
  {
    id: 9,
    binessName: "Tiamiyu Digitals",
    category: "Finance",
    amountProcessed: "N20,000",
    numOfServices: 10,
    amountSettled: "N20,000",
    numOfSubAcct: 15,
  },
  {
    id: 10,
    binessName: "Tiamiyu Digitals",
    category: "Finance",
    amountProcessed: "N20,000",
    numOfServices: 10,
    amountSettled: "N20,000",
    numOfSubAcct: 15,
  },
];
