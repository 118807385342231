import BreakPoints from "shared/utils/Breakpoint";
import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color } from "../../../shared/utils/styles";

type boxPropT = {
  background: boolean;
};
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 1px;
  grid-gap: 2rem;

  @media (${BreakPoints.xs}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    /* border: 2px solid red; */
  }
`;

export const Box = styled.div<boxPropT>`
  background-color: ${(prop) =>
    prop.background ? color.fountain : "rgba(255, 255, 255, 0.8)"};
  color: ${(prop) => (prop.background ? "#fff" : "#000")};
  display: flex;
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.48rem 1.4rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all ease 0.4s;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    background-color: ${(prop) => (prop.background ? "white" : color.fountain)};
    color: ${(prop) => (prop.background ? color.fountain : "#fff")};
  }

  @media (${BreakPoints.xs}) {
    width: 100%;
    height: 160px;
    padding: 1.48rem 1rem;
    /* border: 2px solid red; */
  }
`;

export const BoxIcon = styled.img`
  width: 2.8rem;
  height: 2.8rem;

  @media (${BreakPoints.xs}) {
    position: relative;
    top: 1rem;
    width: 2.2rem;
    height: 2.2rem;
    margin-bottom: 0.2rem;
  }
`;

export const BoxTitle = styled.span`
  margin-top: 1rem;
  padding-top: 0.2rem;
  font-size: 0.95rem;

  @media (${BreakPoints.xs}) {
    font-size: 0.8rem;
    margin-top: 1.4rem;
  }
`;

export const BoxValue = styled.span`
  display: block;
  margin-top: 1.8rem;
  padding-bottom: 0.4rem;
  font-weight: 900;

  @media (${BreakPoints.xs}) {
    position: relative;
    bottom: 0.8rem;
  }
`;
