import React from "react";
import { CheckBox, CheckBoxLabel, CheckBoxWrapper } from "./Styles";

type SwitchPropT = {
  checked: boolean;
  toogle: (id: any) => void;
  id: any;
  bgColor?: string;
};

export default function SwitchButton({
  checked,
  toogle,
  id,
  bgColor,
}: SwitchPropT) {
  return (
    <CheckBoxWrapper>
      <CheckBox
        // id="checkbox"
        id={id}
        type="checkbox"
        checked={checked}
        onChange={() => console.log("")}
        bgColor={bgColor}
      />
      <CheckBoxLabel htmlFor="checkbox" onClick={() => toogle(id)} />
    </CheckBoxWrapper>
  );
}
