import React, { useState, useLayoutEffect } from "react";
import {
  StyledWrapper,
  StyledButton,
  StyledContent,
  Item,
  ButtonText,
} from "./Styles";
import Icon from "../../components/Icon";

type selectPropT = {
  children?: React.ReactNode;
  value?: string;
  variant?: string;
  bgColor?: string;
  btn?: string;
};

export function Select({
  children,
  value,
  variant,
  bgColor,
  btn,
}: selectPropT) {
  const [visibility, setvisibility] = useState<boolean>(true);

  useLayoutEffect(() => {
    // Watching the changes on incoming 'value" prop
    setvisibility(!visibility);
  }, [value]);

  return (
    <StyledWrapper>
      <StyledButton
        onClick={() => setvisibility(!visibility)}
        variant={variant}
        btn={btn}
      >
        <ButtonText>{value ? value : "select"}</ButtonText>

        <div style={{ marginTop: "0.2rem" }}>
          <Icon type="chevron-down" />
        </div>
      </StyledButton>
      <StyledContent
        visible={visibility}
        variant={variant}
        bgColor={bgColor ? bgColor : "#fff"}
      >
        {children}
      </StyledContent>
    </StyledWrapper>
  );
}

type optionPropT = {
  children: string;
  onClick: any;
  variant?: string;
};
export function Option({ children, onClick, variant }: optionPropT) {
  return (
    <>
      <Item onClick={onClick} variant={variant}>
        {children}
      </Item>
    </>
  );
}
