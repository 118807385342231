import React from "react";
import nowifi from "../../../App/assets/images/no-wifi.png";
import { Container, Img, Title, Text } from "./Styles";

export default function NoInternet() {
  return (
    <Container>
      <Img src={nowifi} alt="no internet" />
      <Title>Whoops!!</Title>
      <Text>
        No internet connection found. Check your coneection or try again
      </Text>
    </Container>
  );
}
