import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "Layout";
import useApi from "shared/hooks/api";
import Kycicon1 from "../../Assets/kycicon1.png";
import Kycicon2 from "../../Assets/kycicon2.png";
import { getCurrentUser } from "shared/utils/authToken";

import {
  Box,
  BoxGrid,
  BoxTitle,
  BoxValue,
  Container,
  EditBtn,
  FormGroup,
  FormInput,
  FormInputGroup,
  FormTitle,
  GroupedBtn,
  IconContainer,
  InputLabel,
  ModalBtn,
  PageContent,
  PageHeader,
  PageHeaderButton,
  Pagetitle,
  PlanStatus,
  PlusIcon,
  PriceFormContainer,
  PricingForm,
  SubTitle,
  TableAction,
  TableActions,
  Title,
  ViewAllBtn,
} from "../Styles";
import Table from "shared/clientcomponents/Table";
import SharedCheckList from "shared/clientcomponents/SharedCheckList";
import Modal from "shared/quickComponents/modal";
import Spinner from "shared/components/Spinner";
import { pricingOBJ } from "Pricing/pricingClass";
import { EmptyState, GradientLoader } from "shared/components";
import { toast } from "react-toastify";
import { OtpContainer } from "Pricing/Single/Styles";
import OTPInput from "shared/components/OTPInput";
import { numberFormat, numberIntFormat } from "shared/utils/utility";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { useEffectOnce } from "shared/hooks/useEffectOnce";
import {
  AuxOtpText,
  OtpBtn,
  OtpBtnContainer,
} from "Auth/pages/ReceiveOtp/Styles";
import SelectField from "Business/AddBusiness/SelectField";

const tableHeader: any = [
  { id: "pricingName", label: "Name" },
  { id: "valueType", label: "Type" },
  { id: "pricingType", label: "Channel" },

  { id: "pricingValue", label: "Value" },
  { id: "flatBenchmark", label: "Benchmark" },
  { id: "cap", label: "Cap" },
  { id: "funcProps", label: "Status" },
];

export default function Home() {
  const [planData, setplanData] = useState([]);
  const [localPlan, setlocalPlan] = useState([]);
  const [intlPlan, setintlPlan] = useState([]);

  const [isModalOpen, setisModalOpen] = useState(false);
  const [country, setCountry] = useState<any>({});
  const [pricingName, setpricingName] = useState("");
  const [percentageValue, setpercentageValue] = useState("");
  const [fixedValue, setfixedValue] = useState("");
  const [benchmark, setBenchmark] = useState("0");
  const [cap, setCap] = useState("0");
  const [currency, setCurrency] = useState("NGN");
  const [isPublic, setisPublic] = useState(true);
  const [isPrivate, setisPrivate] = useState(false);
  const [isLocal, setisLocal] = useState(true);
  const [totalLocal, settotalLocal] = useState(0);
  const [totalIntl, settotalIntl] = useState(0);

  const [isInward, setiIsInward] = useState(true);
  const [isOutward, setIsOutward] = useState(false);
  const [isMandate, setIsMandate] = useState(false);
  const [isEducation, setIsEducation] = useState(true);


  const [isInternational, setisInternational] = useState(false);

  const [planType, setplanType] = useState("");
  const [showActivityIndicator, setshowActivityIndicator] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [pageAction, setpageAction] = useState("CREATE");
  const [selectedPlanID, setSelectedPlanID] = useState("");
  const [Otp, setOtp] = useState("");
  const [isOtpModalOpen, setisOtpModalOpen] = useState(false);
  const [isOTPError, setisOTPError] = useState(false);
  const [tempId, settempID] = useState("");
  const [action, setaction] = useState("");
  const [selectedPlan, setselectedPlan] = useState<any>("");
  const [isnoRecord, setisnoRecord] = useState(true);

  const [searchContent, setSearchContent] = useState<string | undefined>();
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");
  const [pinBox, setPinBox] = useState(1);
  const [countries, setCountries] = useState<any>([])

  const currentUser = getCurrentUser(); //Get current login user

  const currencies: any=[
    { label: "NGN", value: "NGN" },
    { label: "USD", value: "USD" },
    { label: "EURO", value: "EURO" },
    { label: "POUNDS", value: "POUNDS" },
  ]

  let btnText = pageAction === "CREATE" ? "Create" : "Edit";

  const closeModal = () => {
    setisModalOpen(false);
    setfixedValue("");
    setpercentageValue("");
    setpricingName("");
    setshowActivityIndicator(false);
  };

  //Controlling otp modal
  const openModal = () => {
    setisOtpModalOpen(false);
  };
  

  const getCountries=async()=>{
    const defat=Intl.DateTimeFormat().resolvedOptions();

    const Default=new Intl.Locale(defat.locale).region;
    //console.log("Browser country: ", Default)
    const response: any= await pricingOBJ.getAllCountry();
    //console.log("Countries: ", response)
    if (response) {
      let defaultCountry=response.find((ct: any)=>ct.isoAlpha2==Default);
      //console.log("Browser country: ", defaultCountry)
      setCountry(defaultCountry)
      setCountries(response)
    }
  }

  const getplans = async () => {
    setisLoading(true);
    const response: any = await pricingOBJ.getAllPricing(currentPage);
    //console.log("Plan data: ", response)
    if (response?.data) {
      setisLoading(false);
      settotalPages(parseInt(response.totalPages));
      // const Localplans = response?.data.filter(
      //   (obj: any) => obj?.currencyType === "LOCAL"
      // );

      setlocalPlan(response?.data);
      settotalLocal(response?.data.length);

      // const Intlplans = response?.data.filter(
      //   (obj: any) => obj?.currencyType === "INTERNATIONAL"
      // );

      // setintlPlan(Intlplans);
      // settotalIntl(Intlplans.length);
      setplanData(localPlan);//pinBox == 1 ? Localplans : Intlplans
      // setcurrentPage(parseInt(data?.payload?.currentPage));
      // console.log("plans ===>", Localplans);

      setisnoRecord(false);
    } else {
      setisLoading(false);
      setisnoRecord(true);
    }
  };

  //====== Handle OTP ======
  const handleOTP = async (otp: string) => {
    console.log("otp====>", otp);
    // setOtp(otp);
    if (otp.length === 6) {
      console.log("OTP=====>", otp, action, tempId);
      const otpResponse = await pricingOBJ.confirmOTP({
        otp,
        action,
        tempId,
      });
      console.log("confirm otp response ====>", otpResponse);
      if (otpResponse?.status) {
        openModal();
        //toast.success(otpResponse?.message);
        getplans();
      } // else {
      //   toast.error(otpResponse?.message);
      // }
    }
  };

  //==== Function for edit button
  const handleEdit = (plan: any, action: any) => {
    setpageAction(action);
    setisModalOpen(true);
    setpricingName(plan.name);
    plan.status === "PRIVATE" ? setisPrivate(true) : setisPrivate(false);
    plan.status === "PUBLIC" ? setisPublic(true) : setisPublic(false);
    setplanType(plan.type);
    setpercentageValue(plan.value.percentage);
    setfixedValue(plan.value.flat);
    setSelectedPlanID(plan.id);
  };

  //===== This handles submit button on modal ==========
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setshowActivityIndicator(true);
    if (!percentageValue && !fixedValue) {
      toast.error("Percentage field and Fixed field can't be empty");
      setshowActivityIndicator(false);
    } else {
      let pricingType = "";
      let channel='';
      let status = "";
      if (percentageValue) pricingType = "PERCENTAGE";
      if (fixedValue) pricingType = "FLAT";
      if (percentageValue && fixedValue) pricingType = "DYNAMIC";

      if (isInward) channel = "INWARD";
      if (isOutward) channel = "OUTWARD";
      if (isMandate) channel = "MANDATE";
      delete country.flag
      const payload = {
        name: pricingName,
        country: country.isoAlpha2,
        currency,
        benchmark,
        cap,
        channel,
        default_value: {
          percentage: percentageValue ? percentageValue : 0,
          flat: fixedValue ? fixedValue : 0,
          flatBenchmark: benchmark,
          cap
        },
        type: pricingType ? pricingType : planType,
      };
      
      //switch is based on page action state
      switch (pageAction) {
        case "EDIT":
          const adminEmail = currentUser.email;
          const pricingId = selectedPlanID;
          const pricingPayload = {
            name: pricingName,
            status: isPublic ? "PUBLIC" : "PRIVATE",
            value: {
              percentage: percentageValue ? percentageValue : 0,
              flat: fixedValue ? fixedValue : 0,
            },
          };
          // // console.log("Edited payload ==>", payload, pageAction);
          const feedback = await pricingOBJ.sendOTP({
            adminEmail,
            pricingId,
            pricingPayload,
          });
          console.log("Feedback: ", feedback);
          if (feedback?.status) {
            //toast.success("OTP sent successfully!.");
            // console.log("feedback data ====>", pricingPayload);
            settempID(feedback.payload?.tempId);
            setaction("UPDATE_PRICING_PLAN");
            closeModal();
            setisOtpModalOpen(true);
            // getplans();
          }
          break;

        default:
          
          const response = await pricingOBJ.addPricing(payload);
          //console.log("Response: ", response);
          if (response.status) {
            // fetchData();
            closeModal();
            toast.success(response?.message);
            getplans();
          } else {
            // fetchData();
            //closeModal();
            //getplans();
            toast.error(response.message);
          }
          break;
      }
    }
  };
  //==========================================================

  //=== This function check and format pricing plan value field
  const displayValue = (priceType: any, priceValue: any) => {
    if (priceType === "PERCENTAGE") return `${priceValue?.percentage}%`;
    if (priceType === "FLAT") {
      if (pinBox == 2) {
        return numberIntFormat(priceValue?.flat);
      } else {
        return numberFormat(priceValue?.flat);
      }
    }
    if (priceType === "DYNAMIC")
      if (pinBox == 2) {
        return `${priceValue.percentage}% + ${numberIntFormat(
          priceValue?.flat
        )}`;
      } else {
        return `${priceValue.percentage}% + ${numberFormat(priceValue?.flat)}`;
      }
  };

  //=== This function create plan list for table
  
  const planList: any =
    planData.length &&
    planData?.map((plan: any, index: number) => ({
      sn: index + 1,
      pricingName: plan.name,
      pricingType: plan.channels,
      flatBenchmark: plan.defaultValue.flatBenchmark,
      cap: plan.defaultValue.cap,
      valueType: plan.valueType,
      pricingValue: displayValue(plan.type, plan.value),
      funcProps: (
        <TableActions>
          <PlanStatus>{plan.status}</PlanStatus>
          <GroupedBtn>
            {/* pass plan into handleEdit function */}
            <EditBtn
              onClick={() => {
                handleEdit(plan, "EDIT");
              }}
            >
              Edit
            </EditBtn>
            <Link to={`/pricing/overview/${plan.id}`}>
              <ViewAllBtn>View</ViewAllBtn>
            </Link>
          </GroupedBtn>
        </TableActions>
      ),
    }));

  const handleFilter = (searchData: any) => {
    setSearchContent(searchData);
    const newlist = planData.filter((plan: any) => {
      if (
        plan.name
          .toString()
          .toLowerCase()
          .indexOf(searchData.toString().toLowerCase()) > -1
      )
        return plan;
    });
    if (newlist.length) setplanData(newlist);

    if (!searchData) {
      if (pinBox == 2) setplanData(intlPlan && intlPlan);
      // reset data
      else setplanData(localPlan && localPlan);
    }
  };

  useEffect(()=>{
    getCountries();
  }, []);

  useEffect(() => {
    if (pinBox != 3) {
      getplans();
    }
  }, [currentPage, pinBox]);

  return (
    <>
      {/* ======= Modal ============== */}
      <Modal
        isOpen={isModalOpen}
        closeButton={true}
        onClose={closeModal}
        width="55%"
        marginTop="2rem"
      >
        <PriceFormContainer>
          <FormTitle>
            {pageAction === "EDIT" ? "Edit pricing" : "Add Pricing"}
          </FormTitle>
          <PricingForm onSubmit={handleSubmit}>
            <FormGroup>
              <FormInputGroup>
                <SelectField
                  value={country?.name}
                  setValue={(value: any) => setCountry(value)}
                  label="Select pricing country"
                  data={countries}
                />
              </FormInputGroup>
              <FormInputGroup>
                <SelectField
                  value={currencies?.label}
                  setValue={(value: any) => setCurrency(value)}
                  label="Currency"
                  data={currencies}
                />
              </FormInputGroup>
            </FormGroup>
            <FormInputGroup>
              <InputLabel>Pricing Name</InputLabel>
              <FormInput
                type="text"
                placeholder="Enter pricing name"
                value={pricingName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setpricingName(event.target.value);
                }}
              />
            </FormInputGroup>

            <FormGroup>
              <FormInputGroup>
                <InputLabel>Percentage (%)</InputLabel>
                <FormInput
                  type="text"
                  placeholder=""
                  value={percentageValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setpercentageValue(event.target.value);
                  }}
                />
              </FormInputGroup>

              <PlusIcon>+</PlusIcon>
              <FormInputGroup>
                <InputLabel>Fixed</InputLabel>
                <FormInput
                  type="text"
                  placeholder=""
                  value={fixedValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setfixedValue(event.target.value);
                  }}
                />
              </FormInputGroup>
            </FormGroup>
            <FormGroup>
              <FormInputGroup>
                <InputLabel>Benchmark</InputLabel>
                <FormInput
                  type="text"
                  placeholder=""
                  value={benchmark}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setBenchmark(event.target.value);
                  }}
                />
              </FormInputGroup>
              <FormInputGroup>
                <InputLabel>Cap</InputLabel>
                <FormInput
                  type="text"
                  placeholder=""
                  value={cap}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCap(event.target.value);
                  }}
                />
              </FormInputGroup>
            </FormGroup>

            {pageAction !== "EDIT" && (
              <FormInputGroup>
                <InputLabel>Price type:</InputLabel>
                <TableAction>
                  <SharedCheckList
                    onChange={() => {
                      setiIsInward(true);
                      setIsMandate(false);
                      setIsOutward(false);
                    }}
                    checked={isInward}
                    label={"Inward"}
                    id={`2`}
                  />

                  <SharedCheckList
                    onChange={() => {
                      setiIsInward(false);
                      setIsMandate(true);
                      setIsOutward(false);
                    }}
                    checked={isMandate}
                    label={"Mandate"}
                    id={`2`}
                  />
                  <SharedCheckList
                    onChange={() => {
                      setiIsInward(false);
                      setIsMandate(false);
                      setIsOutward(true);
                    }}
                    checked={isOutward}
                    label={"Outward"}
                    id={`2`}
                  />
                </TableAction>
              </FormInputGroup>
            )}

            {pageAction === "EDIT" && (
              <FormInputGroup>
                <InputLabel>Status:</InputLabel>
                <TableActions>
                <SharedCheckList
                    onChange={() => {
                      setiIsInward(true);
                      setIsMandate(false);
                      setIsOutward(false);
                    }}
                    checked={isInward}
                    label={"Inward"}
                    id={`1`}
                  />

                  <SharedCheckList
                    onChange={() => {
                      setiIsInward(false);
                      setIsMandate(true);
                      setIsOutward(false);
                    }}
                    checked={isMandate}
                    label={"Mandate"}
                    id={`1`}
                  />
                  <SharedCheckList
                    onChange={() => {
                      setiIsInward(false);
                      setIsMandate(false);
                      setIsOutward(true);
                    }}
                    checked={isOutward}
                    label={"Outward"}
                    id={`1`}
                  />
                </TableActions>
              </FormInputGroup>
            )}
            <ModalBtn type="submit">
              {showActivityIndicator ? <Spinner color="#fff" /> : btnText}
            </ModalBtn>
          </PricingForm>
        </PriceFormContainer>
      </Modal>
      {/* ============== modal ends ============== */}

      <Container>
        <PageHeader>
          <BoxGrid>
            <Box
              key={1}
              isActive={pinBox === 1 ? true : false}
              onClick={() => {
                setPinBox(1);
              }}
            >
              <IconContainer isActive={true}>
                <img src={pinBox === 1 ? Kycicon2 : Kycicon1} alt="" />
              </IconContainer>
              <BoxTitle>Local pricing</BoxTitle>
              <BoxValue>{totalLocal}</BoxValue>
            </Box>

            <Box
              key={2}
              isActive={pinBox === 2 ? true : false}
              onClick={() => {
                setPinBox(2);
              }}
            >
              <IconContainer isActive={true}>
                <img src={pinBox === 2 ? Kycicon2 : Kycicon1} alt="" />
              </IconContainer>
              <BoxTitle>International pricing</BoxTitle>
              <BoxValue>{totalIntl}</BoxValue>
            </Box>

            <Box
              key={3}
              isActive={pinBox === 3 ? true : false}
              onClick={() => {
                setPinBox(3);
                setpageAction("CREATE");
                setisModalOpen(true);
              }}
            >
              <IconContainer isActive={true}>
                <img src={pinBox === 3 ? Kycicon2 : Kycicon1} alt="" />
              </IconContainer>
              <BoxTitle>Add plan</BoxTitle>
              <br />
              {/* <BoxValue>0</BoxValue> */}
            </Box>
          </BoxGrid>
        </PageHeader>
        <PageContent>
          {isLoading ? (
            <GradientLoader />
          ) : (
            <>
              {isnoRecord ? (
                <EmptyState stateTitle="No Data" statePara="" stateBtnText="" />
              ) : (
                <>
                  <DashboardSubHeader
                    // count={totalBusinesses ? totalBusinesses : 0}
                    title={"Price"}
                    btnTitle=""
                    withSearch={true}
                    placeholder="Pricing Name"
                    withFilter={false}
                    widthActionBtn={false}
                    filterOptions={["Name", "Date"]}
                    setSearchData={handleFilter}
                    callBackFn={() => ""}
                    searchContent={searchContent}
                  />
                  <Table
                    headData={tableHeader}
                    bodyData={planData? planList: []}
                    totalPages={totalPages}
                    currentpage={currentPage}
                    goToPage={(value: any) => {
                      setcurrentPage(value);
                      // getKYCList(value);
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
      </Container>

      {/* =======OTP MODAL */}
      <Modal
        isOpen={isOtpModalOpen}
        closeButton={true}
        onClose={openModal}
        width="40%"
      >
        <div style={{ paddingBottom: "2rem" }}>
          <Title>Check your email for OTP</Title>
          <SubTitle>
            We have sent a 6 - digit code to your email address. <br /> The code
            expires shortly, please enter it soon.
          </SubTitle>
          <OtpContainer>
            {" "}
            <OTPInput
              autoFocus
              isNumberInput
              length={6}
              hasError={isOTPError}
              errorStyle={{
                borderColor: "red",
              }}
              inputStyle={{
                border: "1px solid #828282",
                width: "60px",
                height: "60px",
                fontSize: "30px",
                color: "#000",
                fontWeight: "400",
                caretColor: "#000",
                textAlign: "center",
                borderRight: "0px",
              }}
              focusStyle={
                {
                  //border: "1px solid #CFD3DB",
                }
              }
              firstIndexStyle={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderRight: "0px",
              }}
              lastIndexStyle={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderRight: "1px solid #828282",
                borderLeft: "1px solid #828282",
              }}
              className="otpContainer"
              inputClassName="otpInput"
              separator={
                <span
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "10%",
                    fontSize: "50px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  -
                </span>
              }
              onChangeOTP={handleOTP}
            />
          </OtpContainer>
          <OtpBtnContainer>
            <OtpBtn onClick={() => {}}>
              You didn't get an OTP ?<AuxOtpText> Resend</AuxOtpText>
            </OtpBtn>
          </OtpBtnContainer>
        </div>
      </Modal>
    </>
  );
}
