// @ts-ignore
import { CSVLink, CSVDownload } from "react-csv";
import { ActionButton } from "../DashboardSubHeader/Styles";
const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"],
];
export default ({ children, data }: any) => {
  return (
    <>
      <CSVLink
        filename={`Download Summary${new Date().getTime()}.csv`}
        data={data}
        target="_blank"
      >
        <ActionButton>{children}</ActionButton>
      </CSVLink>
    </>
  );
};
