import {
  CloseButton,
  CloseCont,
  Overlay,
  OverlayContent,
  OverlayContentCont,
} from "./styles";
import React from "react";

export default function PageOverlay({
  isToogled,
  toogleOverlay,
  children,
}: any) {
  return (
    <Overlay isCloseClicked={isToogled}>
      <OverlayContentCont>
        <CloseCont>
          <CloseButton onClick={() => toogleOverlay()}>&#x2715;</CloseButton>
        </CloseCont>
        <OverlayContent>{children}</OverlayContent>
      </OverlayContentCont>
    </Overlay>
  );
}
