import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import logo from "../Assets/logo.svg";
import banner from "./assets/banner.png";

import {
  BannerContainer,
  Container,
  FormContainer,
  InputContainer,
  InputLabel,
  CPBanner,
  CPButton,
  CPForm,
  CPInput,
  CPPageContainer,
  LoginPageSpan,
  LogoContainer,
  LogoImage,
  Theform,
  WelcomeText,
  InputBox,
  IconImage,
  PasswordError,
  SuccessImg,
  VaultImage,
  CoverWatermark,
  ModalContent,
} from "./Styles";

import keyicon from "./assets/Key.svg";
import vault from "./assets/vault.svg";
import { EyeClosed, EyeOpen } from "shared/quickComponents/EyeIcons";
import successfullImg from "./assets/successful.gif";
import successImg from "./assets/success.gif";
import { passwordStrength } from "shared/utils/javascript";
import Modal from "shared/quickComponents/modal";
import PasswordMeter from "Auth/pages/PasswordMeter";
import api from "shared/utils/api";
import { Message } from "../shared/components/ConfirmModal/Styles";
import { toast } from "react-toastify";
import passwordOBJ from "./createPasswordClass";

export default function CreatePassword() {
  const [userPassword, setuserPassword] = useState<string>("");
  const [confirmPassword, setconfirmPassword] = useState<string>("");
  const [passwordStatus, setpasswordStatus] = useState<boolean>(false);
  const [showPassword, setshowPassword] = useState<boolean>(false);
  const [error, seterror] = useState<string>("");
  const [isModalOpen, setisModalOpen] = useState<boolean>(false);
  const [btnText, setbtnText] = useState("SUBMIT");
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const history = useHistory();
  const { id } = useParams<{ id: any }>();

  //====================== Page actions =======================
  const handleValidation = (e: React.KeyboardEvent): void => {
    if (userPassword !== confirmPassword) {
      seterror("Error: Your passwords don't match");
    } else {
      const pwdStrength = passwordStrength(userPassword); //Check password strength
      if (pwdStrength.status === false) {
        setpasswordStatus(false);
        seterror(pwdStrength.message);
      } else {
        setpasswordStatus(true);
        seterror("");
      }
    }
  };

  const handleCreatePassword = (e: any) => {
    e.preventDefault();
    // // console.log(userEmail, userPassword);
  };

  const handleCloseModal = () => {
    history.push("/authenticate");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (passwordStatus) {
      try {
        setbtnText("Processing...");
        // console.log("id: ", id)
        const response = await api.post(`/set-password?verification=${id}`, {
          password: userPassword,
          confirm_password: confirmPassword,
        });

        // console.log("ress", response);
        toast.success("Your password has been created successfully.");
        
        setTimeout(() => {
          setbtnText("SUBMIT");
          setisModalOpen(!isModalOpen);
          history.push("/autheticate/login");
        }, 2000);
      } catch (error) {
        toast.error(`${error}`);
        setbtnText("SUBMIT");
      } 
    }
  };
  //==================================================================

  useEffect(() => {
    // console.log(`${isPasswordValid} && (${userPassword}===${confirmPassword})`)
    if (isPasswordValid && userPassword === confirmPassword) {
      setpasswordStatus(true);
    } else {
      setpasswordStatus(false);
    }
  }, [isPasswordValid, userPassword, confirmPassword]);

  return (
    <div>
      <Modal closeButton={true} isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <h2>Account successfully created !</h2>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <SuccessImg src={successImg} />
          </div>
          <CoverWatermark></CoverWatermark>
        </ModalContent>
      </Modal>
      <CPPageContainer>
        <Container>
          <FormContainer>
            <LogoContainer>
              <LogoImage src={logo} alt="fountainpay logo" />
            </LogoContainer>

            <CPForm>
              <WelcomeText>Create Password</WelcomeText>
              <Theform onSubmit={handleSubmit}>
                <InputContainer>
                  <InputLabel>Enter Password</InputLabel>

                  <InputBox>
                    <IconImage src={keyicon} alt="" />
                    <CPInput
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={userPassword}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setuserPassword(event.target.value);
                      }}
                      required
                    />
                  </InputBox>
                </InputContainer>
                <InputContainer>
                  <InputLabel>Confirm Password</InputLabel>
                  <InputBox>
                    <IconImage src={keyicon} alt="" />
                    <CPInput
                      type={showPassword ? "text" : "password"}
                      placeholder="Confirm password"
                      id="confirm_password"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setconfirmPassword(event.target.value);
                      }}
                      required
                    />
                    <span
                      onClick={() => setshowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <EyeOpen height="1rem" />
                      ) : (
                        <EyeClosed size="1.4rem" />
                      )}
                    </span>
                  </InputBox>
                </InputContainer>
                <PasswordMeter
                  password={userPassword}
                  setIsPasswordValid={setIsPasswordValid}
                />

                <div style={{ width: "100%", marginTop: "1rem" }}>
                  <CPButton type="submit" disabled={!passwordStatus}>
                    {btnText}
                  </CPButton>
                </div>
              </Theform>
            </CPForm>
          </FormContainer>

          <VaultImage src={vault} alt="" />
        </Container>
        <BannerContainer>
          <CPBanner src={banner} />
        </BannerContainer>
      </CPPageContainer>
    </div>
  );
}
