import styled, { css } from 'styled-components';
import { mixin } from "shared/utils/styles";
import Icon from "shared/components/Icon";

type PropT={
    isVisible?: boolean;
}
export const CalendaContainer=styled.div`
    //background-color: #000;
    font-family: sans-serif;
    text-align: center;
    margin-top:5px;
    margin-bottom: 15px;
    width: 100%;
`;

export const DateInputContainer=styled.div`
    padding:4px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    gap: 5px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    border-radius: 7px;
    width: 100% !important;
`;

export const DateInput=styled.input.attrs({ 
    type: 'text',
  })`
    height: 40px;
    border: 0px solid #ccc;
    width:90%;
`;

export const Container=styled.div<PropT>`
    width: 100%;
    display: none;
    position: absolute;
    top:40;
    left: 10
    ${props => props.isVisible && css`
        display: block;  
    `};
`;

export const CloseIcon = styled(Icon)<PropT>`
  font-size: 25px;
  background-color: #fff;
  color: #ccc;
  transition: all 0.1s;
  margin-top: 6px;
  ${mixin.clickable};
  &:hover{
    color: #000;
  }
`;
