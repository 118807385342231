import { useState } from "react";
import Modal from "shared/quickComponents/modal";
import {
  ModalContentLeft,
  ModalContentRight,
  ModalContentRow,
  TableContent,
  TableRow,
  UserModal,
  ViewBtn,
} from "./Styles";

type UserRowProp = {
  User: {
    id: number;
    activityLog: string;
    description: string;
    agent: string;
    time: String;
  };
};

export default function AccountRow({ User }: UserRowProp) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <TableRow>
        <TableContent>{User.activityLog}</TableContent>
        <TableContent>{User.description}</TableContent>
        <TableContent>{User.agent}</TableContent>
        <TableContent>{User.time}</TableContent>
        <TableContent alignEnd={false}>
          <ViewBtn onClick={() => setShowModal(true)}>View</ViewBtn>
        </TableContent>
      </TableRow>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(!showModal)}
        closeButton={true}
      >
        <UserModal>
          <ModalContentRow>
            <ModalContentLeft>Activity Log: </ModalContentLeft>
            <ModalContentRight>{User.activityLog} </ModalContentRight>
          </ModalContentRow>
          <ModalContentRow>
            <ModalContentLeft>Activity Description: </ModalContentLeft>
            <ModalContentRight>{User.description} </ModalContentRight>
          </ModalContentRow>
          <ModalContentRow>
            <ModalContentLeft>Agent: </ModalContentLeft>
            <ModalContentRight>{User.agent} </ModalContentRight>
          </ModalContentRow>
          <ModalContentRow>
            <ModalContentLeft>Time: </ModalContentLeft>
            <ModalContentRight>{User.time} </ModalContentRight>
          </ModalContentRow>
          <ModalContentRow>
            <ModalContentLeft>Date: </ModalContentLeft>
            <ModalContentRight>{User.time} </ModalContentRight>
          </ModalContentRow>
        </UserModal>
      </Modal>
    </>
  );
}
