import React from 'react';
import PropTypes from 'prop-types';
import { xor } from 'lodash';

import {
  Filters,
  SearchInput
} from './Styles';



const ProjectBoardFilters = ({ projectUsers, defaultFilters, filters, mergeFilters }: any) => {
  const { searchTerm, userIds, myOnly, recent } = filters;

  const areFiltersCleared = !searchTerm && userIds.length === 0 && !myOnly && !recent;

  return (
    <Filters data-testid="board-filters">
      <SearchInput
        icon="search"
        value={searchTerm}
        onChange={(value: string) => mergeFilters({ searchTerm: value })}
      />
    </Filters>
  );
};


export default ProjectBoardFilters;
