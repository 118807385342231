import api from "shared/utils/api";

class Dispute {
  async getAllDispute() {
    try {
      const response = await api.get("/dispute");
      //console.log("disputes", response);
      if (response?.payload) return response?.payload;
    } catch (error) {
      //console.log("disputes error", error);
    }
  }
  async updateStatus(id: any, data: any) {
    try {
      console.log("disputes status payload", id, data);
      const response = await api.put(`/dispute/update/${id}`, data);
      if (response.status) return true;
    } catch (error) {
      return false;
      console.log("error on update ==>", error);
    }
  }

  async getLineChart() {
    try {
      const response = await api.get("/dispute/line-chart");
      if (response?.payload) return response.payload;
    } catch (error) {
      console.log("line chart error", error);
    }
  }

  async getPieChart() {
    try {
      const response = await api.get("/dispute/bar-chart");
      if (response?.payload) return response.payload;
    } catch (error) {
      console.log("line chart error", error);
    }
  }
}

const disputOBJ = new Dispute();
export default disputOBJ;
