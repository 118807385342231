import { color } from "shared/utils/styles";
import styled from "styled-components";

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 3rem;
`;
export const ListContainer = styled.div<any>`
  display: ${(props) => (props?.show ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.2rem;
  background: rgba(118, 0, 0, 0.06);
  border-radius: 7px;
  margin-bottom: 30px;
  margin-top: 10px;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 50%;
`;
export const FirstColumn = styled.div`
  width: 10%;
`;
export const SecColumn = styled.div`
  padding-left: 16px;
  width: 90%;
  // border: 1px solid #ccc;
`;
export const ThirdColumn = styled.div`
  width: 10%;
`;
