import React, { useRef, useState } from "react";
import FileIcon from "../../Assets/FIleIcon.svg";
import {
  BtnsContainer,
  BusinessForm,
  FieldError,
  FileInputCont,
  FileInputContainer,
  FileInputLabel,
  FormBtn,
  FormTitle,
  TrashBtn,
  UploadBox,
  UploadButton,
  UploadContainer,
  UploadInput,
  UploadLabel,
  UploadedFile
} from "./Styles";
import { toast } from "react-toastify";
import BackBtn from "./BackBtn";
import api from "shared/utils/api";
import { decryptData } from "shared/utils/security";
import { omniRemover, omniSaver } from "shared/utils/ls";
import consoleLog from "shared/utils/consoleLog";
import { shortenUrl } from "./ellipsis";
import { Icon } from "shared/components";
import { omniGetter } from "shared/utils/ls";

type PropT = {
  setcurrentStep: Function;
  setdoneSteps: Function;
  doneSteps: any[];
  currentStep: number;
};
export default function StepThreeComp({
  setcurrentStep,
  setdoneSteps,
  doneSteps,
  currentStep,
}: PropT) {
  const [ImageUrl, setImageUrl] = useState("");
  const [currentInputClicked, setcurrentInputClicked] = useState("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadingArticle, setIsUploadingArticle] = useState<boolean>(false);
  const [isUploadingOthers, setIsUploadingOthers] = useState<boolean>(false);
  const [article, setArticle] = useState<string>("");
  const [cac, setCAC] = useState<string>("");
  const [others, setOthers] = useState<any>([]);
  const [error, setError] = useState<any>({
    article: false,
    cac: false,
    address: false,
    others: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false)


  const cacInputRef: any = useRef(null);
  const moaInputRef: any = useRef(null);
  const otherInputRef: any = useRef(null);

  const handleCACClick = () => {
    setcurrentInputClicked("cac");
    cacInputRef.current.click();
  };

  const handleMOAClick = () => {
    setcurrentInputClicked("articles");
    moaInputRef.current.click();
  };

  const handleOtherClick = () => {
    setcurrentInputClicked("others");
    otherInputRef.current.click();
  };

  const handleFileChange = async (event: any) => {
    event.preventDefault();
  };
  const handleStepThree = (event: any) => {
    event.preventDefault();
    setcurrentStep(5);
    setdoneSteps([...doneSteps, 4]);
  };

  const uploadFile = async (value: any) => {
    try {
      const res = await api.post("/kyc-upload", value);
      // consoleLog(res, "datta1");
      if (res.data) res.data = decryptData(res.data, false);
      // consoleLog(res, "datta");
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  const handleCacUpload = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }

    try {
      setIsUploading(true);
      let formData = new FormData();
      consoleLog('selected file ', files[0])
      formData.append("certificate", files[0]);
      const response: any = await uploadFile(formData);
      if (response.status) {
        //console.log(response);
        const cacUrl = response.payload?.fileUrl;
        // omniSaver("cac", cacUrl);
        setCAC(cacUrl);
        setIsUploading(false);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploading(false);
      // consoleLog(error.toString());
    }
  };

  const handleArticleUpload = async (files: any) => {
    consoleLog('selected file ', files[0])
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }

    try {
      setIsUploadingArticle(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status) {
        const article = response.payload?.fileUrl;
        // omniSaver("article", article);
        setArticle(article);
        setIsUploadingArticle(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingArticle(false);
      // consoleLog(error.toString());
    }
  };

  

  const handleOthersUpload = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }

    try {
      setIsUploadingOthers(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status) {
        const otherUploadDoc = response.payload?.fileUrl;
        let newUpload: any = [...others, otherUploadDoc];
        // omniSaver("other_Upload_Doc", newUpload);
        setOthers(newUpload);
        setIsUploadingOthers(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingOthers(false);
      // consoleLog(error.toString());
    }
  };

  const deleteItem = (id: number) => {
    const filteredArr = others.filter((cur: any, index: any) => index !== id);
    omniSaver("other_Upload_Doc", filteredArr);
    setOthers(filteredArr);
  };

  const onSubmit = async(e:any) => {
    e.preventDefault()
    
    const businessCreateData = {
      personalKyc: omniGetter('personalKyc'),
      business: omniGetter('businessKyc'),
      bankAccount: omniGetter('bankAccountKyc'),
      uploads: {
        cac,
        moa: article,
        others: others
      }
    }

    setIsLoading(true)

    try {
      const res:any = await api.post('/console/business/create-business',
        {
          data: businessCreateData
        }
      )
      consoleLog('res ', res)
      toast.success(res.message)
      setIsLoading(false)
      setcurrentStep(1)
      omniRemover('personalKyc')
      omniRemover('businessKyc')
      omniRemover('bankAccountKyc')
    } catch (error:any) {
      setIsLoading(false)
      consoleLog('business create error ', error)
      toast.error(error.message)
    }

    // console.log('business create date ', businessCreateData)
  }

  return (
    <div>
      <FormTitle>Upload Documents</FormTitle>
      <BusinessForm onSubmit={onSubmit}>
        <FileInputContainer>
          <div>
            <UploadContainer>
              <UploadLabel>CAC Certificate</UploadLabel>
              <input
                type="file"
                onChange={(e) => handleCacUpload(e.target.files)}
                name="cac"
                id="cac"
                disabled={isUploading}
                hidden
              />
              <UploadBox>
                <Icon
                  type={"upload"}
                  color={"#760000"}
                  style={{ marginRight: 10 }}
                />
                <UploadButton htmlFor="cac">
                  {isUploading
                    ? "Loading"
                    : !cac
                    ? "Click to Add files"
                    : "Change file"}
                </UploadButton>
              </UploadBox>
              {error.cac && (
                <FieldError>
                  Uploading of necessary documents needed for validation
                </FieldError>
              )}
            </UploadContainer>
            {cac && (
              <UploadInput>
                <UploadedFile>{shortenUrl(cac)}</UploadedFile>

                <TrashBtn onClick={() => setCAC("")}>
                  <Icon type={"trash"} color={"black"} />
                </TrashBtn>
              </UploadInput>
            )}  
          </div>
          
          <div>
            <UploadContainer>
              <UploadLabel>
                Memorandum and Article of Association
              </UploadLabel>
              <input
                type="file"
                onChange={(e) => handleArticleUpload(e.target.files)}
                name="article"
                id="article"
                disabled={isUploadingArticle}
                hidden
              />

              <UploadBox>
                <Icon
                  type={"upload"}
                  color={"#760000"}
                  style={{ marginRight: 10 }}
                />
                <UploadButton htmlFor="article">
                  {isUploadingArticle
                    ? "Loading"
                    : !article
                    ? "Click to Add files"
                    : "Change file"}
                </UploadButton>
              </UploadBox>
              {error.article && (
                <FieldError>
                  Uploading of necessary documents needed for validation
                </FieldError>
              )}
            </UploadContainer>
            {article && (
              <UploadInput>
                <UploadedFile>{shortenUrl(article)}</UploadedFile>

                <TrashBtn onClick={() => setArticle("")}>
                  <Icon type={"trash"} color={"black"} />
                </TrashBtn>
              </UploadInput>
            )}  
          </div>

          <div>
            <UploadContainer>
              <UploadLabel>
                Other Documents <br />
                Please provided Int'l Passport, Drivers License or Voters
                Card
              </UploadLabel>
              <input
                type="file"
                onChange={(e) => handleOthersUpload(e.target.files)}
                name="others"
                id="others"
                disabled={isUploadingOthers}
                hidden
              />
              <UploadBox>
                <Icon
                  type={"upload"}
                  color={"#760000"}
                  style={{ marginRight: 10 }}
                />
                <UploadButton htmlFor="others">
                  {isUploadingOthers ? "Loading" : "Click to Add files"}
                </UploadButton>
              </UploadBox>
              {error.others && (
                <FieldError>
                  Uploading of necessary documents needed for validation
                </FieldError>
              )}
            </UploadContainer>

            {others.map((doc: any, index: number) => {
              return (
                <UploadInput key={index}>
                  <UploadedFile>{shortenUrl(doc)}</UploadedFile>

                  <TrashBtn onClick={() => deleteItem(index)}>
                    <Icon type={"trash"} color={"black"} />
                  </TrashBtn>
                </UploadInput>
              );
            })}  
          </div>
        </FileInputContainer>

        <BtnsContainer>
          <BackBtn
            setcurrentStep={setcurrentStep}
            setdoneSteps={setdoneSteps}
            currentStep={currentStep}
            doneSteps={doneSteps}
          />
          <FormBtn onClick={onSubmit} disabled={isLoading} type="submit">
            Save and Continue
          </FormBtn>
        </BtnsContainer>
      </BusinessForm>
    </div>
  );
}
