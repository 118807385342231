import React, { useEffect, useRef, useState } from "react";
import {
  ActionBtn,
  Calendar,
  CalendarContainer,
  Container,
  DownloadBtn,
  TableContainer,
  TableHeader,
  TableHeaderActionCol,
  TableHeaderCol,
} from "./styles";
import Table from "shared/clientcomponents/Table";
import reportObj from "Report/report-class";
import {
  EmptyState,
  ExportExcel,
  GradientLoader,
  Header,
} from "shared/components";
import { numFormat, numberFormat, numberIntFormat } from "shared/utils/utility";
import { currencyFormat100 } from "shared/utils/moneyFormat";
import { formatDate } from "shared/utils/dateTime";

const today = new Date();
today.setDate(1);
// let startDate: any=new Date(today.getFullYear(), today.getMonth() +1, 1)
let days = String(today.getDate()).padStart(2, "0");
let months = String(today.getMonth() + 1).padStart(2, "0");
let years = today.getFullYear();
today.setDate(1);
const startDate = `${years}-${months}-${days}`;

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const day = String(currentDate.getDate()).padStart(2, "0");
const formattedDate = `${year}-${month}-${day}`;

export default function CBNReport() {
  const [totalPages, settotalPages] = useState<any>("");
  const [currentPage, setcurrentPage] = useState(1);
  const [fromDate, setfromDate] = useState<any>(startDate);
  const [toDate, settoDate] = useState<any>(formattedDate);
  const [isloading, setisloading] = useState(false);
  const [isDataFetched, setisDataFetched] = useState(false);
  const [reportData, setreportData] = useState<any[]>([]);

  const tableHeader = [
    { id: "channelCode", label: "Channel Code" },
    { id: "channels", label: "Channels" },
    { id: "volume", label: "Volume 1" },
    { id: "amount", label: "Value 1" },
    { id: "volumeUSD", label: "Volume 2" },
    { id: "amountUSD", label: "Value 2" },
    { id: "period", label: "Period" },
  ];

  const formatDates = (dateString: any) => {
    const fromdates = new Date(dateString);
    const formattDate = fromdates.toISOString().split("T");

    return formattDate[0];
  };

  const fetchData = async () => {
    console.log("api call");
    setisloading(true);
    const formattedFromDate = formatDates(Date.parse(fromDate));
    const formattedTDate = formatDates(Date.parse(toDate));
    // console.log("from:", formattedFromDate, "To:", formattedTDate);
    const response = await reportObj.getPSSP(formattedFromDate, formattedTDate);
    if (response?.payload.length) {
      const transformedData = response?.payload.map((obj: any, ind: number) => {
        return {
          channelCode: "CHN" + String(ind + 1).padStart(2, "0"),
          channels: obj?.channel,
          volume: obj?.ngnVolume,
          amount: currencyFormat100(obj?.ngnTotal),
          volumeUSD: obj?.usdVolume,
          amountUSD: currencyFormat100(obj?.usdTotal),
          period: obj?.period,
        };
      });

      console.log("Data: ", transformedData);
      setreportData(transformedData);
      setisDataFetched(true);
      setisloading(false);
    } else {
      setreportData([]);
      setisDataFetched(true);
      setisloading(false);
    }
  };

  const handleFilter = async (fromDateStr: string, toDateStr: string) => {
    //make request using dates
    setfromDate(fromDateStr);
    settoDate(toDateStr);
    fetchData();
  };

  useEffect(() => {
    if (!isDataFetched) {
      fetchData();
    }
  }, []);

  return (
    <div>
      <Container>
        {/* <h2>CBN Report</h2> */}
        <Header
          title={"CBN Report"}
          tableHeader={tableHeader}
          reportData={reportData}
          setfromDate={setfromDate}
          settoDate={settoDate}
          handleFilter={fetchData}
        />

        {isloading && <GradientLoader />}

        {/* {!isloading && isDataFetched && reportData.length < 1 &&
          <div style={{ width: "100%", background: "#fff", marginTop: "1rem" }} >
            <EmptyState statePara="NO RECORD" />
          </div>
        } */}
        {!isloading && isDataFetched && (
          <TableContainer>
            <Table
              headData={tableHeader}
              bodyData={reportData}
              rowStyle={{ background: "#ffffff" }}
              totalPages={totalPages}
              currentpage={currentPage}
              goToPage={(value: any) => {
                setcurrentPage(value);
              }}
              // hoverLink={hoverLink}
            />
          </TableContainer>
        )}
      </Container>
    </div>
  );
}
