import axios from "axios";
import {
  getStoredAuthToken,
  removeStoredAuthToken,
  storeAuthToken,
  storeRefreshToken,
} from "shared/utils/authToken";

const authFetch = axios.create({
  baseURL: "https://staging-python.fountainpay.ng",
});

authFetch.interceptors.request.use(
  (request) => {
    request.headers = {
      "Content-Type": "application/json",
      Authorization: getStoredAuthToken()
        ? `Bearer ${getStoredAuthToken()}`
        : "",
    };

    // request.data = request.method !=='get' ? encryptionService.encrypt(request.data) : request.data;
    // console.log("request sent");
    // console.log(request, "request");
    return request;
  },
  (error) => {
    // console.log("App can't make a request");
    return Promise.reject(error);
  }
);

authFetch.interceptors.response.use(
  (response) => {
    // console.log("response received");
    // response.data = encryptionService.decrypt(response?.data);
    if (response?.data?.payload?.accessToken) {
      //Check if there are tokens in payload
      storeAuthToken(response?.data?.payload?.accessToken);
    }
    return response;
  },
  (error) => {
    //we can do something here too
    removeStoredAuthToken();
    return Promise.reject(error);
  }
);

export default authFetch;
