import styled from "styled-components";

export const PageCont = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
`;

export const PolicyField = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PolicyLabel = styled.label`
  width: 10%;
`;

export const PolicyOption = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const OptionLabel = styled.label`
  width: 60%;
`;
