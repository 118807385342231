import { Route, RouteProps, matchPath } from "react-router-dom";

import { GuardFunction } from "./types/guard";
import { getCurrentUser } from "shared/utils/authToken";
import { validateMenu } from "shared/utils/menurecords";

export type ProtectedRouteProps = RouteProps & {
  guards: GuardFunction[];
  fallback: (routeProps: RouteProps) => JSX.Element | null;
};

export const ProtectedRoute = ({
  fallback,
  guards,
  ...rest
}: ProtectedRouteProps) => {
  const { pathname } = window.location;
  const matchResult = matchPath(pathname, rest);
  const hasMatchedRoute = !!matchResult;
  const currentUser: any = getCurrentUser();

  /**
   * Add role Mgt Contraints
   
*/
  let permissions = currentUser?.Role?.permissions ?? [];
  let permittedMenus = permissions[1];
  if (!(permissions[0]?.allOpen || validateMenu(pathname, permittedMenus))) {
    return fallback(rest);
  }

  if (hasMatchedRoute) {
    /**
     * Make sure the user is accessing the path it intends to access
     * before running any guard functions.
     * Match the route first before validating (running the guards)
     */
    const guardArgs = rest;
    const canBeRendered = guards.some((guard) => guard(guardArgs)); //guards.every(guard => guard(guardArgs));

    if (guards.length && !canBeRendered) {
      const fallbackArgs = rest;
      // alert("You do not have the permission to view this page" + pathname);
      // return null;
      console.log("From Guards");
      return fallback(fallbackArgs);
    }
  }

  return <Route {...rest} exact />;
};
