import styled from "styled-components";

import { color, sizes } from "shared/utils/styles";
import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  padding: 10px;
  gap: 2px;
  margin-bottom: 20px;

  @media ${breakpoints.device.max.xs} {
		flex-direction: column;
	}

  @media ${breakpoints.device.max.sm} {
		flex-direction: row;
	}
`;

export const LeftLayoutPanel = styled.div`
  display: flex;
  flex-direction: column;
  border: 0px solid rgba(0, 0, 0, 0.8);
  font-size: 30px;
  text-align: center;
  gap: 15px;
  width: 45%;
  @media ${breakpoints.device.max.xs} {
		padding: 0;
		width: 100%;
	}
`;

export const RightLayoutPanel = styled.div`
  display: flex;
  flex-direction: column;
  border: 0px solid rgba(0, 0, 0, 0.8);
  font-size: 30px;
  text-align: center;
  gap: 0px;
  width:54%;

  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
		width: 100%;
	}
`;

export const Panels = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width:100%;
  
  &>* {
    flex: 0 0 47%;
  }

  @media ${breakpoints.device.min.sm} {
		flex-direction: row;
	}

  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
		width: 100%;
	}
`;
export const PanelTitle = styled.div`
  text-align: left;
  font-size: 18px;
  margin: 10px;
  color: var(--primary-black, #292929);
  /*font-family: Roboto;*/
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  font-weight: 700;
`;

export const AmountSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap:2px;
  width: 100% !important;
  height: 134px;
  padding: 10px 24px;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  &>:first-child{
    color: var(--primary-black, #292929);
    text-align: left;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &>:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
  }
  @media ${breakpoints.device.max.sm} {
		width: 310px !important;
    padding: 10px 24px;
	}

  @media ${breakpoints.device.max.xs} {
		padding: 0;
		width: 100% !important;
    padding: 5px;
	}
`;

export const MainAmount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 11px;
  &>.title {
    color: var(--primary-black, #292929);
    text-align: left;
    width: 100%;

    /* Body Text/16px/regular 
        font-family: Roboto;*/
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media ${breakpoints.device.max.xs} {
      width: 100% !important;
    }
  }
  &>.amount {
    color: #760000;
    text-align: center;
    /*font-family: Roboto;*/
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const MainNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 11px;

  .main {
    color: var(--primary-black, #292929);
    text-align: center;
    /*font-family: Roboto;*/
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .title {
    color: var(--primary-black, #292929);
    text-align: center;
    /*font-family: Roboto;*/
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const GraphAreaLayout = styled.div`
  width: 100%;
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  
  @media ${breakpoints.device.max.sm} {
		width: 310px !important;
	}

  @media ${breakpoints.device.max.xs} {
		padding: 0;
		width: 100% !important;
	}
`;

export const InnerPanelLayout = styled.div`
  width: 100% !important;
  height: 305px;
  overflow-y: auto;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #d7d7d7;

  @media ${breakpoints.device.min.sm} {
		width: 260px;
	}

  @media ${breakpoints.device.max.xs} {
		padding: 0;
		width: 100%;
	}
`;

export const ContentHeader = styled.div`
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  background: #fbfbfb;
  color: var(--primary-black, #292929);
  /* Body Text/16px/regular
    font-family: Roboto; */
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const ContentRow = styled.div`
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  background: #fff;
  border-bottom: 0.2px solid var(--body-color-grey, #828282);
  color: var(--primary-black, #292929);
  /* Body Text/16px/regular
    font-family: Roboto; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  overflow: scroll;
`;

export const ViewAll = styled.div`
  color: #760000;
  /*font-family: Roboto;*/
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const ServiceTabsCont = styled.div`
  width: 100%;
  /* border: 1px solid #ccc; */
  padding: 0.4rem 0.88rem;
  background: #fff;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  span {
    font-size: 0.88rem;
    cursor: pointer;
    font-weight: 500;
    :hover {
      color: #760000;
    }
  }
`;


export const CardFooter = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${color.fountain};
  cursor: pointer;
`;


export const LoadingCont = styled.div`
  margin-top: 4rem;
`;
export const NoRecordCont = styled.div`
  margin-top: 4rem;
  font-size: 0.88rem;
  font-weight: 400;
  h4 {
    font-size: 0.88rem;
    font-weight: 400;
  }
`;

export const TransactionStat = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.56px solid #ccc;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 2.8rem;

  label {
    font-size: 0.88rem;
  }

  h1 {
    color: #760000;
    margin: 10px 0;
  }
`;

export const TransactionPeriodStat = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;
