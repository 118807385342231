import { combineReducers } from "redux";
import { businessesReducer, businessReducer } from "./businessReducer";
import { userReducer } from "./userReducer";
import {
  setMenuToggleStateReducer,
  settingsReducer,
  sidebarReducer,
} from "./settingsReducer";
import { resetState } from "store/actions/settingAction";
import { pageTitleReducer } from "./pageTitleReducer";
import { consoleCategoryReducer } from "./consoleCategoryReducer";
import { reportLinkReducer } from "./reportLinkReducer";

const reducers = combineReducers({
  businesses: businessesReducer,
  business: businessReducer,
  user: userReducer,
  settings: settingsReducer,
  sidebar: sidebarReducer,
  consoleCategory: consoleCategoryReducer,
  resetState: resetState,
  toggleNav: setMenuToggleStateReducer,
  pageTitle: pageTitleReducer,
  reportLink: reportLinkReducer,
});

export default reducers;
