import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, RoleUserPageCont } from "../Users/Styles";
import {
  FormContainer,
  InputGroup,
  PageContainer,
  Section,
  SectionHeader,
} from "../AddUser/Styles";
import {
  FirstColumn,
  ListContainer,
  MenuContainer,
  RowContainer,
  SecColumn,
  ThirdColumn,
} from "./Styles";
import SwitchButton from "shared/components/SwitchButton";
import consoleLog from "shared/utils/consoleLog";
import Emptycheck from "Assets/Emptycheck.svg";
import Fullcheck from "Assets/Fullcheck.svg";
import Caretup from "Assets/CaretUp.svg";
import Caretdown from "Assets/CaretDown.svg";
import roleApi from "RoleManagement/apiClass";
import { allPlainArray, menuArray, plainArray } from "shared/utils/menurecords";
import { ModalBtn } from "Pricing/Styles";
import { Spinner } from "shared/components";

export default function MenuManager() {
  const history = useHistory();
  const { id: unitId }: any = useParams();
  const [menuList, setMenuList] = useState(menuArray);
  const [formState, setFormState] = useState<any>({});
  const [permissionState, setPermissionState] = useState<any>({});
  const [unitState, setUnitState] = useState<any>({});
  const [openState, setOpenState] = useState<any>({});
  const [iniFormState, setIniFormState] = useState<any>({});

  const [loading, setLoading] = useState<any>(false);

  let operationArr = [
    {
      label: "Allow Can View",
      value: "canView", //with endpoint get
    },
    {
      label: "Allow Can Insert",
      value: "canInsert", //with endpoint post
    },
    {
      label: "Allow Can Update",
      value: "canUpdate", //with endpoint put or patch
    },
    {
      label: "Allow Can Delete",
      value: "canDelete", //with endpoint delete
    },
    {
      label: "Allow Can Download",
      value: "canDownload", //check with download component
    },
    {
      label: "Allow Can Print",
      value: "canPrint", //check with Print component
    },
  ];

  const handleChange = (name: string, value: any) => {
    setPermissionState({ ...permissionState, [name]: value });
  };

  const displayTop = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        {operationArr.map((el: any, idx: number) => (
          <div
            key={`Op${idx}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => handleChange(el?.value, !permissionState[el?.value])}
          >
            <SwitchButton
              toogle={(id: any) => {
                consoleLog(id);
              }}
              checked={permissionState[el?.value]}
              bgColor="green"
              id={`${el?.value}`}
            />
            <div style={{ marginLeft: 30, paddingTop: 5, paddingRight: 5 }}>
              {el?.label}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const checkTrueChild = (item: any) => {
    if (!(item instanceof Object)) return false;
    for (let el of item.children) {
      if (typeof iniFormState[el.id] === "boolean") return true;
    }
    return false;
  };

  consoleLog(iniFormState, "iniFormState");
  const rowLine = (item: any, parent: any = []) => {
    if (!iniFormState?.allOpen) {
      //check does it have children
      if (item.children) {
        //if yes then check if at least one immediate children is true
        let checkFlag = checkTrueChild(item);
        if (!checkFlag) return null;
      } else {
        //if no, check if it is a boolean
        if (!(typeof iniFormState[item.id] === "boolean")) return null;
      }
    }
    return (
      <RowContainer style={{ cursor: "pointer" }}>
        <FirstColumn
          onClick={() => inheritanceFunction(item, !formState[item.id], parent)}
        >
          <img
            src={formState[item.id] ? Fullcheck : Emptycheck}
            style={{ marginTop: -4, cursor: "pointer" }}
          />
        </FirstColumn>
        <SecColumn>
          <div
            onClick={() =>
              item.children
                ? setOpenState({ ...openState, [item.id]: !openState[item.id] })
                : inheritanceFunction(item, !formState[item.id], parent)
            }
          >
            {item.name}
          </div>
          {item.children && (
            <ListContainer show={openState[item.id]}>
              {item.children.map((child: any) =>
                rowLine(child, [...parent, item.id])
              )}
            </ListContainer>
          )}
        </SecColumn>
      </RowContainer>
    );
  };

  const displayContent = () => {
    return menuList.map((el: any) => rowLine(el));
  };

  // const displayContent = () => {
  //   let rendrComp = menuArr.map((menu: any, index: number) => {
  //     return (
  //       <div
  //         style={{ width: "40%", paddingRight: "10%" }}
  //         key={`${menu.title}${index}`}
  //       >
  //         <div style={{ marginBottom: 13 }}>{menu.title}</div>
  //         {menu.list.map((item: any, idx: number) => {
  //           return !iniFormState?.permissions ||
  //             (!iniFormState?.permissions[`${menu.title}${item}Open`] &&
  //               !iniFormState?.permissions[`allOpen`]) ? null : (
  //             <div
  //               style={{ marginBottom: 13 }}
  //               key={`${menu.title}${item}${idx}`}
  //             >
  //               {rowLine(item, `${menu.title}${item}`)}
  //               <ListContainer
  //                 show={
  //                   formState?.permissions &&
  //                   formState?.permissions[`${menu.title}${item}Open`]
  //                 }
  //               >
  //                 {operationArr.map((el: any, idx: number, arr: any) =>
  //                   checkboxes(
  //                     `${menu.title}${item}${el}`,
  //                     `Allow ${operationVerb[idx]}`
  //                   )
  //                 )}
  //               </ListContainer>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     );
  //   });
  //   return rendrComp;
  // };

  const enableAllChildren = (child: any, obj: any, value: boolean) => {
    let flag =
      !(typeof iniFormState[child.id] === "boolean") && !iniFormState?.allOpen
        ? undefined
        : true;

    obj = { ...obj, [child.id]: flag };
    if (child.children) {
      for (let subChild of child.children) {
        obj = enableAllChildren(subChild, obj, true);
      }
    }
    return obj;
  };

  const inheritanceFunction = (item: any, value: boolean, parent = []) => {
    // check the children if true all children are true... if false all children are false
    //if false, if it has parent then it is false..
    let obj: any = {};

    obj[item.id] = value;
    if (value && item.children) {
      for (let child of item.children) {
        obj = enableAllChildren(child, obj, true);
      }
    }
    if (!value) {
      ///find all parent...
      for (let parnt of parent) {
        obj[parnt] = false;
      }
    }
    setFormState({ ...formState, ...obj });
  };

  const fetchUnit = async () => {
    let obj: any = {};
    let data: any = await roleApi.fetchSingleUnit(unitId);
    obj.unit = data;
    let resp_roles = await roleApi.fetchSingleUnitRole(unitId);
    consoleLog(resp_roles, "Response roles");
    if (resp_roles) {
      let newObj: any = {};

      ///filter the roles
      if (resp_roles.permissions?.allOpen) {
        resp_roles.permissions = {};
      }

      newObj.permissions =
        resp_roles.permissions instanceof Object
          ? { ...resp_roles.permissions }
          : {};
      newObj.menus =
        resp_roles.menus instanceof Object ? { ...resp_roles.menus } : {};
      obj.roleId = resp_roles.id;
      obj.id = resp_roles.id;
      ///Remove all open
      if (Object.keys(newObj.menus).length > 2 && newObj.menus.allOpen) {
        newObj.menus.allOpen = undefined;
        newObj.menus.all_open = undefined;
        setIniFormState(newObj.menus);
        setFormState(newObj.menus);
      } else if (newObj.menus.allOpen) {
        let allObjectMenu = allPlainArray();
        consoleLog(allObjectMenu, "allObjectMenu");
        let objNew: any = { allOpen: true };
        for (let menu of allObjectMenu) {
          if (menu.id) objNew[menu.id] = false;
        }
        setIniFormState(objNew);
        setFormState(objNew);
      } else {
        setIniFormState(newObj.menus);
        setFormState(newObj.menus);
      }
      setPermissionState(newObj.permissions);
    }
    setUnitState(obj);

    // setIniFormState({ ...formState, ...obj });
  };

  console.log(formState, "formState");

  const handleSubmit = async () => {
    setLoading(true);
    let newObj: any = {};

    newObj = {
      permissions: permissionState,
      menus: formState,
      // menus: { allOpen: true },
      unitId,
      id: unitState.roleId,
    };
    consoleLog(newObj, "newOBJ");
    await roleApi.addRoles(newObj, true);
    setLoading(false);
  };

  useEffect(() => {
    fetchUnit();
  }, []);

  return (
    <div>
      <RoleUserPageCont>
        <PageHeader style={{ height: 72 }}>
          <h3>{unitState?.unit?.name} Menu Manager</h3>
          {/* display modal */}
        </PageHeader>
      </RoleUserPageCont>
      <PageContainer>
        <Section>
          <SectionHeader>
            <h3>Account Access Roles</h3>
          </SectionHeader>
          {displayTop()}
          <MenuContainer>{displayContent()}</MenuContainer>
          <ModalBtn type="submit" onClick={handleSubmit}>
            {loading ? <Spinner color="#fff" /> : "Submit"}
          </ModalBtn>
        </Section>
      </PageContainer>
    </div>
  );
}
