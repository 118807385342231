import styled from "styled-components";

import { sizes } from "shared/utils/styles";

import breakpoints from "shared/utils/breakpoints";
import BreakPoints from "shared/utils/Breakpoint";

export const Container = styled.div`
  /* margin-top: 2rem; */
  padding: 2rem 0;
  /* border: 1px solid red; */

  @media (${BreakPoints.xs}) {
    position: relative;
    top: -4.45rem;
    padding: 0 0.5rem;
  }
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  padding-left: 10px;

  /* Primary Black */

  color: #292929;
  margin-bottom: 2rem;

  @media ${breakpoints.device.max.xs} {
    position: relative;
    top: 1rem;
    margin-bottom: 2rem;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 5px;
  grid-gap: 2rem;
  @media ${breakpoints.device.max.sm} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${breakpoints.device.max.xs} {
    grid-template-columns: 1fr;
  }
`;

const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

export const TransactionPage = styled.div`
  padding: 25px 32px 50px ${paddingLeft}px;
  margin-left: -38px;
  margin-top: -80px;
  @media (max-width: 1100px) {
    padding: -25px 20px 50px ${paddingLeft - 20}px;
  }
  @media (max-width: 999px) {
    padding-left: ${paddingLeft - 20 - sizes.secondarySideBarWidth}px;
  }
`;

export const TableContainer = styled.div`
  margin-top: 2rem;
`;
