import { IconContainer } from "Layout/Header/HeaderSearch/Styles";
import React from "react";
import { PageLayout } from "shared/section_components/Styles";
import { Box, BoxTitle, BoxValue, BusinesPageLayout, TopGrid } from "./Styles";
import Icon2 from "../assets/icon2.svg";

export default function GridSection() {
  return (
    <BusinesPageLayout>
      <h4>Tiamiyu Digitals</h4>
      <TopGrid>
        <Box>
          <IconContainer>
            <img src={Icon2} alt="" />
          </IconContainer>
          <BoxTitle>Total Amount Of Transaction</BoxTitle>
          <BoxValue>205</BoxValue>
        </Box>
        <Box>
          <IconContainer>
            <img src={Icon2} alt="" />
          </IconContainer>
          <BoxTitle>Total Amount Processed</BoxTitle>
          <BoxValue>305</BoxValue>
        </Box>
      </TopGrid>
    </BusinesPageLayout>
  );
}
