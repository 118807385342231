export const ActionTypes = {
  SET_BUSINESS_LISTS: "SET_BUSINESS_LISTS",
  GET_BUSINESS_LISTS: "GET_BUSINESS_LISTS",
  SET_CURRENT_BUSINESS: "SET_CURRENT_BUSINESS",
  GET_CURRENT_BUSINESS: "GET_CURRENT_BUSINESS",
  GET_USERS: "GET_USERS",
  SET_USERS: "SET_USERS",
  SET_SETTINGS_LISTS: "SET_SETTINGS_LISTS",
  GET_SETTINGS_LISTS: "GET_SETTINGS_LISTS",
  SET_MENUS: "SET_MENUS",
  RESET_STATE: "RESET_STATE",
  SET_TOGGLE_STATE: "SET_TOGGLE_STATE",
  SET_PAGE_TITLE: "SET_PAGE_TITLE",
  SET_CONSOLE_CATEGORY: "SET_CONSOLE_CATEGORY",
  REPORT_LINK: "REPORT_LINK",
};
