import { useHistory } from "react-router-dom";
// import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
// import { color } from "shared/utils/styles";
// import { Icon, AboutTooltip, LogoutTooltip } from "shared/components";

import useCurrentUser from "shared/hooks/currentUser";
import { useAppSelector } from "store/hook";
import HeaderSearch from "./HeaderSearch";
import {
  MenuIcon,
  NavbarContainer,
  NavIcon,
  NavTop,
  PageTitle,
  PageTitleSpan,
  SearchBoxContainer,
} from "./Styles";

type PropT = {
  [x: string]: any;
};

const Header = ({ toggleNav, Title }: any) => {
  // const dispatch = useAppDispatch();
  // const history = useHistory();

  // const currentUser = useCurrentUser();
  // const businesses = useSelector((store: RootStateOrAny) => store.businesses);
  // const businesses = "";

  // const addBusinesses = () => history.push("/business/add");
  // const pageTitle = useAppSelector((state) => state.pageTitle);

  return (
    <NavTop>
      <NavbarContainer>
        <MenuIcon onClick={toggleNav}>
          <NavIcon></NavIcon>
        </MenuIcon>

        <PageTitle>
          ADMIN <PageTitleSpan>{Title}</PageTitleSpan>
        </PageTitle>
        <SearchBoxContainer>
          <HeaderSearch />
        </SearchBoxContainer>
      </NavbarContainer>
    </NavTop>
  );
};

export default Header;
