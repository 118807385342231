import styled from "styled-components";

export const GraphContainer = styled.div`
  width: 60%;
  padding: 1.8rem 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const GraphHeader = styled.div`
  display: flex;
  justify-content: end;
`;

export const GraphContent = styled.div`
  margin-top: 1.4rem;
`;
