import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Logo } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color, font, mixin, sizes, zIndexValues } from "shared/utils/styles";

type PropT = {
  to: boolean;
};

type navbarIconPropT = {
  isClicked?: boolean;
};

export const NavLeft = styled.aside<navbarIconPropT>`
  //mobile stayle goes here
  /* z-index: ${zIndexValues.navLeft}; */
  position: fixed;
  top: 4rem;
  left:${(prop) => (prop.isClicked ? "0" : "-50rem")} ;
  overflow-x: hidden;
  height: 100%;
  width: 50vw;
  background: ${color.fountain};
  transition: all 0.1s;
  ${mixin.hardwareAccelerate}
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.6);
  transition: all ease .5s;
  z-index: 80;
  /* display:${(prop) => (prop.isClicked ? "block" : "none")} ; */
  @media only screen and (min-width: 768px) {
    z-index: ${zIndexValues.navLeft};
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    height: 100vh;
    width: ${sizes.appNavBarLeftWidth}px;
    background: ${color.fountain};
    transition: all 0.1s;
    ${mixin.hardwareAccelerate}
    &:hover {
      width: 200px;
      box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.6);
    }
    display: block;
  }

	@media ${breakpoints.device.max.sm} {
    position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
		width: ${sizes.appNavBarLeftWidth}px;
  }
`;

export const ImageAvatar = styled.div`
  width: 100%;
  height: 50px;
  margin-left: 0.4rem;
  @media only screen and (min-width: 768px) {
    width: 100%;
    height: 50px;
    margin-left: 5px;
  }
`;

export const UserAvatar = styled.img`
  width: 2.8rem;

  @media only screen and (min-width: 768px) {
    width: 50px;
  }
`;

export const LogoLink = styled(NavLink)`
  display: flex;
  position: relative;
  left: 0;
  margin: 20px 0 10px;
  transition: left 0.1s;
`;

export const LogoText = styled.div`
  position: absolute;
  left: 62px;
  top:12px;
  visibility: visible;
  opacity: 1;
  right: 0;
  text-transform: uppercase;
  transition: all 0.1s;
  transition-property: right, visibility, opacity;
  color: #fff;
  ${font.bold}
  ${font.size(12)}

  p{
    font-style: italic;
    text-transform: capitalize;
  }

  @media only screen and (min-width: 768px) {
    position: absolute;
  left: 62px;
  top:12px;
  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  transition: all 0.1s;
  transition-property: right, visibility, opacity;
  color: #fff;
  ${font.bold}
  ${font.size(12)}
  ${NavLeft}:hover & {
    right: 0;
    visibility: visible;
    opacity: 1;
  }
  p{
    font-style: italic;
    text-transform: capitalize;
  }


  }
`;

export const StyledLogo = styled(Logo)`
  display: inline-block;
  margin-left: 8px;
  padding: 10px;
  ${mixin.clickable}
`;

export const Bottom = styled.div`
  position: absolute;
  bottom: 14vh;
  left: 0;
  width: 100%;

  @media only screen and (min-width: 768px) {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
  }
`;

export const Item = styled.div`
  position: relative;
  width: 100%;
  height: 42px;
  line-height: 42px;
  padding-left: 64px;
  color: #deebff;
  transition: color 0.1s;
  ${mixin.clickable}
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  i {
    position: absolute;
    left: 18px;
  }
`;

export const ItemText = styled.div`
  position: relative;
  right: 0;
  visibility: visible;
  opacity: 1;
  text-transform: uppercase;
  transition: all 0.1s;
  transition-property: right, visibility, opacity;
  ${font.bold}
  ${font.size(12)}

  @media only screen and (min-width: 768px) {
    position: relative;
  right: 12px;
  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  transition: all 0.1s;
  transition-property: right, visibility, opacity;
  ${font.bold}
  ${font.size(12)}
  ${NavLeft}:hover & {
    right: 0;
    visibility: visible;
    opacity: 1;
  }

  }
`;

export const LinkItem = styled.div<PropT>`
  position: relative;
  width: 100%;
  height: 42px;
  line-height: 42px;
  padding-left: 64px;
  color: #deebff;
  transition: color 0.1s;
  ${mixin.clickable}
  ${(props) =>
    !props.to
      ? `cursor: not-allowed;`
      : `&:hover { background: ${color.backgroundLight}; }`}
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  i {
    position: absolute;
    left: 18px;
  }
  &.active {
    color: ${color.primary};
    background: ${color.backgroundLight};
    i {
      color: ${color.primary};
    }
  }
`;

export const LinkText = styled.div`
  padding-top: 2px;
  ${font.size(14.7)};
`;

export const NotImplemented = styled.div`
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 40px;
  width: 140px;
  padding: 5px 0 5px 8px;
  border-radius: 3px;
  text-transform: uppercase;
  color: ${color.textDark};
  background: ${color.backgroundMedium};
  opacity: 0;
  ${font.size(11.5)};
  ${font.bold}
  ${ItemText}:hover & {
    opacity: 1;
  }
`;
