import React from "react";
import NoRecord from "Assets/addnewitem.svg";
import {
  Container
} from "./Styles";

function index({ openModal, stateTitle, statePara, stateBtnText }: any) {
  return (
    <Container>
      <img src={NoRecord} />
    </Container>
  );
}

export default index;
