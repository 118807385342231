import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import {} from "react-redux";
import { ForceLogout, Icon } from "shared/components";
import NoInternet from "shared/components/NoInternet";
import useNetwork from "shared/hooks/useNetwork";
import Modal from "shared/quickComponents/modal";
import {
  getCurrentUser,
  getStoredAuthToken,
  removeAll,
  removeStoredAuthToken,
} from "shared/utils/authToken";
import { createQueryParamModalHelpers } from "shared/utils/queryParamModal";
import { MENUS } from "shared/utils/sitemap";
import { setSidebar } from "store/actions/settingAction";
import { useAppDispatch, useAppSelector } from "store/hook";
import Header from "./Header";
import NavbarLeft from "./NavbarLeft";
import Sidebar from "./Sidebar";
import { ContentArea, Layouts, NavLeftMenu, NavWrapper } from "./Styles";
import { useEffectOnce } from "../shared/hooks/useEffectOnce";
import Pusher from "pusher-js";
import { toast } from "react-toastify";
import GoBack from "shared/components/BackButton";

//Pusher.logToConsole = true;
const pusher = new Pusher("22cece4506a9c63ea345", {
  cluster: "mt1",
});

const Layout = ({ children, pageTitle }: any) => {
  const match = useRouteMatch();
  const history = useHistory();
  const utilModalHelpers = createQueryParamModalHelpers("display");

  const [navOpened, setNavOpened] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [eventMessage, setEventMessage] = useState("");
  const consoleCategory = useAppSelector((state) => state.consoleCategory);

  const dispatch = useAppDispatch();

  const isOnline = useNetwork();
  const token = getStoredAuthToken();
  if (!token) {
    removeAll();
  }

  const currentUser: any = getCurrentUser();

  const toggleNav = () => {
    setNavOpened(!navOpened);
  };

  const logUserOut = () => {
    removeStoredAuthToken();
    window.location.href = "/autheticate/login";
  };

  useEffectOnce(() => {});

  const channel = pusher.subscribe("admin_socket");
  channel.bind("handle_user_admin", async (data: any) => {
    if (data && data?.user_id == currentUser.id) {
      switch (data?.action) {
        case "logout":
          setEventMessage(data?.message);
          setIsConfirmModalOpen(true);
          break;
      }
    }
  });

  useEffect(() => {
    (async () => {
      if (MENUS.length && currentUser) {
        const filteredMenus = MENUS.filter(
          (menu) =>
            menu.roles.includes(currentUser?.type) &&
            menu.main &&
            menu.category === consoleCategory //setting console based on menu category
        );
        // console.log(filteredMenus, "filteredMenus");

        if (filteredMenus.length) {
          // console.log(filteredMenus, "filteredMenuslen");

          await dispatch(setSidebar(filteredMenus));
        } else {
          removeAll();
        }
      }
    })();
  }, [MENUS]);
  return (
    <Layouts>
      <NavWrapper navOpened={navOpened}>
        {navOpened && (
          <NavLeftMenu onClick={toggleNav}>
            <Icon type="close" size="20" color="white" />
          </NavLeftMenu>
        )}
        <NavbarLeft />
        {/* @ts-ignore */}
        <Sidebar toggleNav={toggleNav} />
      </NavWrapper>

      <Header toggleNav={toggleNav} Title={pageTitle} />
      {isOnline ? (
        <>
          <GoBack />
          <ContentArea>{children}</ContentArea>
        </>
      ) : (
        <Modal isOpen={true} closeButton={false} onClose={() => ""} width="50%">
          <NoInternet />
        </Modal>
      )}
      <ForceLogout
        message={eventMessage}
        handleCloseModal={logUserOut}
        isModalOpen={isConfirmModalOpen}
      />
    </Layouts>
  );
};

export default Layout;
