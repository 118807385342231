import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Table from "shared/clientcomponents/Table";
import Filter from "shared/components/Filter";
import { DisputeTableHeader } from "Dispute/DisputeTableHeader";
import useApi from "shared/hooks/api";
import EmptyState from "shared/components/EmptyState";
import moment from "moment";
import { PageLayout, TableContainer } from "./Styles";
import { GradientLoader } from "shared/components";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import {
  FilterButton,
  FilterContainer,
} from "Dispute/Home/SecondSection/Styles";
import { numberFormat } from "shared/utils/utility";

type PropT = {
  [x: string]: any;
};

export default function AllDispute() {
  const [disputes, setdisputes] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [NoRecord, setNoRecord] = useState(true);
  const [filterBtn, setfilterBtn] = useState("OPEN");

  const [{ data, error, setLocalData }, fetchData] = useApi.get(`/dispute`);
  const history = useHistory();
  // const [filterBy, setFilterBy] = useState(filterOptions);

  const disputeList = disputes.map((disputeData: any, index: number) => ({
    id: disputeData.id,
    sn: index + 1,
    ticketID: disputeData.ticketId,
    transactionID: disputeData.transactionId,
    amountPAID: numberFormat(disputeData.amount),
    merchant: disputeData.merchant,
    status: disputeData.status,
    email: disputeData.email,
    timeDate: moment(disputeData.createdAt).format("DD/MM/YYYY"),
  }));

  const hoverLink = (elm: PropT) => {
    history.push(`/disputes/single/${elm?.id}`);
  };

  const handleStatusFilter = (statusValue = "OPEN") => {
    if (data) {
      const newDispute = data?.payload?.filter(
        (dispute: any) => dispute.status === statusValue
      );
      setdisputes(newDispute);
    }
  };

  const handleFilter = (searchData: any) => {
    const newlist = data.payload.filter((dispute: any) => {
      if (dispute.ticketId.indexOf(searchData) > -1) return dispute;
    });
    if (newlist.length) setdisputes(newlist);
    if (
      (searchData.length === 7 && newlist.length === 1) ||
      (searchData.length === 5 && newlist.length === 1)
    ) {
      setfilterBtn(newlist[0].status); //set the filtter button to status of result
    }
    if (searchData.length < 1) setfilterBtn("OPEN");
    if (!searchData) setdisputes(data?.payload.slice(0, 10));
  };

  // const handleFilter = (data: any) => {
  //   const filterData = data.filter((option: any) => option.checked);
  //   const sortDataBy = filterData.map((option: any) => option.name);
  //   const unSortDataBy = filterOptions.map((option: any) => option.name);
  //   const newDisputeData =
  //     sortDataBy.length > 0
  //       ? disputeList.sort(fieldSorter(sortDataBy))
  //       : disputeList.sort(fieldSorter(unSortDataBy));
  //   setdisputes(newDisputeData);
  //   setFilterBy(data);
  // };

  useEffect(() => {}, []);
  useLayoutEffect(() => {
    if (!data) setisLoading(true);
    if (error) {
      // console.log("disputes error", error);
      setisLoading(false);
      setNoRecord(true);
    }
    if (!data && !error) setNoRecord(true);
    if (data) {
      console.log("disputes data ==>", data);
      setdisputes(data.payload.slice(0, 10));
      setisLoading(false);
      setNoRecord(false);
      handleStatusFilter();
    }
  }, [data]);

  useEffect(() => {
    handleStatusFilter(filterBtn);
  }, [filterBtn]);

  return (
    <>
      {isLoading ? (
        <GradientLoader />
      ) : (
        <>
          {NoRecord ? (
            <EmptyState stateTitle="No invite" statePara="" stateBtnText="" />
          ) : (
            <>
              <div>
                <br />
                <FilterContainer>
                  <FilterButton
                    onClick={() => setfilterBtn("OPEN")}
                    isclicked={filterBtn === "OPEN" ? true : false}
                  >
                    OPEN
                  </FilterButton>
                  <FilterButton
                    onClick={() => setfilterBtn("CLOSE")}
                    isclicked={filterBtn === "CLOSE" ? true : false}
                  >
                    CLOSED
                  </FilterButton>
                  <FilterButton
                    onClick={() => setfilterBtn("PENDING")}
                    isclicked={filterBtn === "PENDING" ? true : false}
                  >
                    PENDING
                  </FilterButton>
                </FilterContainer>
                <br />
                <DashboardSubHeader
                  count={data?.payload?.length}
                  title={`Disputes`}
                  btnTitle="search"
                  withSearch={true}
                  withFilter={false}
                  widthActionBtn={false}
                  filterOptions={["Ticket ID", "Date"]}
                  setSearchData={handleFilter}
                  callBackFn={handleFilter}
                  placeholder="Ticket ID"
                />
              </div>

              <PageLayout>
                <TableContainer>
                  <Table
                    headData={DisputeTableHeader}
                    bodyData={disputeList}
                    hoverLink={hoverLink}
                  />
                </TableContainer>
              </PageLayout>
            </>
          )}
        </>
      )}
    </>
  );
}
