import styled from "styled-components";
import { sizes, color } from "shared/utils/styles";
import breakpoints from "shared/utils/breakpoints";
import { Button } from "shared/components";
import BreakPoints from "shared/utils/Breakpoint";

type BoxPropT = {
  isActive?: boolean;
};
type StatusBtnT = {
  mode?: string;
};

export const Container = styled.div`
  padding: 2rem 0.5rem;

  @media (${BreakPoints.xs}) {
    padding: 0rem 0.5rem;
    position: relative;
    top: -2rem;
    /* border: 2px solid red; */
  }
`;

export const Container2 = styled.div`
  // padding: 2rem 0.5rem;
  width: 400px;
`;

export const PageTitle = styled.h2`
  font-size: 1.4rem;
`;

export const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 1px;
  grid-gap: 2rem;
  width: 100%;
  /* display: flex;
  gap: 4rem; */

  @media (${BreakPoints.xs}) {
    grid-template-columns: 1fr;
  }
  /* @media (${BreakPoints.medium}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  } */
`;

export const Box = styled.div<BoxPropT>`
  width: 100%;
  padding: 1.48rem 1.4rem;
  height: 14rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  transition: all ease 0.1s;
  cursor: pointer;
  border-radius: 1rem;
  color: ${(prop) => prop.isActive && `#fff`};
  background-color: ${(prop) => (prop.isActive ? `${color.fountain}` : "#fff")};
  border: ${(prop) => prop.isActive && `2px solid ${color.fountain}`};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  :hover {
    /* margin-right: 0.4rem; */
    background: ${color.fountain};
    color: #fff;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const IconContainer = styled.div<BoxPropT>`
  margin-top: 0.4rem;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  background-color: ${(prop) => prop.isActive && `#fff`};
  :hover {
    background-color: #fff;
  }
`;

export const HeadTitle = styled.h3`
  border-bottom: 1px solid black;
  width: 100%;
  height: 40px;
  padding-bottom: 0px;
  margin-bottom: 10px;
`;

export const BoxTitle = styled.span<BoxPropT>`
  display: block;
  font-size: 16px;
  margin-top: 0.4rem;
  @media ${breakpoints.device.max.md} {
    text-align: center;
  }
`;

export const BoxValue = styled.span`
  margin-top: 0.8rem;
  display: block;
  font-size: 2rem;
  font-weight: 900;
`;

export const TableContainer = styled.div`
  margin-top: 4rem;
  width: 100%;
  background: #fff;
  padding: 1.5rem;
  border-radius: 0.4rem;

  @media (${BreakPoints.xs}) {
    padding: 0rem;
  }
`;

export const TableActions = styled.div`
  display: flex;
  gap: 2rem;
`;

export const ViewAllBtn = styled.span`
  background-color: ${color.fountain};
  cursor: pointer;
  color: #fff;
  border-radius: 0.8rem;
  padding: 0.6rem 1rem;
  font-size: 12px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;

  @media ${breakpoints.device.max.sm} {
    flex-direction: column;
  }
`;

export const InfoContainer = styled.div`
  position: relative;
  overflow: hidden;

  width: 500px;

  min-height: 4rem;
  padding: 0.2rem 2rem;
  background: #fff;
  border-radius: 0.4rem;
  h3 {
    padding: 1.5rem 0;
  }
  @media ${breakpoints.device.max.sm} {
    width: 100%;
    margin-bottom: 40px;
  }
`;
export const InfoGroup = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 24px;
  margin-bottom: 1rem;
  font-size: 0.95rem;
  /* border-bottom: 1px solid ${color.backgroundMedium}; */
  p {
    width: 50%;
    height: inherit;
  }
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BtnsContainer = styled.div`
  width: 50%;
  display: flex;
  /* align-items: center; */
  gap: 1rem;
  justify-content: end;
  /* margin-bottom: 2rem; */
`;

export const ApprovedKycBtn = styled.button`
  background: ${color.statusGreen};
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
`;

export const DeactivateKycBtn = styled(ApprovedKycBtn)`
  background: ${color.statusCancel};
`;

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 0.4rem 0 1rem 0;
  /* margin-bottom: 2rem; */
  border-bottom: 1px solid ${color.backgroundMedium};
`;

export const UserAvatarContainer = styled.div`
  width: 5rem;
  height: 5rem;
  background: ${color.backgroundMedium};
  border-radius: 50rem;
  border: 1px solid ${color.backgroundLight};
`;

export const Ribbon = styled.div<StatusBtnT>`
  background: ${(prop) => prop.mode === "PENDING" && "orange"};
  background: ${(prop) => prop.mode === "APPROVED" && color.statusGreen};
  background: ${(prop) => prop.mode === "REJECTED" && color.fountain};
  height: 4rem;
  width: 8rem;
  position: absolute;
  bottom: -2rem;
  right: -4rem;
  transform: rotate(-45deg);
`;

export const StatusButton = styled.span<StatusBtnT>`
  background: ${(prop) => prop.mode === "PENDING" && "orange"};
  background: ${(prop) => prop.mode === "APPROVED" && color.statusGreen};
  color: ${(prop) => prop.mode === "PENDING" && "#000"};
  color: ${(prop) => prop.mode === "APPROVED" && "#fff"};
  padding: 0.4rem 1rem;
  border-radius: 4rem;
  font-size: 10px;
  font-weight: 600;
`;

// ====== File are styles =====
export const FileSection = styled.div`
  width: 100%;
  margin-top: 4rem;
`;
export const FileSectionContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 2rem;
  background: #fff;
  padding: 2rem;
  width: 100%;
  border-radius: 0.4rem;
`;

export const FilesContainer = styled.div`
  margin-top: 2.4rem;
  width: 100%;
`;
export const FileGroup = styled.div`
  width: 100%;
  margin-bottom: 1.8rem;
  img {
    cursor: pointer;
  }
`;
export const FileInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  label {
    margin-top: 0.2rem;
  }
`;

export const FileInput = styled.div`
  overflow-x: hidden;
  margin-top: 0.4rem;
  padding: 1.2rem;
  width: 50%;
  display: flex;
  gap: 0.4rem;
  border: 1px dotted ${color.textMedium};
  @media ${breakpoints.device.max.xs} {
    padding: 0.1rem;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 3rem;
`;

export const AddFileButton = styled.button`
  display: flex;
  background: ${color.fountain};
  color: #fff;
  padding: 1rem 1.4rem;
  border-radius: 0.4rem;
  cursor: pointer;
`;

// ==== File form =====

export const FileFormContainer = styled.div`
  width: 100%;
  min-height: 5rem;
  max-height: 40rem;
  overflow: scroll;
  padding: 0 2rem;
  h2 {
    color: ${color.fountain};
    margin-bottom: 2.5rem;
    text-align: center;
  }
`;

export const FormFileGroup = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  label {
    color: ${color.fountain};
    margin-bottom: 1rem;
  }
`;

export const InputField = styled.input`
  width: 100%;
  padding: 0.8rem;
  outline-style: none;
  border: 1px solid ${color.backgroundLight};
  background: ${color.backgroundLightest};
`;

export const RemarkInput = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  outline-style: none;
  border: 1px solid ${color.backgroundLight};
  background: ${color.backgroundLightest};
`;

export const UploadWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  /* display: inline-block; */
`;

export const UploadButton = styled(Button)`
  color: ${color.textMedium};
  border: 1px solid ${color.backgroundLight};
  background: ${color.backgroundLightest};
  padding: 0.6rem;
  border-radius: 0.2rem;
  font-weight: bold;
  width: 100%;
`;

export const FileField = styled.input.attrs({ type: "file" })`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`;

export const SubmitButton = styled.button`
  background: ${color.fountain};
  padding: 1rem;
  border-radius: 0.4rem;
  color: #fff;
  width: 100%;
  height: 54px;
  margin-bottom: 1rem;
  cursor: pointer;

  :disabled {
    background: ${color.fountainLight};
    cursor: not-allowed;
  }
`;

export const SelectedFileList = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  color: ${color.textMedium};

  span {
    cursor: pointer;
    font-size: 14px;
    color: red;
    display: inline-block;
    margin-top: 0.2rem;
  }
`;

export const VBtn = styled.div`
  padding: 0.25rem 0.8rem;
  cursor: pointer;
  text-align: center;
  background: #d7d7d7;
  color: #333;
  border-radius: 1rem;
  font-size: 0.65rem;
`;

export const ViewBtn = styled.div`
  padding: 0.2rem 1rem;
  cursor: pointer;
  text-align: center;
  transition: all ease 0.6s;
  :hover {
    background: #333;
    color: #fff;
    border-radius: 1rem;
    transition: all ease 0.6s;
  }
`;

export const SingleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  margin-bottom: 2rem;

  span {
    font-size: 0.88rem;
    margin-left: 0.6rem;
  }
`;

//======= Threshold ==============

export const KycThreshold = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  background: #fff;
  margin-bottom: 1.8rem;
  border-radius: 1rem;
  position: sticky;
  top: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 10;

  h3 {
    color: #ea6767;
  }
`;

export const ThreshBtn = styled.button`
  background: #333;
  color: #fff;
  padding: 0.6rem 1.4rem;
  border-radius: 2rem;
  cursor: pointer;
`;
