import { userDataPropT, userSetFirstPasswordDataPropT } from "../AppTypes/index";
import { getStoredAuthToken } from "shared/utils/authToken";
import history from "browserHistory";

import authFetch from "shared/Api/authFetch";

class USER_CLASS {
  //================== Login method ===================
  async login(data: userDataPropT) {
    try {
      const user = await authFetch.post("/fp_admin/login", {
        email: data.userEmail,
        password: data.userPassword,
      });
      user?.data && history.push("/dashboard/over-view");
      return true;
    } catch (error) {
      return false;
    }
  }
  //==================================================

  //================== Set First Password ===================
  async setPassword(data: userSetFirstPasswordDataPropT) {
    try {
      const user = await authFetch.post("/fp_admin/set-password", {
        password: data.userPassword,
      });
      user?.data && history.push("/authenticate");
      return true;
    } catch (error) {
      return false;
    }
  }
  //==================================================

  //================== Already login method =========
  alreadyLogin() {
    if (getStoredAuthToken()) {
      // console.log("you're currently login.");
      return true;
    } else {
      return false;
    }
  }
  //=================================================
}

const UserClass = new USER_CLASS();
export default UserClass;
