import React, { useEffect, useState } from "react";
import {
  BtnCont,
  BusinessForm,
  Col,
  Container,
  FormBtn,
  FormTitle,
  RightCol,
  Step,
  StepLabel,
  StepLine,
  StepsCont,
  Value,
  ValueCont,
} from "./Styles";
import StepComp from "./Step";
import { RegTypeData, StepData } from "./StepsData";
import InputField from "./InputField";
import SelectField from "./SelectField";
import StepOneComp from "./StepOneComp";
import StepTwoComp from "./StepTwoComp";
import StepThreeComp from "./StepThreeComp";
import Layout from "Layout";
import StepFourComp from "./StepFourComp";
import PersonalKyc from "./PersonalKyc";

export default function CreateBusiness() {
  const [currentStep, setcurrentStep] = useState(2);

  const [doneSteps, setdoneSteps] = useState<any>([]);

  useEffect(() => {
    console.log(doneSteps);
  }, [doneSteps]);

  return (
    <Layout pageTitle="Add Business">
      <div>
        <Container>
          <Col>
            <h2>Create a Business</h2>
            <p>
              With your corporate account number, you are guaranteed of your
              settlement
            </p>

            <StepsCont>
              {StepData.map((obj, ind) => (
                <StepComp
                  key={ind}
                  doneSteps={doneSteps}
                  data={obj}
                  isActive={currentStep === obj?.value && true}
                />
              ))}
            </StepsCont>
          </Col>
          <RightCol>
            {currentStep && currentStep === 1 && (
              <PersonalKyc
                setcurrentStep={setcurrentStep}
                setdoneSteps={setdoneSteps}
                doneSteps={doneSteps}
              />
            )}
            {currentStep && currentStep === 2 && (
              <StepOneComp
                setcurrentStep={setcurrentStep}
                setdoneSteps={setdoneSteps}
                doneSteps={doneSteps}
              />
            )}
            {currentStep && currentStep === 3 && (
              <StepTwoComp
                setcurrentStep={setcurrentStep}
                setdoneSteps={setdoneSteps}
                doneSteps={doneSteps}
                currentStep={currentStep}
              />
            )}

            {currentStep && currentStep === 4 && (
              <StepThreeComp
                setcurrentStep={setcurrentStep}
                setdoneSteps={setdoneSteps}
                doneSteps={doneSteps}
                currentStep={currentStep}
              />
            )}

            {currentStep && currentStep === 5 && (
              <StepFourComp
                setcurrentStep={setcurrentStep}
                setdoneSteps={setdoneSteps}
                doneSteps={doneSteps}
                currentStep={currentStep}
              />
            )}
          </RightCol>
        </Container>
      </div>
    </Layout>
  );
}
