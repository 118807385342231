import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import logo from "Assets/logo.svg";
import loginBanner from "Assets/loginbanner.svg";
import show from "shared/utils/toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "shared/quickComponents/modal";
import useLogger from "shared/hooks/useLogger";
import { encryptData, decryptData } from "../../../shared/utils/security";
import Spinner from "shared/components/Spinner";

import {
  BannerContainer,
  Container,
  FormContainer,
  InputContainer,
  InputLabel,
  LoginBanner,
  LoginButton,
  LoginForm,
  LoginInput,
  LoginPageContainer,
  LoginPageSpan,
  LogoContainer,
  LogoImage,
  Theform,
  WelcomeText,
  ErrorText,
  ErrorContainer,
  PasswordResetContainer,
  PwdResetForm,
  ResetEmailInput,
  ResetButton,
  ReguestSubtitle,
} from "./Styles";
import UserClass from "classes/user.class";
import {
  omniSaver,
  removeStoredAuthToken,
  storeAuthToken,
  storeCurrentUser,
  storeLogs,
  storeTempId,
  storeUserCoord,
} from "shared/utils/authToken";
import api from "shared/utils/api";
import {
  getClientDetails,
  getIpAddress,
  getLocation,
} from "shared/utils/location";
import logger from "shared/utils/logger";
import { Button, Icon } from "shared/components";
import consoleLog from "shared/utils/consoleLog";

type userType = {
  email: string;
  fullname: string;
  role: string;
};

type ErrorPropT = {
  status: boolean;
  errors: any;
  message: string;
  meta: any;
  payload: any;
};

const Login = () => {
  // const USER = useAppSelector((state) => state.USER);
  // const dispatch = useAppDispatch();

  const [userEmail, setuserEmail] = useState<string>("");
  const [userPassword, setuserPassword] = useState<string>("");
  const [loginError, setLoginError] = useState<string>("");
  const [isBtnActive, setisBtnActive] = useState<boolean>(false);
  const [btnText, setbtnText] = useState<string>("LOGIN");
  const [isresetModal, setisresetModal] = useState(false);
  const [pwdrestBtnText, setpwdrestBtnText] = useState("Send request");
  const [resetEmail, setresetEmail] = useState<string>("");
  const [loginAttemptCounter, setloginAttemptCounter] = useState<number>(5);
  const [clientLocation, setClientLocation] = useState<any>({});
  const [showActivityIndicator, setshowActivityIndicator] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(true);

  const history = useHistory();
  //const [Logger] = useLogger();

  const openResetModal = () => {
    setisresetModal(!isresetModal);
  };
  storeUserCoord(getLocation());
  const handlePWDrequest = async (e: any) => {
    e.preventDefault();
    if (resetEmail) {
      setpwdrestBtnText("Processing..");
      try {
        const data = await api.post("/reset-password", {
          email: resetEmail,
        });

        await logger.logActivities("remote", {
          date: new Date().toString(),
          author: resetEmail,
          resource: "admin",
          action: "LOGIN",
          payload: {
            userAgent: getClientDetails(),
            data: {
              detail: `${resetEmail} requests password reset in to the admin console`,
            },
          },
        });
        setpwdrestBtnText("Send Request");
        toast.success("Request sent successfully.");
      } catch (error) {
        setpwdrestBtnText("Send Request");
        toast.error(`${error}`);
        await logger.logActivities("remote", {
          date: new Date().toString(),
          author: userEmail,
          resource: "admin",
          action: "LOGIN",
          payload: {
            userAgent: getClientDetails(),
            data: {
              detail: `${resetEmail} requests password reset in to the admin console failed`,
            },
          },
        });
      }
    }
  };

  const resendOtp = async (email: string) => {
    try {
      const data = await api.post("/send-2fa-otp", {
        admin_email: email,
        email_type: "verification",
      });
      // consoleLog("data send otp for login ==>", data);
      const { payload } = data;
      if (payload?.tempId) {
        await storeTempId(payload?.tempId);
        toast.success("OTP sent login for validate!");
        history.push("/authorize/2fa"); //push to 2fa page
        return true;
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error ?? "Something went wrong");
      return false;
    }
  };
  const handleLogin = async (e: any) => {
    e.preventDefault();
    // check email extention
    const emailDomain = userEmail.substring(userEmail.lastIndexOf("@") + 1);
    //if (emailDomain.toLocaleLowerCase() === "fountainpay.ng" || true) {
    if (emailDomain.toLocaleLowerCase() === "fountainpay.ng") {
      //remove the true if fountainpay email is working

      setshowActivityIndicator(true);

      if (isBtnActive) {
        // if (loginAttemptCounter >= 1) {
          try {
            const data = await api.post("/login", {
              email: userEmail,
              password: userPassword,
            });
            // consoleLog("Login data: ", data);
            if (data?.status) {
              const authToken = data?.payload?.accessToken;
              await storeAuthToken(authToken);
              const profile = await api.get("/profile");

              // consoleLog("User profile details: ", profile?.payload);
              if (profile && profile?.payload?.defaultPassword) {
                // toast.success("Login was successful.");
                history.push("/change-password");
              } else if (profile && profile?.payload) {
                omniSaver("otp_email", userEmail);
                resendOtp(userEmail);
                // storeCurrentUser(profile?.payload);
                // toast.success("Login was successful.");
                //Todo:to be remove later==========
                await logger.logActivities("remote", {
                  date: new Date().toString(),
                  author: userEmail,
                  resource: "admin",
                  action: "LOGIN",
                  payload: {
                    userAgent: getClientDetails(),
                    data: {
                      detail: `Login OTP in to the admin console requested by ${userEmail}.`,
                    },
                  },
                });
                // //==============================
                // history.push("/dashboard/over-view");
              } else {
                setshowActivityIndicator(false);
              }
            } else {
              await logger.logActivities("remote", {
                date: new Date().toString(),
                author: userEmail,
                resource: "admin",
                action: "LOGIN",
                payload: {
                  userAgent: getClientDetails(),
                  data: {
                    detail: `${userEmail} logs in to the admin console`,
                  },
                },
              });
            }
          } catch (error) {
            // const errorData: ErrorPropT | any = decryptData(error);
            // // console.log(errorData["message"]);
            setshowActivityIndicator(false);
            consoleLog("error login ", error);
            toast.error(`${error}`);
            await logger.logActivities("remote", {
              date: new Date().toString(),
              author: userEmail,
              resource: "admin",
              action: "LOGIN",
              payload: {
                userAgent: getClientDetails(),
                data: {
                  detail: `${userEmail} login attempt failed.`,
                },
              },
            });
          }
        // } else {
        //   //send user email to the endpoint for barred user.
        //   show.error(
        //     "You have been barred, please contact admin to resolve your account issues"
        //   );
        // }
      }
    } else {
      toast.error("Only email address issued by fountainpay is accepted");
      await logger.logActivities("remote", {
        date: new Date().toString(),
        author: userEmail,
        resource: "admin",
        action: "LOGIN",
        payload: {
          userAgent: getClientDetails(),
          data: {
            detail: `${userEmail} login attempt failed, Not a valid email address`,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (userEmail.length > 5 && userPassword.length > 5) {
      setisBtnActive(true);
    } else {
      setisBtnActive(false);
    }
  }, [userEmail, userPassword]);

  useLayoutEffect(() => {
    removeStoredAuthToken();
    setClientLocation(getLocation());
    //Checking if user already login.
    // UserClass.alreadyLogin() && history.push("/dashboard/over-view");
  }, []);

  return (
    <div>
      <></>
      <Modal isOpen={isresetModal} closeButton={true} onClose={openResetModal}>
        <PasswordResetContainer>
          <h2>Password Reset</h2>
          <ReguestSubtitle>
            To proceed with this request, Kindly provide your registed email
            address
          </ReguestSubtitle>
          <PwdResetForm onSubmit={handlePWDrequest}>
            <ResetEmailInput
              type="email"
              placeholder="Email Address"
              value={resetEmail}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setresetEmail(event.target.value);
              }}
              required
            />
            <ResetButton type="submit">{pwdrestBtnText}</ResetButton>
          </PwdResetForm>
        </PasswordResetContainer>
      </Modal>

      <LoginPageContainer>
        <Container>
          <FormContainer>
            <LogoContainer>
              <LogoImage src={logo} alt="fountainpay logo" />
            </LogoContainer>

            <LoginForm>
              <WelcomeText>Welcome back!</WelcomeText>
              {loginError && (
                <ErrorContainer>
                  <ErrorText>{loginError}</ErrorText>
                </ErrorContainer>
              )}
              <Theform onSubmit={handleLogin} autoComplete="off">
                <InputContainer>
                  <input type="hidden" autoComplete="false" />
                  <InputLabel> Email</InputLabel>
                  <LoginInput
                    type="email"
                    placeholder="Email"
                    value={userEmail}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      setuserEmail(event.toString());
                    }}
                    required
                    autoComplete="off"
                  />
                </InputContainer>
                <InputContainer>
                  <InputLabel> Password</InputLabel>
                  <LoginInput
                    type={passwordVisible ? "password" : "text"}
                    placeholder="Password"
                    value={userPassword}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      setuserPassword(event.toString());
                    }}
                    required
                    autoComplete="off"
                  />
                  <Icon
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    type={passwordVisible ? "eye-blocked" : "eye"}
                    style={{
                      color: "#760000",
                      fontSize: 18,
                      position: "absolute",
                      right: 7,
                      top: 45,
                      cursor: "pointer",
                    }}
                  />
                </InputContainer>

                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <LoginPageSpan onClick={openResetModal}>
                    Reset Password ?
                  </LoginPageSpan>
                  <LoginPageSpan>Contact Support</LoginPageSpan>
                </div>
                <div style={{ width: "100%", marginTop: "4rem" }}>
                  <LoginButton isActive={isBtnActive} type="submit">
                    {showActivityIndicator ? <Spinner color="#fff" /> : "Login"}
                  </LoginButton>
                  {/* <Button /> */}
                </div>
              </Theform>
            </LoginForm>
          </FormContainer>
        </Container>
        <BannerContainer url={loginBanner}></BannerContainer>
      </LoginPageContainer>
    </div>
  );
};

export default Login;
