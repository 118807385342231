import { json } from "node:stream/consumers";
import api from "shared/utils/api";

import ApiMultiPart from "shared/utils/apiiMultiPart";
import consoleLog from "shared/utils/consoleLog";
import { decryptData } from "shared/utils/security";

class KYC {
  async overview(type = "GATEWAY") {
    try {
      const response = await api.get(`/kyc/overview?type=${type}`);
      // console.log("kyc overview", response);
      return response;
    } catch (error) {
      console.log("error in kyc overview", error);
    }
  }

  async getKycView(type: string, status: string, page = 1) {
    try {
      const response = await api.get(
        `/kyc/items?type=${type}&status=${status}&page=${page}`
      );
      // console.log("kyc overview", response);
      return response;
    } catch (error) {
      console.log("error in getting approved KYC", error);
    }
  }

  async getApprovedKyc(type: string, page = 1) {
    try {
      const response = await api.get(`/kyc/approved?type=${type}&page=${page}`);
      // console.log("kyc overview", response);
      return response;
    } catch (error) {
      console.log("error in getting approved KYC", error);
    }
  }

  async getPendingKyc(type: string, page = 1) {
    try {
      const response = await api.get(`/kyc/pending?type=${type}&page=${page}`);
      return response;
    } catch (error) {
      console.log("error in getting pending KYC", error);
    }
  }

  async getRejectedKyc(type: string, page = 1) {
    try {
      const response = await api.get(`/kyc/rejected?type=${type}&page=${page}`);
      // console.log("kyc overview", response);
      return response;
    } catch (error) {
      console.log("error in getting rejected KYC", error);
    }
  }

  async KycList(page = 1, type: string) {
    try {
      const response = await api.get(`/kyc/total?type=${type}&page=${page}`);
      // console.log(type, "response", response);
      return response;
    } catch (error) {
      console.log("error in kyc list", error);
      return error;
    }
  }

  async sendOTP(data: any) {
    try {
      // console.log("send otp data", data);
      const response = await api.post("/send-kyc-otp", data);
      if (response?.payload) return response;
    } catch (error) {
      return error;
    }
  }

  async confirmOTP(data: any) {
    try {
      // console.log("data to confirm otp", data);
      const response = await api.post("/confirm-otp", data);
      // console.log("response from confirming OTP==>", response.message);
      if (response?.status) return response;
    } catch (error) {
      // // console.log("error confirming OTP==>", error);
      return error;
    }
  }

  async rejectKYC(data: any, id: any) {
    try {
      const response = await api.put(`/kyc/${id}/deactivate`, data);
      // console.log("deactivate kyc", response);
      if (response.status) return response;
    } catch (error) {
      // console.log("error deactivating kyc", error);
      return error;
    }
  }

  uploadFile = async (value: any) => {
    try {
      const res = await ApiMultiPart.post("/business/kyc/upload", value);
      if (res.data) {
        res.data = decryptData(res.data, false);
        return res;
      }
    } catch (error) {
      const resErr: any = error;
      console.log("upload response", resErr?.response);
      // throw handleApiError(error);
    }
  };

  updateFiles = async (data: any, ID: any) => {
    try {
      const response = await api.put(`/kyc/${ID}`, data);
      // console.log("upload data response", response);
      if (response.status) return response;
    } catch (error) {
      // console.log("error on uploading files", error);
      return error;
    }
  };

  getKycdata = async (id: string) => {
    try {
      const response = await api.get(`/kyc/${id}`);
      // consoleLog("kyc data", response);
      if (response?.payload) return response;
    } catch (error) {}
  };
}

const kycOBJ = new KYC();
export default kycOBJ;
