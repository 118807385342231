import React from "react";
import { Step, StepLabel, StepLine, Value, ValueCont } from "./Styles";

type PropT = {
  data: {
    value: number;
    label: string;
  };
  isActive: boolean;
  doneSteps: [] | any;
};
export default function StepComp({ data, isActive, doneSteps }: PropT) {
  return (
    <Step>
      <ValueCont>
        <Value isActive={doneSteps.includes(data?.value) || isActive}>
          {doneSteps.includes(data?.value) ? (
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 6.93478L6.14286 11.5L14.5 1"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            data?.value
          )}
        </Value>
        {data.value !== 4 && <StepLine></StepLine>}
      </ValueCont>
      <StepLabel>{data?.label}</StepLabel>
    </Step>
  );
}
