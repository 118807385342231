import { DownloadBtn } from "Report/cbn/styles";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

type Props={
    excelHeader?: any;
    excelData: any;
    fileName: string;
}
const ExportExcel=({excelHeader, excelData, fileName}: Props)=>{
    const fileType='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension=".xlsx";

    

    const renameKey=(obj: any, old_key: string, new_key: string)=> {
        obj = obj?.map((obj: any)=>{
            // Assign new key
            obj[new_key] = obj[old_key];
        
            // Delete old key
            delete obj[old_key];
        
            return obj;
        })
    }
    
    const ExportToExcel=async()=>{
        // console.log("Before: ", excelData)
        if(excelHeader){
            for(var ln in excelHeader){
                renameKey(excelData, excelHeader[ln].id, excelHeader[ln].label)
                //excelData.forEach((obj: any) => renameKey(obj, excelHeader[ln].id, excelHeader[ln].label));
            }
            //excelData=Object.assign(excelHeader, excelData);
        }
        // console.log("Data: ",excelData)
        const ws=XLSX.utils.json_to_sheet(excelData);
        const wb={ Sheets: { 'data': ws}, SheetNames: ['data']};
        const excelBuffer=XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data=new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName+fileExtension);
    }

    
    return(
        <DownloadBtn onClick={(e)=>ExportToExcel()}>Export data</DownloadBtn>
    )
}

export default ExportExcel;