import icon1 from "../Dashboard/assets/icon1.svg";
import icon2 from "../Dashboard/assets/icon2.svg";
import icon3 from "../Dashboard/assets/icon3.svg";
import icon4 from "../Dashboard/assets/icon4.svg";
export const boxData = [
  // {
  //   id: 1,
  //   title: "Transactions",
  //   icon: icon1,
  //   background: true,
  //   urlPath: "transactions",
  //   tableHeader: [
  //     { id: "updatedAt", label: "Date and Time" },
  //     { id: "reference", label: "Transaction ID" },
  //     { id: "businessName", label: "Business Name" },
  //     { id: "serviceName", label: "Service" },
  //     { id: "name", label: "Payer" },
  //     { id: "email", label: "Email" },
  //     { id: "gatewayChannel", label: "Channel Type" },
  //     { id: "amount", label: "Amount" },
  //     { id: "charge", label: "Charges" },
  //     { id: "remitedAmount", label: "Remitted Amount" },
  //     { id: "status", label: "Status" },
  //   ],
  //   endpoints: {
  //     gateway: "/report/transactions",
  //   },
  // },
  {
    id: 2,
    title: "Business",
    icon: icon2,
    background: false,
    urlPath: "business",
    tableHeader: [
      { id: "businessName", label: "Business Name" },
      { id: "numOfServices", label: "No. of Service" },
      { id: "kycStatus", label: "KYC STATUS" },
      { id: "totalTransactions", label: "Total Transactions" },
    ],
    endpoints: {
      gateway: "/report/business",
    },
  },
  // {
  //   id: 3,
  //   title: "Failed Transactions",
  //   icon: icon3,
  //   background: false,
  //   urlPath: "failedtransactions",
  //   tableHeader: [
  //     { id: "reference", label: "Transaction ID" },
  //     { id: "businessName", label: "Business Name" },
  //     { id: "serviceName", label: "Service" },
  //     { id: "amount", label: "Amount" },
  //     { id: "channelType", label: "Channel Type" },
  //     { id: "updatedAt", label: "Date and Time" },
  //   ],
  //   endpoints: {
  //     gateway: "/report/transactions",
  //   },
  //   where: { status: "FAILED" },
  // },

  // {
  //   id: 4,
  //   title: "Success Transactions",
  //   icon: icon4,
  //   background: false,
  //   urlPath: "successtransactions",
  //   tableHeader: [
  //     { id: "reference", label: "Transaction ID" },
  //     { id: "businessName", label: "Business Name" },
  //     { id: "serviceName", label: "Service" },
  //     { id: "amount", label: "Amount" },
  //     { id: "channelType", label: "Channel Type" },
  //     { id: "updatedAt", label: "Date and Time" },
  //   ],
  //   endpoints: {
  //     gateway: "/report/transactions",
  //   },
  //   where: { status: "SUCCESSFUL" },
  // },

  // {
  //   id: 5,
  //   title: "Pending Transactions",
  //   icon: icon4,
  //   background: false,
  //   urlPath: "pendingtransactions",
  //   tableHeader: [
  //     { id: "reference", label: "Transaction ID" },
  //     { id: "businessName", label: "Business Name" },
  //     { id: "serviceName", label: "Service" },
  //     { id: "amount", label: "Amount" },
  //     { id: "channelType", label: "Channel Type" },
  //     { id: "updatedAt", label: "Date and Time" },
  //   ],
  // },
  // {
  //   id: 6,
  //   title: "QR Pay",
  //   icon: icon2,
  //   background: false,
  //   urlPath: "qrpay",
  //   tableHeader: [
  //     { id: "reference", label: "Transaction ID" },
  //     { id: "businessName", label: "Business Name" },
  //     { id: "serviceName", label: "Service" },
  //     { id: "amount", label: "Amount" },
  //     { id: "updatedAt", label: "Date and Time" },
  //     { id: "status", label: "Status" },
  //   ],
  //   endpoints: {
  //     gateway: "/report/transactions",
  //   },
  //   where: { paymentMode: "QR" },
  // },
  // {
  //   id: 7,
  //   title: "Card Payments",
  //   icon: icon3,
  //   background: false,
  //   urlPath: "cardpayments",
  //   tableHeader: [
  //     { id: "reference", label: "Transaction ID" },
  //     { id: "businessName", label: "Business Name" },
  //     { id: "serviceName", label: "Service" },
  //     { id: "amount", label: "Amount" },
  //     { id: "updatedAt", label: "Date and Time" },
  //     { id: "status", label: "Status" },
  //   ],
  //   endpoints: {
  //     gateway: "/report/transactions",
  //   },
  //   where: { paymentMode: "CARD" },
  // },

  // {
  //   id: 8,
  //   icon: icon3,
  //   title: "Direct Debit",
  //   background: false,
  //   urlPath: "directdebit",
  //   tableHeader: [
  //     { id: "reference", label: "Transaction ID" },
  //     { id: "businessName", label: "Business Name" },
  //     { id: "serviceName", label: "Service" },
  //     { id: "amount", label: "Amount" },
  //     { id: "updatedAt", label: "Date and Time" },
  //     { id: "status", label: "Status" },
  //   ],
  //   endpoints: {
  //     gateway: "/report/transactions",
  //   },
  //   where: { paymentMode: "DIRECT_DEBIT" },
  // },
  // {
  //   id: 9,
  //   icon: icon3,
  //   title: "Settlement",
  //   background: false,
  //   urlPath: "./settlements/overview",
  //   tableHeader: [
  //     { id: "reference", label: "Transaction ID" },
  //     { id: "businessName", label: "Business Name" },
  //     { id: "serviceName", label: "Service" },
  //     { id: "amount", label: "Amount" },
  //     { id: "updatedAt", label: "Date and Time" },
  //     { id: "status", label: "Status" },
  //   ],
  // },

  // {
  //   id: 10,
  //   icon: icon3,
  //   title: "CBN Report",
  //   background: false,
  //   urlPath: "cbn",
  //   tableHeader: [
  //     { id: "reference", label: "Transaction ID" },
  //     { id: "businessName", label: "Business Name" },
  //     { id: "serviceName", label: "Service" },
  //     { id: "amount", label: "Amount" },
  //     { id: "updatedAt", label: "Date and Time" },
  //     { id: "status", label: "Status" },
  //   ],
  // },
];
