import GraphSection from "./graphSection";
import TableSection from "./tableSection";
import DonutChart from "./DonutChart/DonutChart";
import PieChart from "./PieChart/PieChart";
import {
  ParentContainer,
  FirstSectionLayout,
  SecondSectionLayout,
  Container,
  DonoutchartContainer,
} from "./Style";

export default () => {
  return (
    <ParentContainer>
      <FirstSectionLayout>
        <Container>
          <GraphSection />
        </Container>

        <Container>
          <TableSection />
        </Container>
      </FirstSectionLayout>

      <SecondSectionLayout>
        <DonoutchartContainer>
          <DonutChart />
        </DonoutchartContainer>

        <DonoutchartContainer>
          <PieChart />
        </DonoutchartContainer>
      </SecondSectionLayout>
    </ParentContainer>
  );
};
