import { getCurrentUser } from "shared/utils/authToken";
import { GuardFunction } from "./types/guard";
import { tryCatchSync } from "./utils/tryCatch";

type User = {
  firstName: string;
  lastName: string;
  roles: string[];
};

export const SuperAdminGuard: GuardFunction = () => {
  const [user, err] = tryCatchSync<any>(() =>
    // TODO - create a hook
    getCurrentUser() //JSON.parse(window.localStorage.getItem('user') || '')
  );
  // Error from `JSON.parse`
  //alert('Super Step 1: '+ JSON.stringify(user))
  if (err) {
    return false;
  }

  //alert('Super Step 2: '+ JSON.stringify(user))
  // If no user found in the local storage
  if (!user) {
    return false;
  }

  //alert('Super Step 3: '+ JSON.stringify(user))
  if (!user.type) {
    return false;
  }

  // If the user roles includes `admin` allow to access the route
  return user.type == "SUPER_ADMIN";
};
