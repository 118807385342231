import styled from "styled-components";
import breakpoints from "shared/utils/breakpoints";
import { color, mixin, sizes, zIndexValues } from "shared/utils/styles";
import BreakPoints from "shared/utils/Breakpoint";

type PageTitlePropT = {
  isTitle?: boolean;
};

type NavIconPropT = {
  isClicked: boolean;
};

export const NavTop = styled.aside`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  /* overflow: hidden; */
  padding: 0.2rem 0;
  position: fixed;
  width: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  z-index: 200;

  @media (${BreakPoints.xs}) {
  }

  @media ${breakpoints.device.min.md} {
    z-index: ${zIndexValues.navtop};
    position: fixed;
    top: 0;
    left: ${sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth}px;
    width: 74%;
    height: ${sizes.appNavBarLeftWidth + 26}px;
    background: ${color.backgroundLightWhite};
    border-bottom: 1px solid ${color.borderLightest};
    transition: all 0.1s;
    ${mixin.hardwareAccelerate}
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.6);
  }

  @media ${breakpoints.device.min.xl} {
    width: 78%;
  }

  @media ${breakpoints.device.min.xxl} {
    width: 80%;
  }
`;

export const NavbarContainer = styled.div`
  width: 100%;
  padding: 0.4rem;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: 768px) {
    padding: 0 2.4rem;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PageTitle = styled.h2`
  font-weight: 800;
  font-size: 1.4rem;
  width: 50%;
  display: none;

  @media only screen and (min-width: 768px) {
    font-weight: 800;
    font-size: 1.4rem;
    width: 50%;
    display: block;
  }
`;

export const SearchBoxContainer = styled.div`
  width: 90%;

  @media only screen and (min-width: 768px) {
    width: 46%;
  }
`;

export const SubContainer = styled.div<PageTitlePropT>`
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
  gap: 1rem;

  display: ${(prop) => prop.isTitle && "none"};
  @media only screen and (min-width: 768px) {
    height: 50px;
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: ${(prop) => prop.isTitle && "block"};
  }
`;

export const MenuIcon = styled.label`
  width: 2rem;
  height: 2rem;
  position: relative;
  margin-left: 0.4rem;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const NavIcon = styled.span`
  display: block;
  position: relative;
  top: 0.4rem;
  background: #000;
  height: 0.2rem;
  width: 1.6rem;

  &::before {
    content: "";
    background: #000;
    height: 0.22rem;
    width: 1.6rem;
    display: block;
    position: absolute;
    top: 0.4rem;
  }

  &::after {
    content: "";
    background: #000;
    height: 0.23rem;
    width: 1.6rem;
    display: block;
    position: absolute;
    top: 0.79rem;
  }
`;

export const PageTitleSpan = styled.span`
  /* background: #828282; */
  background: ${color.backgroundMedium};
  color: #828282;
  margin-left: 0.4rem;
  padding: 0.4rem;
  font-size: 18px;
  border-radius: 0.4rem;
  font-weight: normal;
`;
