import { getCurrentUser } from "./authToken";
import consoleLog from "./consoleLog";

export const menuArray = [
  {
    id: "gateway",
    name: "Gateway",
    children: [
      {
        id: "gOverview",
        name: "Overview",
        link: "/dashboard/over-view",
      },
      {
        id: "gBusiness",
        name: "Businesses",
        link: "/business/home",
      },
      {
        id: "gKyc",
        name: "kyc",
        link: "/kyc/home",
      },
      {
        id: "gDisputes",
        name: "Disputes",
        link: "/disputes",
      },
      {
        id: "gPricing",
        name: "Pricing",
        link: "/pricing",
      },
      {
        id: "gSettlements",
        name: "Settlements",
        link: "/settlements",
      },
    ],
  },
  {
    id: "agencyBanking",
    name: "Agency Banking",
    children: [
      {
        id: "aOverview",
        name: "Overview",
        link: "/agencybanking/over-view",
      },
      {
        id: "aKyc",
        name: "kyc",
        link: "/agencybanking/kyc/home",
      },
      {
        id: "aBusiness",
        name: "Businesses",
        link: "/agencybanking/business/home",
      },

      {
        id: "aTransactions",
        name: "Transactions",
        link: "/agencybanking/financial",
      },
      {
        id: "aInventory",
        name: "Inventory",
        link: "/inventory",
      },
      {
        id: "ageolocation",
        name: "Geolocation",
        link: "/geolocation",
      },
    ],
  },
  {
    id: "reports",
    name: "Reports",
    children: [
      {
        id: "rGateway",
        name: "Gateway Reports",
        link: "/gateway/report",
      },
      {
        id: "rAgency",
        name: "Agency Reports",
        link: "/agency/report",
      },
    ],
  },
  {
    id: "roleMgt",
    name: "Role Management",

    children: [
      {
        id: "users",
        name: "Users",
        link: "/role-management/cusers",
      },
      {
        id: "usersgroup",
        name: "Users Access Group",
        link: "/role-management/users-access-group",
      },
      {
        id: "menumanager",
        name: "Menu Manager",
        link: "/role-management/menu-manager",
      },
    ],
  },
  {
    id: "systemMonitor",
    name: "System Monitor",
    link: "/system-status/admin-logs",
  },
  {
    id: "resetRequest",
    name: "Reset Request",
    link: "/users/reset-request",
  },
  {
    id: "inviteUsers",
    name: "Invite Users",
    link: "/users/invite-users",
  },
  {
    id: "finders",
    name: "Finders",
    link: "/finders",
  },
  {
    id: "settings",
    name: "Settings",
    link: "/settings/channel-activation",
  },
];

const recursiveMenuFn = (menu: any, arr: any) => {
  if (!menu.children) {
    arr.push(menu);
  } else {
    for (let menuChild of menu.children) {
      recursiveMenuFn(menuChild, arr);
    }
  }
};

export const plainArray = () => {
  let arr: any[] = [];
  for (let menu of menuArray) {
    recursiveMenuFn(menu, arr);
  }
  return arr;
};

const recursiveMenuFnAll = (menu: any, arr: any) => {
  if (!menu.children) {
    arr.push(menu);
  } else {
    arr.push({ id: menu?.id, name: menu?.name });
    for (let menuChild of menu.children) {
      recursiveMenuFn(menuChild, arr);
    }
  }
};

export const allPlainArray = () => {
  let arr: any[] = [];
  for (let menu of menuArray) {
    recursiveMenuFnAll(menu, arr);
  }
  return arr;
};

export const checkIfUrlExist = (url: string) => {
  let arr = plainArray();
  for (let menu of arr) {
    if (
      menu.link &&
      url
        .toLowerCase()
        .trim()
        .includes(menu.link.toLowerCase().trim())
    ) {
      return menu;
    }
  }
  return false;
};

export const validateMenu = (url: string, menus: any) => {
  // return true;
  if (menus instanceof Object) {
    let menuItem = checkIfUrlExist(url);
    consoleLog(menuItem, "menuItemmm", url);
    if (!menuItem) return true;
    if (menus[menuItem.id]) return true;
  }
  return false;
};

///permissions..
/*
Task:

check all menus...
check all routes. Done
validate the permissions...

*/

export const validatePermissions = (val: string) => {
  // return true;
  const currentUser = getCurrentUser();
  let permissions = currentUser?.Role?.permissions;
  // return false;
  return (
    permissions instanceof Array &&
    (permissions[0].allOpen || permissions[0][val])
  );
};
