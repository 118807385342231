import "chart.js/auto";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import { Option, Select } from "shared/quickComponents/select";
import { color } from "../../../../shared/utils/styles";
import { Data } from "./Data";
import { GraphContent, GraphHeader } from "./Styles";

type ChartPropT = {
  id: number;
  month: string;
  transaction: number;
  userLost: number;
}[];

export default function LineChart() {
  const [chartData, setchartData] = useState<ChartPropT | null>(Data);
  const [category, setcategory] = useState("This week");
  //set chartData state so we can change the chart data anytime
  const [userData, setUserData] = useState({
    labels: chartData?.map((data) => data.month),
    datasets: [
      {
        label: "Total transactions",
        data: Data.map((data) => data.transaction),
        backgroundColor: "#FFF8F8",
        borderColor: color.fountain,
        borderWidth: 6,
        pointBackgroundColor: "white",
        pointBorderColor: color.fountain,
        pointBorderWidth: 3,
        pointRadius: 8,
        tension: 0.4,
        fill: true,
      },
    ],
  });

  function kFormatter(num: any) {
    let exp = (Math.sign(num) * Math.abs(num)) / 1000;
    return Math.abs(num) > 999
      ? ` ${exp.toFixed(1)}k`
      : Math.sign(num) * Math.abs(num);
  }

  const options = {
    plugins: { legend: { display: false } },
    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          callback: function(value: any, index: any) {
            return kFormatter(value);
          },
        },
      },
    },
  };
  return (
    <>
      <GraphHeader>
        <div style={{ width: "22%" }}>
          <Select value={category} variant="outline">
            <Option variant="outline" onClick={() => setcategory("This week")}>
              This week
            </Option>

            <Option variant="outline" onClick={() => setcategory("This month")}>
              This month
            </Option>

            <Option variant="outline" onClick={() => setcategory("This year")}>
              This year
            </Option>
          </Select>
        </div>
      </GraphHeader>
      <GraphContent>
        <Line data={userData} options={options} />
      </GraphContent>
    </>
  );
}
