import React from "react";
import Layout from "Layout";

export default function Bugs() {
  alert("Bug");
  return (
    <>
      <Layout pageTitle="System Monitor"></Layout>
    </>
  );
}
