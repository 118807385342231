import styled, { css } from "styled-components";

type maxWidthProp = {
  maxwidth?: string;
  border?: boolean;
};

export const TableRowContainer = styled.tr`
  background: #ffffff;
  height: 50px;
  border-bottom: 0.5px solid rgba(130, 130, 130, 0.9);
  &:nth-child(even) {
    background-color: #f1f2f5;
  }

  &:nth-child(odd) {
    background-color: #fff;
  }

  &:hover {
    background-color: #f1f2f5;
    /*cursor: pointer;*/
  }
`;

export const TableLinkRowContainer = styled.tr`
  background: #ffffff;
  height: 50px;
  border-bottom: 0.5px solid rgba(130, 130, 130, 0.9);
  cursor: pointer;
  /* font-size: 14.7px !important; */
  text-align: left;
  &:hover {
    background-color: #eff3f5;
  }
`;

export const TableLinkTdwContainer = styled.td<maxWidthProp>`
  text-align: left;
  padding: 10px 10px;
  width: fit-content;
 /* background-color: #000; */
  font-size: 0.88rem !important;
  /* max-width: ${(prop) => prop.maxwidth && prop.maxwidth}; */
  border: ${(prop) => (prop.border ? "0px solid orange" : "none")};
  vertical-align: middle;

  &:nth-child(1) {
    padding-left: 1.4em;
  }
`;

export const TableData = styled.td<maxWidthProp>`
  text-align: left;
  padding: 10px 10px;
  ${(props) =>
    props.border &&
    css`
      border: 1px solid orange;
    `};

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};

  &:nth-child(1) {
    padding-left: 1.4em;
  }
`;
