import { ActionTypes } from "../constants/actionType";

const initialState = {
  settings: null,
  sidebar: null,
  resetState: false,
  toggle: false,
  consoleCategory: "Gateway",
};

export const settingsReducer = (
  state: any = initialState.settings,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_SETTINGS_LISTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const sidebarReducer = (
  state: any = initialState.sidebar,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_MENUS: {
      return { ...action.payload };
    }
    default:
      return state;
  }
};

export const resetStateReducer = (
  state: any = initialState.resetState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const setMenuToggleStateReducer = (
  state: any = initialState.toggle,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_TOGGLE_STATE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
