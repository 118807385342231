import { TableActions, TransStatus, ViewAllBtn } from "Business/Tab/Styles";
import { numberFormat } from "./utility";

export const businessTableSearch = (
  content: any,
  tableName: string,
  col: any,
  pageData: any[],
  extraFn?: any
) => {
  switch (tableName) {
    //=================== transaction ====================
    case "Transactions":
      const newlist = pageData?.filter((data: any) => {
        if (
          data[col]
            .toString()
            .toLowerCase()
            .indexOf(content.toString().toLowerCase()) > -1
        ) {
          return data;
        }
      });
      const transactions = newlist.map((transaction: any, index: number) => ({
        id: index + 1,
        sn: transaction?.Services?.serviceId,
        serviceName: transaction?.Services?.name,
        reference: transaction?.reference,
        email: transaction?.customer?.email,
        amount: numberFormat(
          transaction?.amount ? parseInt(transaction?.amount) : 0
        ),
        dateAndTime: transaction.createdAt,
        status: (
          <TransStatus
            status={
              transaction.status === "INIT" ? "In Progress" : transaction.status
            }
          >
            {transaction.status === "INIT" ? "In Progress" : transaction.status}
          </TransStatus>
        ),
        funcProps: (
          <TableActions>
            <ViewAllBtn
              onClick={() => {
                extraFn?.setisModalOpen(true);
                extraFn?.setselectedTransaction(transaction);
              }}
            >
              View
            </ViewAllBtn>
          </TableActions>
        ),
      }));
      return transactions;
      break;
    // =====================================================
    case "Customers":
      const newCustormerList = pageData?.filter((data: any) => {
        if (
          data[col]
            .toString()
            .toLowerCase()
            .indexOf(content.toString().toLowerCase()) > -1
        ) {
          return data;
        }
      });

      const custormers = newCustormerList.map(
        (customer: any, index: number) => ({
          id: index + 1,
          sn: customer?.Services?.serviceId,
          serviceName: customer?.Services?.name,
          fullName: customer?.fullName,
          email: customer?.email,
          phoneNumber: customer?.phone,
        })
      );
      console.log("here =====>", custormers);
      return custormers;
      break;

    case "Service":
      break;

    default:
      break;
  }
};
