import React, { useEffect, useState } from "react";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { Container, TableContainer } from "../Styles";
import api from "shared/utils/api";
import summaryData from "shared/utils/dataSummary";
import { currencyFormat100, formateDateString2 } from "shared/utils/utility";
import { EmptyState, EmptyState2, GradientLoader } from "shared/components";
import Table from "shared/clientcomponents/Table";
import { getStatusColor } from "shared/utils/statusColor";
import { useHistory } from "react-router-dom";
import { omniGetter, omniSaver } from "shared/utils/ls";

export default function TransactionOverview() {
  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();
  let currentDate = `${currentDay}/${currentMonth}/${currentYear}`;
  const [isDataFetched, setisDataFetched] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");
  const [transactions, settransactions] = useState<any[]>([]);
  const [filterObj, setfilterObj] = useState(omniGetter("searchParams") || {
    start: currentDate,
    end: currentDate,
    search: "",
    successful: true,
    failed: true,
    terminated: true,
    card: true,
    qrpay: true,
    internetBanking: true,
    directDebit: true,
  });

  const [isloading, setisloading] = useState(false);
  const [bodyData, setBodyData] = useState([] as any);

  const history = useHistory();

  const handleFilter = (obj: any) => {
    console.log("handle transaction filter", obj);
    omniSaver("searchParams", obj);
    setfilterObj(obj);
  };

  const tableHeader = [
    { id: "updatedAt", label: "Date/Time" },
    { id: "reference", label: "Transaction ID" },
    { id: "businessName", label: "Business Name" },
    { id: "serviceName", label: "Service" },
    { id: "name", label: "Payer" },
    { id: "email", label: "Email" },
    { id: "gatewayChannel", label: "Channel" },
    { id: "amount", label: "Amount" },
    { id: "charge", label: "Charges" },
    { id: "remitedAmount", label: "Remitted" },
    { id: "status", label: "Status" },
  ];
  const hoverLink = (val: any) => {
    history.push(`/gateway/report/details/transactions/${val?.id}`);
  };

  const makeApiCall = async () => {
    setisloading(true);
    try {
      const response = await api.get(
        `/report/transactions?page=${currentPage}`,
        filterObj
      );
      if (response?.status) {
        const transactionsData = response?.payload?.data;
        // console.log("new report transactionpage", transactionsData);
        const transformedData = transactionsData.map(
          (obj: any, ind: number) => {
            return {
              id: obj?.id,
              name: obj?.name,
              email: obj?.email,
              amount: currencyFormat100(obj?.amount),
              charge: currencyFormat100(obj?.charge),
              remitedAmount: currencyFormat100(obj?.remitedAmount),
              status:
                obj?.status &&
                (obj.status.toLowerCase().includes("init") ? (
                  <div style={{ color: getStatusColor("terminated") }}>
                    In progress
                  </div>
                ) : (
                  <div style={{ color: getStatusColor(obj?.status) }}>
                    {obj?.status?.toLowerCase()[0].toUpperCase() +
                      obj?.status?.toLowerCase().substring(1)}
                  </div>
                )),
              updatedAt: formateDateString2(obj?.updatedAt),
              reference: obj?.reference,
              businessName: obj?.businessName,
              serviceName: obj?.serviceName,
              gatewayChannel: obj?.gatewayChannel.replace("_", " "),
            };
          }
        );

        //   console.log("Data: ", transformedData);
        settransactions(transformedData);
        settotalPages(response?.payload?.totalPages);
        setisDataFetched(true);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setisloading(false);
    }
  };

  useEffect(() => {
    if (!isDataFetched) {
      makeApiCall();
    }
  }, []);

  useEffect(() => {
    makeApiCall();
  }, [currentPage, filterObj]);

  return (
    <Container>
      <DashboardSubHeader
        title={"Transactions"}
        btnTitle="Download"
        widthActionBtn={true}
        withSearch={false}
        withFilter={true}
        filterType={"transactions"}
        callBackFn={(obj: any) => handleFilter(obj)}
        excelExport
        excelExportData={summaryData(
          transactions?.length ?? 0,
          tableHeader,
          transactions
        )}
      />

      {isloading && <GradientLoader />}
      
      {!isloading && transactions.length < 1  && <EmptyState2 />}
      {!isloading && transactions.length>0 && (
        <TableContainer>
          <Table
            headData={tableHeader}
            bodyData={transactions}
            rowStyle={{ background: "#ffffff" }}
            totalPages={totalPages}
            currentpage={currentPage}
            goToPage={(value: any) => {
              setcurrentPage(value);
            }}
            hoverLink={hoverLink}
          />
        </TableContainer>
      )}
    </Container>
  );
}
