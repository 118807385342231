import React from "react";
import { useLocation } from "react-router-dom";
import backicon from "../../../App/assets/images/backicon.png";
import styled from "styled-components";
import { color } from "../../utils/styles";
import BreakPoints from "shared/utils/Breakpoint";
import breakpoints from "shared/utils/breakpoints";
const BackBtn = styled.img`
  margin-top: 5rem;
  margin-left: -1.6rem;
  position: absolute;
  z-index: 100;
  cursor: pointer;
  background: #ffffff;
  padding: 0.4rem;
  border-radius: 20.4rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  @media ${breakpoints.device.max.sm} {
    margin-left: 1rem;
    top: -0.4rem;
    width: 1.8rem;
    height: 1.8rem;
    border: 1px solid ${color.fountain};
    background: ${color.secondary};
  }

  @media (${BreakPoints.xs}) {
    margin-left: 1rem;
    top: -0.4rem;
    width: 1.8rem;
    height: 1.8rem;
    background: ${color.fountain};
  }
`;

export default function GoBack() {
  const location = useLocation();
  let currentRoute = location.pathname;
  // console.log("current location", location.pathname);
  let lastPageUrl = document.referrer;

  const handleClick = () => {
    if (currentRoute === "/dashboard/over-view") {
      return;
    } else {
      history.back();
    }
  };

  return <BackBtn onClick={handleClick} src={backicon} />;
}
