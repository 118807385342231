import React, { useState } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { ProtectedRoute } from "guards/ProtectedRoute";
import { SuperAdminGuard } from "guards/super-admin-guard";
import { AdminGuard } from "guards/admin-guard";

import { GradientLoader, PageError } from "shared/components";
import Layout from "Layout";
import AllBusiness from "./AllBusiness/AllBusiness";
import BusinessHome from "./Home";
import Single from "./Single";
import TabSelected from "./Tab";
import CreateBusiness from "./AddBusiness/Index";
import TransactionDetails from "./Single/TransactionDetails";

export default function Business() {
  const [data, setdata] = useState<boolean>(true);
  const match = useRouteMatch();

  return (
    <>
      {/* <Layout pageTitle="Business"> */}
      <>
        <ProtectedRoute
          path={`${match.path}/home`}
          guards={[SuperAdminGuard, AdminGuard]}
          component={BusinessHome}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/overview/:id`}
          guards={[SuperAdminGuard, AdminGuard]}
          component={Single}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/overview/transaction/:id`}
          guards={[SuperAdminGuard, AdminGuard]}
          component={TransactionDetails}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/create-business`}
          guards={[SuperAdminGuard, AdminGuard]}
          component={CreateBusiness}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/overview/business/service`}
          guards={[SuperAdminGuard, AdminGuard]}
          component={TabSelected}
          fallback={() => <PageError />}
        />

        {/* <Route
              exact
              path={`${match.path}/overview/:businessid/:tabName/:id`}
              render={() => <TabSelected />}
            /> */}

        <ProtectedRoute
          path={`${match.path}/all`}
          guards={[SuperAdminGuard, AdminGuard]}
          component={AllBusiness}
          fallback={() => <PageError />}
        />
        {match.isExact && <Redirect to={`${match.url}/home`} />}
      </>
      {/* </Layout> */}
    </>
  );
}
