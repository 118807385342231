import React from "react";
import { Link } from "react-router-dom";
import { BusinessData } from "../Data";
import {
  PageLayout,
  TableContainer,
  TableContent,
  TableContentMain,
  TableHeader,
  TableRow,
} from "./Styles";

export default function AllBusiness() {
  const businessList = BusinessData.map((biz) => (
    <div key={biz.id}>
      <Link to={`/agencybanking/business/overview/${biz.id}`}>
        <TableRow>
          <TableContentMain>{biz.binessName}</TableContentMain>
          <TableContentMain>{biz.amountProcessed}</TableContentMain>
          <TableContentMain>{biz.numOfServices}</TableContentMain>
          <TableContentMain>{biz.amountSettled}</TableContentMain>
          <TableContentMain>{biz.numOfSubAcct}</TableContentMain>
        </TableRow>
      </Link>
    </div>
  ));
  return (
    <>
      <PageLayout>
        <h2>All Businesses</h2>
        <TableContainer>
          <TableHeader>
            <TableContent>Agent Name</TableContent>
            <TableContent>Amount Processed</TableContent>
            <TableContent>Number of Services</TableContent>
            <TableContent>Amount Settled</TableContent>
            <TableContent>Number Of Subaccount</TableContent>
          </TableHeader>
          {businessList}
        </TableContainer>
      </PageLayout>
    </>
  );
}
