import styled from "styled-components";

type boxPropT = {
  bgColor: string;
};
export const GraphContainer = styled.div`
  width: 60%;
  padding: 1.8rem 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const GraphHeader = styled.div`
  display: flex;
  justify-content: center;
`;

export const GraphContent = styled.div`
  margin-top: 1.4rem;
  width: 60%;
  margin: auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ChartLabelContainer = styled.div`
  margin-top: 2rem;
  padding: 0.4rem 0.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  overflow-x: auto;
`;
export const ChartDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;
export const Box = styled.div<boxPropT>`
  width: 1rem;
  height: 1rem;
  border-radius: 50rem;
  background-color: ${(prop) => prop.bgColor && prop.bgColor};
`;
