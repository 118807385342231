export const ROLES = [
  {
    id: 1,
    name: "Super Admin",
    code: "SUPER_ADMIN",
    permissions: {
      canView: true,
      canInsert: true,
      canUpdate: true,
      canDelete: true,
      canDownload: true,
      canPrint: true,
    },
  },
  {
    id: 2,
    name: "Admin",
    code: "ADMIN",
    permissions: {
      canView: true,
      canInsert: true,
      canUpdate: true,
      canDelete: false,
      canDownload: true,
      canPrint: true,
    },
  },

  {
    id: 2,
    name: "Control and compliance",
    code: "CONTROL_COMPLIANCE",
    permissions: {
      canView: true,
      canInsert: true,
      canUpdate: true,
      canDelete: false,
      canDownload: true,
      canPrint: true,
    },
  },
  {
    id: 3,
    name: "User",
    code: "USER",
    permissions: {
      canView: true,
      canInsert: false,
      canUpdate: false,
      canDelete: false,
      canDownload: false,
      canPrint: false,
    },
  },

  {
    id: 4,
    name: "Customer support",
    code: "CUSTOMER_SUPPORT",
    permissions: {
      canView: true,
      canInsert: false,
      canUpdate: true,
      canDelete: false,
      canDownload: false,
      canPrint: true,
    },
  },

  {
    id: 4,
    name: "Technical support",
    code: "TECHNICAL_SUPPORT",
    permissions: {
      canView: true,
      canInsert: true,
      canUpdate: true,
      canDelete: false,
      canDownload: true,
      canPrint: true,
    },
  },
];

export const MENUS = [
  {
    id: 1,
    title: "Overview",
    icon: "home",
    path: "/dashboard/over-view",
    category: "Gateway",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE"],
  },
  {
    id: 2,
    title: "Businesses",
    path: "/business",
    icon: "board",
    category: "Gateway",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 21,
    title: "KYC",
    path: "/kyc",
    icon: "page",
    main: true,
    system: false,
    category: "Gateway",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 3,
    title: "Client business",
    path: "/business/overview/:id",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },
  {
    id: 4,
    title: "Services list",
    path: "business/overview/:id/services",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },
  {
    id: 5,
    title: "Service overview",
    path: "business/overview/:id/services/:id/detail",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },
  {
    id: 6,
    title: "Service transactions",
    path: "business/overview/:id/services/:id/transactions",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },
  {
    id: 7,
    title: "Service customers",
    path: "business/overview/:id/services/:id/customers",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 8,
    title: "Service accounts details",
    path: "business/overview/:id/services/:id/accounts",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },
  {
    id: 9,
    title: "service settlement report",
    path: "business/overview/:id/services/:id/settlement",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },
  {
    id: 10,
    title: "Service subaccount details",
    path: "business/overview/:id/services/:id/subaccounts",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 12,
    title: "Disputes",
    icon: "component",
    path: "/disputes",
    category: "Gateway",
    main: true,
    system: false,
    roles: [
      "SUPER_ADMIN",
      "ADMIN",
      "USER",
      "CUSTOMER_SUPPORT",
      "CONTROL_COMPLIANCE",
      "TECHNICAL_SUPPORT",
    ],
  },

  {
    id: 13,
    title: "Pricing",
    icon: "price-tags",
    path: "/pricing",
    category: "Gateway",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN"],
  },
  {
    id: 35,
    title: "Settlements",
    icon: "reports",
    path: "/settlements",
    category: "Gateway",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN"],
  },

  {
    id: 14,
    title: "Report Overview",
    icon: "reports",
    path: "/report",
    category: "Report",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN", "CONTROL_COMPLIANCE"],
  },

  // ==== role management tabs ======
  {
    id: 21,
    title: "Users",
    path: "/role-management/cusers",
    icon: "users",
    main: true,
    system: false,
    category: "Role Management",
    roles: ["SUPER_ADMIN", "USER", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },
  // {
  //   id: 21,
  //   title: "Users",
  //   path: "/role-management/users",
  //   icon: "users",
  //   main: true,
  //   system: false,
  //   category: "Role Management",
  //   roles: ["SUPER_ADMIN", "USER", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  // },

  {
    id: 21,
    title: "Users Access Group",
    path: "/role-management/users-access-group",
    icon: "stack",
    main: true,
    system: false,
    category: "Role Management",
    roles: ["SUPER_ADMIN", "USER", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 21,
    title: "Menu Manager",
    path: "/role-management/menu-manager/:id",
    icon: "settings",
    main: false,
    system: false,
    category: "Role Management",
    roles: ["SUPER_ADMIN", "USER", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  // =======================

  {
    id: 15,
    title: "System Monitor",
    icon: "bug",
    path: "/system-monitor",
    category: "System Monitor",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 21,
    title: "Reset request",
    icon: "user-plus",
    path: "/users/reset-request",
    category: "Gateway",
    main: true,
    system: true,
    roles: ["SUPER_ADMIN"],
  },
  {
    id: 22,
    title: "Invite users",
    icon: "user-plus",
    path: "/users/invite-users",
    category: "Gateway",
    main: true,
    system: true,
    roles: ["SUPER_ADMIN"],
  },

  {
    id: 23,
    title: "Users",
    icon: "users",
    path: "/users/list",
    category: "Gateway",
    main: true,
    system: true,
    roles: ["SUPER_ADMIN"],
  },

  //  {
  //    id: 24,
  //    title: "Change password",
  //    icon: "user-check",
  //    path: "/change-password",
  //    category: "Gateway",
  //    main: true,
  //    system: true,
  //    roles: ["SUPER_ADMIN", "ADMIN", "USER"],
  //  },

  // {
  //   id: 25,
  //   title: "System status",
  //   icon: "stack",
  //   path: "/system-status",
  //   category: "Gateway",
  //   system: true,
  //   main: true,
  //   roles: ["SUPER_ADMIN"],
  // },

  {
    id: 25,
    title: "Client Logs",
    icon: "page",
    path: "/system-status/client-logs",
    category: "System Status",
    system: false,
    main: true,
    roles: ["SUPER_ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },
  {
    id: 21,
    title: "Admin Logs",
    path: "/system-status/admin-logs",
    icon: "page",
    main: true,
    system: false,
    category: "System Status",
    roles: ["SUPER_ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 21,
    title: "Gateway reports",
    path: "/gateway/report",
    icon: "page",
    main: true,
    system: false,
    category: "Reports",
    roles: ["SUPER_ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 21,
    title: "Agency reports",
    path: "/agency/report",
    icon: "page",
    main: true,
    system: false,
    category: "Reports",
    roles: ["SUPER_ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 26,
    title: "Pricing",
    path: "pricing/overview/:id",
    roles: ["SUPER_ADMIN", "CONTROL_COMPLIANCE"],
  },

  // {
  //   id: 26.1,
  //   title: "Report",
  //   icon: "stack",
  //   path: "/gateway/report",
  //   category: "Gateway",
  //   roles: ["SUPER_ADMIN", "ADMIN", "USER"],
  //   system: false,
  //   main: true,
  // },

  {
    id: 27,
    title: "Finders",
    path: "/finders",
    icon: "component",
    main: true,
    system: true,
    category: "Gateway",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 27,
    title: "Settings",
    path: "/settings",
    icon: "settings",
    main: true,
    system: true,
    category: "Gateway",
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE", "TECHNICAL_SUPPORT"],
  },

  {
    id: 28,
    title: "By Transaction",
    icon: "board",
    path: "/report",
    category: "Report",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE"],
  },

  {
    id: 29,
    title: "By Business",
    icon: "stack",
    path: "/report",
    category: "Report",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE"],
  },

  {
    id: 30,
    title: "By Settlememnts",
    icon: "component",
    path: "/report",
    category: "Report",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE"],
  },

  {
    id: 31,
    title: "By Spliting",
    icon: "issues",
    path: "/report",
    category: "Report",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE"],
  },

  {
    id: 32,
    title: "By Kyc",
    icon: "page",
    path: "/report",
    category: "Report",
    main: true,
    system: false,
    roles: ["SUPER_ADMIN", "ADMIN", "CONTROL_COMPLIANCE"],
  },

  {
    id: 32,
    title: "Overview",
    path: "/agencybanking",
    icon: "page",
    main: true,
    system: false,
    category: "Agency",
    roles: [
      "SUPER_ADMIN",
      "ADMIN",
      "USER",
      "CUSTOMER_SUPPORT",
      "CONTROL_COMPLIANCE",
    ],
  },

  {
    id: 33,
    title: "KYC",
    path: "/agencybanking/kyc",
    icon: "users",
    main: true,
    system: false,
    category: "Agency",
    roles: [
      "SUPER_ADMIN",
      "ADMIN",
      "USER",
      "CUSTOMER_SUPPORT",
      "CONTROL_COMPLIANCE",
    ],
  },
  // {
  //   id: 36,
  //   title: "Report",
  //   icon: "stack",
  //   path: "/agency/report",
  //   category: "Agency",
  //   roles: ["SUPER_ADMIN", "ADMIN", "USER"],
  //   system: false,
  //   main: true,
  // },
  {
    id: 33,
    // title: "Agents",
    title: "Business",
    path: "/agencybanking/business",
    icon: "users",
    main: true,
    system: false,
    category: "Agency",
    roles: [
      "SUPER_ADMIN",
      "ADMIN",
      "USER",
      "CUSTOMER_SUPPORT",
      "CONTROL_COMPLIANCE",
    ],
  },

  {
    id: 33,
    title: "Transactions",
    path: "/agencybanking/financial",
    icon: "reports",
    main: true,
    system: false,
    category: "Agency",
    roles: [
      "SUPER_ADMIN",
      "ADMIN",
      "USER",
      "CUSTOMER_SUPPORT",
      "CONTROL_COMPLIANCE",
    ],
  },

  {
    id: 34,
    title: "Inventory",
    path: "/inventory",
    icon: "shipping",
    main: true,
    system: false,
    category: "Agency",
    roles: [
      "SUPER_ADMIN",
      "ADMIN",
      "USER",
      "CUSTOMER_SUPPORT",
      "CONTROL_COMPLIANCE",
    ],
  },
  {
    id: 35,
    title: "Geolocation",
    path: "/geolocation",
    icon: "location",
    main: true,
    system: false,
    category: "Agency",
    roles: [
      "SUPER_ADMIN",
      "ADMIN",
      "USER",
      "CUSTOMER_SUPPORT",
      "CONTROL_COMPLIANCE",
    ],
  },
];

export const USERS = [
  {
    id: 1,
    userName: "John Oke",
    email: "john@fountainpay.ng",
    phoneNumber: "+234234567890",
    password: "fountainpay",
    activated: false,
    role: "SUPER_ADMIN",
  },
  {
    id: 2,
    userName: "Adedeji Richards",
    email: "adedeji@fountainpay.ng",
    phoneNumber: "+2341234567890",
    password: "fountainpay",
    activated: false,
    role: "ADMIN",
  },
  {
    id: 3,
    userName: "Eto Olabode",
    email: "eto@fountainpay.ng",
    phoneNumber: "+2341234567890",
    password: "fountainpay",
    activated: false,
    role: "USER",
  },
];

export const RESETREQUEST = [
  {
    id: 1,
    userName: "Adesare Adegbagi",
    email: "tunmisehassan@gmail.com",
    phoneNumber: "+234234567890",
    activated: false,
  },
  {
    id: 2,
    userName: "Kunle Dende",
    email: "kunle@google.com",
    phoneNumber: "+2341234567890",
    activated: false,
  },
  {
    id: 3,
    userName: "Adesare Adegbagi",
    email: "tunmisehassan@gmail.com",
    phoneNumber: "+234234567890",
    activated: false,
  },
  {
    id: 4,
    userName: "Kunle Dende",
    email: "kunle@google.com",
    phoneNumber: "+2341234567890",
    activated: false,
  },
];

export const CLIENTLOGS = [
  {
    id: 1,
    activityLog: "Payment",
    description:
      "Sends money the sum of N200,000 to tiamiyu for the goods i purchased from himSends money the sum of N200,000 to tiamiyu for the goods i purchased from him",
    merchant: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 2,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    merchant: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 3,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    merchant: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 4,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    merchant: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 5,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    merchant: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 6,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    merchant: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 7,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    merchant: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
];
