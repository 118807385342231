export const Data = [
  {
    id: 1,
    month: "Jan",
    transaction: 800,
    userLost: 800,
  },
  {
    id: 2,
    month: "Feb",
    transaction: 4200,
    userLost: 240,
  },
  {
    id: 3,
    month: "Mar",
    transaction: 100,
    userLost: 555,
  },
  {
    id: 4,
    month: "Apr",
    transaction: 2000,
    userLost: 4555,
  },
  {
    id: 5,
    month: "May",
    transaction: 1400,
    userLost: 234,
  },
  {
    id: 6,
    month: "Jun",
    transaction: 2000,
    userLost: 234,
  },
  {
    id: 7,
    month: "Jul",
    transaction: 10000,
    userLost: 234,
  },
];
