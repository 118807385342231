import React, { useState, useLayoutEffect, useEffect } from "react";
import EmptyState from "../../shared/components/EmptyState";
import { useHistory, useParams } from "react-router-dom";
import Table from "shared/clientcomponents/Table";

import api from "shared/utils/api";
import useApi from "shared/hooks/api";
import logosample from "App/assets/images/amazon.png";
import {
  AllBusinessTableContainer,
  PageLayout,
  PaginationButtonsContainer,
  PaginationContainer,
  PaginationNext,
  PaginationPrev,
} from "./Styles";
import { GradientLoader } from "shared/components";
import { businessTableHeader } from "../businessTableHeader";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { numberFormat } from "shared/utils/utility";
import businessOBJ from "Business/businessClass";
import Layout from "Layout";

type PropT = {
  [x: string]: any;
};

export default function AllBusiness() {
  const [content, setcontent] = useState<any>([]);
  const [isloading, setIsloading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");
  const [numberOfItems, setnumberOfItems] = useState<any>("");
  const [data, setdata] = useState([]);
  const [searchContent, setSearchContent] = useState<string | undefined>();

  const [noRecord, setNoRecord] = useState(true);
  const history = useHistory();
  const { location } = useHistory();
  const bizDetails: any = location?.state;
  const totalBusinesses = bizDetails && bizDetails["totalBusinesses"];

  // const [{ data, error, setLocalData }, fetchData] = useApi.get(
  //   `/business?page=${currentPage}`
  // );
  const getBusinesses = async () => {
    const response = await businessOBJ.getAllBusiness(currentPage);
    if (response) {
      setdata(response?.data);
      settotalPages(parseInt(response.totalPages));
      setcurrentPage(parseInt(response?.currentPage));
      setnumberOfItems(parseInt(response?.numberOfItems));
      setcontent(response?.data);
      setNoRecord(false);
      setIsloading(false);
    }
  };

  const handleFilter = (searchData: any) => {
    setSearchContent(searchData);
    const newlist = content.filter((business: any) => {
      if (business.businessId.indexOf(searchData) > -1) return business;
    });
    if (newlist.length) setcontent(newlist);
    if (!searchData) setcontent(data); //reset content
  };

  const hoverLink = (elm: PropT) => {
    history.push(`/business/overview/${elm?.busId}`);
  };

  const businessData = content?.map((el: any, index: number) => {
    return {
      index: index + 1,
      busId: el?.id,
      businessID: el?.businessId,
      businessName: el?.businessName,
      amountSettled:
        el?.amountSettled === 0 ? "₦0.00" : numberFormat(el?.amountSettled),
      totalServices: el?.services?.length,
      totalTransactions: el?.numOfTransactions,
      logo: logosample && <img src={logosample} />,
    };
  });

  // const nextPage = () => {
  //   if (currentPage < totalPages) {
  //     setcurrentPage(currentPage + 1);
  //   }
  // };

  // const prevPage = () => {
  //   if (currentPage > 1) {
  //     setcurrentPage(currentPage - 1);
  //   }
  // };

  useEffect(() => {
    getBusinesses();
    setIsloading(true);
  }, [currentPage]);

  return (
    <>
      <Layout>
        <PageLayout>
          <h2>All Businesses</h2>
          <br />
          <br />
          {isloading ? (
            <GradientLoader />
          ) : (
            <>
              <AllBusinessTableContainer>
                <DashboardSubHeader
                  // count={totalBusinesses ? totalBusinesses : 0}
                  title={"Businesses"}
                  btnTitle=""
                  withSearch={true}
                  placeholder="Business ID"
                  withFilter={false}
                  widthActionBtn={false}
                  filterOptions={["Name", "Date"]}
                  setSearchData={handleFilter}
                  callBackFn={() => ""}
                  searchContent={searchContent}
                />

                {content && (
                  <Table
                    headData={businessTableHeader}
                    bodyData={businessData}
                    rowStyle={{ background: "#ffffff" }}
                    totalPages={totalPages}
                    currentpage={currentPage}
                    goToPage={(value: any) => {
                      setcurrentPage(value);
                      // getKYCList(value);
                    }}
                    hoverLink={hoverLink}
                  />
                )}
              </AllBusinessTableContainer>
            </>
          )}
        </PageLayout>
      </Layout>
    </>
  );
}
