import { useState } from "react";
import SharedCheckList from "shared/section_components/SharedCheckList";
import Icon from "../Icon";
import {
  FilterActionArea,
  FilterBox,
  FIlterBoxContent,
  FilterBoxHeader,
  FilterButton,
  FilterButtonContainer,
  FilterCheckBox,
  FilterContainer,
  FilterDateContainer,
  FilterTitle,
  SubActions,
} from "./Styles";

import { ActionButton } from "../DashboardSubHeader/Styles";
import Calenda from "../Calenda";
import { exportCSVFile } from "shared/utils/utility";

type PropT = {
  title?: String;
  options: any;
  itemRef?: any;
  tableName?: HTMLElement;
  downloadable?: any;
  onChange?: Function;
};

const Filter = ({ title, options, itemRef, downloadable, onChange }: PropT) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState<any>([]);
  const [selectDate, setSelectDate] = useState<string>("");

  const handleCheck = (name: String) => {
    let newData = options.map((option: any) => {
      if (option.name == name) {
        option.checked = !option.checked;
      }
      return option;
    });
    setFilter(newData);
  };

  const handleSelect = () => {
    let selectActions = filter.filter((item: any) => item.checked);

    onChange!({ date: selectDate, actions: selectActions });
  };

  const generateExcel = async () => {
    let itemsFormatted: any = [],
      headers: any = {};

    downloadable.headers.forEach((item: any, index: number) => {
      if (item.id !== "funcProps") {
        headers[item.id] = item.label.toString().replace(/,/g, "");
      }
    });

    let keys = Object.keys(headers);

    Promise.all([
      downloadable.content.forEach(async (item: any, index: number) => {
        const getCommonKeys = (a1: any, a2: any) => {
          return a1.filter(function(n: any) {
            return a2.indexOf(n) !== -1;
          });
        };
        var commonKeys = await getCommonKeys(Object.keys(item), keys);

        if (commonKeys) {
          let rowItem: any = {};
          commonKeys.forEach((key: string) => {
            rowItem[key] = item[key].toString().replace(/,/g, "");
          });

          itemsFormatted.push(rowItem);
        }
      }),
    ]).then((response) => {
      exportCSVFile(headers, itemsFormatted, downloadable.title);
    });
  };

  const printDoc = async () => {
    console.log("items to print", itemRef);
    var win = window.open(
      "",
      "Popup",
      "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
    )!;

    let stylesHtml = "";
    const elements: Element[] = Array.from(
      document.querySelectorAll('link[rel="stylesheet"], style')
    );
    for (const node of elements) {
      stylesHtml += node.outerHTML;
    }

    const prtHtml = itemRef?.innerHTML;

    var contents = `<!DOCTYPE html>
                  <html>
                  <head>
                      ${stylesHtml}
                  </head>
                  <body>
                      <h3>${title}</h3>
                      ${prtHtml}
                  </body>
                  </html>`;
    win.document.body.innerHTML = contents;
    win.print();
  };

  return (
    <FilterContainer className="no-print">
      {title && <FilterTitle>{title}</FilterTitle>}
      <FilterButtonContainer>
        <FilterButton onClick={() => setShowFilter(!showFilter)}>
          <div>
            <Icon type="equalizer" /> Filter
          </div>
          <Icon type="chevron-down" />
        </FilterButton>
        {showFilter && (
          <FilterBox>
            <FilterBoxHeader>Filter By</FilterBoxHeader>
            <FIlterBoxContent>
              <FilterDateContainer>
                <Calenda onSelect={setSelectDate} />
              </FilterDateContainer>
              {options?.map((option: any, i: any) => (
                <FilterCheckBox key={option.label}>
                  <SharedCheckList
                    onChange={() => handleCheck(option.name)}
                    id={i + 1}
                    checked={option.checked}
                    label={option.label}
                  />
                </FilterCheckBox>
              ))}
              <FilterDateContainer>
                <FilterBoxHeader></FilterBoxHeader>
                <FilterActionArea>
                  <ActionButton onClick={handleSelect}>
                    <Icon type="search" size={22} />
                  </ActionButton>
                  <SubActions>
                    <ActionButton onClick={generateExcel}>
                      <Icon type="file-text2" size={22} />
                    </ActionButton>

                    <ActionButton onClick={printDoc}>
                      <Icon type="printer" size={22} />
                    </ActionButton>
                  </SubActions>
                </FilterActionArea>
              </FilterDateContainer>
            </FIlterBoxContent>
          </FilterBox>
        )}
      </FilterButtonContainer>
    </FilterContainer>
  );
};

export default Filter;
