import { useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import AgencyBanking from "AgencyBanking";
import Authenticate from "Auth/Authenticate";
import history from "browserHistory";
import Dashboard from "Dashboard";
import Preloader from "preloader";
import Settings from "Settings";
import Business from "Business";
import PageError from "shared/components/PageError";
import Users from "Users";
import { ProtectedRoute } from "guards/ProtectedRoute";
import { AdminGuard } from "guards/admin-guard";
import { SuperAdminGuard } from "guards/super-admin-guard";
import { UserGuard } from "guards/user-guard";
import AuditTrail from "../AuditTrail/index";
import CreatePassword from "CreatePassword";
import LogDetail from "AuditTrail/LogDetail";
import Dispute from "Dispute";
import ChangePassword from "ChangePassword";
import Pricing from "Pricing";
import Kyc from "Kyc";
import Profile from "../Profile";
import Report from "Report";
import ReportDetails from "Report/details";
import Settlements from "Settlements";
import Bugs from "Bugs";
import ReceiveOtp from "Auth/pages/ReceiveOtp";
import GenerateComponent from "Auth/pages/Generate";
import AgencyReport from "AgencyBanking/Report";
import Finders from "Finders/Finders";
import RoleManagement from "RoleManagement/Index";
import CBNReport from "Report/cbn/cbn-report";
import TransactionDetails from "Transactions/TransactionDetails";

const Routes = () => {
  const [isLoading, setisLoading] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(false);

  function urlChecker(stateSetter: Function, state: boolean) {}

  //============Checking changes in url ==================
  let lastUrl = location.href;
  new MutationObserver(() => {
    const url = location.href;
    if (url !== lastUrl) {
      lastUrl = url;
      onUrlChange();
    }
  }).observe(document, { subtree: true, childList: true });

  function onUrlChange() {
    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);
    }, 400);
  }
  //=====================================================

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <Router history={history}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route path="/autheticate" component={Authenticate} />
            <Route path="/authorize/2fa" component={ReceiveOtp} />
            <Route path="/create-password/:id" component={CreatePassword} />
            <Route exact path="/generate" component={GenerateComponent} />
            <ProtectedRoute
              path="/dashboard"
              guards={[SuperAdminGuard, AdminGuard, UserGuard]}
              component={Dashboard}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/business"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={Business}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/disputes"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={Dispute}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/agencybanking"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={AgencyBanking}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/settings"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={Settings}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/users"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={Users}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/pricing"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={Pricing}
              fallback={() => <Redirect to="/autheticate/login" />}
            />

            <ProtectedRoute
              path="/change-password"
              guards={[]}
              component={ChangePassword}
              fallback={() => <Redirect to="/autheticate/login" />}
            />

            {/* <ProtectedRoute
              path="/change-password"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={ChangePassword}
              fallback={() => <Redirect to="/autheticate/login" />}
            /> */}
            <ProtectedRoute
              path="/system-status"
              guards={[SuperAdminGuard, UserGuard]}
              component={AuditTrail}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/system-logs/details/:id"
              guards={[SuperAdminGuard]}
              component={LogDetail}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/kyc"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={Kyc}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/profile"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={Profile}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/finders"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={Finders}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/role-management"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={RoleManagement}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/settlements"
              guards={[AdminGuard, SuperAdminGuard]}
              component={Settlements}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            {/* <ProtectedRoute
              path="/gateway/report/:id"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={ReportDetails}
              fallback={() => <Redirect to="/autheticate/login" />}
            /> */}
            <ProtectedRoute
              path="/gateway/report"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={Report}
              fallback={() => <Redirect to="/autheticate/login" />}
            />

            <ProtectedRoute
              path="/agency/report/:id"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={ReportDetails}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <ProtectedRoute
              path="/agency/report"
              guards={[AdminGuard, SuperAdminGuard, UserGuard]}
              component={AgencyReport}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            {/* <ProtectedRoute
              path="/reports"
              guards={[AdminGuard, SuperAdminGuard]}
              component={Report}
              fallback={() => <Redirect to="/autheticate/login" />}
            /> */}
            <ProtectedRoute
              path="/system-monitor"
              guards={[SuperAdminGuard, AdminGuard, UserGuard]}
              component={Bugs}
              fallback={() => <Redirect to="/autheticate/login" />}
            />
            <Route component={PageError} />
          </Switch>
        </Router>
      )}
    </>
  );
};

export default Routes;
