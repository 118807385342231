import styled from "styled-components";

type navbarIconPropT = {
  isClicked: boolean;
};

import breakpoints from "shared/utils/breakpoints";
import { color, font, mixin, sizes, zIndexValues } from "shared/utils/styles";
type PropT = {
  to?: boolean;
  margin?: number;
};

export const ConsoleNameCont = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  h3 {
    font-weight: 600;
    font-size: 1.2rem;
  }
`;

export const SidebarContainer = styled.div<navbarIconPropT>`
  //mobile styles here
  /* display: block; */
  /* width: ${sizes.secondarySideBarWidth - 10}px; */
  position: fixed;
  z-index: ${zIndexValues.navLeft - 1};
  top: 0;
  /* right:${(prop) => (prop.isClicked ? "0" : "-100rem")} ; */
	left: ${sizes.appNavBarLeftWidth}px;
  height: 100vh;
  /* width: 50vw; */
	width: ${sizes.secondarySideBarWidth}px;
  padding: 0 16px 24px;
  background: ${color.backgroundLightWhite};
  border-right: 1px solid ${color.borderLightest};
  ${mixin.scrollableY}
  ${mixin.customScrollbar()}
  /* transition: all ease-in-out .4s; */

  /* @media only screen and (min-width: 768px) {
    position: fixed;
    z-index: ${zIndexValues.navLeft - 1};
    top: 0;
    left: ${sizes.appNavBarLeftWidth}px;
    height: 100vh;
    width: ${sizes.secondarySideBarWidth}px;
    padding: 0 16px 24px;
    background: ${color.backgroundLightWhite};
    border-right: 1px solid ${color.borderLightest};
    ${mixin.scrollableY}
    ${mixin.customScrollbar()}
    display: block;
  } */

  /* @media (max-width: 1100px) {
    width: ${sizes.secondarySideBarWidth - 10}px;
  } */
	@media (max-width: 1100px) {
    width: ${sizes.secondarySideBarWidth - 10}px;
  }
  @media ${breakpoints.device.max.sm} {
    position: absolute;
  }
`;

export const Item = styled.div`
  position: relative;
  height: 42px;
  line-height: 42px;
  // padding-left: 64px;
  color: #002;
  transition: color 0.1s;
  ${mixin.clickable}
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  i {
    position: absolute;
    left: 18px;
  }
`;
export const BusinessInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 4px;
`;

export const BusinessTexts = styled.div`
  padding: 20px 0 0 0px;
`;

export const BusinessName = styled.div`
  color: ${color.textDark};
  ${font.size(16)};
  ${font.medium};
`;

export const BusinessCategory = styled.div`
  color: ${color.textMedium};
  ${font.size(13)};
`;

export const Divider = styled.div`
  margin-top: 17px;
  padding-top: 18px;
  border-top: 1px solid ${color.borderLight};
`;

export const Label = styled.div`
  width: 100%;
  font-weight: bold;
  padding-left: 10px;
`;

export const TitleLabel = styled.div<PropT>`
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  margin-left: 12px;
  margin-bottom: 10px;
  margin-top: ${(props) => props.margin}px;
  color: #828282;
`;
export const BusinessList = styled.div`
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
`;

export const LinkItem = styled.div<PropT>`
  position: relative;
  display: flex;
  padding: 8px 12px;
  border-radius: 3px;
  ${mixin.clickable}
  ${(props) =>
    !props.to
      ? `cursor: not-allowed;`
      : `&:hover { background: ${color.backgroundLight}; }`}
  i {
    margin-right: 15px;
    font-size: 20px;
  }
  &.active {
    // color: ${color.fpPrimary};
    color: #760000;
    // background: ${color.backgroundLight};
    i {
      color: ${color.fpPrimary};
    }
  }
`;

export const LinkItemm = styled.div<PropT>`
  position: relative;
  display: flex;
  justify-content: start;

  gap: 0.4rem;
  padding: 8px 12px;
  border-radius: 3px;
  ${mixin.clickable}
  &.active {
    color: ${color.fpPrimary};
    background: ${color.backgroundLight};
    i {
      color: ${color.fpPrimary};
    }
  }
`;

export const ProjectLinkItem = styled.div<PropT>`
  width: 100%;
  overflow-y: auto ${mixin.clickable}
    ${(props) =>
      !props.to
        ? `cursor: not-allowed;`
        : `&:hover { background: ${color.backgroundLight}; }`}
    i {
    font-size: 20px;
  }
  &.active {
    color: ${color.primary};
    i {
      color: ${color.primary};
    }
  }
`;

export const LinkText = styled.div`
  padding-top: 2px;
  ${font.size(14.7)};
`;

export const NotImplemented = styled.div`
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 40px;
  width: 140px;
  padding: 5px 0 5px 8px;
  border-radius: 3px;
  text-transform: uppercase;
  color: ${color.textDark};
  background: ${color.backgroundMedium};
  opacity: 0;
  ${font.size(11.5)};
  ${font.bold}
  ${LinkItem}:hover & {
    opacity: 1;
  }
`;

export const Paymentlabel = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #828282;
  margin-bottom: 13px;
`;
