import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color } from "../../../../shared/utils/styles";

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  @media ${breakpoints.device.max.md} {
    flex-direction: column;
   }
`;
export const TableContainer = styled.div`
  width: 64%;
  background-color: #fff;
  padding: 0rem 0.4rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
  /* height: 28rem; */
  /* margin-bottom: 2rem; */
  @media ${breakpoints.device.max.md} {
    width: 100%;  
  }
`;

export const Container = styled.div`
  margin-bottom: "4rem";
  height: 20rem;
  overflow-y: scroll;
  margin-bottom: 2rem;
  &::-webkit-scrollbar {
    background-color: white;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${color.fountain};
    max-height: 20px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const PiechartContainer = styled.div`
  width: 34%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media ${breakpoints.device.max.md} {
    width: 100%; 
    margin-top: 50px; 
  }
`;
export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
  /* background-color: #fff; */
`;
export const ViewAllBtn = styled.button`
  padding: 1rem 4rem;
  color: white;
  background-color: ${color.fountain};
  border-radius: 0.4rem;

  &:hover {
    cursor: pointer;
  }
`;
