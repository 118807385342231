import styled from "styled-components";

type PropT = {
  isCloseClicked: boolean;
};
export const Overlay = styled.div<PropT>`
  width: 100vw;
  height: 100vh;
  background: rgba(9, 30, 66, 0.54);
  /* background: black; */
  position: fixed;
  overflow: scroll;
  left: 0rem;
  top: 0;
  /* position: absolute; */
  z-index: 200000;
  display: ${(prop) => (prop.isCloseClicked ? "block" : "none")};
`;

export const OverlayContentCont = styled.div`
  width: 80%;
  margin: 2rem auto;
  min-height: 40vh;
`;

export const CloseCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const CloseButton = styled.button`
  width: 22px;
  height: 22px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40rem;
  cursor: pointer;
  margin-right: -1rem;
`;

export const OverlayContent = styled.div`
  width: 100%;
  margin: 1rem auto;
  min-height: 40vh;
  background: #fff;
  border-radius: 0.5rem;
  padding: 2rem;
`;
