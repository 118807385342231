import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import settlementOBJ from "Settlements/settlementClass";
import Table from "shared/clientcomponents/Table";
import { GradientLoader } from "shared/components";
import EmptyState from "shared/components/EmptyState";
import { numberFormat, numFormatter } from "shared/utils/utility";
import { PageTitle, TableCont } from "./Style";
import { getStatusColor } from "shared/utils/statusColor";

export default function Single() {
  const history = useHistory();
  const { id: Id } = useParams<{
    id: string;
  }>();

  const [transactions, settransactions] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [isRecord, setisRecord] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");

  const TableHeader = [
    { id: "sn", label: "S/N", width: "2%" },
    { id: "amount", label: "Amount" },
    { id: "bankName", label: "Bank" },
    { id: "accountNumber", label: "Account No" },
    { id: "bankAccountName", label: "Account Name" },
    { id: "name", label: "Account Tag" },
    { id: "type", label: "Account Type" },
    { id: "currency", label: "Currency" },
    { id: "status", label: "Status" },
    { id: "time", label: "Date/Time" },
  ];

  const formatStatusColor=(status: string)=>{
    return(
      <>
        {status && (status.toLowerCase().includes("init") ? (
        <div style={{ color: getStatusColor("terminated") }}>
          terminated
        </div>
      ) : (
        <div style={{ color: getStatusColor(status) }}>
          {status?.toLowerCase()[0].toUpperCase() + status?.toLowerCase().substring(1)}
        </div>
      ))}
      </>
    )
            
  }
  
  const transactionList = transactions.map((obj: any, index: number) => ({
    Id: obj?.id,
    sn: ++index,
    amount: `${numberFormat(obj?.amount)}`,
    bankName: obj?.BankAccount?.bank?.name,
    accountNumber: obj?.BankAccount?.accountNumber,
    bankAccountName: obj?.BankAccount?.bankAccountName,
    name: obj?.BankAccount?.name,
    type: obj?.BankAccount?.type,
    currency: obj?.BankAccount?.currency,
    status: formatStatusColor(obj?.status),
    time: obj?.createdAt,
  }));

  const getTransactions = async () => {
    const response = await settlementOBJ.getAccountDetails(Id, currentPage);
    if (response?.status && response?.payload?.data.length) {
      // console.log("transactions ====>", response?.payload);
      setcurrentPage(response?.currentPage);
      setisRecord(true);
      setisloading(false);
      settransactions(response?.payload?.data);
    } else {
      setisRecord(false);
      setisloading(false);
    }
  };

  useLayoutEffect(() => {
    if (!Id) {
      history.push("/settlements/overview");
    }
  }, [Id]);

  useEffect(() => {
    console.log("Getting transactions");
    getTransactions();
  }, []);

  return (
    <div>
      <PageTitle>Settlement account details</PageTitle>
      <TableCont>
        {isloading ? (
          <GradientLoader />
        ) : (
          <>
            {isRecord ? (
              <Table
                headData={TableHeader}
                bodyData={transactionList}
                rowStyle={{ background: "#ffffff" }}
                totalPages={totalPages}
                currentpage={currentPage}
                goToPage={(value: any) => {
                  setcurrentPage(value);
                }}
              />
            ) : (
              <EmptyState
                stateTitle="No Transaction record"
                statePara=""
                stateBtnText=""
              />
            )}
          </>
        )}
      </TableCont>
    </div>
  );
}
