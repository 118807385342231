import React, { useState, useEffect, useLayoutEffect } from "react";
import { toast } from "react-toastify";
import ConfirmModal from "../../../shared/quickComponents/ConfirmModal";
import OtpModal from "../../../shared/quickComponents/OtpModal";
import { getCurrentUser } from "shared/utils/authToken";
import HandleLog from "shared/utils/handleLog";
import {
  ApprovedKycBtn,
  BoxHeader,
  BtnsContainer,
  Container,
  DeactivateKycBtn,
  DetailsContainer,
  FileFormContainer,
  FormFileGroup,
  InfoContainer,
  InfoGroup,
  InputField,
  PageTitle,
  RemarkInput,
  Ribbon,
  StatusButton,
  SubmitButton,
  UserAvatarContainer,
} from "Kyc/Styles";
import Switch from "shared/components/Switch";
import FileSection from "./FileSelection";
// import data from "shared/utils/data";
import { useParams, useLocation, useHistory } from "react-router-dom";
import StatusBadge from "shared/components/StatusBadge";
import useApi from "shared/hooks/api";
import { GradientLoader, Spinner } from "shared/components";
import Modal from "shared/quickComponents/modal";
import { getClientDetails } from "shared/utils/location";
import logger from "shared/utils/logger";
import { useEffectOnce } from "shared/hooks/useEffectOnce";
import kycOBJ from "Kyc/kycClass";

export default function index() {
  const location: any = useLocation();
  const [id, setid] = useState(location?.state?.data);
  const [businessData, setbusinessData] = useState<any>({});
  const [businessName, setbusinessName] = useState("");

  const [veriStatus, setVeriStatus] = useState("");

  const [btnAction, setbtnAction] = useState("");
  const [showIndicator, setshowIndicator] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [kycStatus, setkycStatus] = useState(false);
  const [isOtpModalOpen, setOtpModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [remark, setremark] = useState("");

  const [isModalOpen, setisModalOpen] = useState(false);

  const [otp, setOtp] = useState("");
  const [tempId, settempId] = useState(""); //OTP response temp id

  const currentUser = getCurrentUser();
  const [{ data, error, setLocalData }, fetchData] = useApi.get(`/kyc/${id}`);

  const history = useHistory();
  // let { id }: any = useParams();

  // if (!id) return null;
  // let foundKyc = data.find((el: any) => el.id === id);
  let foundKyc = businessData;
  // if (!foundKyc) return null;

  const handleApprove = async () => {
    setshowIndicator(true);
    const adminEmail = currentUser.email;
    const response = await kycOBJ.sendOTP({
      adminEmail,
      businessKycId: id,
    });
    if (response.status) {
      settempId(response?.payload?.tempId);
      toast.success(response?.message);
      setshowIndicator(false);
      setConfirmModalOpen(!isConfirmModalOpen);
      setOtpModalOpen(true);
    } else {
      toast.error(`${response.message}`);
      setshowIndicator(false);
      setConfirmModalOpen(!isConfirmModalOpen);
    }
  };

  const handleOTP = async (otp: string) => {
    setOtp(otp);
    if (otp.length === 6) {
      const response = await kycOBJ.confirmOTP({
        otp,
        action: "APPROVE_BUSINESS_KYC",
        tempId,
      });
      if (response.status) {
        toast.success(`${response.message}`);
        setshowIndicator(false);
        setOtpModalOpen(false);
        fetchData();
      } else {
        toast.error(`${response.message}`);
      }
    }
  };

  const handleDeactivate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (remark) {
      setshowIndicator(true);
      const response = await kycOBJ.rejectKYC({ remark }, id);
      if (response.status) {
        setshowIndicator(false);
        setshowIndicator(false);
        setisModalOpen(false);
        setremark("");
        toast.success(response.message);
        fetchData();
      } else {
        toast.error(response.message);
        setshowIndicator(false);
        setshowIndicator(false);
        setisModalOpen(false);
      }
    }
  };

  const statusBtnChecker = (status: string) => {
    if (status === "APPROVED") {
      return (
        <DeactivateKycBtn
          onClick={() => {
            setConfirmModalOpen(true);
            setbtnAction("Deactivate"); //tracking which btn was clicked
          }}
        >
          {/* Deactivate */}
          Reject
        </DeactivateKycBtn>
      );
    }

    if (status === "PENDING" || veriStatus === "REJECTED") {
      return (
        <>
          <ApprovedKycBtn
            onClick={() => {
              setConfirmModalOpen(true);
              setbtnAction("Approve"); //tracking which btn was clicked
            }}
          >
            Approve
          </ApprovedKycBtn>

          <DeactivateKycBtn
            onClick={() => {
              setConfirmModalOpen(true);
              setbtnAction("Deactivate"); //tracking which btn was clicked
            }}
          >
            {/* Deactivate */}
            Reject
          </DeactivateKycBtn>
        </>
      );
    }
  };

  const handleLog = async () => {
    console.log("logging on Kyc single on bix", businessName);
    await logger.logActivities("remote", {
      date: new Date().toString(),
      author: currentUser.fullName,
      resource: "admin",
      action: "MERCHANT",
      payload: {
        userAgent: getClientDetails(),
        data: {
          detail: `${currentUser.fullName} access the ${businessName}  "Kyc"`,
        },
      },
    });
  };

  useLayoutEffect(() => {
    if (!location.state) {
      history.push("agencybanking/kyc/home");
    }
    // console.log("page data", location.state);
  }, []);

  useLayoutEffect(() => {
    if (data) {
      setisLoading(false);
      setbusinessData(data?.payload);
      setVeriStatus(data?.payload?.verificationStatus);
      setbusinessName(data?.payload?.businessMeta?.rcBusinessName);
      // console.log("single kyc data ====>", data);
    }
    console.log("error", error);
  }, [data, error]);

  console.log('fetched data ', data)

  useEffect(() => {
    handleLog();
    // console.log("single kyc name", businessName);
  }, []);

  return (
    <>
      {isLoading ? (
        <GradientLoader />
      ) : (
        <Container>
          <BtnsContainer>{statusBtnChecker(veriStatus)}</BtnsContainer>
          <DetailsContainer>
            <InfoContainer>
              <h3>Business Kyc</h3>
              <InfoGroup>
                <p>Industry:</p>
                <p>{foundKyc?.Business?.category?.name ?? "Not Provided"}</p>
              </InfoGroup>
              <InfoGroup>
                <p>Business Name:</p>
                <p>
                  {foundKyc?.businessMeta?.rcBusinessName ?? "Not Provided"}
                </p>
              </InfoGroup>
              <InfoGroup>
                <p>Business Address:</p>
                <p>{foundKyc?.businessMeta?.address ?? "Not Provided"}</p>
              </InfoGroup>
              <InfoGroup>
                <p>CAC NO:</p>
                <p>
                  {foundKyc?.Business?.registrationType}{" "}
                  {foundKyc?.Business?.registrationNumber}
                  {!foundKyc?.Business?.registrationType &&
                    !foundKyc?.Business?.registrationType &&
                    "Not Provided"}
                </p>
              </InfoGroup>
              <InfoGroup>
                <p>State</p>
                <p>{foundKyc?.businessMeta?.state ?? "Not Provided"}</p>
              </InfoGroup>
              <InfoGroup>
                <p>City</p>
                <p>{foundKyc?.businessMeta?.city ?? "Not Provided"}</p>
              </InfoGroup>
              <InfoGroup>
                <p>LGA</p>
                <p>{foundKyc?.businessMeta?.lga ?? "Not Provided"}</p>
              </InfoGroup>
              <InfoGroup>
                <p>Status</p>
                <p>
                  <StatusBadge
                    //Component accepts 'ACTIVE or PENDING as value for mode prop
                    mode={veriStatus}
                    title={veriStatus}
                  />
                </p>
              </InfoGroup>
              <Ribbon mode={veriStatus}></Ribbon>
            </InfoContainer>

            <InfoContainer>
              <BoxHeader>
                <h3>Personal Kyc</h3>
                <UserAvatarContainer></UserAvatarContainer>
              </BoxHeader>
              <InfoGroup>
                <p>Merchant Name:</p>
                <p>
                  {foundKyc?.User?.firstName} {foundKyc?.User?.lastName}
                </p>
              </InfoGroup>
              <InfoGroup>
                <p>Mobile Number:</p>
                <p>{foundKyc?.User?.phone ?? "Not Provided"}</p>
              </InfoGroup>
              <InfoGroup>
                <p>Email:</p>
                <p>{foundKyc?.User?.email ?? "Not Provided"}</p>
              </InfoGroup>
              <InfoGroup>
                <p>Merchant ID:</p>
                <p>{foundKyc?.User?.id ?? "Not Provided"}</p>
              </InfoGroup>
              <InfoGroup>
                <p>DOB</p>
                <p>{foundKyc?.User?.dob ?? "Not Provided"}</p>
              </InfoGroup>
              <InfoGroup>
                <p>BVN</p>
                <p>{foundKyc?.User?.bvn ?? "Not Provided"}</p>
              </InfoGroup>
              <InfoGroup>
                <p>Means of ID</p>
                <p>{foundKyc?.User?.nimc ?? "Not Provided"}</p>
              </InfoGroup>
              {/* <InfoGroup>
              <p>ID Number</p>
              <p>{foundKyc?.User?.id}</p>
            </InfoGroup> */}
              <Ribbon></Ribbon>
            </InfoContainer>
          </DetailsContainer>
          <FileSection
            files={foundKyc?.uploadMeta}
            id={id}
            fetchData={fetchData}
          />
        </Container>
      )}
      {/*=========== modals ============= */}
      <ConfirmModal
        message={
          btnAction === "Approve"
            ? "Are sure you want to approve this kyc?"
            : "Are sure you want to deactivate this kyc?"
        }
        handleYesModalButton={() => {
          // value of action btn determine func to be called
          if (btnAction === "Approve") handleApprove();
          if (btnAction === "Deactivate") {
            setisModalOpen(!isModalOpen);
            setConfirmModalOpen(false);
          }
        }}
        handleNoModalButton={() => setConfirmModalOpen(!isConfirmModalOpen)}
        isModalOpen={isConfirmModalOpen}
        showIndicator={showIndicator}
        handleCloseModal={() => setConfirmModalOpen(!isConfirmModalOpen)}
      />

      <OtpModal
        onChangeOTP={handleOTP}
        onClose={() => setOtpModalOpen(false)}
        isOpen={isOtpModalOpen}
      />

      {/*=========== Remark modal form ======== */}

      <Modal
        marginTop="1.5rem"
        onClose={() => setisModalOpen(false)}
        isOpen={isModalOpen}
        closeButton={true}
        width="40%"
      >
        <FileFormContainer>
          <h2>Remark Form</h2>
          <form onSubmit={handleDeactivate}>
            <FormFileGroup>
              <label>Remark</label>
              <RemarkInput
                placeholder="Write your remark"
                value={remark}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setremark(event.target.value);
                }}
                required
              />
            </FormFileGroup>

            <FormFileGroup>
              <SubmitButton type="submit">
                {showIndicator ? <Spinner color="#fff" /> : "Submit"}
              </SubmitButton>
            </FormFileGroup>
          </form>
        </FileFormContainer>
      </Modal>
    </>
  );
}
