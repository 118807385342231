import { ActionTypes } from '../constants/actionType';

const initialState = {
  users: {}
};

export const userReducer = (state: any = initialState.users, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_USERS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};


