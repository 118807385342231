import React, { useEffect, useState } from "react";
import {
  BtnsContainer,
  BusinessForm,
  FormBtn,
  FormTitle,
  LoaderCont,
  RoundBtn,
} from "./Styles";
import InputField from "./InputField";
import SelectField from "./SelectField";
import BackBtn from "./BackBtn";
import api from "shared/utils/api";
import { toast } from "react-toastify";
import consoleLog from "shared/utils/consoleLog";
import { omniGetter } from "shared/utils/authToken";
import { similarity } from "shared/utils/similarity";
import Loaders from 'shared/components/Loaders/index'
import { omniSaver } from "shared/utils/ls";
type PropT = {
  setcurrentStep: Function;
  setdoneSteps: Function;
  doneSteps: any[];
  currentStep: number;
};

export default function StepTwoComp({
  setcurrentStep,
  setdoneSteps,
  doneSteps,
  currentStep,
}: PropT) {
  const [accountNo, setaccountNo] = useState("");
  const [bankName, setbankName] = useState("");
  const [accountName, setaccountName] = useState("");
  const [loadingBanks, setLoadingBanks] = useState(false);
  const [banks, setBanks] = useState([]);
  const [bankCode, setBankCode] = useState("");
  const [bankAccId, setBankAccId] = useState("");
  const [loadingAcc, setLoadingAcc] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const handleStepTwo = (e: any) => {
    e.preventDefault();
    setcurrentStep(4);
    setdoneSteps([...doneSteps, 3]);
  };

  const getBankList = async () => {
    setLoadingBanks(true);
    try {
      const res = await api.get("/get-all-banks");
      const { payload } = res;
      // consoleLog("bank list", payload);
      const bankList: any = Object.values(payload).map((role: any) => ({
        value: role.id,
        label: role.name,
        code: role.code,
      }));
      setBanks(bankList.sort((a:any, b:any) => (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0));
      setLoadingBanks(false);
    } catch (error) {
      // @ts-ignore
      //toast.error(error);
      // consoleLog("bank ", error);
      setLoadingBanks(false);
    }
  };

  const getDetails = async () => {
    // consoleLog({accNum, bankCode});
    setIsValidating(true);
    // consoleLog({
    //   accNum,
    //   bankCode,
    // });
    try {
      const { status, message, payload }: any = await api.post(
        "/bank-account-verify",
        {
          accountNumber: accountNo,
          bankCode: bankCode,
        }
      );
      if (status && payload.accountName) {
        let business_name = omniGetter("businessName");
        let bank_acc_name = payload.accountName;
        const stringCompare = similarity(business_name, bank_acc_name);
        
        setaccountName(bank_acc_name);
        setLoadingAcc(false);
        
        const obj = {
          bankAccountName: bank_acc_name,
          accountNumber: accountNo,
          bankId: bankCode,
        }
        // consoleLog('bank dets ', obj)
        omniSaver('bankAccountKyc', obj)
      } else {
        setaccountName("");
        toast.error(message);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      consoleLog(error);
    }finally{
      setIsValidating(false)
    }
  };

  const handleSelect = (e: any) => {
    const filtedBank: any = banks.find((bank: any) => bank.value === e);
    //consoleLog("selected Bank:", filtedBank)
    if (filtedBank) {
      setbankName(filtedBank.label);
      setBankCode(filtedBank.code);
      setBankAccId(filtedBank.value);
    }
  };

  // const onSubmit = async() => {
  //   setLoadingAcc(true)
  //   try {
  //     const res:any = await api.post(
  //       '/kyc-verify',
  //       {
  //         data: {
  //           rcNumber: RegNo,
  //           rcType: RegType
  //         }
  //       }
  //     )
  //     consoleLog('business detail res ', res)
  //     setLoadingAcc(false)
  //   } catch (error) {
  //     setLoadingAcc(false)
  //     consoleLog('business detail error ', error)
  //   }
  // }

  useEffect(() => {
    getBankList();
  }, []);

  useEffect(() => {
    if (bankCode !== "" && accountNo !== "" && accountNo.length >= 10) {
      getDetails();
    }
  }, [accountNo, bankCode]);

  return (
    <div>
      <FormTitle>Enter Account Details</FormTitle>
      <BusinessForm onSubmit={handleStepTwo}>
        {loadingBanks && (
          <h4 style={{ marginTop: 10, marginBottom: 0 }}>
            Bank list loading...
          </h4>
        )}

       {
        <>
             <InputField
                value={accountNo}
                label="Account Number"
                max={10}
                inputType="Number"
                handleChange={(value: any) => setaccountNo(value)}
              />
              <SelectField
                disabled={loadingAcc}
                setValue={handleSelect}
                label="Bank Name"
                data={banks}
                value={bankAccId}
              />

              <InputField
                disabled={true}
                value={accountName}
                label={isValidating? "Validating account no..." :"Account Name"}
                handleChange={(value: any) => setaccountName(value)}
              />
        </>
       }

        <BtnsContainer>
          <BackBtn
            setcurrentStep={setcurrentStep}
            setdoneSteps={setdoneSteps}
            currentStep={currentStep}
            doneSteps={doneSteps}
          />
          <FormBtn disabled={false} type="submit">
            Save and Continue
          </FormBtn>
        </BtnsContainer>
      </BusinessForm>
    </div>
  );
}
