import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

type boxPropT = {
  bgColor: string;
};

interface PropT {
  url?: string;
}

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 6rem;
  @media ${breakpoints.device.max.xs} {
    display: block;
    margin-bottom: 25px;
  }
`;
export const LineChartContainer = styled.div`
  width: 64%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const ChartContainer = styled.div<PropT>`
  width: 34%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;

  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const ChartContainerImage = styled.div<PropT>`
  width: 34%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;

  @media ${breakpoints.device.max.xs} {
    width: 100%;
    display: none;
  }
`;

export const GraphContainer = styled.div`
  width: 60%;
  padding: 1.8rem 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const GraphHeader = styled.div`
  display: flex;
  justify-content: center;
`;

export const GraphContent = styled.div`
  width: 60%;
  height: 16rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
`;

export const ChartLabelContainer = styled.div`
  overflow: auto;
  margin-top: 0rem;
  padding: 0.4rem 0.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;
export const ChartDetails = styled.div`
  display: flex;
  flex-direction: row;
  /*gap: 0.4rem;*/
`;
export const Box = styled.div<boxPropT>`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50rem;
  background-color: ${(prop) => prop.bgColor && prop.bgColor};
`;

// =========== Add business section =========
export const AddBusinessCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 1.2rem;
`;

export const AddBusinessBtn = styled.button`
  background-color: ${color.fountain};
  padding: 0.68rem 1rem;
  border-radius: 4rem;
  border: none;
  color: #fff;
  cursor: pointer;
`;
