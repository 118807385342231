import React from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import PieChart from "./PieChart/PieChart";
import { Link } from "react-router-dom";
import {
  BtnContainer,
  Container,
  PiechartContainer,
  Section,
  TableContainer,
  ViewAllBtn,
} from "./Styles";
import TableSection from "./TableSection/TableSection";

export default function SecondSection() {
  const match = useRouteMatch();
  return (
    <Section>
      <TableContainer>
        <h2 style={{ padding: "0.8rem 0" }}>Agents</h2>
        <Container>
          <TableSection />
        </Container>
        <BtnContainer>
          <Link to={`/agencybanking/business/all`}>
            <ViewAllBtn>View All</ViewAllBtn>
          </Link>
        </BtnContainer>
      </TableContainer>
      <PiechartContainer>
        <PieChart />
      </PiechartContainer>
    </Section>
  );
}
