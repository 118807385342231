import React from "react";
import {
  CheckboxContainer,
  ChecklistContainer,
  ChecklistLabel,
  HiddenCheckbox,
  Label,
  StyledCheckbox,
} from "./Styles";

type PropT = {
  checked: boolean;
  label: string;
  onChange: Function;
  id: number | string;
};

export default function SharedCheckList({
  checked,
  label,
  onChange,
  id,
}: PropT) {
  return (
    <ChecklistContainer>
      <ChecklistLabel>{label}</ChecklistLabel>
      <CheckboxContainer onClick={() => onChange(id)}>
        <HiddenCheckbox onChange={() => onChange(id)} checked={checked} />
        <Label htmlFor={HiddenCheckbox} checked={checked} />
        <StyledCheckbox onChange={() => onChange(id)} checked={checked} />
      </CheckboxContainer>
    </ChecklistContainer>
  );
}
