import React from "react";
import Button from "shared/components/Button";
import Tooltip from "shared/components/Tooltip";
import { removeStoredAuthToken, removeAll } from "shared/utils/authToken";

import feedbackImage from "../../../App/assets/images/logo.svg";
import {
  FeedbackDropdown,
  FeedbackImageCont,
  FeedbackImage,
  FeedbackParagraph,
} from "./Styles";

const LogoutTooltip = (tooltipProps: any) => (
  <Tooltip
    width={300}
    {...tooltipProps}
    renderContent={() => (
      <FeedbackDropdown>
        <FeedbackImageCont>
          <FeedbackImage src={feedbackImage} alt="Give feedback" />
        </FeedbackImageCont>

        <FeedbackParagraph>
          You are about to logout. Are you sure you still want to logout?
        </FeedbackParagraph>

        <FeedbackParagraph>
          If you have any issue using this app, kindly share your view with us
          on
          <br />
          <a href="mailto:icm@fountainpay.ng">
            <strong>support@fountainpay.ng</strong>
          </a>
        </FeedbackParagraph>

        <Button>Cancel</Button>
        <Button
          variant="danger"
          style={{ marginLeft: 40 }}
          icon="log_out_outlined_logout_sign"
          onClick={() => {
            removeStoredAuthToken();
            window.location.href = "/autheticate/login";
            //removeAll();
          }}
        >
          Logout
        </Button>
      </FeedbackDropdown>
    )}
  />
);

export default LogoutTooltip;
