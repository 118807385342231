import styled from "styled-components";
import { color } from "../../../../../shared/utils/styles";

export const TableHeader = styled.div`
  background-color: ${color.fountainLight};
  display: flex;
  width: 100%;
  padding: 1rem 0.2rem;
  justify-content: space-between;
  color: ${color.fountain};
  font-size: 14px;
`;

export const TableRow = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0.2rem;
  justify-content: space-between;
  color: ${color.fountain};
  font-size: 14px;
  border: 1px solid ${color.backgroundMedium};
  cursor: pointer;
`;

export const TableContent = styled.div`
  width: 15%;
  padding-left: 0.2rem;
  justify-content: center;
`;
export const TableContentMain = styled.div`
  width: 15%;
  padding-left: 0.2rem;
  justify-content: center;
  font-size: 13px;
  color: ${color.textDark};
`;
