import React, { useState, useLayoutEffect, useRef } from "react";
import Table from "shared/clientcomponents/Table";
import Filter from "shared/components/Filter";
import { fieldSorter } from "shared/utils/url";
import { USERS } from "shared/utils/sitemap";
import logger from "shared/utils/logger";
import Switch from "shared/components/Switch";
import { ROLES } from "shared/utils/sitemap";
import {
  getClientDetails,
  getIpAddress,
  getLocation,
} from "shared/utils/location";
import { toast, ToastContainer } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import Modal from "shared/quickComponents/modal";
import { OtpContainer, SubTitle, Title } from "Users/Styles";
import OTPInput from "shared/components/OTPInput";
import { getCurrentUser } from "shared/utils/authToken";
import api from "shared/utils/api";
import useApi from "shared/hooks/api";
import { GradientLoader, Spinner } from "shared/components";
import { Section, TableContainer } from "AuditTrail/Styles";
import EmptyState from "shared/components/EmptyState";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { getRole, privilege } from "shared/utils/utility";

import {
  ModalButton,
  ModalButtonsContainer,
  ModalContent,
  SelectContainer,
} from "./Styles";
import { sortBy } from "lodash";
// import consoleLog from "shared/utils/consoleLog";

const filterOptions = [
  {
    name: "name",
    label: "Name",
    checked: false,
  },
  {
    name: "email",
    label: "Email ID",
    checked: false,
  },
  {
    name: "phoneNumber",
    label: "Phone",
    checked: false,
  },
  {
    name: "activated",
    label: "Activated",
    checked: false,
  },
];

interface DownloadFormat {
  headers: any;
  content: any;
  title: string;
}

export const UserList = () => {
  const currentUser = getCurrentUser();

  const [filterBy, setFilterBy] = useState(filterOptions);
  const [users, setUsers] = useState<any[]>([]);
  const [presetUsers, setPresetUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [Otp, setOtp] = useState("");
  const [isOTPError, setisOTPError] = useState(false);
  const [userID, setuserID] = useState<string>("");
  const [isopen, setisopen] = useState(false);
  const [clientLocation, setClientLocation] = useState<any>({});
  const [isloading, setIsloading] = useState(true);
  const [noRecord, setNoRecord] = useState(true);
  const [downloadable, setDownloadable] = useState<DownloadFormat>();
  const [seletedData, setseletedData] = useState("");
  const [isModalOpen, setisModalOpen] = useState(false);
  const [showIndicator, setshowIndicator] = useState(false);
  const [roles, setroles] = useState<[] | any>([]);
  const [changingRole, setchangingRole] = useState(false);
  const [selectedRole, setselectedRole] = useState(currentUser.type);
  const [usersList, setuserslist] = useState([]);

  // const tableRef = useRef(null);

  const [{ data, error, setLocalData }, fetchData] = useApi.get("/users");

  const sendOTP = async (user = selectedUser) => {
    setshowIndicator(true);
    // console.log("changing role", changingRole, selectedRole);
    try {
      const payloadData = {
        adminEmail: currentUser.email,
        requestedEmail: user.email,
        role: selectedRole,
      };
      //console.log("payload on send otp", payloadData);
      const data = await api.post("/send-user-otp", payloadData);
      const { payload } = data;
      console.log("===>", data);
      if (payload?.tempId) {
        setisModalOpen(false);
        toast.success("OTP sent successfully!");
        setuserID(payload?.tempId);
        setSelectedUser(user);
        openModal();
      }
    } catch (error) {
      toast.error("Operation failed. Please try again later.");
    } finally {
      setshowIndicator(false);
    }
  };

  //controlling confirmation modal
  const handleCloseModal = () => {
    setisModalOpen(false);
    fetchData();
  };

  //Handling 'No' button on confrimation modal
  const handleNoModalButton = () => {
    setisModalOpen(false);
  };

  //Handling 'Yes'  button on confrimation modal
  const handleYesModalButton = async () => {
    sendOTP();
  };

  const usersTableHeaders = [
    // { id: "sn", label: "#" },
    { id: "userName", label: "Name" },
    { id: "email", label: "Email", width: "25%" },
    { id: "phone", label: "Phone Number" },
    { id: "role", label: "Roles", width: "20%" },
    { id: "funcProps", label: "", width: "14%" },
  ];

  const filteredList = usersList.filter(
    (list: any) => list.email !== currentUser.email
  );

  const columns = [
    {
      headerName: "Name",
      field: "username",
      defaultSort: "asc",
      sortable: true,
    },
    { headerName: "Email", field: "email" },
    { headerName: "Phone Number", field: "phone", filter: true },
    {
      headerName: "Role",
      field: "role",
      cellRenderer: (params: any) => {
        return <div>{params?.value} kkk</div>;
      },
    },

    {
      headerName: "",
      field: "action",
      cellRenderer: (params: any) => {
        return <div>{params?.value} kkk</div>;
      },
    },
  ];

  const rowData = filteredList.map((data: any) => {
    return {
      username: data.fullName,
      email: data?.email,
      phone: data?.phoneNumber,
      role: (
        <SelectContainer>
          <select
            onChange={(event) => {
              setseletedData(event.target.value);
              const selected = event.target.value.split(",", 2);
              setSelectedUser(data);
              setselectedRole(event.target.value);
              setchangingRole(true); //set changing role watcher to true
              if (selected[1] != currentUser.type) setisModalOpen(true); //Open modal box if not current plan
            }}
          >
            {roles.map((role: any, index: number) => {
              return (
                <option
                  key={index}
                  value={role}
                  selected={data.type === role && data.type}
                >
                  {role}
                </option>
              );
            })}
          </select>
        </SelectContainer>
      ),

      action: (
        <div style={{ marginTop: "10px" }}>
          <Switch
            id={data.id.toString()}
            status={data.status == "ACTIVE" ? true : false}
            label={data.status == "ACTIVE" ? "Deactivate" : "Activate"}
            toggleMethod={() => {
              setchangingRole(false);
              sendOTP(data);
            }}
          />
        </div>
      ),
    };
  });

  const prepareUser = async (users: any) => {
    const list = users.filter((list: any) => list.email !== currentUser.email);
    const UsersList: any = list.map((user: any, index: number) => ({
      id: user.id,
      sn: index + 1,
      userName: user.fullName,
      email: user.email,
      phone: user.phoneNumber,
      // role: user.type,
      role: (
        <SelectContainer>
          <select
            onChange={(event) => {
              setseletedData(event.target.value);
              const selected = event.target.value.split(",", 2);
              setSelectedUser(user);
              setselectedRole(event.target.value);
              setchangingRole(true); //set changing role watcher to true
              if (selected[1] != currentUser.type) setisModalOpen(true); //Open modal box if not current plan
            }}
          >
            {roles.map((role: any, index: number) => {
              return (
                <option
                  key={index}
                  value={role}
                  selected={user.type === role && user.type}
                >
                  {role}
                </option>
              );
            })}
          </select>
        </SelectContainer>
      ),
      funcProps: (
        <Switch
          id={user.id.toString()}
          status={user.status == "ACTIVE" ? true : false}
          label={user.status == "ACTIVE" ? "Deactivate" : "Activate"}
          toggleMethod={() => {
            setchangingRole(false);
            sendOTP(user);
          }}
        />
      ),
    }));

    setNoRecord(false);
    setUsers(UsersList);
  };

  const handleFilter = (data: any) => {
    const filterData = data.filter((option: any) => option.checked);
    const sortDataBy = filterData.map((option: any) => option.name);
    const unSortDataBy = filterOptions.map((option: any) => option.name);
    const newDisputeData =
      sortDataBy.length > 0
        ? users.sort(fieldSorter(sortDataBy))
        : users.sort(fieldSorter(unSortDataBy));
    setUsers(newDisputeData);
    setFilterBy(data);
  };

  const openModal = () => {
    setisopen(!isopen);
  };

  const handleChange = async (otp: string) => {
    setOtp(otp);
    if (otp.length === 6) {
      const userAction =
        selectedUser.status === "ACTIVE" ? "DEACTIVATE_USER" : "ACTIVATE_USER";
      try {
        const payload = {
          tempId: userID,
          otp: otp,
          action: changingRole ? "UPDATE_USER_ROLE" : userAction,
        };
        // console.log("payload to confirm otp", payload);
        const response = await api.post("/confirm-otp", payload);
        console.log("Confirm OTP response: ", response);
        if (response?.status) {
          toast.success(response?.message);
          await fetchData();
          await logger.logActivities("remote", {
            date: new Date().toString(),
            author: currentUser.fullName,
            resource: "admin",
            action: changingRole ? "REVIEW" : "LOGIN",
            payload: {
              userAgent: getClientDetails(),
              data: {
                detail: `${currentUser.fullName} ${
                  changingRole
                    ? `Changed account role for ${selectedUser.fullName}`
                    : userAction
                } account for ${selectedUser.fullName}`,
              },
            },
          });
          openModal();
          setchangingRole(false);
        } else {
          setOtp("");
        }
      } catch (error) {
        toast.error(`${error}`);
        setOtp("");
        await logger.logActivities("remote", {
          date: new Date().toString(),
          author: currentUser.fullName,
          resource: "admin",
          action: `LOGIN`,
          payload: {
            userAgent: getClientDetails(),
            data: {
              detail: `${userAction} failed with the following error: ${error}`,
            },
          },
        });
      }
    } else {
      setisOTPError(true);
    }
  };

  useLayoutEffect(() => {
    if (!data) setIsloading(true);
    if (error) {
      // console.log(error)
      setIsloading(false);
      setNoRecord(true);
    }
    if (!data && !error) setNoRecord(true);
    if (data && data.status && data.payload.length) {
      // console.log("users ==>", data.payload);
      prepareUser(data.payload);
      setuserslist(data.payload);
      setIsloading(false);
    }
  }, [data]);

  useLayoutEffect(() => {
    const el = document.getElementById("table-to-xls");
    const rolesList = ROLES.map((role) => role.code);
    setroles(rolesList);
  }, []);

  const printArea: HTMLElement | null = document.getElementById("printArea");
  // consoleLog("printarea on userlist ==>", printArea);

  return (
    <>
      {/* ==== Confirmation modal ====== */}
      <Modal isOpen={isModalOpen} closeButton={true} onClose={handleCloseModal}>
        <ModalContent>
          <p>Are sure you want to change this user's role?</p>

          <ModalButtonsContainer>
            <ModalButton onClick={handleYesModalButton}>
              {showIndicator ? <Spinner color="#fff" /> : "Yes"}
            </ModalButton>
            <ModalButton onClick={handleNoModalButton} option={true}>
              No
            </ModalButton>
          </ModalButtonsContainer>
        </ModalContent>
      </Modal>

      {/* <Filter
        onChange={handleFilter}
        options={filterBy}
        title="Users"
        downloadable={{
          headers: usersTableHeaders,
          content: users,
          title: "List of users",
        }}
        tableName={document.getElementById("table-to-xls") as HTMLElement}
        itemRef={printArea}
      /> */}
      <h2 style={{ marginTop: "24px", marginBottom: "20px" }}>Users</h2>
      <Section>
        {isloading ? (
          <GradientLoader />
        ) : (
          <>
            {noRecord ? (
              <EmptyState stateTitle="No user" statePara="" stateBtnText="" />
            ) : (
              <>
                <TableContainer id="printArea">
                  <div
                    className="ag-theme-alpine"
                    style={{ width: "100%", height: "500px" }}
                  >
                    <AgGridReact rowData={rowData} columnDefs={columns} />
                  </div>
                  {/* <Table
                    headData={usersTableHeaders}
                    bodyData={users}
                    id="table-to-xls"
                  /> */}
                </TableContainer>
              </>
            )}
          </>
        )}
      </Section>

      <Modal isOpen={isopen} closeButton={true} onClose={openModal} width="40%">
        <div style={{ paddingBottom: "2rem" }}>
          <Title>Check your email for OTP</Title>
          <SubTitle>
            We have sent a 6 - digit code to your email address. <br /> The code
            expires shortly, please enter it soon.
          </SubTitle>
          <OtpContainer>
            {" "}
            <OTPInput
              autoFocus
              isNumberInput
              length={6}
              hasError={isOTPError}
              errorStyle={{ borderColor: "red" }}
              inputStyle={{
                border: "1px solid #828282",
                width: "60px",
                height: "60px",
                fontSize: "30px",
                color: "#000",
                fontWeight: "400",
                caretColor: "#000",
                textAlign: "center",
                borderRight: "0px",
              }}
              focusStyle={
                {
                  //border: "1px solid #CFD3DB",
                }
              }
              firstIndexStyle={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderRight: "0px",
              }}
              lastIndexStyle={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderRight: "1px solid #828282",
                borderLeft: "1px solid #828282",
              }}
              className="otpContainer"
              inputClassName="otpInput"
              separator={
                <span
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "10%",
                    fontSize: "50px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  -
                </span>
              }
              onChangeOTP={handleChange}
            />
          </OtpContainer>
        </div>
      </Modal>
    </>
  );
};
