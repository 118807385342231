import Layout from "Layout";
import { ProtectedRoute } from "guards/ProtectedRoute";
import { AdminGuard } from "guards/admin-guard";
import { SuperAdminGuard } from "guards/super-admin-guard";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import Users from "./Users/Index";
import { PageError } from "shared/components";
import AccessGroup from "./AccessGroup/Index";
import MenuManager from "./MenuManager/Index";
import { UserGuard } from "guards/user-guard";
import AddUser from "./AddUser/Index";

export default function RoleManagement() {
  const match = useRouteMatch();
  let guardsArray = [SuperAdminGuard, AdminGuard, UserGuard];
  // alert(`${match.path}`);
  // return null;
  return (
    <Layout pageTitle="Role Management">
      {/* <ProtectedRoute
        
        path={`${match.path}`}
        guards={guardsArray}
        component={Users}
        fallback={() => <PageError />}
      /> */}

      <ProtectedRoute
        path={`${match.path}/cusers`}
        guards={guardsArray}
        component={Users}
        fallback={() => <PageError />}
      />

      <ProtectedRoute
        path={`${match.path}/add-user`}
        guards={guardsArray}
        component={AddUser}
        fallback={() => <PageError />}
      />

      {/* <ProtectedRoute
        path={`${match.path}/menu-manager`}
        guards={guardsArray}
        component={MenuManager}
        fallback={() => <PageError />}
      /> */}

      <ProtectedRoute
        path={`${match.path}/menu-manager/:id`}
        guards={guardsArray}
        component={MenuManager}
        fallback={() => <PageError />}
      />

      <ProtectedRoute
        path={`${match.path}/edit-user/:id`}
        guards={guardsArray}
        component={AddUser}
        fallback={() => <PageError />}
      />

      <ProtectedRoute
        path={`${match.path}/users-access-group`}
        guards={guardsArray}
        component={AccessGroup}
        fallback={() => <PageError />}
      />
    </Layout>
  );
}
