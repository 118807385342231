import styled from "styled-components";

export const FilterInputCont = styled.div`
  margin-bottom: 5px;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FilterParamsCont = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 35px;
`;

export const FilterText = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px 35px;
`;

export const TextDescription = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #828282;
`;

export const FilterSec = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const InputHolder = styled.div`
  width: 45%;
`;