import React, { useState, useEffect } from "react";
import {
  Card,
  CardIconContainer,
  CardsContainer,
  SwitchContainer,
} from "./Styles";
import CardIcon from "../../App/assets/icons/CardIcon.svg";
import ScanIcon from "../../App/assets/icons/ScanIcon.svg";
import SendIcon from "../../App/assets/icons/SendIcon.svg";
import Switch from "shared/components/Switch";
import { toast } from "react-toastify";
import api from "shared/utils/api";

export default function ChannelActivation() {
  const [isSelected, setisSelected] = useState("Card");
  const [UPS, setUPS] = useState(false);
  const [interswitch, setinterswitch] = useState(false);
  const [NIBSS, setNIBSS] = useState(false);
  const [visaQR, setvisaQR] = useState(false);
  const [channels, setChannels] = useState([] as any);


  const changeOption = async (id: string, valKey: string, value: boolean) => {
    try {
      let clickedChannelIndex: any = channels?.findIndex(
        (el: any) => el?.id === id
      );
      let objChannels = [...channels];
      if (clickedChannelIndex >= 0) {
        let obj: any = {};
        Object.keys(objChannels[clickedChannelIndex]?.settings).forEach(
          (el: any) => {
            if (value === true) {
              obj[el] = valKey === el ? value : false;
            } else {
              obj[el] =
                valKey === el
                  ? value
                  : objChannels[clickedChannelIndex]?.settings[el];
            }
          }
        );
        objChannels[clickedChannelIndex].settings = obj;
        let reqObj = {
          name: objChannels[clickedChannelIndex]?.name,
          ...objChannels[clickedChannelIndex].settings,
        };
        console.log(reqObj, "reqObj");
        const response = await api.post(`/channels/${id}`, reqObj);
        setChannels(objChannels);
        toast.success(response.message);
      }
    } catch (err) {
      console.log(err);
      // @ts-ignore
      toast.error(err?.message);
    }
  };

  const OptionsView = () => {
    let clickedChannel: any = channels?.find(
      (el: any) =>
        el?.name
          .toString()
          .trim()
          .toLowerCase() ===
        isSelected
          .toString()
          .trim()
          .toLowerCase()
    );

    if (clickedChannel && clickedChannel?.settings) {
      return Object.keys(clickedChannel?.settings).map(
        (el: any, idx: number) => {
          return (
            <Switch
              id={`${idx}_${clickedChannel?.id}`}
              status={clickedChannel?.settings[el]}
              toggleMethod={() =>
                changeOption(
                  clickedChannel?.id,
                  el,
                  !clickedChannel?.settings[el]
                )
              }
              label={el?.toString().toUpperCase()}
              color={clickedChannel?.settings[el] ? "#760000" : "#333"}
            />
          );
        }
      );
    }
  };

  const fetchChannels = async () => {
    try {
      const response = await api.get("/channels");
      
      if (response?.payload instanceof Array) {
        setChannels(response.payload);
      } else {
        toast.error("Unable to fetch channels");
      }
      console.log(response.payload, "fetchChannels");
    } catch (err) {
      console.log(err);
      // @ts-ignore
      toast.error(err?.toString());
    }
  };

  useEffect(() => {
    fetchChannels();
  }, []);

  useEffect(() => {
    OptionsView();
  }, [isSelected]);

  return (
    <div>
      <CardsContainer>
        <div>
          <Card
            isActive={isSelected === "Card" ? true : false}
            onClick={() => setisSelected("Card")}
          >
            <CardIconContainer
              isActive={isSelected === "Card" ? true : false}
              onClick={() => setisSelected("Card")}
            >
              <img src={CardIcon} alt="" />
            </CardIconContainer>
            <h2>Card</h2>
          </Card>
        </div>

        <div>
          <Card
            isActive={isSelected === "QRPay" ? true : false}
            onClick={() => setisSelected("QRPay")}
          >
            <CardIconContainer
              isActive={isSelected === "QRPay" ? true : false}
              onClick={() => setisSelected("QRPay")}
            >
              <img src={ScanIcon} alt="" />
            </CardIconContainer>
            <h2>QR Pay</h2>
          </Card>
        </div>

        <div>
          <Card
            isActive={isSelected === "DirectDebit" ? true : false}
            onClick={() => setisSelected("DirectDebit")}
          >
            <CardIconContainer
              isActive={isSelected === "DirectDebit" ? true : false}
              onClick={() => setisSelected("DirectDebit")}
            >
              <img src={SendIcon} alt="" />
            </CardIconContainer>
            <h2>Direct Debit</h2>
          </Card>
        </div>
      </CardsContainer>

      <SwitchContainer>{OptionsView()}</SwitchContainer>
    </div>
  );
}
