import { useEffect, useState } from "react";

import {
  CardFooter,
  ContentHeader,
  ContentRow,
  InnerPanelLayout,
  PanelTitle,
  ViewAll,
} from "./Style";
import { useSelector } from "react-redux";
import api from "shared/utils/api";
import reportObj from "Report/report-class";
import { useHistory } from "react-router-dom";
import { Spinner } from "shared/components";

const BusinessStat = () => {
  const [businessData, setbusinessData] = useState<any[]>([]);
  const [isloading, setisloading] = useState(false);
  const [isDatafetched, setisDatafetched] = useState(false);

  const history=useHistory();

  const linkUrlPath = () => {
    history.push(`${history?.location?.pathname}/details/businesses`);
  };

  const renderBusiness = (arr: any[]) => {
    return arr.map((obj, index: number) => (
      <ContentRow key={index}>
        <span>
          {obj?.businessName.length > 24
            ? `${obj?.businessName.slice(0, 24) + "..."}`
            : obj?.businessName}
        </span>
        <span>{obj?.totalTransactions}</span>
      </ContentRow>
    ));
  };
  const getBizStats = async () => {
    const response = await reportObj.getBusinessStat();
    if (response?.length > 0) {
      setbusinessData(response.slice(0, 5));
      setisloading(false);
      setisDatafetched(true);
    }
  };

  useEffect(() => {
    if (!isDatafetched) {
      setisloading(true);
      getBizStats();
    }
  }, []);

  return (
    <InnerPanelLayout>
      <>
        <PanelTitle>Business</PanelTitle>
        <ContentHeader>
          <span>Name</span>
          <span>Total Transactions</span>
        </ContentHeader>
        {isloading && <Spinner size={30} />}
        {!isloading && businessData && renderBusiness(businessData)}

        <ViewAll onClick={()=>linkUrlPath()}>View all</ViewAll>
      </>
    </InnerPanelLayout>
  );
};

export default BusinessStat;
