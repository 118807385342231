import React, { useEffect, useState } from "react";
import {
  CardFlex,
  CardsContainer,
  ChartCont,
  ChartContainer,
  StatContainer,
} from "./Styles";
import GraphArea from "../GraphArea";
import Channels from "../Channels";
import PendingSettlement from "../PendingSettlement";
import LastSettlement from "../LastSettlement";
import BusinessStat from "../BusinessStat";
import TransactionSummary from "../TransactionSummary";
import reportObj from "Report/report-class";
import { Layout, LeftLayoutPanel, Panels, RightLayoutPanel } from "../Style";
import Transactions from "../Transactions";

export default function ReportStats() {
  

  return (
    <>
      <Layout>
        <LeftLayoutPanel>
          <TransactionSummary />
          <GraphArea />
        </LeftLayoutPanel>
        <RightLayoutPanel>
          <Panels>
            <Channels />
            <Transactions />
            <BusinessStat />
            <PendingSettlement />
          </Panels>
        </RightLayoutPanel>
      </Layout>
    </>
  );
}
