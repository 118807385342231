import { useLayoutEffect, useState } from "react";

// import { useAppDispatch, useAppSelector } from "AppStore/hooks";
import Layout from "Layout";
import {
  Redirect,
  Route,
  Router,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { GradientLoader, PageError } from "shared/components";
import {
  AuditTrailPage,
  Content,
  ContentArea,
  Items,
  LeftPanel,
  SearchDropdown,
  TabList,
  TopPanel,
} from "./Styles";
import { ProtectedRoute } from "guards/ProtectedRoute";
import { SuperAdminGuard } from "guards/super-admin-guard";
import AdminLog from "./AdminLog";
import ClientAreaLog from "./ClientAreaLog";
import { Select, Option } from "shared/quickComponents/select";

type DashboardDataPropT = {
  data: {}[];
};

const AuditTrail = () => {
  const [isLoggedIn, setisLoggedIn] = useState(true);
  const [data, setdata] = useState<boolean>(true);
  const [filterByAction, setFilterByAction] = useState<string>("Logins");
  const [activeTab, setActiveTab] = useState("admin");

  const match = useRouteMatch();
  const history = useHistory();

  const actions = ["Logins", "Approvals", "Transactions"];
  useLayoutEffect(() => {
    const { pathname } = window.location;
    if (pathname == "/system-status/admin-logs") {
      setActiveTab("admin");
    }
    if (pathname == "/system-status/client-logs") {
      setActiveTab("client");
    }
    if (pathname === "/system-status") {
      setActiveTab("admin");
      history.push("/system-status/admin-logs");
    }
  }, []);

  return (
    <Layout pageTitle="System Status">
      {!data ? (
        <GradientLoader />
      ) : (
        <AuditTrailPage>
          {/* <LeftPanel>
            <TabList
              onClick={() => history.push("/system-status/admin-logs")}
              isTabActive={activeTab == "admin"}
            >
              Admin Logs
            </TabList>
            <TabList
              onClick={() => history.push("/system-status/client-logs")}
              isTabActive={activeTab == "client"}
            >
              Clients Logs
            </TabList>
          </LeftPanel> */}

          <ContentArea>
            <Content>
              <ProtectedRoute
                path={`${match.path}/admin-logs`}
                guards={[SuperAdminGuard]}
                component={AdminLog}
                fallback={() => <Route component={PageError} />}
              />

              <ProtectedRoute
                path={`${match.path}/client-logs`}
                guards={[SuperAdminGuard]}
                component={ClientAreaLog}
                fallback={() => <Route component={PageError} />}
              />
            </Content>
          </ContentArea>
        </AuditTrailPage>
      )}
    </Layout>
  );
};

export default AuditTrail;
