import React, { useState, useEffect, useLayoutEffect } from "react";
import { PageLayout } from "shared/section_components/Styles";
import { getCurrentUser } from "shared/utils/authToken";
import FirstSection from "./FirstSection";
import ThirdSection from "./ThirdSection/ThirdSection";
import SecondSection from "./SecondSection/index";
import EmptyState from "../../shared/components/EmptyState";
import api from "shared/utils/api";
import show from "shared/utils/toast";
import { GradientLoader } from "shared/components";
import useApi from "shared/hooks/api";
import logger from "shared/utils/logger";
import { getClientDetails } from "shared/utils/location";
import { useEffectOnce } from "shared/hooks/useEffectOnce";
import businessOBJ from "Business/businessClass";
import Layout from "Layout";

export default function BusinessHome() {
  const [content, setcontent] = useState<any>({});
  const [isloading, setIsloading] = useState(true);
  const [noRecord, setNoRecord] = useState(true);

  const currentUser = getCurrentUser();

  // const [{ data, error, setLocalData }, fetchData] = useApi.get(`/overview`);

  const handleLog = async () => {
    // console.log("logging on bussiness");
    await logger.logActivities("remote", {
      date: new Date().toString(),
      author: currentUser.fullName,
      resource: "admin",
      action: "MERCHANT",
      payload: {
        userAgent: getClientDetails(),
        data: {
          detail: `${currentUser.fullName} access the "Business" overview`,
        },
      },
    });
  };
  const getBusinessOverview = async () => {
    const data = await businessOBJ.getBusinessOverview();
    if (data && data?.status && data?.payload?.businessCategories?.length) {
      setcontent(data.payload);
      setNoRecord(false);
      setIsloading(false);
    }
  };

  useLayoutEffect(() => {
    getBusinessOverview();
  }, []);

  useEffect(() => {
    if (content) {
      handleLog();
    }
  }, []);

  return (
    <Layout pageTitle="Business">
      <>
        {isloading ? (
          <GradientLoader />
        ) : (
          <PageLayout>
            {noRecord ? (
              <EmptyState stateTitle="No Data" statePara="" stateBtnText="" />
            ) : (
              <>
                <FirstSection data={content} />
                <SecondSection data={content?.transactionGraph ?? {}} />
                <ThirdSection />
              </>
            )}
          </PageLayout>
        )}
      </>
    </Layout>
  );
}
