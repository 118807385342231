import React, { useState } from "react";
import { Button, Form } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormElement,
  StyledLink,
  Title,
} from "./Styles";
import { getStoredAuthToken, storeAuthToken } from "shared/utils/authToken";
import toast from "shared/utils/toast";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";

const SessionTimeout = () => {
  const [isLoginIn, setIsLoginIn] = useState(false);
  const history = useHistory();

  const onSubmit = async (values: any, form: any) => {
    setIsLoginIn(true);
    try {
      const { authToken }: any = await api.post("/authentication/login", {
        values,
      });
      storeAuthToken(authToken);
      setIsLoginIn(false);
      toast.success("Login was successful.");
      history.push("/");
    } catch (error) {
      // toast.error(error);
      setIsLoginIn(false);
    }
  };
  return (
    <ComponentBody>
      <Title>SESSION TMEOUT</Title>
      {/* @ts-ignore */}
      <Form
        enableReinitialize
        initialValues={{
          email: "",
          password: "",
        }}
        validations={{
          email: Form.is.required(),
          password: [Form.is.required(), Form.is.maxLength(200)],
        }}
        onSubmit
      >
        <FormElement>
          <Form.Field.Input
            name="email"
            label="Enter Email address"
            // tip="Kindly enter your user email address."
          />
          <Form.Field.Input
            name="phone"
            type="tel"
            label="Phone number"
            // tip="Kindly enter your user password."
          />

          <ActionButton type="submit" variant="primary" isWorking={isLoginIn}>
            Login
          </ActionButton>

         
        </FormElement>
      </Form>
    </ComponentBody>
  );
};

export default SessionTimeout;
