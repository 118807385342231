import { PageLayout } from "shared/section_components/Styles";
import BreakPoints from "shared/utils/Breakpoint";
import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color } from "../../../shared/utils/styles";

type BoxPropT = {
  isActive?: boolean;
};

type ContainerT = {
  isDetails?: boolean;
};

type StatusBtnT = {
  mode?: string;
};

export const BusinesPageLayout = styled(PageLayout)`
  background-color: transparent !important;
  padding-bottom: 20px;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 4rem;
  /* border: 1px solid red; */

  @media (${BreakPoints.xs}) {
    h4 {
      position: relative;
      left: -1rem;
      padding: 0;
      /* border: 1px solid red; */
      text-align: left;
      font-size: 0.8rem;
    }
    padding: 0 2.8rem;
    width: 100%;
    margin-right: 0rem;
    margin-top: 1.2rem;
  }
`;

export const TopSection = styled.section`
  display: flex;
  gap: 2rem;
`;

export const DetailsContainer = styled.div`
  position: relative;
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  @media (${BreakPoints.xs}) {
    margin-top: 2.6rem;
    flex-direction: column;
    /* border: 2px solid red; */
  }
`;
export const InfoContainer = styled.div<ContainerT>`
  position: relative;
  overflow: hidden;
  width: ${(prop) => (prop.isDetails ? "45%" : "50%")};
  min-height: 4rem;
  max-height: ${(prop) => prop.isDetails && "30rem"};
  padding: ${(prop) => (prop.isDetails ? "0.4rem 2rem" : "0")};
  background: #fff;
  border-radius: 0.4rem;
  h3 {
    padding: 1.5rem 0;
  }

  @media (${BreakPoints.xs}) {
    width: 100%;
    min-height: 0;
    max-height: 100vh;
    overflow: none;
    margin-top: -1rem;
    /* border: 2px solid red; */
  }
`;
export const InfoGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  p {
    width: 50%;
  }
`;

export const StatusButton = styled.span<StatusBtnT>`
  background: ${color.fountain};
  background: ${(prop) => prop.mode === "INACTIVE" && "orange"};
  background: ${(prop) => prop.mode === "ACTIVE" && "green"};
  color: bisque;
  color: ${(prop) => prop.mode === "INACTIVE" && "#000"};
  color: ${(prop) => prop.mode === "ACTIVE" && "#fff"};
  padding: 0.2rem 1rem;
  border-radius: 4rem;
`;

export const Ribbon = styled.div<StatusBtnT>`
  background: ${color.fountain};
  background: ${(prop) => prop.mode === "INACTIVE" && "red"};
  background: ${(prop) => prop.mode === "ACTIVE" && color.statusGreen};
  height: 4rem;
  width: 8rem;
  position: absolute;
  bottom: -2rem;
  right: -4rem;
  transform: rotate(-45deg);
`;

export const TopGrid = styled.div`
  padding: 0.4rem;
  width: 100%;
  height: 50vh;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
  @media ${breakpoints.device.max.xs} {
  }

  @media (${BreakPoints.xs}) {
    display: grid;
    padding: 5px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    /* border: 2px solid red; */
    height: auto;
  }
`;

export const Box = styled.div<BoxPropT>`
  width: 10rem;
  padding: 1.48rem 0.8rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;
  transition: all ease 0.1s;
  cursor: pointer;
  border-radius: 1rem;
  color: ${(prop) => prop.isActive && `#fff`};
  background-color: ${(prop) => (prop.isActive ? `${color.fountain}` : "#fff")};
  border: ${(prop) => prop.isActive && `2px solid ${color.fountain}`};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  :hover {
    /* margin-right: 0.4rem; */
    background: ${color.fountain};
    color: #fff;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const IconContainer = styled.div<BoxPropT>`
  margin-top: 0.4rem;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  background-color: ${(prop) => prop.isActive && `#fff`};
  :hover {
    background-color: #fff;
  }
  img {
    width: 25px;
    height: 25px;
  }
`;

export const BoxTitle = styled.span<BoxPropT>`
  display: block;
  font-size: 14px;
  margin-top: 0.4rem;
  text-align: center;
`;

export const BoxValue = styled.span`
  margin-top: 0.2rem;
  display: block;
  font-size: 1.5rem;
  font-weight: 900;
`;
