import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 4rem;
`;

export const Img = styled.img`
  width: 8rem;
  height: auto;
  margin-bottom: 1rem;
`;

export const Title = styled.h2`
  font-weight: 900;
  font-size: 2rem;
  color: #828282;
  margin-bottom: 0.5rem;
`;

export const Text = styled.p`
  color: #828282;
  margin-top: 4.8rem;
  width: 60%;
  margin: auto;
  text-align: center;
`;
