import React from "react";
import Modal from "shared/quickComponents/modal";
import OTPInput from "shared/components/OTPInput";
import { OtpContainer, SubTitle, Title } from "./Styles";

type propT = {
  isOpen: boolean;
  onClose: Function;
  onChangeOTP: Function;
};

export default function index({ isOpen, onClose, onChangeOTP }: propT) {
  return (
    <Modal isOpen={isOpen} closeButton={true} onClose={onClose} width="40%">
      <div style={{ paddingBottom: "2rem" }}>
        <Title>Check your email for OTP</Title>
        <SubTitle>
          We have sent a 6 - digit code to your email address. <br /> The code
          expires shortly, please enter it soon.
        </SubTitle>
        <OtpContainer>
          {" "}
          <OTPInput
            autoFocus
            isNumberInput
            length={6}
            hasError={false}
            errorStyle={{ borderColor: "red" }}
            inputStyle={{
              border: "1px solid #828282",
              width: "60px",
              height: "60px",
              fontSize: "30px",
              color: "#000",
              fontWeight: "400",
              caretColor: "#000",
              textAlign: "center",
              borderRight: "0px",
            }}
            focusStyle={
              {
                //border: "1px solid #CFD3DB",
              }
            }
            firstIndexStyle={{
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
              borderRight: "0px",
            }}
            lastIndexStyle={{
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
              borderRight: "1px solid #828282",
              borderLeft: "1px solid #828282",
            }}
            className="otpContainer"
            inputClassName="otpInput"
            separator={
              <span
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginTop: "10%",
                  fontSize: "50px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                -
              </span>
            }
            onChangeOTP={(otp) => onChangeOTP(otp)}
          />
        </OtpContainer>
      </div>
    </Modal>
  );
}
