import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color, font } from "shared/utils/styles";

type modalButtonPropT = {
  option?: boolean;
};

export const SingleDispute = styled.div`
  /* background-color: #ffffff; */
  text-align: left;
  padding-bottom: 50px;
`;

export const PageContainer = styled.div`
  padding-left: 1.5rem;
`;

export const BackButton = styled.img`
  cursor: pointer;
`;

export const Heading = styled.div`
  display: flex;
  align-content: center;
  gap: 1rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  color: #292929;
  text-align: left;
  margin: 30px 0px 30px 20px;
`;

export const ReasonContainer = styled.div`
  text-align: left;
`;
export const ReasonLabel = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #292929;
  text-align: left;
`;

export const ReasonBox = styled.div`
  max-width: 557px;
  min-height: 172px;
  background: rgba(217, 217, 217, 0.2);
  border-radius: 13px;
  padding: 20px 20px;
  margin-top: 15px;
`;

export const Reason = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 192.69%;
  color: #292929;
`;

export const StatusContainer = styled.div`
  display: flex;
  margin: 35px 0px;
  align-items: center;
`;

export const StatusBox = styled.div`
  width: 150px;
  margin-left: 20px;
  border: 1px solid ${color.fountain};
  border-radius: 10rem;
  padding-right: 0.4rem;

  & select {
    background-color: transparent;
    border: none;
    padding: 0.6rem 1rem 0.6rem 0.4rem;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
  }
`;

export const PersonContainer = styled.div`
  margin-top: 15px;
`;

export const PersonLabel = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #292929;
  text-align: left;
`;

export const PersonBox = styled.div`
  /* max-width: 745; */
  background: rgba(217, 217, 217, 0.2);
  border-radius: 13px;
  padding: 20px 20px;
  margin-top: 15px;
  display: flex;
  & p {
    margin: 1.5rem 0px;
  }
  @media ${breakpoints.device.max.xs} {
    display: block;
  }
`;

export const PersonBoxLeft = styled.div`
  width: 50%;
  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const PersonBoxRight = styled.div`
  width: 50%;
  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const BoldText = styled.strong`
  font-size: 16px;
  color: #292929;
  letter-spacing: 0.01ch;
`;

export const AmountBox = styled.div`
  margin: 3rem 0px;
  & p {
    margin: 1.5rem 0px;
  }
`;

export const AwaitingText = styled.span`
  color: #f2994a;
  padding: 0.2rem 0.8rem;
  margin-left: 0.4rem;
  background: ${color.fountain};
  border-radius: 5rem;
  font-size: 14px;
`;

// ==== Modal styles ======

export const ModalContent = styled.div`
  width: 90%;
  margin: auto;
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    padding: 0.4rem;
    text-align: center;
  }
`;

export const ModalButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
`;

export const ModalButton = styled.button<modalButtonPropT>`
  padding: 0rem 2rem;
  height: 40px;
  background-color: ${(prop) =>
    prop.option ? color.fountainLight : color.fountain};
  color: ${(prop) => (prop.option ? "#000" : "#fff")};
  border-radius: 0.4rem;
`;

// OTP Modal

export const OtpContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const Title = styled.h1`
  color: #292929;
  padding: 10px 0;
  ${font.size(32)}
`;

export const SubTitle = styled.p`
  color: #828282;
  ${font.size(16)}
`;
