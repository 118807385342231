import React from "react";
import {
  BtnCont,
  FormContainer,
  Input,
  InputGroup,
  InputLabel,
  ResetBtn,
  ResetForm,
} from "./Styles";

export default function PasswordReset() {
  return (
    <div>
      <FormContainer>
        <ResetForm>
          <InputGroup>
            <InputLabel>Current Password</InputLabel>
            <Input type="password" placeholder="Current password" />
          </InputGroup>

          <InputGroup>
            <InputLabel>New Password</InputLabel>
            <Input type="password" placeholder="New password" />
          </InputGroup>

          <InputGroup>
            <InputLabel>Confirm New Password</InputLabel>
            <Input type="password" placeholder="Confirm new password" />
          </InputGroup>

          <BtnCont>
            <ResetBtn>Reset Password</ResetBtn>
          </BtnCont>
        </ResetForm>
      </FormContainer>
    </div>
  );
}
