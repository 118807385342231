import { Button } from "shared/components";
import styled from "styled-components";
import { color, font } from "shared/utils/styles";
import BreakPoints from "shared/utils/Breakpoint";
import breakpoints from "shared/utils/breakpoints";

type BoxPropT = {
  isActive?: boolean;
};

export const Container = styled.div`
  margin-top: 2rem;
  padding: 2rem 0;
  /* border: 1px solid red; */
  @media (${BreakPoints.xs}) {
    position: relative;
    top: -4rem;
    margin-top: 0rem;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${breakpoints.device.max.sm} {
    padding: 0px 10px;
  }

  @media ${breakpoints.device.max.xs} {
    padding: 0 0.5rem;
    /* flex-direction: column; */
  }
`;

export const Pagetitle = styled.h2`
  font-size: large;
  color: ${color.fountain};
`;
export const TotalRecord = styled.span`
  display: block;
  margin-top: 0.5rem;
  font-weight: 800;
`;

export const PageHeaderButton = styled.button`
  border-radius: 10rem;
  color: #fff;
  background: ${color.fountain};
  padding: 0.8rem 1rem;
  cursor: pointer;
  :hover {
    background: ${color.fountainAccent};
  }

  @media (${BreakPoints.xs}) {
    padding: 0.65rem 1rem;
    font-size: 0.66rem;
    /* flex-direction: column; */
  }
`;

export const PageContent = styled.div`
  margin-top: 2rem;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 1px 4px;
  padding: 1rem 2rem 1.4rem 2rem;
  border-radius: 1rem;

  @media (${BreakPoints.xs}) {
    padding: 0.5rem;
    border-radius: 0.2rem;
    /* flex-direction: column; */
  }
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
`;

export const TableAction = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  gap: 5rem;
`;

export const ViewAllBtn = styled.span`
  background: ${color.fountain};
  color: #fff;
  border-radius: 8rem;
  padding: 0.6rem 1rem;
  font-size: 12px;
`;

export const EditBtn = styled.span`
  /* background: ${color.fountain}; */
  background: #f1e6e6;
  color:#000;
  /* color: ${color.textDark}; */
  border-radius: 8rem;
  padding: 0.6rem 1rem;
  font-size: 12px;
  cursor: pointer;
`;

export const GroupedBtn = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  align-content: center;
`;

export const RemoveBtn = styled.span`
  // background: ${color.fountain};
  color: red;
  border-radius: 8rem;
  padding: 0.6rem 1rem;
  font-size: 14px;
`;

export const PriceFormContainer = styled.div`
  width: 96%;
`;

export const FormTitle = styled.h2`
  text-align: center;
  color: ${color.fountain};
`;

export const PricingForm = styled.form`
  margin-top: 2rem;
  padding: 2rem 4rem;
`;

export const FormGroup = styled.div`
  display: flex;
  gap: 1.8rem;
  width: 100%;
`;

export const FormInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6rem;
  width: 100%;
  margin-bottom: 2rem;
`;
export const InputLabel = styled.label`
  color: ${color.textMedium};
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 1rem 0.4rem;
  background: ${color.backgroundLight};
  outline-style: none;
  border: none;
  border-radius: 0.4rem;
`;

export const PlusIcon = styled.span`
  font-size: 3rem;
  color: ${color.fountain};
  position: relative;
  top: 1.2rem;
`;

export const ModalBtn = styled.button`
  border-radius: 0.4rem;
  width: 100%;
  color: #fff;
  background: ${color.fountain};
  // padding: 1rem 2rem;
  height: 55px;
  cursor: pointer;
  :hover {
    background: ${color.fountainAccent};
  }
`;

export const PageHeaderSearch = styled.input`
  width: 90%;
  height: 50px;
  padding: 0.6rem 1.5rem;
  border: 1px solid ${color.fountain};
  outline-style: none;
  border-radius: 0.4rem;
`;

export const PlanStatus = styled.span`
  display: block;
  width: 10%;
`;

export const Title = styled.h1`
  color: #292929;
  padding: 10px 0;
  ${font.size(32)}
`;

export const SubTitle = styled.p`
  color: #828282;
  ${font.size(16)}
`;

// =========== boxes =================

export const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1px;
  grid-gap: 2rem;
  width: 100%;
  /* display: flex;
  gap: 4rem; */

  @media (${BreakPoints.xs}) {
    grid-template-columns: 1fr;
  }
  /* @media (${BreakPoints.medium}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  } */
`;

export const Box = styled.div<BoxPropT>`
  width: 100%;
  padding: 1.48rem 1.4rem;
  height: 14rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  transition: all ease 0.1s;
  cursor: pointer;
  border-radius: 1rem;
  color: ${(prop) => prop.isActive && `#fff`};
  background-color: ${(prop) => (prop.isActive ? `${color.fountain}` : "#fff")};
  border: ${(prop) => prop.isActive && `2px solid ${color.fountain}`};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  :hover {
    /* margin-right: 0.4rem; */
    background: ${color.fountain};
    color: #fff;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const IconContainer = styled.div<BoxPropT>`
  margin-top: 0.4rem;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  background-color: ${(prop) => prop.isActive && `#fff`};
  :hover {
    background-color: #fff;
  }
`;

export const BoxTitle = styled.span<BoxPropT>`
  display: block;
  font-size: 16px;
  margin-top: 0.4rem;
  @media ${breakpoints.device.max.md} {
    text-align: center;
  }
`;

export const BoxValue = styled.span`
  margin-top: 0.8rem;
  display: block;
  font-size: 2rem;
  font-weight: 900;
`;
