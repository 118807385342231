import React, { useState } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { GradientLoader } from "shared/components";
import AllBusiness from "./AllBusiness/AllBusiness";
import BusinessHome from "./Home";
import Single from "./Single";

export default function Business() {
  const [data, setdata] = useState<boolean>(true);
  const match = useRouteMatch();

  return (
    <>
      {!data ? (
        <GradientLoader />
      ) : (
        <>
          <Route path={`${match.path}/home`} render={() => <BusinessHome />} />
          <Route
            path={`${match.path}/overview/:id`}
            render={() => <Single />}
          />
          <Route
            path={`${match.path}/overview`}
            exact
            render={() => <Single />}
          />
          <Route path={`${match.path}/all`} render={() => <AllBusiness />} />
          {match.isExact && <Redirect to={`${match.url}/home`} />}
        </>
      )}
    </>
  );
}
