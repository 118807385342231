import React, { useState, useEffect } from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import { color } from "shared/utils/styles";
import { GraphContainer, GraphContent, GraphHeader } from "../Style";
import { Select, Option } from "shared/quickComponents/select";
import { Data } from "./Data";
import { SelectBoxCont } from "Dashboard/Overview/secondSection/Styles";
import AgencyBankingObj from "AgencyBanking/class.agency";
import consoleLog from "shared/utils/consoleLog";

type ChartPropT = {
  id: number;
  xAxis: string;
  transaction: number;
  // userLost: number;
}[];

export default function LineChart() {
  const [chatdata, setdata] = useState([]);
  const [chartData, setchartData] = useState<ChartPropT | null>([]);
  const [category, setcategory] = useState(0);
  //set chartData state so we can change the chart data anytime
  const [userData, setUserData] = useState<any>({ labels: [], datasets: [] });

  const fetchData = () => {
    try {
      if (chartData instanceof Array) {
        setchartData(chartData);
        setUserData({
          labels: chartData?.map((el: any) => el.label),
          datasets: [
            {
              label: "Total transactions",
              data: chartData?.map((el: any) => el?.value),
              backgroundColor: "#FFF8F8",
              borderColor: color.fountain,
              borderWidth: 6,
              pointBackgroundColor: "white",
              pointBorderColor: color.fountain,
              pointBorderWidth: 3,
              pointRadius: 8,
              tension: 0.4,
              fill: true,
            },
          ],
        });
      }
    } catch (err) {
      consoleLog("error in agency banking line chart", err);
    }
  };

  function kFormatter(num: any) {
    let exp = (Math.sign(num) * Math.abs(num)) / 1000;
    return Math.abs(num) > 999
      ? ` ${exp.toFixed(1)}k`
      : Math.sign(num) * Math.abs(num);
  }

  const options = {
    plugins: { legend: { display: false } },
    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          callback: function(value: any, index: any) {
            return kFormatter(value);
          },
        },
      },
    },
  };

  let arrOptions = [
    { label: "This week", value: 0 },
    { label: "This month", value: 1 },
    { label: "This year", value: 2 },
  ];

  const getChartData = async () => {
    const filterArray = ["week", "month", "year"];
    const response = await AgencyBankingObj.getChartData(filterArray[category]);
    if (response) setchartData(response);
  };

  useEffect(() => {
    fetchData();
  }, [chartData]);

  useEffect(() => {
    getChartData();
  }, [category]);

  return (
    <>
      <GraphHeader>
        <SelectBoxCont>
          <Select
            value={arrOptions.find((el: any) => el.value === category)?.label}
            variant="outline"
            bgColor="#fff"
          >
            <Option variant="outline" onClick={() => setcategory(0)}>
              This week
            </Option>

            <Option variant="outline" onClick={() => setcategory(1)}>
              This month
            </Option>

            <Option variant="outline" onClick={() => setcategory(2)}>
              This year
            </Option>
          </Select>
        </SelectBoxCont>
      </GraphHeader>
      <GraphContent>
        <Line key={Math.random()} data={userData} options={options} />
      </GraphContent>
    </>
  );
}
