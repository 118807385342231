import React from "react";
import escalamationImage from "../../../App/assets/images/addnewitem.svg";
import {
  Container,
  EsclamationImg,
  StateTitle,
  StatePara,
  CreateSubAccBtn,
} from "./Styles";

function index({ openModal, stateTitle, statePara, stateBtnText }: any) {
  return (
    <Container>
      <EsclamationImg src={escalamationImage} alt="esclamation image" />
      {stateTitle && <StateTitle>{stateTitle}</StateTitle>}

      {statePara && <StatePara>{statePara}</StatePara>}

      {stateBtnText && (
        <CreateSubAccBtn onClick={openModal}>{stateBtnText}</CreateSubAccBtn>
      )}
    </Container>
  );
}

export default index;
