import Gmail from "App/assets/images/icons/gmail.svg";
import Outlook from "App/assets/images/icons/outlook.svg";
import Logo from "App/assets/images/logo.svg";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OTPInput } from "shared/components";
import api from "shared/utils/api";
import { getTempId, storeCurrentUser } from "shared/utils/authToken";
import toast from "shared/utils/toast";
import {
  Actions,
  Footer,
  Info,
  OtpContainer,
  ReportBody,
  SubTitle,
  Title,
  OtpBtnContainer,
  OtpBtn,
  AuxOtpText,
} from "./Styles";
import { omniGetter, omniRemover } from "shared/utils/authToken";
import consoleLog from "shared/utils/consoleLog";
import { storeTempId } from "shared/utils/authToken";
import Preloader from "preloader";
import {
  getClientDetails,
  getIpAddress,
  getLocation,
} from "shared/utils/location";
import logger from "shared/utils/logger";
import roleApi from "RoleManagement/apiClass";

const ReceiveOtp = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const history = useHistory();
  const [showIndicator, setshowIndicator] = useState(false);

  const [otp, setOtp] = useState("");

  const resendOtp = async () => {
    let email = omniGetter("otp_email");

    setIsLoginIn(true);
    try {
      const data = await api.post("/send-2fa-otp", {
        admin_email: email,
        email_type: "verification",
      });

      console.log("data send otp", data);

      const { payload } = data;
      if (payload?.tempId) {
        storeTempId(payload?.tempId);
        setshowIndicator(false);
        toast.success("OTP sent login for validate!");
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error ?? "Something went wrong");

      setSentOtp(false);
    } finally {
      setIsLoginIn(false);
    }
  };

  const handleChange = async (otp: string) => {
    let tempId: any = getTempId();
    setError(false);
    setOtp(otp);
    
    if (otp.length === 6) {
      
      setIsLoginIn(true);
      const values: any = {
        tempId,
        otp
      };
      
      try {
        // if (
        //   process.env.REACT_APP_JUMP_OTP &&
        //   process.env.REACT_APP_JUMP_OTP === "true" &&
        //   process.env.NODE_ENV !== "development"
        // ) {
          // Should be removed even in staging environment
          const data = await api.post("/send-2fa-verify-otp", {
            tempId,
            otp
          });
          //const data = await api.post("/send-2fa-verify-otp", values);
          
          console.log("verify otp response: ", data);
          if(data.status){
            const { payload } = await api.get("/profile");

            storeCurrentUser(payload);
            // console.log("User details: ", payload);

            await logger.logActivities("remote", {
              date: new Date().toString(),
              author: payload?.fullName,
              resource: "admin",
              action: "LOGIN",
              payload: {
                userAgent: getClientDetails(),
                data: {
                  detail: `${payload.fullName} logs in to the admin console`,
                },
              },
            });

            toast.success("Login was successful.");
            history.push("/dashboard/over-view");
          }else{
            toast.error(data.message);
          }
          
        // }
        
      } catch (error) {
        //history.push(authPath.OTP_FAILED)
        console.log("Error: ", error)
        setError(true);
        storeCurrentUser({});
        toast.error("Invalid OTP");
      } finally {
        setIsLoginIn(false);
      }
    }else{
      console.log("Not")
    }
  };

  // useEffect(() => {
  //   resendOtp();
  // }, []);

  if (isLoginIn) {
    return <Preloader />;
  }

  return (
    <ReportBody>
      {/* <img src={Logo} alt="Logo" /> */}
      <Title>Check your email for a code.</Title>
      <SubTitle>
        We have sent a 6 - digit code to your email address. <br /> The code
        expires shortly, please enter it soon.
      </SubTitle>

      <OtpContainer>
        {" "}
        <OTPInput
          autoFocus
          isNumberInput
          length={6}
          hasError={error}
          errorStyle={{ borderColor: "red" }}
          inputStyle={{
            border: "1px solid #828282",
            width: "74px",
            height: "78px",
            fontSize: "30px",
            color: "#000",
            fontWeight: "400",
            caretColor: "#000",
            textAlign: "center",
            borderRight: "0px",
          }}
          focusStyle={
            {
              //border: "1px solid #CFD3DB",
            }
          }
          firstIndexStyle={{
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderRight: "0px",
          }}
          lastIndexStyle={{
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            borderRight: "1px solid #828282",
            borderLeft: "1px solid #828282",
          }}
          className="otpContainer"
          inputClassName="otpInput"
          separator={
            <span
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "10%",
                fontSize: "50px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              -
            </span>
          }
          onChangeOTP={handleChange}
        />
      </OtpContainer>

      <OtpBtnContainer>
        <OtpBtn onClick={resendOtp}>
          Haven't gotten your OTP ?<AuxOtpText> Resend</AuxOtpText>
        </OtpBtn>
      </OtpBtnContainer>

      <Actions>
        <div>
          <img src={Gmail} alt="Gmail" />
          <p>Open Gmail.</p>
        </div>

        <div>
          <img src={Outlook} alt="Outlook" />
          <p>Open Outlook.</p>
        </div>
      </Actions>

      <Info>Can’t find your code? Check your spam folder.</Info>
      <Footer>
        <p>Privacy and terms</p> <p>Contact us</p>
      </Footer>
    </ReportBody>
  );
};

export default ReceiveOtp;
