import React from "react";
import Layout from "Layout";
import {
  Container,
  TableActions,
  TableContainer,
  ViewAllBtn,
} from "Kyc/Styles";
import { useHistory } from "react-router-dom";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import Table from "shared/clientcomponents/Table";

type PropT = {
  [x: string]: any;
};
export default function AllKyc() {
  const history = useHistory();
  const businessData = [
    {
      id: 1,
      businessID: 653429,
      merchantName: "Portevest Ltd",
      status: "COMPLETED",
    },

    {
      id: 2,
      businessID: 293228,
      merchantName: "ABC Company",
      status: "COMPLETED",
    },
  ];
  const kycTableHeader = [
    { id: "sn", label: "S/N", width: "10%" },
    { id: "businessID", label: "Business ID", width: "24%" },
    { id: "merchantName", label: "Merchant Name", width: "40%" },
    { id: "status", label: "Status", width: "20%" },
    { id: "functionProp", label: "", width: "20%" },
  ];

  const kycList = businessData.map((business: any) => ({
    sn: business.id,
    businessID: business.businessID,
    merchantName: business.merchantName,
    status: business.status,
    functionProp: (
      <TableActions>
        <ViewAllBtn onClick={() => ""}>View</ViewAllBtn>
      </TableActions>
    ),
  }));

  const hoverLink = (elm: PropT) => {
    history.push(`/kyc/overview/${elm?.sn}`);
  };

  return (
    <>
      <Container>
        <TableContainer>
          <DashboardSubHeader
            count={kycList.length}
            title={"KYC List"}
            btnTitle=""
            withSearch={true}
            placeholder="Business ID"
            withFilter={false}
            widthActionBtn={false}
            filterOptions={["Name", "Date"]}
            setSearchData={() => ""}
            callBackFn={() => ""}
          />
          <Table
            headData={kycTableHeader}
            bodyData={kycList}
            rowStyle={{ background: "#ffffff" }}
            hoverLink={hoverLink}
          />
        </TableContainer>
      </Container>
    </>
  );
}
