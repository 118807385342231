import React, { useEffect, useState } from "react";
import Table from "shared/clientcomponents/Table";
import { Icon, Input } from "shared/components";
import {
  Btn,
  TopBtnsContainer,
  IndicatorBtn,
  CardsCont,
  Card,
  CardIcon,
  TableCont,
  ViewBtn,
  HeaderBtnContainer,
  FilterBtn,
  DownloadBtn,
  CardHeader,
} from "./Styles";
import SettleIcon from "../../App/assets/icons/settle.png";
import { useHistory } from "react-router-dom";
import { numberFormat, numFormatter } from "shared/utils/utility";
import settlementOBJ from "../settlementClass";
import { GradientLoader } from "shared/components";
import EmptyState from "shared/components/EmptyState";
import DashboardSubHeader from "shared/components/DashboardSubHeader";

import {
  CloseFilter,
  FilterTitle,
  PopContent,
  PopUp,
  PopUpHead,
} from "shared/components/DashboardSubHeader/Styles";
import SettlementFilter from "shared/components/Filters/SettlementFilter";

type PropT = {
  [x: string]: any;
};

export default function Home() {
  const [btnName, setbtnName] = useState("Today");
  const [IndicatorLeft, setIndicatorLeft] = useState<any>("0");
  const [IndicatorRignt, setIndicatorRignt] = useState<any>("");
  const [midCardValue, setmidCardValue] = useState<any>(0);
  const [settlementsData, setsettlementsData] = useState<any>([]);

  const [settlements, setsettlements] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [isRecord, setisRecord] = useState(false);
  const [isFilterOpen, setisFilterOpen] = useState(false);

  const history = useHistory();
  const TableHeader = [
    // { id: "settlementID", label: "Settlement ID", width: "14%" },
    { id: "amount", label: "Amount", width: "10%" },
    { id: "serviceId", label: "Service ID", width: "10%" },
    { id: "businessName", label: "Business Name", width: "50%" },
    { id: "time", label: "Date/Time", width: "10%" },
    { id: "accountFunctionProp", label: "", width: "2%" },
    { id: "transactionFunctionProp", label: "", width: "2%" },
  ];

  // const settlementList = settlements.map((obj: any, index: number) => ({
  //   Id: obj?.id,
  //   sn: ++index,
  //   amount: `${numberFormat(obj?.amount)}`,
  //   serviceId: obj?.Service?.serviceId,
  //   businessName: obj?.Service?.business?.businessName,
  //   time: obj?.createdAt,
  // }));

  const handleFilter = (filterObj: any) => {
    console.log("settlement filter obj==>", filterObj);
  };

  const hoverLink = (elm: PropT) => {
    history.push(`/settlements/single/${elm?.Id}`);
  };

  const getSettlements = async () => {
    const response = await settlementOBJ.getSettlements();
    //console.log(response?.payload?.data)
    if (response?.status && response?.payload?.data.length) {
      console.log("settlements", response?.payload);
      setisRecord(true);
      setisloading(false);
      setsettlements(response?.payload);
      const settlementList = response?.payload?.data.map(
        (obj: any, index: number) => ({
          Id: obj?.id,
          sn: ++index,
          amount: `${numberFormat(obj?.amount)}`,
          serviceId: obj?.serviceId,
          businessName: obj?.businessName,
          time: obj?.updatedAt,
          accountFunctionProp: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.6rem",
                cursor: "pointer",
                color: "green",
              }}
              onClick={() => {
                history.push(`/settlements/details/${obj?.id}/account-details`);
              }}
            >
              View accounts
            </div>
          ),

          transactionFunctionProp: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.6rem",
                cursor: "pointer",
                color: "blue",
              }}
              onClick={() => {
                history.push(`/settlements/details/${obj?.id}/transactions`);
              }}
            >
              View transactions
            </div>
          ),
        })
      );

      setsettlementsData(settlementList);
    } else {
      setisRecord(false);

      setisloading(false);
    }
  };

  useEffect(() => {
    getSettlements();
  }, []);

  return (
    <div>
      <>
        <HeaderBtnContainer>
          <TopBtnsContainer>
            {/* <Myindicator>hello</Myindicator> */}
            {/* <IndicatorBtn left={IndicatorLeft} right={""}>
          {btnName}
        </IndicatorBtn> */}
            <Btn
              isClicked={btnName === "Today" ? true : false}
              onClick={() => {
                setbtnName("Today");
                setIndicatorLeft(0);
              }}
            >
              Today
            </Btn>
            <Btn
              isClicked={btnName === "This Week" ? true : false}
              onClick={() => {
                setbtnName("This Week");
                setIndicatorLeft("8");
              }}
            >
              This Week
            </Btn>
            <Btn
              isClicked={btnName === "This Month" ? true : false}
              onClick={() => {
                setbtnName("This Month");
                setIndicatorLeft("18");
              }}
            >
              This Month
            </Btn>
            <Btn
              isClicked={btnName === "Custom" ? true : false}
              onClick={() => {
                setbtnName("Custom");
                setIndicatorLeft("26.25");
              }}
            >
              Custom
            </Btn>
          </TopBtnsContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginTop: "-2.7rem",
            }}
          >
            {btnName === "Custom" && (
              <FilterBtn onClick={() => setisFilterOpen(!isFilterOpen)}>
                Filter
              </FilterBtn>
            )}
            <DownloadBtn>Download</DownloadBtn>
          </div>
        </HeaderBtnContainer>
        {isFilterOpen && (
          <div
            style={{
              marginTop: "-2rem",
              position: "absolute",
              right: "-2rem",
            }}
          >
            <PopUp>
              <PopUpHead>
                <FilterTitle>Filter</FilterTitle>

                <CloseFilter onClick={() => setisFilterOpen(!isFilterOpen)}>
                  <Icon type={"close"} />
                </CloseFilter>
              </PopUpHead>
              <PopContent>
                <SettlementFilter
                  onProcessFilter={(obj: any) => handleFilter(obj)}
                />
              </PopContent>
            </PopUp>
          </div>
        )}
      </>

      <CardsCont>
        <Card>
          <CardHeader>
            <span>{btnName}</span>
          </CardHeader>
          <CardIcon src={SettleIcon} />
          <p>Total Settlement</p>
          <h2>₦{numFormatter(0)}</h2>
        </Card>
        <Card>
          <CardHeader>
            <span>{btnName}</span>
          </CardHeader>
          <CardIcon src={SettleIcon} />
          {/* <p>{btnName !== "Custom" ? btnName + " Settlement" : "Custom"}</p> */}
          <p>Success</p>

          <h2>₦{numFormatter(midCardValue)}</h2>
        </Card>

        <Card>
          <CardHeader>
            <span>{btnName}</span>
          </CardHeader>
          <CardIcon src={SettleIcon} />
          <p>Pending</p>
          <h2>₦{numFormatter(0)}</h2>
        </Card>
      </CardsCont>

      <div style={{ marginTop: "2rem" }}></div>

      <TableCont>
        {isloading ? (
          <GradientLoader />
        ) : (
          <>
            {isRecord ? (
              <Table
                headData={TableHeader}
                bodyData={settlementsData}
                rowStyle={{ background: "#ffffff" }}
                // hoverLink={hoverLink}
              />
            ) : (
              <EmptyState
                stateTitle="No Settlement record"
                statePara=""
                stateBtnText=""
              />
            )}
          </>
        )}
      </TableCont>
    </div>
  );
}
