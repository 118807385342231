import React from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import useApi from "shared/hooks/api";

import { PageLoader, PageError } from "shared/components";
import { useDispatch, useSelector } from "react-redux";

import {
  setBusinessLists,
  setCurrentBusiness,
} from "store/actions/businessAction";
import { setUser } from "store/actions/userAction";

import Layout from "Layout";
import History from "./History";
import { TransactionPage } from "./Styles";

const Transactions = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  // dispatch(setUsers());

  return (
    <Layout>
      <TransactionPage>
        <Route path={`${match.path}`} render={() => <History />} />

        <Route path={`${match.path}/customers`} render={() => <History />} />

        {/* {match.isExact && <Redirect to={`${match.url}/lists`} />} */}
      </TransactionPage>
    </Layout>
  );
};

export default Transactions;
