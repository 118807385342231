import Filter from "App/assets/images/services/filter.svg";
import { ReactNode, useEffect, useState } from "react";
import ExcelExport from "shared/components/ExcelExport";
import { Icon, Input } from "shared/components";
import {
  ActionButton,
  FilterButton,
  Header,
  LeftPane,
  Title,
  PopUpCont,
  PopUp,
  PopUpItem,
  PopUpHead,
  FilterTitle,
  CloseFilter,
  PopContent,
  PopUpFooter,
  ResetButton,
} from "./Styles";
import { SubaccountFilter, TransactionFilter } from "../Filters";

type PropT = {
  count?: number;
  title: string | ReactNode;
  btnTitle: string;
  withSearch?: boolean;
  withFilter?: boolean;
  callBackFn?: any;
  setSearchData?: any;
  widthActionBtn?: boolean;
  filterOptions?: any;
  filterType?: any;
  padHorizontalOff?: boolean;
  placeholder?: string;
  excelExport?: boolean;
  excelExportData?: Array<object>;
  searchContent?: string;
};

const DashboardSubHeader = ({
  count,
  title,
  btnTitle,
  withSearch,
  withFilter,
  widthActionBtn,
  callBackFn,
  setSearchData,
  filterOptions,
  filterType,
  placeholder,
  excelExport,
  excelExportData = [],
  searchContent = "",
}: PropT) => {
  const [filterParam, setFilterPara] = useState("");
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterPayload, setFIlterPayload] = useState<any>({});

  const onFilterProcess = () => {
    callBackFn && callBackFn();
  };

  const resetFilterParams = () => {
    setStartDate && setStartDate(""); // remove later
    setEndDate && setEndDate(""); // remove later

    callBackFn && callBackFn({});

    setOpenPopUp(false);
  };

  const renderFilterContent = () => {
    switch (filterType) {
      case "transactions":
        return (
          <TransactionFilter onProcessFilter={(obj: any) => callBackFn(obj)} />
        );

      case "subaccount":
        return <SubaccountFilter onProcessFilter={callBackFn} />;
    }
  };

  useEffect(() => {
    const date = new Date();
    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentYear = date.getFullYear();
    // we will display the date as DD-MM-YYYY
    let currentDate = `${currentDay}/${currentMonth}/${currentYear}`;
    setFIlterPayload({
      start: currentDate,
      end: currentDate,
      search: "",
      successful: true,
      failed: true,
      terminated: true,
      card: true,
      qrpay: true,
      internetBanking: true,
      directDebit: true,
    });
    onFilterProcess();
  }, []);

  return (
    <Header>
      <Title>
        {count} {title}
      </Title>

      <LeftPane>
        {/* <Input
          type="text"
          name=""
          placeholder="Search"
          onChange={setSearchData}
        /> */}

        {withSearch && (
          <Input
            type="text"
            value={searchContent}
            placeholder={placeholder ? placeholder : "Search"}
            onChange={setSearchData}
          />
        )}

        {/* With Filter */}
        {withFilter && (
          <PopUpCont>
            <FilterButton onClick={() => setOpenPopUp(!openPopUp)}>
              Filter <img src={Filter} alt="" />
            </FilterButton>

            {openPopUp && (
              <PopUp>
                <PopUpHead>
                  <FilterTitle>Filter</FilterTitle>

                  <CloseFilter onClick={() => setOpenPopUp(false)}>
                    <Icon type={"close"} />
                  </CloseFilter>
                </PopUpHead>
                <PopContent>{renderFilterContent()}</PopContent>

                {filterType !== "transactions" && (
                  <PopUpFooter>
                    <ResetButton onClick={resetFilterParams}>Reset</ResetButton>
                    <ActionButton
                      onClick={() => {
                        onFilterProcess();
                        setOpenPopUp(false);
                      }}
                    >
                      Apply
                    </ActionButton>
                  </PopUpFooter>
                )}
              </PopUp>
            )}
          </PopUpCont>
        )}

        {/* <FilterButton>
          Filiter <img src={Filter} alt="" />
        </FilterButton> */}
        {excelExport ? (
          <ExcelExport data={excelExportData}>
            <ActionButton onClick={() => {}} type="button">
              {btnTitle}
            </ActionButton>
          </ExcelExport>
        ) : (
          widthActionBtn && (
            <ActionButton onClick={callBackFn}>{btnTitle}</ActionButton>
          )
        )}
      </LeftPane>
    </Header>
  );
};

export default DashboardSubHeader;
