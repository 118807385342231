import styled from "styled-components";
import Input from "../../../shared/components/Input/index";
import { InputElement } from "shared/components/Input/Styles";
import { color } from "../../../shared/utils/styles";
import { mixin, font } from "../../../shared/utils/styles";

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.5rem;
  padding-left: 0.6rem;
  background-color: ${color.backgroundLight};
`;

export const SearchInputContainer = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (min-width: 768px) {
    width: 80%;
  }
`;

export const IconContainer = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SearchInput = styled.input`
  width: 90%;
  padding: 0.5rem 0.4rem;
  background-color: ${color.backgroundLight};
  outline: none;
  outline-style: none;
  border: none;

  @media only screen and (min-width: 768px) {
    width: 100%;
    padding: 1rem 0.4rem;
    background-color: ${color.backgroundLight};
    outline: none;
    outline-style: none;
    border: none;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  background-color: ${color.fountain};
  color: #fff;
  width: 50%;
  font-size: 12px;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    background-color: ${color.fountain};
    color: #fff;
    width: 20%;
    font-size: 14px;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    cursor: pointer;
  }
`;

export const SearchDropdown = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.fountain};
  color: #fff;
  max-width: 28%;
  cursor: pointer;
`;

export const SelectList = styled.select`
  background-color: transparent;
  color: white;
  border: none;
  &::selection {
    -webkit-appearance: none;
    appearance: none;
  }
`;
