import { Button } from "shared/components";
import BreakPoints from "shared/utils/Breakpoint";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  align-items: center;
  height: 74px;
  padding: 10px;
  justify-content: space-between;
  margin-bottom: 15px;
  transition: ease-in-out 0.3s;
  overflow: hidden;

  @media (${BreakPoints.xs}) {
    padding: 0.4rem 0;
    height: 50px;
    border: 0.5px solid #d8dadd;
    /* flex-direction: column; */
  }
`;

export const Title = styled.p`
  margin-left: 1em;
  @media (${BreakPoints.xs}) {
    text-align: left;
    margin-bottom: 0px;
    font-size: 0.8rem;
    font-weight: 600;
    width: 50%;
  }
`;

export const ActionButton = styled(Button)`
  background-color: ${color.fountain};
  color: #fff;
  padding: 0 2em;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const LeftPane = styled.div`
  display: flex;
  align-items: center;
  // flex-direction: column;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    flex-direction: column;
  }
`;

export const PopUpCont = styled.div`
  transition: ease-in-out 0.5s;
  /* position: relative; */
  /* position: absolute; */
  right: 250px;

  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const PopUp = styled.div`
  position: absolute !important;
  border: 2px solid #ddd;
  background-color: #fff;
  z-index: 9999999 !important;
  font-size: 14px;
  text-align: center;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  right: 4rem;
  width: 469px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const PopUpItem = styled.p`
  /* margin-bottom: 5px; */
  padding: 5px 10px;
  border-bottom: 1px solid #d7d7d7;
  &:hover {
    cursor: pointer;
  }
`;
export const FilterButton = styled(Button)`
  background-color: ${color.textDarkest};
  color: #fff;
  margin: 0 1em;
  padding: 0 2em;
  div {
    display: flex;
    align-items: center;
  }
  img {
    margin-left: 10px;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin: 0;
    margin-top: 5px;
  }
`;

export const PopUpHead = styled.div`
  width: 465px;
  height: 54px;
  background-color: #fad6d6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const PopUpSubHead = styled.div`
  width: 100%;
  height: 40px;
  background-color: #fbfbfb;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 30px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const PopContent = styled.div`
  margin: 5px 0px;
  height: 300px;
  overflow: auto;
`;
export const FilterTitle = styled.p`
  color: ${color.fountain};
  font-size: 16px;
  font-weight: 600;
`;

export const CloseFilter = styled.p`
  padding-top: 3px;
  background-color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const PopUpFooter = styled.div`
  background-color: #fff;
  width: 439px;
  border-top: 1px solid #d7d7d7;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 10% 10px 0px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    flex-direction: column;
  }
`;

export const ResetButton = styled(Button)`
  background-color: transparent;
  color: ${color.fountain};
  padding: 0 2em;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;
