import styled from "styled-components";
import { color } from "../../../shared/utils/styles";

type ContentPropT = {
  alignEnd?: boolean;
  width?: string;
};

export const Section = styled.section`
  margin-top: 1rem;
  margin-bottom: 5rem;
  background: #fff;
  padding: 2.8rem 2rem 4rem 2rem;
  border-radius: 1rem;
  /* height: 50rem; */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

export const TableContainer = styled.div`
  width: 100%;
  border: 1px solid ${color.fountainLight};
  padding-bottom: 2rem;
  overflow-y: auto;
  height: 40rem;
`;

export const TableHeader = styled.div`
  display: flex;
  background-color: ${color.fountainLight};
  color: ${color.fountain};
  justify-content: space-between;
  padding: 0 1rem;
  gap: 2rem;
`;

export const TableRow = styled.div`
  display: flex;
  background-color: #fff;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  border: 1px solid ${color.fountainLight};
`;
export const TableContent = styled.div<ContentPropT>`
  width: ${(prop) => (prop.width ? prop.width : "24%")};
  padding: 0.8rem 0;
  display: flex;
  justify-content: ${(prop) => (prop.alignEnd ? "end" : "start")};
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #760000;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const ViewBtn = styled.button`
  padding: 0.5rem 1rem;
  color: white;
  background-color: ${color.fountain};
  border-radius: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const UserModal = styled.div`
  padding: 45px 35px;
`;

export const ModalContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const ModalContentLeft = styled.div`
  width: 30%;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`;

export const ModalContentRight = styled.div`
  width: 70%;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
`;
