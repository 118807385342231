import styled, { css } from "styled-components";
type Props={
  border?: boolean;
}
export const Container = styled.div`
  width: 100%;
  margin-top: 2rem;
`;
export const TableHeader = styled.div`
  width: 100%;
  padding: 0.88rem;
  margin-top: 5rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableHeaderCol = styled.div`
  width: 20%;
`;

export const TableHeaderActionCol = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2rem;
`;

export const CalendarContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 4rem;
  ${props=>props.border && css`
    border: 0.56px solid #333;
  `}
  padding: 0.66rem;
  width: 50%;
  font-size: 14px;
  input {
    border: none;
  }
`;

export const Calendar = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const ActionBtn = styled.button`
  padding: 0.8rem 2rem;
  color: #fff;
  background: #0e314c;
  border-radius: 0.2rem;
  cursor: pointer;
`;

export const DownloadBtn = styled.button`
  padding: 0.8rem 2rem;
  color: #fff;
  background: #4d0000;
  border-radius: 0.2rem;
  cursor: pointer;
`;

export const TableContainer = styled.div`
  margin-top: 2rem;
`;
