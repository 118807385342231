import { color } from "./../../../shared/utils/styles";
import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";

export const ParentContainer = styled.div`
  width: 100%;
  // border: 1px solid greem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${breakpoints.device.max.xxl} {
    flex-direction: column;
  }
`;

export const FirstSectionLayout = styled.div`
  flex-direction: column;
  display: flex;
  // border: 1px solid red;
`;

export const SecondSectionLayout = styled.div`
  flex-direction: column;
  display: flex;
  // border: 1px solid green;
  justify-content: space-between;
`;

export const DonoutchartContainer = styled.div`
  width: 400px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media ${breakpoints.device.max.xxl} {
    width: 70%;
    margin-bottom: 40px;
  }
  @media ${breakpoints.device.max.xl} {
    width: 100%;
  }
  @media ${breakpoints.device.max.md} {
    margin-bottom: 40px;
  }
`;

export const Container = styled.div`
  width: 645px;
  // height: 388px;
  padding: 20px 0px;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  @media ${breakpoints.device.max.xxl} {
    width: 70%;
  }
  @media ${breakpoints.device.max.xl} {
    width: 100%;
  }
  @media ${breakpoints.device.max.md} {
    width: 100%;
  }
`;
type BoxheightPropT = {
  isToday?: boolean;
};

export const SectionContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ViewAllBtn = styled.span`
  margin-top: 10px;
  background-color: ${color.fountain};
  cursor: pointer;
  color: #fff;
  border-radius: 0.8rem;
  padding: 0.6rem 1rem;
  font-size: 12px;
`;

export const GraphContainer = styled.div`
  width: 90%;
  // padding: 1.8rem 1rem;
  // border-radius: 1rem;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  // align-items: center;
`;

export const GraphHeader = styled.div`
  display: flex;
  justify-content: end;
`;

export const GraphContent = styled.div`
  margin-top: 1.4rem;
`;

export const StatContainer = styled.div`
  width: 38%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const BoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.4rem;
  width: 48%;
`;

export const BoxBig = styled.div<BoxheightPropT>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(prop) => (prop.isToday ? "14rem" : "12rem")};
  font-size: 2rem;
  padding: 1rem 0;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const BoxSmall = styled(BoxBig)`
  width: 90%;
  height: 8rem;
  padding: 1rem 0;
`;

export const BoxTitle = styled.span`
  margin-top: 1rem;
  padding-top: 0.2rem;
  font-size: 0.95rem;
`;

export const BoxValue = styled.span`
  display: block;
  margin-top: 1.6rem;
  padding-bottom: 0.4rem;
  font-weight: 900;
`;

export const TableContainer = styled.div`
  overflow-y: auto;
  width: 90%;
  min-height: 12rem;
  max-height: 20rem;
  padding: 0.5rem;
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #760000;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #760010;
  }
`;
