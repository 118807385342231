import React from "react";
import {
  CloseSpan,
  ModalCloseBtn,
  ModalContainer,
  ModalContent,
  ModalMain,
  ModalMainHeader,
} from "./Styles";

type modalPropT = {
  children?: React.ReactNode;
  width?: string;
  isOpen: boolean;
  onClose: Function;
  closeButton?: boolean;
  marginTop?: string;
};

export default function Modal({
  children,
  width,
  isOpen,
  onClose,
  closeButton,
  marginTop,
}: modalPropT) {
  return (
    <ModalContainer visibile={isOpen}>
      <ModalMain width={width} marginTop={marginTop}>
        <ModalMainHeader>
          {closeButton && (
            <ModalCloseBtn onClick={() => onClose()}>
              <CloseSpan></CloseSpan>
            </ModalCloseBtn>
          )}
        </ModalMainHeader>
        <ModalContent>{children}</ModalContent>
      </ModalMain>
    </ModalContainer>
  );
}
