import React from "react";
import styled from "styled-components";
import { color } from "../../utils/styles";

type StatusBtnT = {
  mode?: string;
};

const StatusButton = styled.span<StatusBtnT>`
  background: ${(prop) => prop.mode === "TRANSPARENT" && 'transparent'};
  background: ${(prop) => prop.mode === "PENDING" && color.statusPending};
  background: ${(prop) => prop.mode === "APPROVED" && color.statusGreen};
  background: ${(prop) => prop.mode === "ACTIVE" && color.statusGreen};
  background: ${(prop) => prop.mode === "REJECTED" && "#FC4A51"};
  color: ${(prop) => prop.mode === "TRANSPARENT" && "#000"};
  color: ${(prop) => prop.mode === "PENDING" && "#000"};
  color: ${(prop) => prop.mode === "ACTIVE" && "#fff"};
  color: ${(prop) => prop.mode === "APPROVED" && "#fff"};
  color: ${(prop) => prop.mode === "REJECTED" && "#fff"};
  display: block;
  padding: 0.4rem;
  border-radius: 4rem;
  font-size: 10px;
  font-weight: 600;
  width: 4rem;
  text-align: center;
`;

export default function StatusBadge({ title, mode }: any) {
  return <StatusButton mode={mode}>{title ?? "Not Provided"}</StatusButton>;
}
