import React from "react";
import "./Style.css";

export default function index() {
  return (
    <div className="load">
      <div className="ring"></div>
    </div>
  );
}
