import React, { useState } from "react";
import { BtnsContainer, BusinessForm, FormBtn, FormTitle } from "./Styles";
import InputField from "./InputField";
import BackBtn from "./BackBtn";

type PropT = {
  setcurrentStep: Function;
  setdoneSteps: Function;
  doneSteps: any[];
  currentStep: number;
};
export default function StepFourComp({
  setcurrentStep,
  setdoneSteps,
  doneSteps,
  currentStep,
}: PropT) {
  const [merchantEmail, setmerchantEmail] = useState("");

  const handleStepFour = (event: any) => {
    event.preventDefault();
    setcurrentStep(5);
    setdoneSteps([...doneSteps, 5]);
  };
  return (
    <div>
      <FormTitle>Enter Merchant Email</FormTitle>
      <BusinessForm onSubmit={handleStepFour}>
        <InputField
          value={merchantEmail}
          label="Email Address"
          inputType="email"
          handleChange={(value: any) => setmerchantEmail(value)}
        />

        <BtnsContainer>
          <BackBtn
            setcurrentStep={setcurrentStep}
            setdoneSteps={setdoneSteps}
            currentStep={currentStep}
            doneSteps={doneSteps}
          />
          <FormBtn disabled={false} type="submit">
            Save and Continue
          </FormBtn>
        </BtnsContainer>
      </BusinessForm>
    </div>
  );
}
