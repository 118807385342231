import React from "react";
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
  TableContent,
  TableRow,
} from "./Styles";

type ServiceRowProp = {
  Service: {
    id: number;
    ServiceName: string;
    AccountNumber: string;
    BankName: string;
    activated: boolean;
  };
  toogleswitch: Function;
};

export default function ServiceRow({ Service, toogleswitch }: ServiceRowProp) {
  return (
    <>
      <TableRow key={Service.id}>
        <TableContent>{Service.ServiceName}</TableContent>
        <TableContent width="25%">{Service.AccountNumber}</TableContent>
        <TableContent>{Service.BankName}</TableContent>
        <TableContent width="12%" alignEnd={true}>
          <CheckBoxWrapper>
            <CheckBox
              id="checkbox"
              type="checkbox"
              checked={Service.activated}
              onChange={() => console.log("")}
            />
            <CheckBoxLabel
              htmlFor="checkbox"
              onClick={() => toogleswitch(Service.id)}
            />
          </CheckBoxWrapper>
        </TableContent>
      </TableRow>
    </>
  );
}
