import { useState } from "react";
import Filter from "shared/components/Filter";
import { fieldSorter } from "shared/utils/url";
import AccountRow from "./AccountRow";
import { Section, TableContainer, TableContent, TableHeader } from "./Styles";

type usersPropT = {
  id: number;
  activityLog: string;
  description: string;
  agent: string;
  time: String;
};

const data = [
  {
    id: 1,
    activityLog: "Payment",
    description:
      "Sends money the sum of N200,000 to tiamiyu for the goods i purchased from himSends money the sum of N200,000 to tiamiyu for the goods i purchased from him",
    agent: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 2,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    agent: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 3,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    agent: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 4,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    agent: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 5,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    agent: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 6,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    agent: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
  {
    id: 7,
    activityLog: "Payment",
    description: "Sends Tiamiyu N200,879",
    agent: "Tade Ogunsowo",
    time: "11:00 PM 20th JUNE 2022",
  },
];

const filterOptions = [
  {
    name: "activityLog",
    label: "Activity",
    checked: false,
  },
  {
    name: "description",
    label: "Description",
    checked: false,
  },
  {
    name: "agent",
    label: "Agent",
    checked: false,
  },
  {
    name: "time",
    label: "Time",
    checked: false,
  },
];

export default function TablesSection() {
  const [filterBy, setFilterBy] = useState(filterOptions);
  const [users, setUsers] = useState(data.slice(0, 7));

  const UserList = users.map((user) => (
    <AccountRow key={user.id} User={user} />
  ));

  const handleFilter = (data: any) => {
    const filterData = data.filter((option: any) => option.checked);
    const sortDataBy = filterData.map((option: any) => option.name);
    const unSortDataBy = filterOptions.map((option: any) => option.name);
    const newDisputeData =
      sortDataBy.length > 0
        ? users.sort(fieldSorter(sortDataBy))
        : users.sort(fieldSorter(unSortDataBy));
    setUsers(newDisputeData);
    setFilterBy(data);
  };

  return (
    <>
      <Filter
        onChange={handleFilter}
        options={filterBy}
        title="System Status"
      />
      <Section>
        <TableContainer>
          <TableHeader>
            <TableContent>Activity Log</TableContent>
            <TableContent>Activity Description</TableContent>
            <TableContent>Agent</TableContent>
            <TableContent>Time/Date</TableContent>
            <TableContent>Action</TableContent>
          </TableHeader>
          {UserList}
        </TableContainer>
      </Section>
    </>
  );
}
