import styled from "styled-components";
import { color } from "shared/utils/styles";
import Button from "shared/components/Button/index";
import Input from "shared/components/Input/index";

interface PropT {
  url?: string;
}

type loginButtonPropT = {
  isActive: boolean;
};
export const LoginPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: ${color.backgroundLight};
  }
`;

//=============== First part =============
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1.8rem 1rem;
  background-color: #fff;
  height: 100vh;

  @media only screen and (min-width: 768px) {
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 0rem 4rem 5rem 4rem;
    background-color: #fff;
  }
`;

export const FormContainer = styled.div`
  margin-top: 2rem;

  /* overflow-y: scroll; */
  width: 100%;
  /* min-height: 4rem; */
  padding: 0.2rem;

  @media only screen and (min-width: 768px) {
    margin-top: 5rem;
    margin-bottom: 4rem;
    width: 100%;
    /* min-height: 4rem; */
    padding: 0rem 0.2rem 2rem 0.2rem;
    /* overflow-y: scroll; */
    /* height: 100%; */
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const LogoImage = styled.img`
  width: 40%;
  height: auto;

  @media only screen and (min-width: 768px) {
    width: 50%;
    height: auto;
  }
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 0rem;
  width: 100%;
`;

export const WelcomeText = styled.h2`
  margin-top: 3.8rem;
  color: ${color.fountain};
`;

export const Theform = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4.4rem;
  width: 90%;
`;

export const LoginPageSpan = styled.span`
  color: #078ff8;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 1.2rem;
  position: relative;
`;

export const InputLabel = styled.span`
  display: block;
  margin-bottom: 0.4rem;
`;

export const LoginInput = styled(Input)`
  width: 100%;
`;

export const LoginButton = styled.button<loginButtonPropT>`
  color: white;
  background-color: ${(prop) =>
    prop.isActive ? color.fountain : color.backgroundMedium};
  width: 100%;
  /* padding: 1.2rem; */
  height: 55px;
  border-radius: 0.4rem;
  cursor: ${(prop) => (prop.isActive ? "pointer" : "none")};
  /* pointer-events: ${(prop) => !prop.isActive && "none"}; */
  &:hover {
    background-color: ${(prop) =>
      prop.isActive ? color.fountainAccent : color.backgroundMedium};
  }
`;

export const ErrorContainer = styled.div`
  margin-top: 1.4rem;
  height: auto;
`;

export const ErrorText = styled.span`
  color: ${color.danger};
  font-size: 16px;
`;
// =======================================

//============== second part =============

export const BannerContainer = styled.div<PropT>`
  width: 50%;
  min-height: 4rem;
  display: block;
  @media (max-width: 390px) {
    display: none;
  }
  @media (min-width: 1020px) {
    display: block;
    background-image: url(${(props) => props.url});
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const LoginBanner = styled.img`
  width: 100%;
  height: 100%;
`;

export const PasswordResetContainer = styled.div`
  padding: 0 1rem 2rem 1rem;
  width: 100%;
`;

export const ReguestSubtitle = styled.p`
  color: ${color.textDark};
  margin-top: 0.4rem;
  width: 80%;
  font-size: 14px;
`;
export const PwdResetForm = styled.form`
  width: 100%;
  margin-top: 2rem;
`;

export const ResetEmailInput = styled.input`
  width: 100%;
  padding: 1rem 0.4rem;
  border: none;
  background: ${color.backgroundLight};
`;

export const ResetButton = styled.button`
  margin-top: 1rem;
  width: 100%;
  padding: 1rem 0.4rem;
  color: #fff;
  background: ${color.fountain};
  border-radius: 0.4rem;
`;

// ===================================
