import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { PageLayout } from "shared/section_components/Styles";
import { toast } from "react-toastify";
import {
  Box,
  BoxTitle,
  BoxValue,
  BusinesPageLayout,
  TopGrid,
  IconContainer,
  PageHeader,
  TopSection,
  DetailsContainer,
  InfoContainer,
  InfoGroup,
  StatusButton,
  Ribbon,
} from "./Styles";
import { getCurrentUser } from "shared/utils/authToken";
import Icon1 from "../../../App/assets/business page icons/icon1.svg";
import Icon2 from "../../../App/assets/business page icons/icon2.svg";
import Icon3 from "../../../App/assets/business page icons/icon3.svg";
import Icon4 from "../../../App/assets/business page icons/icon4.svg";
import Icon5 from "../../../App/assets/business page icons/icon5.svg";
import Icon6 from "../../../App/assets/business page icons/icon6.svg";
import Switch from "shared/components/Switch";
import ConfirmModal from "../../../shared/quickComponents/ConfirmModal";
import OtpModal from "../../../shared/quickComponents/OtpModal";
import businessOBJ from "Business/businessClass";
import StatusBadge from "shared/components/StatusBadge";

type PropT = {
  reloadData: Function;
  getData: Function;
  businessName: string;
  businessData: any;
  businessStatus: any;
  details: any;
};

export default function GridSection({
  getData,
  businessName,
  businessData,
  businessStatus,
  reloadData,
  details,
}: PropT) {
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isOtpModalOpen, setOtpModalOpen] = useState(false);
  const [activeBox, setactiveBox] = useState("Services");
  const [showIndicator, setshowIndicator] = useState(false);
  const [tempId, settempId] = useState("");
  const [otp, setOtp] = useState("");

  const currentUser = getCurrentUser();
  const { id: busId } = useParams<{
    id: string;
  }>();

  const boxData = [
    {
      id: 1,
      icon: Icon1,
      label: "Services",
      tabName: "services",
      value: 0,
      isActive: true,
    },
    {
      id: 2,
      icon: Icon2,
      label: "Transactions",
      tabName: "transactions",
      value: 0,
      isActive: false,
    },
    {
      id: 3,
      icon: Icon3,
      label: "Customers",
      tabName: "customers",
      value: 0,
      isActive: false,
    },
    {
      id: 4,
      icon: Icon4,
      label: "Total Settlement",
      tabName: "settlements",
      value: 0,
      isActive: false,
    },
    {
      id: 5,
      icon: Icon5,
      label: "Third Party Account",
      tabName: "thirdPartyAccounts",
      value: 0,
      isActive: false,
    },
    {
      id: 6,
      icon: Icon6,
      label: "Account Details",
      tabName: "accountDetails",
      value: 0,
      isActive: false,
    },
    {
      id: 7,
      icon: Icon6,
      label: "Dispute Created",
      tabName: "disputesCreated",
      value: 0,
      isActive: false,
    },
  ];

  const sendOTP = async () => {
    setshowIndicator(true);
    console.log("business status", businessStatus);
    const OTPpayload = {
      adminEmail: currentUser.email,
      businessId: busId,
      payload: {
        status: businessStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE",
      },
    };
    const response = await businessOBJ.sendOTP(OTPpayload);
    if (response?.status && response?.payload) {
      settempId(response?.payload?.tempId);
      setshowIndicator(false);
      toast.success(response?.message);
      setConfirmModalOpen(false);
      setOtpModalOpen(true);
      // console.log("otp response", response);
    } else {
      setConfirmModalOpen(false);
      toast.error(response?.message);
    }
  };

  const handleOTP = async (otp: string) => {
    setOtp(otp);
    if (otp.length === 6) {
      const OTPpayload = { otp, tempId, action: "UPDATE_BUSINESS_STATUS" };
      const response = await businessOBJ.confirmOTP(OTPpayload);
      if (response.status) {
        toast.success(response?.message);
        setOtpModalOpen(false);
        await reloadData();
      } else {
        toast.error(response?.message);
      }
    }
  };

  const boxSelected = (label: any) => {
    getData(label);
    setactiveBox(label);
  };

  const boxList = boxData.map((box) => (
    <Box
      key={box.id}
      isActive={activeBox === box.label ? true : false}
      onClick={() => {
        boxSelected(box.label);
      }}
    >
      <IconContainer isActive={activeBox === box.label ? true : false}>
        <img src={box.icon} alt="" />
      </IconContainer>
      <BoxTitle>{box.label}</BoxTitle>
      {/* Checking business data to get one that match the tabname */}
      <BoxValue>{businessData[box.tabName]}</BoxValue>
    </Box>
  ));

  // console.log("business stat", businessData);

  return (
    <BusinesPageLayout>
      <ConfirmModal
        message="Are sure you want to change this business status"
        handleYesModalButton={sendOTP}
        handleNoModalButton={() => setConfirmModalOpen(!isConfirmModalOpen)}
        isModalOpen={isConfirmModalOpen}
        showIndicator={showIndicator}
        handleCloseModal={() => setConfirmModalOpen(!isConfirmModalOpen)}
      />
      <OtpModal
        onChangeOTP={handleOTP}
        onClose={() => setOtpModalOpen(false)}
        isOpen={isOtpModalOpen}
      />
      <PageHeader>
        <h4>{businessName.toUpperCase()}</h4>
        <Switch
          id="0"
          status={businessStatus === "ACTIVE" ? true : false}
          label="Deactivate"
          toggleMethod={() => setConfirmModalOpen(true)}
        />
      </PageHeader>

      <DetailsContainer>
        <InfoContainer>
          <TopGrid>{boxList}</TopGrid>
        </InfoContainer>
        <InfoContainer isDetails={true}>
          <h3>Business Details</h3>

          <InfoGroup>
            <p>Business Name:</p>
            <p>{details?.businessName.toUpperCase()}</p>
          </InfoGroup>
          <InfoGroup>
            <p>Business ID:</p>
            <p>{details?.businessId}</p>
          </InfoGroup>
          <InfoGroup>
            <p>RC NO:</p>
            <p>{details?.rcNumber}</p>
          </InfoGroup>
          <InfoGroup>
            <p>Country:</p>
            <p>{details?.country}</p>
          </InfoGroup>

          <InfoGroup>
            <p>Phone:</p>
            <p>{details?.phone}</p>
          </InfoGroup>

          <InfoGroup>
            <p>Email:</p>
            <p>{details?.email}</p>
          </InfoGroup>

          <InfoGroup>
            <p>Industry:</p>
            <p>{details?.industryType}</p>
          </InfoGroup>
          <InfoGroup>
            <p>Business Address:</p>
            <p>{details?.address}</p>
          </InfoGroup>

          <InfoGroup>
            <p>Status</p>
            <p>
              <StatusBadge mode={details?.status} title={details?.status} />
            </p>
          </InfoGroup>
          <Ribbon mode={details?.status}></Ribbon>
        </InfoContainer>
      </DetailsContainer>

      {/*  */}
    </BusinesPageLayout>
  );
}
