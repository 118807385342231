import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { getCurrentUser } from "shared/utils/authToken";
import logger from "shared/utils/logger";

import Table from "shared/clientcomponents/Table";
import {
  Box,
  BoxGrid,
  BoxTitle,
  BoxValue,
  Container,
  IconContainer,
  KycThreshold,
  TableActions,
  TableContainer,
  ThreshBtn,
  VBtn,
  ViewAllBtn,
  ViewBtn,
} from "Kyc/Styles";
import Kycicon1 from "../../Assets/kycicon1.png";
import Kycicon2 from "../../Assets/kycicon2.png";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { useHistory } from "react-router-dom";
import { getClientDetails } from "shared/utils/location";
import data from "shared/utils/data";
import { object } from "prop-types";
import StatusBadge from "shared/components/StatusBadge";
import { useEffectOnce } from "shared/hooks/useEffectOnce";
import kycOBJ from "Kyc/kycClass";
import { GradientLoader } from "shared/components";
import EmptyState from "shared/components/EmptyState";
import useApi from "shared/hooks/api";
import consoleLog from "shared/utils/consoleLog";

type PropT = {
  [x: string]: any;
};

export default function index() {
  const [noRecord, setnoRecord] = useState(false);
  const [noValue, setnoValue] = useState(false);
  const [kycOviewData, setkycOviewData] = useState<any>({});
  const [kycData, setKycData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [fn, SetFn] = useState<any>(1);
  const [searchContent, setSearchContent] = useState<string | undefined>();
  const [isnoReecord, setisnoReecord] = useState(false);
  const [complianceKYC, setcomplianceKYC] = useState([]);
  const [completedKYC, setcompletedKYC] = useState([]);

  const [isLoading, setisLoading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");
  const [pinBox, setPinBox] = useState(0);

  const currentUser = getCurrentUser();
  const history = useHistory();
  // const [{ data, error, setLocalData }, fetchData] = useApi.get(
  //   `/kyc/total?type=GATEWAY&page=${currentPage}`
  // );

  const kycOverviewRef = useRef<any>(null);

  const kycTableHeader = [
    // { id: "sn", label: "S/N", width: "10%" },
    { id: "businessID", label: "Business ID", width: "15%" },
    { id: "merchantName", label: "Merchant Name", width: "25%" },
    { id: "email", label: "Email", width: "30%" },
    { id: "status", label: "First Level", width: "10%" },
    { id: "secondLevel", label: "Second Level", width: "10%" },
    { id: "functionProp", label: "", width: "20%" },
  ];

  const kycList = filteredData.map((business: any, index: number) => ({
    // sn: ++index,
    businessID: business?.Business?.businessId,
    merchantName: `${business?.Business?.businessName ?? "Not provided"} `,
    email: business?.User?.email,
    status: <StatusBadge mode={business.status} title={business.status} />,
    secondLevel: (
      <div style={{ display: "flex", alignItems: "center", gap: "0.6rem" }}>
        <VBtn>{business?.verificationStatus}</VBtn>
      </div>
    ),
    functionProp: (
      <div style={{ display: "flex", alignItems: "center", gap: "0.6rem" }}>
        <ViewBtn
          onClick={() => {
            history.push(`/kyc/overview`, { data: business.uuid });
          }}
        >
          View
        </ViewBtn>
      </div>
    ),
    ID: business.id,
  }));

  // const hoverLink = (elm: PropT) => {
  //   history.push(`/kyc/overview/${elm?.ID}`);
  // };

  const handleLog = async () => {
    // consoleLog("logging on Kyc");
    await logger.logActivities("remote", {
      date: new Date().toString(),
      author: currentUser.fullName,
      resource: "admin",
      action: "MERCHANT",
      payload: {
        userAgent: getClientDetails(),
        data: {
          detail: `${currentUser.fullName} access the "Kyc" overview`,
        },
      },
    });
  };

  const getApproved = async (page = 1) => {
    setisLoading(true);
    try {
      const response = await kycOBJ.getKycView("GATEWAY", "APPROVED", page);
      // const response = await kycOBJ.getApprovedKyc("GATEWAY", page);
      console.log("compliance kyc ====>", response);
      if (response.status && response.payload) {
        // const compliKyc = response.payload?.data.filter(
        //   (kyc: any) => kyc?.verificationStatus === "APPROVED"
        // );

        // const completeKyc = response.payload?.data.filter(
        //   (kyc: any) => kyc?.verificationStatus === "PENDING"
        // );
        setKycData(response.payload?.data);
        // setcomplianceKYC(compliKyc);
        // setcompletedKYC(completeKyc);
        if (fn !== 1) setcurrentPage(1);
        settotalPages(response?.payload?.totalPages);
        SetFn(1);
        setisLoading(false);
        setnoRecord(false);
        handleLog();
      } else {
        setnoRecord(true);
      }
    } catch (error) {
      setnoRecord(true);
    }
  };

  // const getApproved = async (page = 1) => {
  //   setisLoading(true);
  //   try {
  //     const response = await kycOBJ.getApprovedKyc("GATEWAY", page);
  //     if (response.status && response.payload) {
  //       setisLoading(false);
  //       setnoRecord(false);
  //       setKycData(response.payload?.data);
  //       if (fn !== 1) setcurrentPage(1);
  //       settotalPages(response?.payload?.totalPages);
  //       SetFn(1);
  //       setisLoading(false);

  //       //create log after getting approved kyc
  //       handleLog();
  //     } else {
  //       setnoRecord(true);
  //     }
  //   } catch (error) {
  //     setnoRecord(true);
  //   }
  // };

  const getPending = async (page = 1) => {
    setisLoading(true);
    try {
      const response = await kycOBJ.getKycView("GATEWAY", "PENDING", page);
      // const response = await kycOBJ.getPendingKyc("GATEWAY", page);
      if (response.status && response.payload) {
        setisLoading(false);
        setKycData(response.payload?.data);

        consoleLog("kyc data ", response.payload?.data);
        if (fn !== 2) setcurrentPage(1);

        settotalPages(response?.payload?.totalPages);
        SetFn(2);

        setisLoading(false);
      } else setnoRecord(true);
    } catch (error) {
      setnoRecord(true);
    }
  };

  const getRejected = async (page = 1) => {
    setisLoading(true);
    try {
      const response = await kycOBJ.getKycView("GATEWAY", "REJECTED", page);

      // const response = await kycOBJ.getRejectedKyc("GATEWAY", page);
      if (response.status && response.payload) {
        setisLoading(false);
        setKycData(response.payload?.data);

        // console.log('kyc data ', response.payload?.data)
        // setcurrentPage(response?.payload?.currentPage);
        if (fn !== 3) setcurrentPage(1);

        SetFn(3);

        settotalPages(response?.payload?.totalPages);
        setisLoading(false);
      } else setnoRecord(true);
    } catch (error) {
      setnoRecord(true);
    }
  };

  const getIncomplete = async (page = 1) => {
    setisLoading(true);
    try {
      const response = await kycOBJ.getKycView("GATEWAY", "INCOMPLETE", page);

      // const response = await kycOBJ.getRejectedKyc("GATEWAY", page);
      if (response.status && response.payload) {
        setisLoading(false);
        setKycData(response.payload?.data);

        // console.log('kyc data ', response.payload?.data)
        // setcurrentPage(response?.payload?.currentPage);
        if (fn !== 4) setcurrentPage(1);

        SetFn(4);

        settotalPages(response?.payload?.totalPages);
        setisLoading(false);
      } else setnoRecord(true);
    } catch (error) {
      setnoRecord(true);
    }
  };

  const getKYCList = async (page: any) => {
    setisLoading(true);
    try {
      switch (fn) {
        case 1:
          await getApproved(page);
          return;
        case 2:
          await getPending(page);
          return;
        case 3:
          await getRejected(page);
          return;
        case 4:
          await getIncomplete(page);
          return;
        default:
          return;
      }
    } catch (error) {
      setnoRecord(true);
    }
  };

  const getKYCOverview = async () => {
    const response = await kycOBJ.overview();
    if (response?.status) {
      kycOverviewRef.current = response?.payload;
      consoleLog(response?.payload, "KYCC");
      setkycOviewData(response?.payload);
      getApproved();
      // getComplianceKyc();
    }
    filterDataFn();
  };

  // const changeCurrentPage = (value: number) => {
  //   setcurrentPage(value);
  //   alert(currentPage);
  //   fetchData();
  // };

  const filterDataFn = (e: any = undefined) => {
    let newArr =
      e && e.toString().trim() !== ""
        ? kycData.filter((el: any) =>
            el?.Business?.businessId
              .toString()
              .trim()
              .includes(e.trim())
          )
        : kycData;
    setFilteredData(newArr);
    setSearchContent(e);
  };

  useLayoutEffect(() => {
    getKYCOverview();
  }, []);

  useEffect(() => {
    filterDataFn();
  }, [kycData]);

  return (
    <Container>
      <KycThreshold>
        <h3>New threshold alert</h3>
        <ThreshBtn>View business</ThreshBtn>
      </KycThreshold>
      <BoxGrid>
        <Box
          key={1}
          isActive={pinBox === 0 ? true : false}
          onClick={() => {
            setPinBox(0);
            // setcurrentPage(1);
            // settotalPages(0);
            getApproved();
          }}
        >
          <IconContainer isActive={true}>
            <img src={Kycicon2} alt="" />
          </IconContainer>
          <BoxTitle>Verified KYC</BoxTitle>
          {/* <BoxValue>{complianceKYC.length}</BoxValue> */}
          <BoxValue>{kycOviewData?.approved}</BoxValue>
        </Box>

        <Box
          key={1}
          isActive={pinBox === 1 ? true : false}
          onClick={() => {
            setPinBox(1);
            // setcurrentPage(1);
            // settotalPages(0);
            // getApproved();
            // setKycData(completedKYC);
            getPending();
          }}
        >
          <IconContainer isActive={false}>
            <img src={Kycicon2} alt="" />
          </IconContainer>
          <BoxTitle>Pending Verification</BoxTitle>
          {/* <BoxValue>{completedKYC.length}</BoxValue> */}
          <BoxValue>{kycOviewData?.pending}</BoxValue>
        </Box>

        <Box
          key={2}
          isActive={pinBox === 2 ? true : false}
          onClick={() => {
            setPinBox(2);
            // setcurrentPage(1);
            // settotalPages(0);
            getIncomplete();
          }}
        >
          <IconContainer isActive={true}>
            <img src={Kycicon1} alt="" />
          </IconContainer>
          <BoxTitle>Incomplete KYC</BoxTitle>
          <BoxValue>{kycOviewData?.incomplete}</BoxValue>

          {/* <BoxValue>{kycOverviewRef.current?.pending}</BoxValue> */}
        </Box>

        <Box
          key={3}
          isActive={pinBox === 3 ? true : false}
          onClick={() => {
            setPinBox(3);
            // setcurrentPage(1);
            // settotalPages(0);
            getRejected();
          }}
        >
          <IconContainer isActive={true}>
            <img src={Kycicon1} alt="" />
          </IconContainer>
          <BoxTitle>Rejected KYC</BoxTitle>
          <BoxValue>{kycOviewData?.rejected}</BoxValue>
          {/* <BoxValue>{kycOverviewRef.current?.rejected}</BoxValue> */}
        </Box>
      </BoxGrid>
      <TableContainer>
        <DashboardSubHeader
          // count={0}
          title={"KYC LIST"}
          btnTitle=""
          withSearch={kycData?.length > 0}
          placeholder="Business ID"
          withFilter={false}
          widthActionBtn={false}
          filterOptions={["Name", "Date"]}
          setSearchData={filterDataFn}
          callBackFn={() => ""}
          searchContent={searchContent}
        />
        {isLoading ? (
          <GradientLoader />
        ) : (
          <>
            {noRecord || kycList?.length === 0 ? (
              <EmptyState stateTitle="No Record" statePara="" stateBtnText="" />
            ) : (
              <>
                {isLoading ? (
                  <GradientLoader />
                ) : (
                  <>
                    {noRecord ? (
                      <EmptyState
                        stateTitle="No Data"
                        statePara=""
                        stateBtnText=""
                      />
                    ) : (
                      <Table
                        headData={kycTableHeader}
                        bodyData={kycList}
                        rowStyle={{ background: "#ffffff" }}
                        totalPages={totalPages}
                        currentpage={currentPage}
                        goToPage={(value: any) => {
                          setcurrentPage(value);
                          getKYCList(value);
                        }}
                        // hoverLink={hoverLink}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </TableContainer>
    </Container>
  );
}
