import React, { useState, useEffect } from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import { color } from "../../../../shared/utils/styles";
import { Select, Option } from "shared/quickComponents/select";
import { Data } from "./Data";
import { GraphContainer, GraphContent, GraphHeader } from "./Styles";
import { GradientLoader } from "shared/components";

type ChartPropT = {
  id: number;
  xAxis: string;
  transaction: number;
  // userLost: number;
}[];

export default function LineChart({ data }: any) {
  if (Object.keys(data).length === 0) return null;
  const [chartData, setchartData] = useState<ChartPropT | null>([]);
  const [category, setcategory] = useState(0);
  //set chartData state so we can change the chart data anytime
  const [userData, setUserData] = useState<any>({ labels: [], datasets: [] });

  const fetchData = () => {
    try {
      let arrUse = ["weekArray", "monthArray", "yearArray"];
      let cat = category;
      if (data[arrUse[cat]] && data[arrUse[cat]] instanceof Array) {
        let toUseData = data[arrUse[cat]].map((el: any, id: number) => {
          let str = el.label;
          str = str[0].toUpperCase() + str.substring(1);
          return { id, xAxis: str, transaction: el.value };
        });
        setchartData(toUseData);
        setUserData({
          labels: toUseData?.map((el: any) => el.xAxis),
          datasets: [
            {
              label: "Total transactions",
              data: toUseData?.map((el: any) => el.transaction),
              backgroundColor: "#FFF8F8",
              borderColor: color.fountain,
              borderWidth: 6,
              pointBackgroundColor: "white",
              pointBorderColor: color.fountain,
              pointBorderWidth: 3,
              pointRadius: 8,
              tension: 0.4,
              fill: true,
            },
          ],
        });
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [category]);

  function kFormatter(num: any) {
    let exp = (Math.sign(num) * Math.abs(num)) / 1000;
    return Math.abs(num) > 999
      ? ` ${exp.toFixed(1)}k`
      : Math.sign(num) * Math.abs(num);
  }

  const options = {
    plugins: { legend: { display: false } },
    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          callback: function(value: any, index: any) {
            return kFormatter(value);
          },
        },
      },
    },
  };

  let arrOptions = [
    { label: "This week", value: 0 },
    { label: "This month", value: 1 },
    { label: "This year", value: 2 },
  ];
  return (
    <>
      <GraphHeader>
        <div style={{ width: "22%" }}>
          <Select
            value={arrOptions.find((el: any) => el.value === category)?.label}
            variant="outline"
            bgColor="#fff"
          >
            <Option variant="outline" onClick={() => setcategory(0)}>
              This week
            </Option>

            <Option variant="outline" onClick={() => setcategory(1)}>
              This month
            </Option>

            <Option variant="outline" onClick={() => setcategory(2)}>
              This year
            </Option>
          </Select>
        </div>
      </GraphHeader>
      <GraphContent>
        <Line key={Math.random()} data={userData} options={options} />
      </GraphContent>
    </>
  );
}

// import "chart.js/auto";
// import { useState } from "react";
// import { Line } from "react-chartjs-2";
// import { Option, Select } from "shared/quickComponents/select";
// import { SelectMainContainer } from "shared/quickComponents/select/Styles";
// import { color } from "../../../../shared/utils/styles";
// import { Data } from "./Data";
// import { GraphContent, GraphHeader } from "./Styles";

// type ChartPropT = {
//   id: number;
//   month: string;
//   transaction: number;
//   userLost: number;
// }[];

// export default function LineChart() {
//   const [chartData, setchartData] = useState<ChartPropT | null>(Data);
//   const [category, setcategory] = useState("This week");
//   //set chartData state so we can change the chart data anytime
//   const [userData, setUserData] = useState({
//     labels: chartData?.map((data) => data.month),
//     datasets: [
//       {
//         label: "Total transactions",
//         data: Data.map((data) => data.transaction),
//         backgroundColor: "#FFF8F8",
//         borderColor: color.fountain,
//         borderWidth: 6,
//         pointBackgroundColor: "white",
//         pointBorderColor: color.fountain,
//         pointBorderWidth: 3,
//         pointRadius: 8,
//         tension: 0.4,
//         fill: true,
//       },
//     ],
//   });

//   function kFormatter(num: any) {
//     let exp = (Math.sign(num) * Math.abs(num)) / 1000;
//     return Math.abs(num) > 999
//       ? ` ${exp.toFixed(1)}k`
//       : Math.sign(num) * Math.abs(num);
//   }

//   const options = {
//     plugins: { legend: { display: false } },
//     scales: {
//       y: {
//         beginAtZero: false,
//         ticks: {
//           callback: function(value: any, index: any) {
//             return kFormatter(value);
//           },
//         },
//       },
//     },
//   };
//   return (
//     <>
//       <GraphHeader>
//         <SelectMainContainer>
//           <Select value={category} variant="outline">
//             <Option variant="outline" onClick={() => setcategory("This week")}>
//               This week
//             </Option>

//             <Option variant="outline" onClick={() => setcategory("This month")}>
//               This month
//             </Option>

//             <Option variant="outline" onClick={() => setcategory("This year")}>
//               This year
//             </Option>
//           </Select>
//         </SelectMainContainer>
//       </GraphHeader>
//       <GraphContent>
//         <Line data={userData} options={options} />
//       </GraphContent>
//     </>
//   );
// }
