import { useLayoutEffect, useState } from "react";

// import { useAppDispatch, useAppSelector } from "AppStore/hooks";
import Layout from "Layout";
import { Redirect, Route, Router, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { GradientLoader, PageError } from "shared/components";
import { AuditTrailPage, LogDescription, LogItem, LogMap } from "./Styles";
import useApi from "shared/hooks/api";
import { formateDateString } from "shared/utils/utility";
import GoogleMapReact from "google-map-react";
import { Map, Marker } from "pigeon-maps"
import { osm } from 'pigeon-maps/providers'
import moment from "moment";


const AuditTrail = () => {
    const [isLoggedIn, setisLoggedIn] = useState(true);
    const [mapVisible, setMapVisible]=useState(false)

    const match = useRouteMatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [{ data, error, setLocalData }, fetchbusiness] = useApi.get(
        `/system-logs/${id}`
    );
    
    const logDetail=data?.payload;
    // console.log(logDetail)

    const MapMaker = (text: string) => <div>{text}</div>;
    const locations: any={lat:logDetail?.payload?.geolocation?.latitude || 50.879, long: logDetail?.payload?.geolocation?.longitude||4.6997};

    return (
        <Layout>
        {!data ? (
            <GradientLoader />
        ) : (
            <AuditTrailPage>
                <LogDescription>
                    <LogItem>
                        <div>Date: </div>
                        <div>{moment(logDetail.createdAt).format("LLLL")}</div>
                    </LogItem>
                    <LogItem>
                        <div>Action: </div>
                        <div>{logDetail.action}</div>
                    </LogItem>
                    <LogItem>
                        <div>Author: </div>
                        <div>{logDetail.author}</div>
                    </LogItem>
                    <LogItem>
                        <div>IP Address: </div>
                        <div>{logDetail?.payload?.ipAddress}</div>
                    </LogItem>
                    <LogItem>
                        <div>Machine: </div>
                        <div>{logDetail?.payload?.userAgent}</div>
                    </LogItem>
                    <LogItem>
                        <div>Details: </div>
                        <div>{logDetail?.payload?.data?.detail}</div>
                    </LogItem>
                </LogDescription>
                <LogMap isMapVisible={mapVisible}>
                    
                    <Map
                        provider={osm}
                        height={500}
                        width={1070}
                        defaultCenter={[locations.lat, locations.long]}
                        defaultZoom={11}
                    >
                        <Marker width={50} anchor={[locations.lat, locations.long]} />
                    </Map>
                    {/* {mapVisible && getMapContainer(logDetail?.payload?.geolocation)}
                    {!mapVisible && <button>Load Map</button>} */}
                </LogMap>
            </AuditTrailPage>
        )}
        </Layout>
    );
};

export default AuditTrail;
