import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  min-height: 300px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin: 10px;
  margin: auto;
`;

export const EsclamationImg = styled.img`
  height: 200px;
  width: 80%;
  margin-bottom: 15px;
`;

export const StateTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #292929;
  margin-bottom: 10px;
  text-align: center;
`;

export const StatePara = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #828282;
  margin-bottom: 5px;
  text-align: center;
`;

export const CreateSubAccBtn = styled.button`
  margin-top: 20px;
  background-color: #760000;
  border: none;
  outline: none;
  height: 54px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  width: 241px;
  cursor: pointer;
  border-radius: 5px;
`;
