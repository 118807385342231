import {
  ActionBtn,
  Calendar,
  CalendarContainer,
  TableHeader,
  TableHeaderActionCol,
  TableHeaderCol,
} from "Report/cbn/styles";
import { useEffect, useState } from "react";
import ExportExcel from "../ExportExcel";

type Props = {
  title: string;
  tableHeader: Object;
  reportData: Object;
  handleFilter: Function;
  setfromDate: Function;
  settoDate: Function;
};

const today = new Date();
today.setDate(1);
// let startDate: any=new Date(today.getFullYear(), today.getMonth() +1, 1)
let days = String(today.getDate()).padStart(2, "0");
let months = String(today.getMonth() + 1).padStart(2, "0");
let years = today.getFullYear();
today.setDate(1);
const startDate = `${years}-${months}-${days}`;

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const day = String(currentDate.getDate()).padStart(2, "0");
const formattedDate = `${year}-${month}-${day}`;

const Header = (props: Props) => {
  const [fromDate, setfromDate] = useState<any>(startDate);
  const [toDate, settoDate] = useState<any>(formattedDate);

  useEffect(() => {
    props.setfromDate(fromDate);
    props.settoDate(toDate);
  }, [fromDate, toDate]);

  return (
    <TableHeader>
      <TableHeaderCol>
        <h4>{props.title}</h4>
      </TableHeaderCol>

      <TableHeaderActionCol>
        <CalendarContainer border={true}>
          <Calendar>
            From:
            <input
              value={fromDate}
              type="date"
              min={"2022-08-24"}
              max={formattedDate}
              onChange={(e: any) => {
                // console.log(e.target.value);
                setfromDate(e.target.value);
              }}
            />
          </Calendar>

          <Calendar>
            To:
            <input
              value={toDate}
              max={formattedDate}
              type="date"
              onChange={(e: any) => settoDate(e.target.value)}
            />
          </Calendar>
        </CalendarContainer>
        <ActionBtn onClick={() => props.handleFilter()}>Apply</ActionBtn>
        <ExportExcel
          excelHeader={props.tableHeader}
          excelData={props.reportData}
          fileName={"export data"}
        />
      </TableHeaderActionCol>
    </TableHeader>
  );
};

export default Header;
