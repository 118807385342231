import styled from "styled-components";
import { color } from "../shared/utils/styles";
import Button from "../shared/components/Button/index";
import Input from "../shared/components/Input/index";

interface PropT {
  url?: string;
}
export const CPPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: ${color.backgroundLight};
`;

//=============== First part =============
export const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.4rem 4rem;
  background-color: #fff;
  padding: 40px 60px 60px 60px;
`;

export const FormContainer = styled.div`
  width: 100%;
  min-height: 4rem;
  padding: 4rem;
  padding-top:0rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const LogoImage = styled.img`
  width: 50%;
  height: auto;
`;

export const CPForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 0rem;
  width: 100%;
`;

export const WelcomeText = styled.h2`
  margin-top: 1.8rem;
  color: ${color.fountain};
`;

export const Theform = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.4rem;
  width: 90%;
`;

export const LoginPageSpan = styled.span`
  color: #078ff8;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 1.2rem;
`;

export const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
  background-color: ${color.backgroundMedium};
  border: 1px solid ${color.backgroundMedium};
  border-radius: 0.5rem;
`;

export const InputLabel = styled.span`
  display: block;
  margin-bottom: 0.88rem;
  color: ${color.fountain};
`;

export const CPInput = styled.input`
  width: 100%;
  border: none !important;
  outline-style: none;
  padding: 1rem;
  background-color: ${color.backgroundMedium};
`;

export const CPButton = styled.button`
  color: white;
  background-color: ${color.fountain};
  width: 100%;
  padding: 0.4rem 0.4;
  height: 2.98rem;
  border-radius: 0.4rem;
  font-weight: 800;
  &:hover {
    background-color: ${color.fountainAccent} !important;
    cursor: pointer;
  }
`;

export const IconImage = styled.img`
  width: 2rem;
  height: auto;
`;

export const PasswordError = styled.p`
  color: ${color.danger};
`;

export const SuccessImg = styled.img`
  width: 10rem;
  height: 10rem;
  margin-top: 1rem;
  margin-bottom: 1.8rem;
`;
export const CoverWatermark = styled.div`
  background-color: #fff;
  height: 2rem;
  position: absolute;
  bottom: 1rem;
  width: 100%;
`;

export const ModalContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const VaultImage = styled.img`
  width: 14rem;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 36rem;
`;

// =======================================

//============== second part =============

export const BannerContainer = styled.div<PropT>`
  width: 50%;
  min-height: 4rem;
  display: block;

  @media (min-width: 1020px) {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #760000;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const CPBanner = styled.img`
  width: auto;
  height: 90%;
`;

// ===================================
