import React, { useEffect, useState } from "react";
import { Spinner } from "shared/components";
import Modal from "shared/quickComponents/modal";
import { ModalButton, ModalButtonsContainer, ModalContent } from "./Styles";

type PropT = {
  isModalOpen: boolean;
  handleCloseModal: Function;
  message: string;
};

export default function index({
  isModalOpen,
  handleCloseModal,
  message,
}: PropT) {
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (isModalOpen) {
      let sec = 30;
      setInterval(function() {
        setTimer(sec);
        sec--;

        if (sec == 0) {
          handleCloseModal();
        }
      }, 1000);
    }
  }, [isModalOpen]);
  return (
    <Modal isOpen={isModalOpen} closeButton={false} onClose={handleCloseModal}>
      <ModalContent>
        <p>{message}</p>
        <p>{timer.toString().padStart(2, "0")}</p>

        <ModalButtonsContainer>
          <ModalButton onClick={() => handleCloseModal()} option={true}>
            Logout now
          </ModalButton>
        </ModalButtonsContainer>
      </ModalContent>
    </Modal>
  );
}
