import React, { useEffect, useState } from "react";
import { AmountSummary, MainAmount, MainNumber, TransactionPeriodStat, TransactionStat } from "./Style";
import reportObj from "Report/report-class";
import { abbrNum, numFormat, numFormatter } from "shared/utils/utility";

export default function TransactionSummary() {
  const [summary, setsummary] = useState<any>({});

  const [isloading, setisloading] = useState(false);
  const [isDatafetched, setisDatafetched] = useState(false);
  const { todayTransaction, weeklyTransaction, monthlyTransaction } = summary;
  const transactionsCount =
    todayTransaction?.totalTransaction +
    weeklyTransaction?.totalTransaction +
    monthlyTransaction?.totalTransaction;

  const getSummary = async () => {
    const response = await reportObj.getTransactionSummary();
    console.log("Summary: ", response)
    if (response) {
      setsummary(response);
      setisDatafetched(true);
      setisloading(false);
    } else {
      setisDatafetched(true);
      setisloading(false);
    }
  };

  useEffect(() => {
    if (!isDatafetched) {
      setisloading(true);
      getSummary();
    }
  }, []);

  return (
    <AmountSummary>
      <div className={"title"}>Total Transaction Today</div>
      <div>
        <MainAmount>
          <div className={"amount"}>{todayTransaction?.totalVolume ?? "0"}</div>
          <div className={"title"}>{abbrNum(transactionsCount, 1) ?? "0"} transactions</div>
        </MainAmount>
        <MainNumber>
          <div className={"main"}>{abbrNum(weeklyTransaction?.totalVolume, 1) ?? "0"}</div>
          <div className={"title"}>This week</div>
        </MainNumber>
        <MainNumber>
          <div className={"main"}>{abbrNum(monthlyTransaction?.totalVolume, 1) ?? "0"}</div>
          <div className={"title"}>This month</div>
        </MainNumber>
      </div>
      
    </AmountSummary>
  );
}
