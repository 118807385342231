import React, { Component, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Route, Switch, Router } from "react-router";
import SetPassword from "./pages/SetPassword";
import ResetPassword from "./pages/ResetPassword";
import MainLayout from "./Layouts/MainLayout";
import ForgotPassword from "./pages/ForgotPassword";
import SessionTimeout from "./pages/SessionTimeout";
import Login from "./pages/Login";
import ReceiveOtp from "./pages/ReceiveOtp";


const Authenticate = () => {
  const history = useHistory();
  return (
    <Router history={history}>
      <Switch>
        <Redirect exact from="/authenticate" to="/authenticate/login" />
        <Route exact path="/autheticate/login" component={Login} />
        <Route exact path="/autheticate/set-password" component={SetPassword} />
        
        <Route
          exact
          path="/authenticate/reset-password"
          component={ResetPassword}
        />


        <Route
          exact
          path="/authenticate/forgot-password"
          component={ForgotPassword}
        />
        <Route
          exact
          path="/authenticate/session-timeout"
          component={SessionTimeout}
        />
      </Switch>
    </Router>
  );
};

export default Authenticate;
