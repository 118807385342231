import { ActionTypes } from "../constants/actionType";
import api from "shared/utils/api";

export const setSettings = () => {
  return async (dispatch: any) => {
    const settings = await api.get("/settings");
    await dispatch({
      type: ActionTypes.SET_SETTINGS_LISTS,
      payload: settings,
    });
  };
};

export const setSidebar = (menus: any) => {
  return {
    type: ActionTypes.SET_MENUS,
    payload: menus,
  };
};

export const resetState = (val: boolean) => {
  return {
    type: ActionTypes.RESET_STATE,
    payload: val,
  };
};

export const toogleNav = (val: boolean) => {
  return {
    type: ActionTypes.SET_TOGGLE_STATE,
    payload: val,
  };
};
