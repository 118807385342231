import styled from "styled-components";
import { color } from "shared/utils/styles";

export const TableActions = styled.div`
  display: flex;
  gap: 2rem;
`;

export const ViewAllBtn = styled.span`
  background: ${color.fountain};
  cursor: pointer;
  color: #fff;
  border-radius: 0.4rem;
  padding: 0.6rem 1rem;
  font-size: 12px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem auto;
  background: #fff;
  padding: 0 1rem;
  height: 4.3rem;
  width: 100%;
  position: relative;
  bottom: 0;
  border: 1px solid ${color.backgroundMedium};
`;

export const PaginationButtonsContainer = styled.div`
  display: flex;
  gap: 1.4rem;
`;

export const PaginationNext = styled.button`
  background-color: ${color.fountain};
  color: #fff;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 12px;
`;

export const PaginationPrev = styled.button`
  background-color: #bf7a79;
  color: #fff;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  font-size: 12px;
  cursor: pointer;
`;
