import React, { ReactNode } from "react";
import PropTypes from "prop-types";

import { StyledIcon } from "./Styles";

type FontP = {
  [x: string]: string;
};

const fontIconCodes: FontP = {
  [`bug`]: "\\e90f",
  [`stopwatch`]: "\\e914",
  [`task`]: "\\e910",
  [`story`]: "\\e911",
  [`arrow-down`]: "\\e90a",
  [`arrow-left-circle`]: "\\e917",
  [`arrow-up`]: "\\e90b",
  [`chevron-down`]: "\\e900",
  [`chevron-left`]: "\\e901",
  [`chevron-right`]: "\\e902",
  [`chevron-up`]: "\\e903",
  [`board`]: "\\e904",
  [`help`]: "\\e905",
  [`link`]: "\\e90c",
  [`menu`]: "\\e916",
  [`more`]: "\\e90e",
  [`attach`]: "\\e90d",
  [`plus`]: "\\e906",
  [`search`]: "\\e907",
  [`issues`]: "\\e908",
  [`settings`]: "\\e909",
  [`close`]: "\\e913",
  [`feedback`]: "\\e918",
  [`trash`]: "\\e912",
  [`github`]: "\\e915",
  [`shipping`]: "\\e91c",
  [`component`]: "\\e91a",
  [`reports`]: "\\e91b",
  [`page`]: "\\e919",
  [`calendar`]: "\\e91d",
  [`arrow-left`]: "\\e91e",
  [`arrow-right`]: "\\e91f",
  // [`location`]: '\\f3c5',
  [`globe`]: "\\E0F6",
  [`signout`]: "\\e868",
  [`mail`]: "\\ea86",
  [`radio-checked`]: "\\ea54",
  [`radio-unchecked`]: "\\ea56",
  [`circle-left`]: "\\ea44",
  [`circle-right`]: "\\ea42",
  [`warining`]: "\\ea07",
  [`bookmarks`]: "\\e9d3",
  [`bookmark`]: "\\e9d2",
  [`eye-blocked`]: "\\e9d1",
  [`eye`]: "\\e9ce",
  [`earth`]: "\\e9ca",
  [`upload3`]: "\\e9c8",
  [`download3`]: "\\e9c7",
  [`upload2`]: "\\e9c6",
  [`download2`]: "\\e9c5",
  [`cloud-check`]: "\\e9c4",
  [`cloud-upload`]: "\\e9c3",
  [`cloud-download`]: "\\e9c2",
  [`cloud`]: "\\e9c1",
  [`switch`]: "\\e9b6",
  [`cogs`]: "\\e995",
  [`cog`]: "\\e994",
  [`equalizer2`]: "\\e993",
  [`equalizer`]: "\\e992",
  [`shrink2`]: "\\e98c",
  [`enlarge2`]: "\\e98b",
  [`shrink`]: "\\e98a",
  [`enlarge`]: "\\e989",
  [`user-check`]: "\\e975",
  [`user-minus`]: "\\e974",
  [`user-plus`]: "\\e973",
  [`users`]: "\\e972",
  [`user`]: "\\e971",
  [`bubbles4`]: "\\e970",
  [`bubbles3`]: "\\e96f",
  [`bubble2`]: "\\e96e",
  [`bubbles2`]: "\\e96d",
  [`bubbles`]: "\\e96c",
  [`redo`]: "\\e966",
  [`undo`]: "\\e965",
  [`upload`]: "\\e961",
  [`download`]: "\\e960",
  [`printer`]: "\\e954",
  [`onStack`]: "\\e92e",
  [`map2`]: "\\e94c",
  [`map`]: "\\e94b",
  [`compass2`]: "\\e94a",
  [`compass`]: "\\e949",
  [`location`]: "\\e948",
  [`phone`]: "\\e942",
  [`qrcode`]: "\\e939",
  [`price-tags`]: "\\e938",
  [`stack`]: "\\e937",
  [`copy`]: "\\e936",
  [`file-video`]: "\\e935",
  [`file-picture`]: "\\e934",
  [`file-text2`]: "\\e933",
  [`files-empty`]: "\\e932",
  [`file-empty`]: "\\e931",
  [`connection`]: "\\e930",
  [`images`]: "\\e92f",
  [`pencil`]: "\\e92e",
  [`home3`]: "\\e92d",
  [`home`]: "\\e92c",
  [`log_out_outlined_logout_sign`]: "\\e92b",
  [`customer_user_userphoto_account_person_icon`]: "\\e92a",
  [`close_exit_logout_power_icon`]: "\\e929",
  [`arrow_exit_logout_sign`]: "\\e927",
  [`group_user_icon`]: "\\e920",
  [`team_teamwork_users_icon`]: "\\e93a",
  [`users_people_icon`]: "\\e93b",
  [`pending_chatting_load_chat_social`]: "\\e93c",
  // [`pending_chatting_load_chat_social`]: '\\e93d',
  // [`pending_chatting_load_chat_social`]: '\\e93e',
  [`pending_icon`]: "\\e93f",
  [`pending_filled_icon`]: "\\e940",
};

// const propTypes = {
//   className: PropTypes.string,
//   type: PropTypes.oneOf(Object.keys(fontIconCodes)).isRequired,
//   size: PropTypes.number,
//   left: PropTypes.number,
//   top: PropTypes.number,
//   color: PropTypes.string,
//   hoverColor: PropTypes.string
// };

let fontIconArray = Object.keys(fontIconCodes);
type FontIconP = typeof fontIconArray[number];

type PropT = {
  className: string;
  type: FontIconP;
  size: number;
  left: number;
  top: number;
  color: string;
  hoverColor: string;
  [x: string]: any;
};

const defaultProps = {
  className: undefined,
  size: 16,
  left: 0,
  top: 0,
  hoverColor: "",
};

const Icon: any = ({ type, ...iconProps }: PropT) => (
  <StyledIcon
    {...iconProps}
    data-testid={`icon:${type}`}
    code={fontIconCodes[type]}
  />
);

// Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
