import React, { useLayoutEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import GridSection from "./GridSection";
import TablesSection from "./TablesSection";

export default function Single() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  useLayoutEffect(() => {
    //check for route param
    // console.log({ id });
    if (!id) {
      history.push("/dashboard/business/home");
      // // console.log(window.location.href);
      // window.location.href = "/dashboard/business/home";
    }
  }, []);
  return (
    <>
      <GridSection />
      <TablesSection />
    </>
  );
}
