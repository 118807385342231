import React, { useState, useEffect } from "react";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { Icon } from "shared/components";
import SuccessIcon from "App/assets/images/icons/round-success.svg";
import FailureIcon from "App/assets/images/icons/round-failure.svg";
import CheckSuccess from "App/assets/images/icons/check-success.svg";
import Tablet from "App/assets/images/icons/tablet.svg";
import ErrorTag from "App/assets/images/icons/error.svg";
import RefreshIcon from "App/assets/images/icons/refresh.svg";
import api from "shared/utils/api";

import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  ActionButton,
  Back,
  ButtonContainer,
  LeftContainer,
  RightContainer,
  Container,
  LSection,
  RSection,
  TitleCase,
  SubContainer,
  LeftTitle,
  LeftValue,
  LeftDiv,
  RightDiv,
  RightTitle,
  RightValue,
  ButtonDiv,
  RightTopContainer,
  LeftTopContainer,
  InnerRight,
  RightTopInner,
  RightTopInnerRem,
  Divider,
  MiniChartContainer,
  MiniChartSection,
  MiniItem,
  MiniItemSection,
  MiniItemSectionUp,
  MiniItemSectionDown,
  LogSectionContainer,
  LogSection,
  LogSectionLeft,
  LogSectionRight,
  IconImage,
  LeftRightIcon,
  RoundClock,
  ClockSpentMessage,
  MinutesDiv,
  LeftTitleAmount,
  LeftTitleRevalidate,
  RightCont,
} from "./Style";
import { copyToClipboard } from "shared/utils/browser";
import toast from "shared/utils/toast";
import { GradientLoader } from "shared/components";
// import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
// import { currencyFormat100, maskString } from "shared/utils/moneyFormat";
// import { getStatusColor } from "shared/utils/statusColor";
import consoleLog from "shared/utils/consoleLog";
import Layout from "Layout";
import { formatDate, formatDateTime } from "shared/utils/dateTime";
// import { differnceTime, getOnlyTime } from "shared/utils/dateTime";

const TransactionDetails = () => {
  const [active, setActive] = useState(0);
  const [data, setData] = useState({} as any);
  const [isLinkCopied, setLinkCopied] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  let { id: transactId }: any = useParams();

  const location: any = useLocation();
  const transactionData = location.state.data;

  //   let obj: any = {};
  // let { id }: any = useParams();
  // if (!id) return null;

  // let obj: any = {};
  // consoleLog(obj, "obj");

  // const getTransactionDetails = async () => {
  //   try {
  //     const response = await api.get(
  //       `/services/${service?.id}/transactions/${transactId}`
  //     );

  //     consoleLog(response, "respTransDet");

  //     if (
  //       response.status == true &&
  //       response.payload &&
  //       response.payload instanceof Object
  //     ) {
  //       let respObj = response.payload;
  //       respObj.remitedAmount =
  //         respObj.remitedAmount &&
  //         `${currencyFormat100(respObj.remitedAmount)}`;
  //       respObj.senderName = respObj?.Customer?.fullName;
  //       respObj.email = respObj?.Customer?.email;

  //       respObj.amount =
  //         respObj.amount && `${currencyFormat100(respObj.amount)}`;
  //       respObj.charge =
  //         respObj.amount && `${currencyFormat100(respObj.charge)}`;
  //       respObj.origStatus = respObj.status;
  //       respObj.deviceType =
  //         respObj?.transactionDetails?.meta.deviceType ?? respObj?.deviceType;
  //       respObj.status =
  //         respObj.status &&
  //         (respObj.status.toLowerCase().includes("init") ? (
  //           <div style={{ color: getStatusColor("terminated") }}>
  //             terminated
  //           </div>
  //         ) : (
  //           <div style={{ color: getStatusColor(respObj?.status) }}>
  //             {respObj?.status?.toLowerCase()[0].toUpperCase() +
  //               respObj?.status?.toLowerCase().substring(1)}
  //           </div>
  //         ));

  //       respObj.timeSpent = differnceTime(
  //         respObj?.createdAt,
  //         respObj?.updatedAt
  //       );

  //       respObj.updatedAt =
  //         respObj?.updatedAt &&
  //         new Date(respObj?.updatedAt).toLocaleString("en-GB", {
  //           year: "numeric",
  //           month: "numeric",
  //           day: "numeric",
  //           hour: "2-digit",
  //           minute: "2-digit",
  //           hour12: true,
  //         });
  //       consoleLog(respObj, "respObj");
  //       setData(respObj);
  //     } else {
  //       toast.error("Unable to fetch Transaction Details");
  //     }

  //     //consoleLog(response, "responseServ");
  //   } catch (err) {
  //     // @ts-ignore
  //     toast.error("Unable to fetch Transaction Details");
  //     // toast.error(err && err.detail);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getTransactionDetails();
  // }, []);

  // useEffect(() => {
  //   if (isDownload) setDownload(false);
  // }, [isDownload]);

  // if (loading) {
  //   return <GradientLoader />;
  // }

  let obj = data;
  const handleLinkCopy = (link: any) => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
    copyToClipboard(link || window.location.href);
    toast.success("Copied to clipboard!");
  };

  // const handleRevalidate = async (id: string) => {
  //   try {
  //     const response = await api.get(`/checkout/revalidate-payment/${id}`);

  //     consoleLog(response, "respTransDet");

  //     if (
  //       response.status == true &&
  //       response.payload &&
  //       response.payload instanceof Object
  //     ) {
  //       // getTransactionDetails();
  //     } else {
  //       toast.error("Unable to revalidate transaction.");
  //     }
  //   } catch (err) {
  //     // @ts-ignore
  //     consoleLog(err);
  //     toast.error("Unable to revalidate transaction.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  let leftTop = (
    <LeftTopContainer>
      <LeftTitleAmount>
        <div>Amount</div>
        <LeftTitleRevalidate>
          {transactionData?.status === "SUCCESSFUL" ? (
            <p
              style={{
                color: "#219653",
                marginLeft: "8px",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {" "}
              {transactionData?.paymentCurrency} {transactionData?.amount}
            </p>
          ) : (
            <p
              style={{
                color: "#cb423b",
                marginLeft: "8px",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {" "}
              {transactionData?.paymentCurrency} {transactionData?.amount}
            </p>
          )}
        </LeftTitleRevalidate>
      </LeftTitleAmount>

      {transactionData?.status === "SUCCESSFUL" && (
        <LeftRightIcon>
          <>
            <img src={CheckSuccess} />
            <div>Success</div>
          </>
        </LeftRightIcon>
      )}
    </LeftTopContainer>
  );

  let rightTop = (
    <RightTopContainer>
      <RightTopInner onClick={() => setActive(0)} active={active === 0}>
        Analytics
      </RightTopInner>
      <RightTopInner onClick={() => setActive(1)} active={active === 1}>
        Refunds
      </RightTopInner>
      <RightTopInnerRem />
    </RightTopContainer>
  );

  // let arrLeft = [
  //   { id: "mode", label: "Mode" },
  //   { id: "reference", label: "Transaction ID" },
  //   { id: "remitedAmount", label: "Amount Paid" },
  //   { id: "gatewayChannel", label: "Channel" },
  //   { id: "narration", label: "Narration" },
  //   { id: "charge", label: "Charge" },
  //   { id: "updatedAt", label: "Paid At" },
  //   { id: "status", label: "Remark" },
  // ];

  // let leftSide = arrLeft.map((el: any, idx) => {
  //   return (
  //     <LeftDiv key={`L${idx}`}>
  //       <LeftTitle>{el.label}</LeftTitle>
  //       {["transactionId", "reference"].includes(el.id) ? (
  //         <div
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <LeftValue>{obj[el.id]}</LeftValue>
  //           <p
  //             style={{
  //               color: "#219653",
  //               marginLeft: "8px",
  //               fontSize: "14px",
  //               cursor: "pointer",
  //             }}
  //             onClick={() => handleLinkCopy(obj[el.id])}
  //           >
  //             Copy
  //           </p>
  //         </div>
  //       ) : (
  //         <LeftValue>{obj[el.id]}</LeftValue>
  //       )}
  //     </LeftDiv>
  //   );
  // });

  // let arrCard = [
  //   { id: "cardType", label: "Card Type" },
  //   { id: "country", label: "Bank and Country" },
  //   { id: "senderName", label: "Sender's Name" },
  //   { id: "cardNumber", label: "Card Number" },
  //   { id: "IPAddress", label: "IP Address" },
  //   { id: "transactionIdentifier", label: "Authorization" },
  // ];

  // let arrDirectDebit = [
  //   { id: "bankName", label: "Bank Name" },
  //   { id: "country", label: "Country" },
  //   { id: "sendersName", label: "Sender's Name" },
  //   { id: "IPAddress", label: "IP Address" },
  //   { id: "sendersAccountNumber", label: "Sender's Account" },
  //   { id: "transactionIdentifier", label: "Authorization" },
  // ];

  // let arrRight = () => {
  //   let arr = [
  //     {
  //       id: "direct_debit",
  //       attr: "directDebitDetails",
  //       useArr: arrDirectDebit,
  //     },
  //     {
  //       id: "card",
  //       attr: "cardDetails",
  //       useArr: arrCard,
  //     },
  //   ];

  //   for (let el of arr) {
  //     if (
  //       obj?.paymentMode &&
  //       String(obj?.paymentMode)
  //         ?.toLowerCase()
  //         .trim() === el?.id
  //     ) {
  //       return [el?.useArr, el?.attr];
  //     }
  //   }

  //   return [arrCard, "cardDetails"];
  // };

  let otherObj: object = {}; //other items for summaryArray
  let otherArr: Array<object> = []; //other items for summaryArray

  // let rightSide = () => {
  //   let arr: any = arrRight();
  //   let useObj =
  //     obj?.transactionDetails?.meta && obj?.transactionDetails?.meta[arr[1]];
  //   if (!useObj || !(useObj instanceof Object)) return null;
  //   let useArr = arr[0];
  //   useObj.transactionIdentifier =
  //     obj?.transactionDetails?.meta?.transactionIdentifier ?? "";
  //   useObj.senderName = data?.senderName;
  //   otherObj = { ...otherObj, ...useObj };
  //   consoleLog(otherObj, "otherObj");
  //   return useArr.map((el: any, idx: number) => {
  //     if (el.id === "cardNumber") {
  //       useObj[el.id] = maskString(useObj[el.id]);
  //     }
  //     if (el.id === "sendersAccountNumber") {
  //       useObj[el.id] = maskString(useObj[el.id], 2, 3);
  //     }
  //     otherArr.push({ id: el.id, label: el.label });
  //     return (
  //       <RightDiv key={`L${idx}`}>
  //         <RightTitle>{el.label}</RightTitle>
  //         <RightValue>{useObj[el.id]}</RightValue>
  //       </RightDiv>
  //     );
  //   });
  // };

  let rightSide = () => {
    return (
      <RightDiv key={`1`}>
        <RightTitle>label</RightTitle>
        <RightValue>value</RightValue>
      </RightDiv>
    );
  };
  let rightSideFn = rightSide();

  let arrMiniChart = [
    { id: "deviceType", label: "Device Type", icon: Tablet },
    { id: "attempts", label: "Attempts", icon: RefreshIcon },
    { id: "errors", label: "errors", icon: ErrorTag },
  ];
  let rightMiniChart: any = arrMiniChart.map((el: any, idx: number) => {
    return (
      <MiniItem key={`CH${idx}`}>
        <MiniItemSection>
          <img src={el.icon} />
        </MiniItemSection>
        <MiniItemSection>
          <MiniItemSectionUp>{el.label}</MiniItemSectionUp>
          <MiniItemSectionDown>{obj[el.id]}</MiniItemSectionDown>
        </MiniItemSection>
      </MiniItem>
    );
  });

  let rightChartSide = (
    <MiniChartContainer>
      <MiniChartSection>
        <RoundClock>
          {data?.timeSpent} <MinutesDiv>MINUTES</MinutesDiv>
          <ClockSpentMessage>SPENT ON PAGE</ClockSpentMessage>
        </RoundClock>
      </MiniChartSection>
      <MiniChartSection style={{ marginLeft: 5 }}>
        {rightMiniChart}
      </MiniChartSection>
    </MiniChartContainer>
  );

  // let refundArray = [
  //   { id: "refund", label: "Amount Refunded" },
  //   { id: "refund", label: "Refund created at" },
  //   { id: "refund", label: "Refund initiated by" },
  //   { id: "refund", label: "Reason for refund" },
  // ];

  // let refundComp1 = refundArray.map((el: any, idx) => {
  //   return (
  //     <LeftDiv key={`Ref${idx}`}>
  //       <LeftTitle>{el.label}</LeftTitle>
  //       <LeftValue>{obj[el.id]}</LeftValue>
  //     </LeftDiv>
  //   );
  // });

  let refundComp2 = <h2>No data</h2>;
  // let refundComp = [refundComp1, refundComp2];
  // let refundIndex = Math.floor(Math.random() * 2);

  // let logArray = () => {
  //   return obj?.TransactionActivities &&
  //     obj.TransactionActivities instanceof Array
  //     ? obj.TransactionActivities.sort(
  //         (a: any, b: any) =>
  //           new Date(a?.createdAt).getTime() - new Date(b?.createdAt).getTime()
  //       ).map((el: any) => {
  //         let logObj: any = {};
  //         let chkValues = ["fail", "abandon", "cancel"];
  //         logObj.time = getOnlyTime(el?.updatedAt);
  //         logObj.stmt = el?.description;
  //         let status = "";
  //         for (let elm of chkValues) {
  //           if (
  //             el?.description &&
  //             el?.description
  //               .trim()
  //               .toLowerCase()
  //               .indexOf(elm.trim().toLowerCase()) > -1
  //           ) {
  //             status = "fail";
  //             break;
  //           }
  //         }

  //         logObj.status = status === "" ? "success" : status;
  //         return logObj;
  //       })
  //     : [];
  // };

  let dispLog = () => {
    return (
      <LogSection key={`2`}>
        {/* <LogSectionLeft>
          time
          <IconImage src={SuccessIcon} />
          <IconImage src={FailureIcon} />
        </LogSectionLeft>
        <LogSectionRight>statement</LogSectionRight> */}
      </LogSection>
    );
  };

  consoleLog(otherArr, otherObj, "Others");
  // let summaryArray: any = [
  //   { id: "reference", label: "Reference" },
  //   { id: "updatedAt", label: "Transaction Date" },
  //   { id: "email", label: "Customer (email)" },
  //   { id: "amount", label: "Amount Paid" },
  //   ...otherArr,
  // ];

  // let summaryData = () => {
  //   let summaryObj = { ...obj, ...otherObj };
  //   let resArr: any = [];
  //   for (let el of summaryArray) {
  //     let itemArr: any = [];
  //     itemArr.push(el.label);
  //     itemArr.push(summaryObj[el.id] ?? "");
  //     resArr.push(itemArr);
  //   }
  //   return resArr;
  // };

  // if (!shouldPerform(getRoleName(currentUser, business?.id), "VTrans")) {
  //   history.push("/authenticate");
  //   return null;
  // }

  useEffect(() => {
    console.log("transaction data", transactionData);
  }, []);

  return (
    <>
      <Layout pageTitle="Business">
        <div style={{ marginLeft: -15, marginRight: -15, marginTop: 80 }}>
          <Container>
            <LeftContainer>
              {leftTop}
              {/* {leftSide} */}
              <LeftDiv>
                <LeftTitle>Mode</LeftTitle>
                <LeftValue>{transactionData?.mode}</LeftValue>
              </LeftDiv>
              <LeftDiv>
                <LeftTitle>Transaction ID</LeftTitle>
                <LeftValue>{transactionData?.reference}</LeftValue>
              </LeftDiv>

              <LeftDiv>
                <LeftTitle>Amount Paid</LeftTitle>
                <LeftValue>
                  {transactionData?.paymentCurrency}{" "}
                  {transactionData?.remitedAmount}
                </LeftValue>
              </LeftDiv>

              <LeftDiv>
                <LeftTitle>Channel</LeftTitle>
                <LeftValue>{transactionData?.gatewayChannel}</LeftValue>
              </LeftDiv>

              <LeftDiv>
                <LeftTitle>Gateway</LeftTitle>
                <LeftValue>
                  {transactionData?.gateway ? transactionData?.gateway : "---"}
                </LeftValue>
              </LeftDiv>

              <LeftDiv>
                <LeftTitle>Narration</LeftTitle>
                <LeftValue>
                  {transactionData?.narration !== " "
                    ? transactionData?.narration
                    : "---"}
                </LeftValue>
              </LeftDiv>

              <LeftDiv>
                <LeftTitle>Charge</LeftTitle>
                <LeftValue>
                  {" "}
                  {transactionData?.paymentCurrency} {transactionData?.charge}
                </LeftValue>
              </LeftDiv>

              <LeftDiv>
                <LeftTitle>Paid At</LeftTitle>
                <LeftValue>
                  {" "}
                  {formatDateTime(transactionData?.createdAt)}
                </LeftValue>
              </LeftDiv>

              <LeftDiv>
                <LeftTitle>Remark</LeftTitle>
                <LeftValue> {transactionData?.status}</LeftValue>
              </LeftDiv>
              <br />
              <hr />
              <h3>Service details</h3>
              <br />
              <LeftDiv>
                <LeftTitle>Service Name</LeftTitle>
                <LeftValue> {transactionData?.Services?.name}</LeftValue>
              </LeftDiv>

              <LeftDiv>
                <LeftTitle>Service ID</LeftTitle>
                <LeftValue> {transactionData?.Services?.serviceId}</LeftValue>
              </LeftDiv>

              <br />
              <hr />
              <h3>Customer details</h3>
              <br />
              <LeftDiv>
                <LeftTitle>Customer Name</LeftTitle>
                <LeftValue> {transactionData?.Customer?.fullName}</LeftValue>
              </LeftDiv>

              <LeftDiv>
                <LeftTitle>Customer Phone</LeftTitle>
                <LeftValue> {transactionData?.Customer?.phone}</LeftValue>
              </LeftDiv>

              <LeftDiv>
                <LeftTitle>Customer Email</LeftTitle>
                <LeftValue> {transactionData?.Customer?.email}</LeftValue>
              </LeftDiv>
            </LeftContainer>
            <RightContainer>
              <RightCont>
                <InnerRight>
                  {rightTop}
                  {active === 0 ? (
                    <>
                      {/* <RSection>{rightSideFn}</RSection> */}
                      <Divider />
                      {rightChartSide}
                      <LogSectionContainer>{dispLog()}</LogSectionContainer>
                    </>
                  ) : (
                    <>{refundComp2}</>
                  )}
                </InnerRight>
              </RightCont>
            </RightContainer>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default TransactionDetails;
