import React, { useState, useLayoutEffect, useEffect } from "react";
import EmptyState from "../../shared/components/EmptyState";
import { useHistory, useParams } from "react-router-dom";
import Table from "shared/clientcomponents/Table";

import api from "shared/utils/api";
import useApi from "shared/hooks/api";
import logosample from "App/assets/images/amazon.png";
import {
  AllBusinessTableContainer,
  PageLayout,
  PaginationButtonsContainer,
  PaginationContainer,
  PaginationNext,
  PaginationPrev,
} from "./Styles";
import { GradientLoader } from "shared/components";
import { businessTableHeader } from "../businessTableHeader";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { numberFormat } from "shared/utils/utility";
import businessOBJ from "Business/businessClass";
import AgencyBankingObj from "AgencyBanking/class.agency";

type PropT = {
  [x: string]: any;
};

export default function AllAgents() {
  const [content, setcontent] = useState<any>([]);
  const [isloading, setIsloading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");
  const [numberOfItems, setnumberOfItems] = useState<any>("");
  const [data, setdata] = useState([]);
  const [searchContent, setSearchContent] = useState<string | undefined>();

  const [noRecord, setNoRecord] = useState(true);
  const history = useHistory();
  const { location } = useHistory();
  const bizDetails: any = location?.state;
  const totalBusinesses = bizDetails && bizDetails["totalBusinesses"];

  // const [{ data, error, setLocalData }, fetchData] = useApi.get(
  //   `/business?page=${currentPage}`
  // );

  const tableHeader = [
    { id: "agentID", label: "Agent ID" },
    { id: "agentName", label: "Agent Name" },
    { id: "numberOfAgents", label: "Number of Agents" },
    { id: "amountWithdraw", label: "Amount Withdraw" },
    { id: "transactions", label: "Transactions" },
  ];

  const getAgents = async () => {
    const response = await AgencyBankingObj.getAllAgents(currentPage);
    console.log("agents fetched", response);
    if (response) {
      setdata(response?.data);
      settotalPages(parseInt(response.totalPages));
      setcurrentPage(parseInt(response?.currentPage));
      setnumberOfItems(parseInt(response?.numberOfItems));
      setcontent(response?.data);
      setNoRecord(false);
      setIsloading(false);
    }
  };

  const handleFilter = (searchData: any) => {
    setSearchContent(searchData);
    const newlist = content.filter((agent: any) => {
      if (agent?.agentId.indexOf(searchData) > -1) {
        return agent;
      }
    });
    if (newlist.length) setcontent(newlist);
    if (!searchData) setcontent(data); //reset content
  };

  const hoverLink = (elm: PropT) => {
    history.push(`/agencybanking/agent/${elm?.agentID}`);
  };

  const agentsData = content?.map((el: any) => {
    return {
      agentID: el?.agentId,
      agentName: `${el?.user.firstName} ${el?.user.lastName}`,
      numberOfAgents: el?.numberOfAgents,
      amountWithdraw: numberFormat(el?.amountWithdraw),
      transactions: el?.numOfTransactions,
    };
  });

  useEffect(() => {
    getAgents();
    setIsloading(true);
  }, [currentPage]);

  return (
    <>
      <PageLayout>
        <h2>All Agents</h2>
        <br />
        <br />
        {isloading ? (
          <GradientLoader />
        ) : (
          <>
            <AllBusinessTableContainer>
              <DashboardSubHeader
                // count={totalBusinesses ? totalBusinesses : 0}
                title={"Agents"}
                btnTitle=""
                withSearch={true}
                placeholder="Agent ID"
                withFilter={false}
                widthActionBtn={false}
                filterOptions={["Name", "Date"]}
                setSearchData={handleFilter}
                callBackFn={() => ""}
                searchContent={searchContent}
                
              />

              {content && (
                <Table
                  headData={tableHeader}
                  bodyData={agentsData}
                  rowStyle={{ background: "#ffffff" }}
                  totalPages={totalPages}
                  currentpage={currentPage}
                  goToPage={(value: any) => {
                    setcurrentPage(value);
                  }}
                  hoverLink={hoverLink}
                />
              )}
            </AllBusinessTableContainer>
          </>
        )}
      </PageLayout>
    </>
  );
}
