import { any } from "cypress/types/bluebird";
import { tryCatch } from "guards/utils/tryCatch";
import api from "shared/utils/api";

class Business {
  async getBusinessOverview() {
    try {
      const response = await api.get("/overview");
      if (response?.payload) return response;
    } catch (error) {}
  }

  async getBusiness(busId: string) {
    try {
      const response = await api.get(`/business/${busId}`);
      if (response?.payload) return response;
    } catch (error) {}
  }

  async getService(serviceId: string) {
    try {
      const response = await api.get(`/services/${serviceId}`);
      if (response?.payload) return response;
    } catch (error) {}
  }

  async getServiceDetails(serviceId: string) {
    try {
      const response = await api.get(`/services/${serviceId}`);
      if (response?.payload) return response;
    } catch (error) {}
  }

  async getTeams(busId: string, page: number) {
    try {
      const response = await api.get(`/${busId}/team?page=${page}`);
      if (response?.payload) return response;
    } catch (error) {}
  }

  async getExtraData(busId: any) {
    try {
      const response = await api.get(`/business/${busId}/extra`);
      console.log("business id", busId);
      // console.log("business extra data", response);
      if (response.status) return response?.payload;
    } catch (error) {
      console.log("extra business data error", error);
    }
  }

  async getServiceExtraData(serviceId: any) {
    try {
      const response = await api.get(`/services/${serviceId}/extra`);
      // console.log("service extra data", response);
      if (response.status) return response?.payload;
    } catch (error) {
      console.log("extra data error", error);
    }
  }

  async sendOTP(data: any) {
    try {
      const response = await api.post(`/send-business-otp`, data);
      if (response.status) return response;
    } catch (error) {
      console.log("business otp error", error);
    }
  }

  async confirmOTP(data: any) {
    try {
      const response = await api.post(`/confirm-otp`, data);
      if (response.status) return response;
    } catch (error) {
      console.log("business confirm otp error", error);
    }
  }

  async getTransactions(resource: string, resourceId: any, page = 1) {
    try {
      const response = await api.get(
        `/${resource}/${resourceId}/transactions?page=${page}`
      );
      if (response.status) return response.payload;
    } catch (error) {
      console.log("error while fetching transactions");
    }
  }

  async getDisputes(resource: string, resourceId: any, page = 1) {
    try {
      const response = await api.get(
        `/${resource}/${resourceId}/disputes?page=${page}`
      );
      console.log("disputes data ==>", response);
      if (response.status) return response.payload;
    } catch (error) {
      console.log("error while fetching disputes");
    }
  }

  async getAccountDetails(businessId: any) {
    try {
      const response = await api.get(`/business/${businessId}/bank-accounts`);
      if (response.status) return response.payload;
    } catch (error) {
      console.log("error while fetching account details");
    }
  }

  async getCustomers(resource: string, resourceId: any, page = 1) {
    try {
      const response = await api.get(`/${resource}/${resourceId}/customers`);
      console.log("customer data", resource);
      if (response.status) return response.payload;
    } catch (error) {
      console.log("error while fetching account details");
    }
  }

  async getSettlements(resource: string, resourceId: any, page = 1) {
    try {
      const response = await api.get(`/${resource}/${resourceId}/settlements`);
      if (response.status) return response.payload;
    } catch (error) {
      console.log("error while fetching settlements");
    }
  }

  async getSubaccounts(resource: string, resourceId: any) {
    try {
      const response = await api.get(`/${resource}/${resourceId}/subaccounts`);
      // console.log("calling subaccounts", response);
      if (response.status) return response.payload;
    } catch (error) {
      // console.log("error while fetching third party accounts", error);
      // return [];
    }
  }

  async getAllBusiness(currentPage = 1) {
    try {
      const response = await api.get(`/business?page=${currentPage}`);
      if (response?.payload?.data?.length) {
        return response?.payload;
      }
    } catch (error) {
      console.log("error while fetching businesses", error);
      return [];
    }
  }

  async getAllPricing(currentPage = 1) {
    try {
      const response = await api.get(`/pricing?page=${currentPage}`);
      if (response?.payload) return response?.payload;
    } catch (error) {
      // console.log("error in fetching pricing list ==>", error);
    }
  }

  async searchPricing(currentPage = 1, search = "") {
    try {
      const response = await api.get(
        `/pricing?page=${currentPage}&search=${search}`
      );
      if (response?.payload) return response?.payload;
    } catch (error) {
      // console.log("error in fetching pricing list ==>", error);
    }
  }

  async updatePricing(serviceId: string, data: any) {
    
    try {
      const response = await api.put(`/services/pricing-plan-update/${serviceId}`, data);
      if (response && response.status) return response;
    } catch (error) {}
  }
}

const businessOBJ = new Business();
export default businessOBJ;
