import { color, font } from "shared/utils/styles";
import styled from "styled-components";

type ValuePropT = {
  isActive: boolean;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 4rem auto;
  background-color: #fff;
  margin-left: -0.8rem;
`;

export const LoaderCont = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Col = styled.div`
  width: 50%;
  padding: 2.5rem 2rem;
  min-height: 40vh;

  h2 {
    font-size: 2rem;
    font-weight: 500;
  }
  p {
    width: 90%;
    margin-top: 1rem;
    color: #828282;
  }
`;

export const StepsCont = styled.div`
  width: 90%;
  margin-top: 2rem;
  padding: 1rem 0;
`;

export const Step = styled.div`
  display: flex;
  gap: 1.4rem;
  width: 100%;
  margin-top: 0rem;
  /* border: 1px solid red; */
`;

export const StepLabel = styled.span`
  font-weight: 100;
  color: #292929;
  font-size: 1.2rem;
`;

export const ValueCont = styled.div`
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  /* border: 1px solid red; */
`;

export const Value = styled.div<ValuePropT>`
  width: 32px;
  height: 32px;
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  border-radius: 9rem;
  background-color: ${(prop) =>
    prop.isActive ? color.fountain : "rgba(248, 110, 127, 0.19)"};
  color: ${(prop) => (prop.isActive ? "#fff" : color.fountain)};
`;

export const StepLine = styled.div`
  width: 4px;
  height: 64px;
  background: rgba(248, 110, 127, 0.09);
`;

// ==================

export const RightCol = styled(Col)`
  width: 50%;
  background-color: #fff;
  /* padding: 2.5rem 2rem; */
  border-left: 0.56px solid #ccc;
  /* padding: 1rem 2rem; */
`;
export const FormTitle = styled.p`
  font-size: 1.4rem;
  color: #292929 !important;
`;

//=========== form ============
export const BusinessForm = styled.form`
  margin-top: 1.5rem;
  /* border: 1px solid black; */
`;
export const InputCont = styled.div`
  margin-bottom: 1.2rem;
  label {
    margin-bottom: 0.88rem;
    display: block;
  }
`;

export const FormInput = styled.input`
  width: 100%;
  border: 1px solid rgba(215, 215, 215, 0.6);
  padding: 0.8rem;
  border-radius: 0.2rem;
`;

export const FormSelect = styled.select`
  width: 100%;
  border: 1px solid rgba(215, 215, 215, 0.6);
  padding: 0.8rem;
  border-radius: 0.2rem;
  margin-bottom: 1.4rem;
`;

//===========

export const BtnCont = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
`;

export const FormBtn = styled.button`
  padding: 0.8rem 2rem;
  background-color: ${color.fountain};
  color: #fff;
  border-radius: 0.4rem;
  cursor: pointer;
  :disabled {
    background-color: #ccc;
    cursor: no-drop;
  }
`;

//btn and back cont div
export const BtnsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
`;

export const RoundBtn = styled.div`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 10rem;
  border: 1px solid #828282;
  cursor: pointer;
`;

//file input
export const FileInputContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* border: 1px solid red; */
`;
export const FileInputLabel = styled.label`
  display: block;
  margin-bottom: 0.48rem;
`;
export const FileInputCont = styled.div`
  width: 100%;
  border: 1px dashed #ccc;
  display: flex;
  gap: 0.5rem;
  color: ${color.fountain};
  justify-content: center;
  padding: 1rem;
  cursor: pointer;
`;
export const UploadContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 15px;
`

export const UploadLabel = styled.label`
	display: block;
	padding-bottom: 5px;
	color: #5e6c84;
	font-family: 'CircularStdMedium';
	font-weight: normal;
	font-size: 13px;
	text-align: left;
`

export const UploadBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px dashed grey;
	border-radius: 5px;
	padding: 10px;
	background-color: #ffffff;
`

export const UploadInput = styled.div`
	background-color: #ffffff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-left: 4px solid green;
	padding: 10px;
	width: 100%;
	height: 50px;
	text-align: left;
	margin-bottom: 5px;
	${font.size(12.5)}
`

export const UploadedFile = styled.p`
	text-align: left;
	width: 300px;
	height: 20px;
	overflow: hidden;
`

export const UploadButton = styled.label`
	line-height: 18.75px;
	font-weight: 400;
	${font.size(16)};
	color: ${color.fountain};
	height: 39px;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
`

export const TrashBtn = styled.p`
	background-color: lightGrey;
	padding: 5px 8px;
	border-radius: 7px;
	&:hover {
		cursor: pointer;
	}
`

export const FieldError = styled.div`
	margin-top: 6px;
	text-align: left;
	line-height: 1;
	color: ${color.danger};
	${font.medium}
	${font.size(12.5)}
`
