import "chart.js/auto";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import {
  Box,
  ChartDetails,
  ChartLabelContainer,
  GraphContent,
  GraphHeader,
  NodataContainer,
} from "./Styles";

export default function PieChart({ data }: any) {
  const colors = [
    "rgb(36, 107, 154)",
    "rgb(255, 99, 132)",
    "rgb(54, 162, 235)",
    "rgb(255, 205, 86)",
    "rgb(39, 137, 146)",
  ];
  let chartValues: any = [];
  let colorsArr: string[] = [];
  let chartLabels: string[] = [];

  data.map((dispute: any, index: number) => {
    colorsArr.push(colors[index]);
    chartValues.push(dispute?.Count?.businessId);
    chartLabels.push(dispute?.merchant);
    return {
      merchant: dispute.merchant,
      color: colors[index],
    };
  });

  let userData = {
    labels: chartLabels,
    datasets: [
      {
        label: "My First Dataset",
        data: chartValues, //feeding the chart with dispute data values array
        backgroundColor: colorsArr,
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: { legend: { display: false } },
  };

  // ====== details ==========
  const detailsList = data.map((dispute: any, i: number) => (
    <ChartDetails key={dispute.businessId}>
      <Link to={`/business/overview/${dispute.businessId}`}>
        <div style={{ display: "flex", gap: "0.4rem" }}>
          <Box bgColor={colors[i]}></Box>
          <span>{dispute.merchant}</span>
        </div>
        <span
          style={{
            display: "block",
            width: "100%",
            textAlign: "center",
            fontSize: "1rem",
          }}
        >
          {dispute.Count.businessId}
        </span>
      </Link>
    </ChartDetails>
  ));
  // =================

  useEffect(() => {
    console.log("pie chart data", data);
  }, []);

  return (
    <>
      {data.length ? (
        <>
          <GraphHeader>
            <h4>Businesses with the Highest Dispute</h4>
          </GraphHeader>

          <GraphContent>
            <Pie data={userData} options={options} />
          </GraphContent>
          <ChartLabelContainer>{detailsList}</ChartLabelContainer>
        </>
      ) : (
        <NodataContainer>No data</NodataContainer>
      )}
    </>
  );
}
