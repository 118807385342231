import BreakPoints from "shared/utils/Breakpoint";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

type btnPropT = {
  isClicked: boolean;
};

type IndicatorT = {
  left: any;
  right: any;
};

export const TopBtnsContainer = styled.div`
  height: 40px;
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 52%;
  border-radius: 4rem;
  background-color: #e1e6ef;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 2.8rem;

  @media (${BreakPoints.xs}) {
    width: 100%;
    height: 38px;
    justify-content: space-between;
    /* border: 2px solid red; */
  }
`;

export const Btn = styled.button<btnPropT>`
  height: 40px;
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(prop) => (prop.isClicked ? color.fountain : "#e1e6ef")};
  color: ${(prop) => (prop.isClicked ? "#fff" : "#292929")};
  border-radius: 4rem;
  transition: all ease-out 0.2s;
  cursor: pointer;

  @media (${BreakPoints.xs}) {
    height: 35px;
    width: 5rem;
    font-size: 0.8rem;
    /* border: 2px solid red; */
  }
`;

export const IndicatorBtn = styled.button<IndicatorT>`
  height: 40px;
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.fountain};
  color: #fff;
  border-radius: 4rem;
  transition: all ease-out 0.6s;
  cursor: pointer;
  position: absolute;
  right: ${(prop) => prop.right && prop.right + "rem"};
  left: ${(prop) => prop.left && prop.left + "rem"};

  @media (${BreakPoints.xs}) {
    height: 35px;
    width: 5rem;
    font-size: 0.66rem;
    right: ${(prop) => prop.right && prop.right - 1 + "rem"};
    /* left: ${(prop) => prop.left && prop.left - 1 + "rem"}; */
    left: ${(prop) => prop.left === "0" && prop.left + 10 + "rem"};
    /* left: ${(prop) => prop.left && prop.left - 10 + "rem"}; */
    left: ${(prop) => prop.left === "8" && prop.left - 1 + "rem"};
    /* border: 2px solid red; */
  }
`;

export const CardsCont = styled.div`
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1px;
  grid-gap: 2rem;
  width: 100%;

  @media ${breakpoints.device.max.xs} {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    /* border: 2px solid red; */
  }
`;

export const Card = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  height: 14.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  cursor: pointer;
  p {
    margin-top: 0.8rem;
    font-size: 14px;
  }
  h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 1.4rem;
  }

  :hover {
    border: solid 1px #760000;
  }
`;

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 0.68rem;
  padding: 0 1.88rem;
  color: #000;

  span {
    /* background-color: #e6b9b9; */
    background-color: #ddd;

    /* color: #fff; */
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    color: #000;
  }
`;

export const CardIcon = styled.img`
  width: 44px;
  margin-top: 1rem;
`;

export const TableCont = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  margin-top: 4rem;
`;

export const ViewBtn = styled.div`
  padding: 0.2rem 1rem;
  cursor: pointer;
  text-align: center;
  :hover {
    border: 1px solid ${color.fountain};
    border-radius: 1rem;
  }
`;

export const HeaderBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FilterBtn = styled.button`
  height: 40px;
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #172b4d;
  color: #fff;
  border-radius: 4rem;
  transition: all ease-out 0.2s;
  cursor: pointer;
  margin-top: 1.2rem;

  :hover {
    background-color: #354c74;
  }
  @media (${BreakPoints.xs}) {
    height: 35px;
    width: 5rem;
    font-size: 0.8rem;
  }
`;

export const DownloadBtn = styled.button`
  height: 40px;
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #760000;
  color: #fff;
  border-radius: 4rem;
  transition: all ease-out 0.2s;
  cursor: pointer;
  margin-top: 1.2rem;
  transition: all ease 0.8s;

  :hover {
    background-color: #8d4646;
  }

  @media (${BreakPoints.xs}) {
    height: 35px;
    width: 5rem;
    font-size: 0.8rem;
  }
`;
