export const businessTableHeader = [
  { id: "logo", label: "", width: "10%" },
  { id: "businessID", label: "Business ID", width: "14%" },
  { id: "businessName", label: "Business Name", width: "40%" },
  { id: "amountSettled", label: "Amount Settled", width: "20%" },
  { id: "totalServices", label: "Services", width: "20%" },
  { id: "totalTransactions", label: "Transactions", width: "20%" },
];

export const ServiceTableHead = [
  { id: "sn", label: "Service ID", width: "30%" },
  { id: "serviceName", label: "Service Name", width: "60%" },
  { id: "funcProps", label: "" },
];

export const CustomersTableHead = [
  { id: "sn", label: "Service ID" },
  { id: "serviceName", label: "Service Name" },
  { id: "fullName", label: "Full Name" },
  { id: "email", label: "Email Address" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "funcProps", label: "" },
];

export const CustomersServiceTableHead = [
  { id: "fullName", label: "Full Name" },
  { id: "email", label: "Email Address" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "funcProps", label: "" },
];

export const TransactionTableHead = [
  { id: "sn", label: "ServiceID" },
  { id: "serviceName", label: "Service Name" },
  // { id: "transactionType", label: "Transaction Type" },
  { id: "amount", label: "Amount" },
  { id: "dateAndTime", label: "Date and Time" },
  { id: "status", label: "Status" },
  { id: "funcProps", label: "" },
];

export const ServiceTransTableHead = [
  { id: "amount", label: "Amount" },
  { id: "dateAndTime", label: "Date and Time" },
  { id: "status", label: "Status" },
  { id: "funcProps", label: "" },
];

export const accDetailsTableHead = [
  { id: "serviceID", label: "Service ID" },
  { id: "serviceName", label: "Service Name" },
  { id: "bankName", label: "Bank Name" },
  { id: "accountNumber", label: "Account Number" },
  { id: "funcProps", label: "" },
];

export const totalSettlementHead = [
  { id: "serviceID", label: "Service ID" },
  { id: "serviceName", label: "Service Name" },
  { id: "recepient", label: "Recipient" },
  { id: "amountSettled", label: "Amount Settled" },
];

export const ServiceSettlementHead = [
  // { id: "recepient", label: "Recipient" },
  { id: "amountSettled", label: "Amount Settled" },
  { id: "status", label: "Status" },
];

export const subAccountTableHead = [
  { id: "serviceID", label: "Service ID" },
  { id: "serviceName", label: "Service Name" },
  { id: "bankName", label: "Bank Name" },
  { id: "accountNumber", label: "Bank Account Number" },
  { id: "funcProps", label: "" },
];

export const disputeTableHead = [
  // { id: "sn", label: "Service ID" },
  { id: "serviceID", label: "Service ID" },
  { id: "serviceName", label: "Service Name" },
  { id: "amount", label: "Amount Paid" },
  { id: "merchant", label: "Merchant" },
  { id: "email", label: "Email Address" },
  { id: "dateandtime", label: "Date and Time" },
  { id: "funcProps", label: "" },
];
