import styled from "styled-components";
import { color } from "../../utils/styles";

type PropT = {
  checked: boolean;
};

export const ChecklistContainer = styled.div`
  margin-top: -0.2rem;
  display: flex;
  padding: 0rem 0 0.4rem 0;
  justify-content: start;
  align-items: center;
  padding: 0.4rem 0;
  text-align: left;
  position: relative;
  /* margin-bottom: 1.4rem; */
`;

export const ChecklistLabel = styled.label`
  margin-right: 0.6rem;
  margin-top: 0.4rem;
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: -0.4rem;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 10px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 10px;
`;

export const Label = styled.label<PropT>`
  position: absolute;
  pointer-events: none;
  width: 34px;
  height: 34px;
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA5NiA5NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgOTYgOTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMzMzMzMzM7fQ0KPC9zdHlsZT4NCjxnIGlkPSJYTUxJRF80XyI+DQoJPHBvbHlnb24gaWQ9IlhNTElEXzZfIiBjbGFzcz0ic3QwIiBwb2ludHM9IjM4LjUsNjEuMiAyNS4zLDQ4IDIwLjgsNTIuNSAzOC41LDcwLjIgNzYuNSwzMi4yIDcyLDI3LjcgCSIvPg0KPC9nPg0KPC9zdmc+DQo=");
  margin-top: 0.2rem;
  margin-left: -0.3rem;
  visibility: ${(props) => (props.checked ? "visible" : "hidden")};
`;

export const StyledCheckbox = styled.div<PropT>`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 10px;
  content: "";
  top: 50%;
  right: 0;
  border-radius: 4px;
  border: 2px solid ${color.fountain};
  background: ${color.fountainLight};
  transition: 0.2s;
`;
