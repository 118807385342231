import React from "react";
import { GradientLoader } from "shared/components";
import Loaders from "../../../shared/components/Loaders";
import { Box, BoxTitle, BoxIcon, BoxValue } from "./Styles";

type SingleBoxPropT = {
  boxdata: {
    id: number;
    title: string;
    value?: any;
    icon?: string;
    background: boolean;
    urlPath: string;
  };
  variant?: boolean;
  noPointer?: boolean;
  isLoading?: boolean;
  onClick?: () => any;
};

export default function SingleBox({
  boxdata,
  noPointer = false,
  variant,
  onClick,
  isLoading,
}: SingleBoxPropT) {
  return (
    <>
      <Box
        background={isLoading ? false : boxdata.background}
        style={{ cursor: !noPointer ? "pointer" : "default" }}
        onClick={onClick}
      >
        {isLoading ? (
          <Loaders />
        ) : (
          <>
            {boxdata.icon && <BoxIcon src={boxdata?.icon} />}

            <BoxTitle>{boxdata?.title}</BoxTitle>
            <BoxValue>{boxdata?.value}</BoxValue>
          </>
        )}
      </Box>
    </>
  );
}
