import styled from "styled-components";
import { PageLayout } from "shared/section_components/Styles";

export const BusinesPageLayout = styled(PageLayout)`
  background-color: transparent !important;
`;

export const TopGrid = styled.div`
  padding: 2.6rem 0;
  width: 100%;
  display: flex;
  gap: 2rem;
`;

export const Box = styled.div`
  width: 30%;
  padding: 1.48rem 1.4rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  transition: all ease 0.4s;
  cursor: pointer;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  &:hover {
    margin-right: 0.4rem;
  }
`;

export const IconContainer = styled.div`
  margin-top: 0.4rem;
`;

export const BoxTitle = styled.span`
  display: block;
  font-size: 16px;
  margin-top: 0.4rem;
`;

export const BoxValue = styled.span`
  margin-top: 0.8rem;
  display: block;
  font-size: 2rem;
  font-weight: 900;
`;
