import { ActionTypes } from '../constants/actionType';
import {storeCurrentBusiness, getCurrentBusiness} from "shared/utils/authToken";

const initialState = {
  businesses: null,
  currentBusiness: null,
};

export const businessesReducer = (state: any = initialState.businesses, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_BUSINESS_LISTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const businessReducer = (state: any = initialState.currentBusiness, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_BUSINESS: {
      storeCurrentBusiness(action.payload)
      return { ...state, ...action.payload };
    }
    default:
      return getCurrentBusiness() || state;
  }
};
