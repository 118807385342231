import React, { useState } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { ProtectedRoute } from "guards/ProtectedRoute";
import { SuperAdminGuard } from "guards/super-admin-guard";
import { AdminGuard } from "guards/admin-guard";
import { GradientLoader, PageError } from "shared/components";
import Home from "./Home/Index";
import Layout from "Layout";

export default function AgencyReport() {
  const match = useRouteMatch();
  return (
    <>
      <Layout pageTitle="Agency Report">
        <>
          <ProtectedRoute
            path={`${match.path}/`}
            guards={[SuperAdminGuard, AdminGuard]}
            component={Home}
            fallback={() => <PageError />}
          />
          {/* {match.isExact && <Redirect to={`${match.url}/home`} />} */}
        </>
      </Layout>
    </>
  );
}
