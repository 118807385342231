import axios from "axios";

import history from "browserHistory";
import toast from "shared/utils/toast";
import { objectToQueryString } from "shared/utils/url";
import {
  getStoredAuthToken,
  removeStoredAuthToken,
  storeAuthToken,
} from "shared/utils/authToken";
import {
  encryptData,
  getClientDetails,
  decryptData,
  isItHere,
  isPartOfString,
} from "./security";
import { plainArray, validatePermissions } from "./menurecords";
import consoleLog from "./consoleLog";

type PropT = {
  [x: string]: any;
};

const defaults = {

  baseURL: process.env.API_URL || "https://api-5916234be.fountainpay.ng/console",


  headers: () => ({
    Accept: "application/json",
    "Content-Type": "application/json",

    "x-api-client": "api",
    //"Access-Control-Allow-Origin": "*",
    //"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Authorization: getStoredAuthToken()
      ? `Bearer ${getStoredAuthToken()}`
      : undefined,
  }),
  error: {
    code: "INTERNAL_ERROR",
    message: "Something went wrong. Please check your internet connection or contact our support.",
    status: 503,
    data: {},
  },
};

let exceptionUrlArray: any = [
  // "/dispute",
  // "authentication/confirm-otp",
  // "authentication/forgot-password",
  // "authentication/login",
  "authentication/",
  // "authentication/reset-password",
  // "authentication/send-user-otp",
  // "authentication/signup",
  // "fp_admin/login",
  "fp_admin/system-logs/create",
  "fp_admin/send-user-otp",
  "fp_admin/confirm-otp",
  "/set-password",
  "fp_admin/reset-password",
  "business/kyc/upload",
  "/kyc-upload",
  "/shared/",
  // "/kyc-validate"
];

const api = (method: string, url?: string, variables?: any, cache = true) => {
  
  let strictlyUrlArray = ["/dispute/update"];
  let checkFlag = isItHere(exceptionUrlArray, url, strictlyUrlArray);
  url=url && url.includes("/shared/")?defaults.baseURL.replace("/console", "")+url:defaults.baseURL+url;
  
  const moreExceptional = (
    url: string = "",
    variables: any,
    checkFlag: boolean
  ) => {
    if (isItHere(["business/kyc/upload", "/kyc-upload"], url)) return variables;
    return encryptData(variables, checkFlag);
  };

  // let checkResponse = cache && checkForResponses(method, url, variables);
  // if (checkResponse) {
  //   consoleLog("returned cached data", checkResponse);
  //   return checkResponse;
  // }

  return new Promise((resolve, reject) => {
    axios(<PropT>{
      url,
      method,
      headers: defaults.headers(),
      params: method === "get" ? variables : undefined,
      data:
        method !== "get"
          ? moreExceptional(url, variables, checkFlag)
          : undefined,
      // data: method !== "get" ? variables : undefined,
      paramsSerializer: objectToQueryString,
    }).then(
      (response) => {
        // const headers = response.headers;
        // if (headers && headers["auth-token"]) {
        //   storeAuthToken(headers["auth-token"]);
        // }
        //consoleLog("api res", response);
        const decyptedDataContent = decryptData(response.data, checkFlag);
        //consoleLog("Decrypted", { URL: url, Data: decyptedDataContent });
        // cachedResponse(method, url, variables, decyptedDataContent);
        resolve(decyptedDataContent);
        // resolve(response.data);
      },
      (error) => {
        //console.log("Error: ", error);
        // const headers = error.response.headers;
        // if (headers && headers["auth-token"]) {
        //   storeAuthToken(headers["auth-token"]);
        // }

        if (error.response) {
          // if(error.response.status==='401'){
          //   toast.error(error.message);
          // }
          if (error?.response?.data) {
            error.response.data = decryptData(error.response.data, checkFlag);
          }

          if (
            ["INVALID_TOKEN", "token_not_valid"].includes(error.code) ||
            [401].includes(error.response.status)
          ) {
            removeStoredAuthToken();
            if (
              !["/autheticate/login", "/autheticate"].includes(
                window.location.pathname
              )
            ) {
              toast.error("Request Timed Out");
            }
            if (!["/autheticate"].includes(window.location.pathname)) {
              history.push("/autheticate/login");
            }
          } else if ([500, 501].includes(error.response.status)) {
            //console.log(error);
            reject(
              error?.response?.data?.detail ??
                error?.response?.data?.message ??
                error?.response?.data
            );
          } else {
            // toast.error(
            //   error?.response?.data?.detail ??
            //     error?.response?.data?.message ??
            //     error?.response?.data?.message ??
            //     error?.response?.data?.message?.role[0] ??
            //     ""
            // );
            reject(
              error?.response?.data?.detail ??
                error?.response?.data?.message ??
                error?.response?.data
            );
            // reject(error.response.data.message);
          }
        } else {
          reject(defaults.error);
        }
      }
    );
  }).finally(() => {});
};

const optimisticUpdate = async (
  url: string,
  { updatedFields, currentFields, setLocalData }: PropT
) => {
  try {
    setLocalData(updatedFields);
    await api("put", url, updatedFields);
  } catch (error) {
    setLocalData(currentFields);
    toast.error("Invalid credentials");
  }
};

export default <PropT>{
  get: (...args: any) =>
    validateApi(() => api("get", ...args), "canView", [...args]),
  post: (...args: any) =>
    validateApi(() => api("post", ...args), "canInsert", [...args]),
  put: (...args: any) =>
    validateApi(() => api("put", ...args), "canUpdate", [...args]),
  patch: (...args: any) =>
    validateApi(() => api("patch", ...args), "canUpdate", [...args]),
  delete: (...args: any) =>
    validateApi(() => api("delete", ...args), "canDelete", [...args]),
  optimisticUpdate,
};

const validateApi = (fn: any, action: string, args: any[]) => {
  let url = args[0];
  // consoleLog(args, "args");
  // alert(url);
  // let toUseArray = plainArray();
  // let exemptFromArray = toUseArray.map((el: any) => el.link);
  let exemptFromArray = [
    ...exceptionUrlArray,
    "login",
    "profile",
    "send-2fa-verify-otp",
    "send-2fa-otp",
    "system-logs/create",
  ];
  consoleLog(!isPartOfString(exemptFromArray, url), url);
  // consoleLog(url, "url__");
  if (isPartOfString(exemptFromArray, url) || validatePermissions(action)) {
    // return fn();
    return fn();
  } else {
    toast.error("Invalid Permissions");
  }
};
