import api from "shared/utils/api";

class Overview {
  getOverviewData = async () => {
    try {
      const response = await api.get("/overview");
      if (response) return response;
    } catch (error) {
      return error;
    }
  };
}

const overviewOBJ = new Overview();
export default overviewOBJ;
