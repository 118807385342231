import styled from "styled-components";

import { color, sizes } from "shared/utils/styles";

type TabPropT = {
  isActive: boolean;
};

// const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

// export const SettingsPage = styled.div``;

export const SettingsPageNav = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  display: flex;
  gap: 1.2rem;
  overflow-x: auto;
`;

export const Tab = styled.div<TabPropT>`
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  background: ${(prop) => (prop.isActive ? color.backgroundMedium : "")};
  color: ${(prop) => (prop.isActive ? color.fountain : "#000")};
  cursor: pointer;
`;
