import React, { useState } from "react";
import Layout from "Layout";
import { useHistory } from "react-router";
import { Container, Heading, GridContainer } from "../Styles";
import SingleBox from "../../Dashboard/Overview/firstSection/Box";
import { boxData } from "./../datalist";
import ReportStats from "./Stats/ReportStat";
import icon3 from "../../Dashboard/assets/icon3.svg";

export default function Home() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const linkUrlPath = (urlPath: string) => {
    history.push(`${history?.location?.pathname}/details/${urlPath}`);
  };

  const boxLists = boxData.map((box) => {
    let check = ![1, 4].includes(box?.id);
    return (
      <SingleBox
        noPointer={false}
        key={box.id}
        boxdata={box}
        onClick={() => box?.urlPath && linkUrlPath(box?.urlPath)}
        isLoading={isLoading}
      />
    );
  });

  return (
    <Container>
      <Heading>Report based on Gateway</Heading>
      {/* === report stats ===*/}
      <ReportStats />
      {/* ====== */}
      <GridContainer>
        <SingleBox
          noPointer={false}
          boxdata={{
            id: 10,
            icon: icon3,
            title: "Transactions",
            background: false,
            urlPath: "",
          }}
          onClick={() => history.push("/gateway/report/transactions")}
          isLoading={isLoading}
        />

        {boxLists}

        <SingleBox
          noPointer={false}
          boxdata={{
            id: 10,
            icon: icon3,
            title: "Settlement",
            background: false,
            urlPath: "",
          }}
          onClick={() => history.push("/settlements/overview")}
          isLoading={isLoading}
        />

        <SingleBox
          noPointer={false}
          boxdata={{
            id: 10,
            icon: icon3,
            title: "CBN Report",
            background: false,
            urlPath: "cbn",
          }}
          onClick={() => history.push("/gateway/report/cbnreport")}
          isLoading={isLoading}
        />
      </GridContainer>
    </Container>
  );
}
