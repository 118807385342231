import LineChart from "./LineChart/LineChart";
import PieChart from "./PieChart/PieChart";
import { DonoutchartContainer, LineChartContainer, Section } from "./Styles";

export default function FirstSection() {
  return (
    <>
      <Section>
        <LineChartContainer>
          <LineChart />
        </LineChartContainer>
        <DonoutchartContainer>
          <PieChart />
        </DonoutchartContainer>
      </Section>
    </>
  );
}
