type Tryer<T> = () => Promise<T>;
type TryerSync<T> = () => T;


// try-catch that handles async `tryer` function
export const tryCatch = async <T>(
  tryer: Tryer<T>,
) => {
  try {
    const result = await tryer();
    return [result, null] as const;
  } catch (error) {
    return [null, error] as const;
  }
};

export const tryCatchSync = <T>(
  tryer: TryerSync<T>,
) => {
  try {
    const result = tryer();
    return [result, null] as const;
  } catch (error) {
    return [null, error] as const;
  }
};
