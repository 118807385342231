import { ReactNode } from "react";

export const getStatusColor: any = (val: string) => {
  if (val && val.trim() !== "") {
    val = val.trim();
    if (val.toLowerCase().indexOf("failed") > -1) return "#FF2424";
    if (val.toLowerCase().indexOf("terminated") > -1) return "orange";
    if (val.toLowerCase().indexOf("success") > -1) return "#219653";
    if (val.toLowerCase().indexOf("active") > -1) return "#219653";
  }

  return "#ff871d";
};

export const getStatusDivColor = (val: string) => {
  if (val && val.trim() !== "") {
    return <div style={{ color: getStatusColor(val) }}>{val} </div>;
  }
  return null;
};
