import { color as colors } from "shared/utils/styles";
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
  SwitchContainer,
  SwitchLabel,
  SwitchLink,
} from "./Styles";

interface ToggleProps {
  id: string;
  status: boolean;
  label: string;
  color?: string;
  style?: any;
  toggleMethod: (item: any) => any;
}
const Switch = ({
  id,
  status,
  label,
  toggleMethod,
  color,
  style,
}: ToggleProps) => {
  const toggleNow = () => {
    toggleMethod(!status);
  };
  return (
    <SwitchContainer style={{ ...style }}>
      <CheckBoxWrapper>
        <CheckBox
          id={id}
          type="checkbox"
          onChange={() => toggleNow()}
          checked={status}
          // color={color ? colors.fountain : ""}
          style={{ backgroundColor: "red" }}
          color={status ? "#219653" : "red"}
        />
        <CheckBoxLabel htmlFor={id} />
      </CheckBoxWrapper>
      {color && (
        <SwitchLink
          style={{ color: status ? colors.fountain : "red" }}
          onClick={() => toggleNow()}
        >
          {/* "#292929" */}
          <SwitchLabel>{label}</SwitchLabel>
        </SwitchLink>
      )}
      {!color && (
        <SwitchLink
          style={{ color: status ? " #219653" : "red" }}
          onClick={() => toggleNow()}
        >
          {/* "#292929" */}
          <SwitchLabel>{label}</SwitchLabel>
        </SwitchLink>
      )}
    </SwitchContainer>
  );
};

export default Switch;
