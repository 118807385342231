import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import logo from "Assets/logo.svg";
import loginBanner from "Assets/loginbanner.svg";
import show from "shared/utils/toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "shared/quickComponents/modal";
import {
  BannerContainer,
  Container,
  FormContainer,
  InputContainer,
  InputLabel,
  LoginBanner,
  LoginButton,
  LoginForm,
  LoginInput,
  LoginPageContainer,
  LoginPageSpan,
  LogoContainer,
  LogoImage,
  Theform,
  WelcomeText,
  ErrorText,
  ErrorContainer,
  PasswordResetContainer,
  PwdResetForm,
  ResetEmailInput,
  ResetButton,
  ReguestSubtitle,
  BackIcon,
} from "./Styles";
import UserClass from "classes/user.class";
import PasswordMeter from "../PasswordMeter";
import Icon from "shared/components/Icon";
type userType = {
  email: string;
  fullname: string;
  role: string;
};

export default function SetPassword() {
  // const USER = useAppSelector((state) => state.USER);
  // const dispatch = useAppDispatch();

  const [userConfirmPassword, setUserConfirmPassword] = useState<string>("");
  const [userPassword, setuserPassword] = useState<string>("");
  const [loginError, setLoginError] = useState<string>("");
  const [isBtnActive, setisBtnActive] = useState<boolean>(false);
  const [btnText, setbtnText] = useState<string>("SET PASSWORD");
  const [loginAttemptCounter, setloginAttemptCounter] = useState<number>(5);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const history = useHistory();

  useEffect(()=>{
    // console.log(userPassword, userConfirmPassword, isPasswordValid);
    if(isPasswordValid && (userPassword===userConfirmPassword)){
      setisBtnActive(true);
    }else{
      setisBtnActive(false);
    }
  }, [isPasswordValid, userConfirmPassword, userPassword])

  const handleReset = async (e: any) => {
    e.preventDefault();
    setbtnText("Processing..");
    if (isBtnActive) {
      if (loginAttemptCounter >= 1) {
        const result = await UserClass.setPassword({ userPassword, userConfirmPassword });
        if (result === false) {
          toast.error(
            `Login not successful,you have ${loginAttemptCounter} attempt(s) left`,
            {
              position: "top-center",
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );

          setbtnText("SET PASSWORD");
        }
      } else {
        //send user email to the endpoint for barred user.
        show.error(
          "You have been barred, please contact admin to resolve your account issues"
        );
      }
    }
  };

  useLayoutEffect(() => {
    //Checking if user already login.
    UserClass.alreadyLogin() && history.push("/dashboard/over-view");
  }, []);

  return (
    <div>

      <LoginPageContainer>
        <Container>
          <FormContainer>
 
            <LoginForm>
              <LoginPageSpan>
                <BackIcon onClick={()=>history.push("/authenticate/login")}><Icon type="circle-left" />Back</BackIcon>
              </LoginPageSpan>
              <LoginPageSpan>
                <WelcomeText>Set your password</WelcomeText>
              </LoginPageSpan>
              
              {loginError && (
                <ErrorContainer>
                  <ErrorText>{loginError}</ErrorText>
                </ErrorContainer>
              )}
              <Theform onSubmit={handleReset} autoComplete="off">
                <InputContainer>
                  <InputLabel> Password</InputLabel>
                  <LoginInput
                    type="password"
                    placeholder="Password"
                    value={userPassword}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      setuserPassword(event.toString());
                    }}
                    required
                    autocomplete="off"
                  />
                </InputContainer>
                <InputContainer>
                  <InputLabel> Confirm Password</InputLabel>
                  <LoginInput
                    type="password"
                    placeholder="Confirm password"
                    value={userConfirmPassword}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      setUserConfirmPassword(event.toString());
                    }}
                    required
                    autocomplete="off"
                  />
                </InputContainer>
                <div style={{ width: "100%", marginTop: ".5rem" }}>
                  <LoginButton isActive={isBtnActive} type="submit">
                    {btnText}
                  </LoginButton>
                </div>
                <LoginPageSpan>
                  <PasswordMeter password={userPassword} setIsPasswordValid={setIsPasswordValid} />
                </LoginPageSpan>
                
              </Theform>
            </LoginForm>
          </FormContainer>
        </Container>
        <BannerContainer url={loginBanner}></BannerContainer>
      </LoginPageContainer>
    </div>
  );
}
