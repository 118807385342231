import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducer";
import idbs from "shared/utils/idb";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) || compose;

const checkStorage = async ({ state, commit }: any) => {
  state.dataFields.forEach(async (field: any) => {
    try {
      let data = await idbs.checkStorage(field);
      if (data === undefined) data = [];
      if (data === null) data = [];

      commit("setState", { field, data });
    } catch (e) {
      commit("setState", { field, data: [] });
    }
  });
};

const saveStorage = async ({ state }: any) => {
  // console.log(state);
  try {
    await Promise.all(
      state.dataFields.map((field: any) =>
        idbs.saveToStorage(field, state[field])
      )
    );
  } catch (e) {
    return;
  }
};

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);

// console.log(store.getState())
const allState = store.getState();
store.subscribe(() => saveStorage(store.getState()));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch