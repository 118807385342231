import { useLayoutEffect, useState } from "react";
import Filter from "shared/components/Filter";
import {
  ActionArea,
  ApprovedButton,
  OtpContainer,
  SubTitle,
  Title,
} from "../Styles";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../../shared/quickComponents/modal";
import OTPInput from "shared/components/OTPInput";
import Table from "shared/clientcomponents/Table";
import Switch from "shared/components/Switch";
import { RESETREQUEST } from "shared/utils/sitemap";
import logger from "../../shared/utils/logger";
import EmptyState from "../../shared/components/EmptyState";
import useLogger from "shared/hooks/useLogger";
import {
  getClientDetails,
  getIpAddress,
  getLocation,
} from "shared/utils/location";
import { getCurrentUser } from "shared/utils/authToken";
import api from "shared/utils/api";
import useApi from "shared/hooks/api";
import { GradientLoader, Icon } from "shared/components";
import { Section, TableContainer } from "AuditTrail/Styles";
import { fieldSorter } from "shared/utils/url";
import consoleLog from "shared/utils/consoleLog";

type usersPropT = {
  id: number;
  name: string;
  phoneNumber: string;
  email: string;
  activated: boolean;
};

const filterOptions = [
  {
    name: "name",
    label: "Name",
    checked: false,
  },
  {
    name: "email",
    label: "Email ID",
    checked: false,
  },
  {
    name: "phoneNumber",
    label: "Phone",
    checked: false,
  },
  {
    name: "activated",
    label: "Activated",
    checked: false,
  },
];

export default function ResetRequest() {
  const [filterBy, setFilterBy] = useState(filterOptions);
  const [users, setUsers] = useState<any[]>([]);
  const [adminAction, setAdminAction] = useState(1);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [Otp, setOtp] = useState("");
  const [isOTPError, setisOTPError] = useState(false);
  const [userID, setuserID] = useState<string>("");
  const [isopen, setisopen] = useState(false);
  const [clientLocation, setClientLocation] = useState<any>({});
  const [isloading, setIsloading] = useState(true);
  const [noRecord, setNoRecord] = useState(true);

  const currentUser = getCurrentUser();

  const [{ data, error, setLocalData }, fetchData] = useApi.get(
    "/reset-requests"
  );

  const usersTableHeaders = [
    // { id: "sn", label: "#" },
    { id: "userName", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone Number" },
    { id: "role", label: "Roles" },
    { id: "funcProps", label: "" },
  ];
  const prepareUser = async (users: any) => {
    const list = users.filter(
      (list: any) => list.email !== currentUser.email && list.passwordReset == 1
    );
    const UsersList: any = list.map((user: any, index: number) => ({
      id: user.id,
      sn: index + 1,
      userName: user.fullName,
      email: user.email,
      phone: user.phoneNumber,
      role: user.type,
      funcProps: (
        <ActionArea>
          <Icon
            type="user-check"
            color="green"
            size={22}
            title="Approve request"
            onClick={() => {
              setAdminAction(1);
              handleSwitch(user, 0);
            }}
            style={{ cursor: "pointer" }}
          />

          <Icon
            type="trash"
            color="red"
            size={22}
            title="Cancel request"
            onClick={() => {
              setAdminAction(0);
              handleSwitch(user, 0);
            }}
            style={{ cursor: "pointer" }}
          />
        </ActionArea>
      ),
    }));

    setNoRecord(false);
    setUsers(UsersList);
  };

  const handleFilter = (data: any) => {
    const filterData = data.filter((option: any) => option.checked);
    const sortDataBy = filterData.map((option: any) => option.name);
    const unSortDataBy = filterOptions.map((option: any) => option.name);
    const newDisputeData =
      sortDataBy.length > 0
        ? users.sort(fieldSorter(sortDataBy))
        : users.sort(fieldSorter(unSortDataBy));
    setUsers(newDisputeData);
    setFilterBy(data);
  };

  const openModal = () => {
    setisopen(!isopen);
  };

  const confirmOTP = async (otp: string, action: string) => {
    try {
      let response = await api.post("/confirm-otp", {
        otp,
        action,
        tempId: userID,
      });
      if (response.status) {
        toast.success(`${response.message}`);
        return true;
      } else {
        toast.error(`${response.message}`);
        return false;
      }
    } catch (e) {
      toast.error(`${e}`);
      return false;
    }
  };

  const handleChange = async (otp: string) => {
    setOtp(otp);
    if (otp.length === 6) {
      const userAction =
        adminAction === 1 ? "RESET_USER_PASSWORD" : "CANCEL_PASSWORD_REQUEST";
      try {
        if (await confirmOTP(otp, userAction)) {
          await api.post("/reset-password", { email: selectedUser.email });
          const { status, payload, message, error } = data;
          await fetchData();
          await logger.logActivities("remote", {
            date: new Date().toString(),
            author: currentUser.fullName,
            resource: "admin",
            action: `LOGIN`,
            payload: {
              userAgent: getClientDetails(),
              data: {
                detail: `${currentUser.fullName} ${userAction} by ${selectedUser.fullName}`,
              },
            },
          });
          openModal();
        }
      } catch (error) {
        toast.error("Operation failed. Please try again later.");
        // console.log("error", error);
        await logger.logActivities("remote", {
          date: new Date().toString(),
          author: currentUser.fullName,
          resource: "admin",
          action: `LOGIN`,
          payload: {
            userAgent: getClientDetails(),
            data: {
              detail: `${userAction} failed with the following error: ${error}`,
            },
          },
        });
      }
    } else {
      setisOTPError(true);
    }
  };

  const handleSwitch = async (user: any, action: number) => {
    try {
      const data = await api.post("/send-user-otp", {
        adminEmail: currentUser.email,
        requestedEmail: user.email,
      });
      const { status, payload, message, error } = data;
      consoleLog(data, "data");
      setuserID(payload?.tempId);
      setSelectedUser(user);
      openModal();
    } catch (error) {
      toast.error("Operation failed. Please try again later.");
      // console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (!data) setIsloading(true);
    if (error) {
      // console.log(error);
      setIsloading(false);
      setNoRecord(true);
    }
    if (!data && !error) setNoRecord(true);
    if (!data?.payload.length) {
      setIsloading(false);
      setNoRecord(true);
    }
    // console.log("Data loads: ", data);
    if (data && data?.status && data?.payload.length) {
      // console.log(data.payload);
      prepareUser(data.payload);
      setIsloading(false);
    }
  }, [data]);

  return (
    <>
      <Filter
        onChange={handleFilter}
        options={filterBy}
        title="Reset Requests"
      />
      <Section>
        {isloading ? (
          <GradientLoader />
        ) : (
          <>
            {noRecord ? (
              <EmptyState
                stateTitle="No pending request"
                statePara=""
                stateBtnText=""
              />
            ) : (
              <TableContainer>
                <Table headData={usersTableHeaders} bodyData={users} />
              </TableContainer>
            )}
          </>
        )}
      </Section>
      <Modal isOpen={isopen} closeButton={true} onClose={openModal} width="40%">
        <div style={{ paddingBottom: "2rem" }}>
          <Title>Check your email for OTP</Title>
          <SubTitle>
            We have sent a 6 - digit code to your email address. <br /> The code
            expires shortly, please enter it soon.
          </SubTitle>
          <OtpContainer>
            {" "}
            <OTPInput
              autoFocus
              isNumberInput
              length={6}
              hasError={isOTPError}
              errorStyle={{ borderColor: "red" }}
              inputStyle={{
                border: "1px solid #828282",
                width: "60px",
                height: "60px",
                fontSize: "30px",
                color: "#000",
                fontWeight: "400",
                caretColor: "#000",
                textAlign: "center",
                borderRight: "0px",
              }}
              focusStyle={
                {
                  //border: "1px solid #CFD3DB",
                }
              }
              firstIndexStyle={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderRight: "0px",
              }}
              lastIndexStyle={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderRight: "1px solid #828282",
                borderLeft: "1px solid #828282",
              }}
              className="otpContainer"
              inputClassName="otpInput"
              separator={
                <span
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "10%",
                    fontSize: "50px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  -
                </span>
              }
              onChangeOTP={handleChange}
            />
          </OtpContainer>
        </div>
      </Modal>
    </>
  );
}
