import { LogActivityData } from "AppTypes/index";
import history from "browserHistory";
import { tryCatchSync } from "guards/utils/tryCatch";
import api from "shared/utils/api";
import authFetch from "shared/Api/authFetch";
import { getStoredLogs, storeLogs } from "shared/utils/authToken";

interface LogReqPayload {
  resource: string;
  action: string[];
  pagination?: object;
  range?: object;
  sorting?: object;
}
class Logger_class {
  async logActivities(event: string, data: LogActivityData) {
    const formatted = await this.formatLogData(data);
    // console.log("outgoing data", formatted);
    if (event == "remote") {
      // console.log("log data", formatted);
      const res = await this.postLogInDb(formatted);
      // console.log("logger response:", res);
    } else {
      await storeLogs(formatted);
    }
  }
  async formatLogData(data: LogActivityData): Promise<LogActivityData> {
    return data as LogActivityData;
  }
  async postLogInDb(data: LogActivityData) {
    try {
      const user = await api.post("/system-logs/create", data);
      // user?.data && history.push("/dashboard/over-view");
      // // console.log("sending log details ==>", data);
      return true;
    } catch (error) {
      return false;
    }
  }

  async getLogs(payload: LogReqPayload) {
    try {
      const res = await api.post(`/system-logs`, payload);
      console.log("logs retrieved ===>", res);
      return res;
    } catch (error) {
      // console.log("log response from endpoint", error);
    }
  }

  async getClientLogs(payload: any) {
    // console.log("page id", payload);
    try {
      const res = await api.post(`/system-logs-client`, payload);
      console.log("logs retrieved ===>", res);
      return res;
    } catch (error) {
      // console.log("log response from endpoint", error);
    }
  }
}

const logger = new Logger_class();
export default logger;
