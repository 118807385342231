import React, { useState, useLayoutEffect, useEffect } from "react";
import useApi from "shared/hooks/api";
import {
  Box,
  BoxTitle,
  BoxValue,
  BusinesPageLayout,
  TopGrid,
  IconContainer,
  InfoGroup,
  StatusButton,
  Ribbon,
  InfoContainer,
  DetailsContainer,
  AttachBtn,
  PricingTabsCont,
  PricingTab,
} from "./Styles";
import Icon2 from "../../../App/assets/business page icons/icon2.svg";
import Icon3 from "../../../App/assets/business page icons/icon3.svg";
import Icon4 from "../../../App/assets/business page icons/icon4.svg";
import Icon5 from "../../../App/assets/business page icons/icon5.svg";
import Icon6 from "../../../App/assets/business page icons/icon6.svg";
import { Link, useHistory } from "react-router-dom";
import businessOBJ from "Business/businessClass";
import { EmptyState, GradientLoader } from "shared/components";
import {
  FontFigure,
  Single,
  SplitLabels,
} from "Business/Single/GridSection/Styles";
import PageOverlay from "shared/clientcomponents/PageOverlay";
import Table from "shared/clientcomponents/Table";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import {
  EditBtn,
  GroupedBtn,
  PlanStatus,
  TableActions,
  ViewAllBtn,
} from "Pricing/Styles";
import { numberFormat, numberIntFormat } from "shared/utils/utility";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";

type PropT = {
  getData: Function;
  tabName?: string;
};

export default function GridSection({ getData, tabName }: PropT) {
  const [details, setDetails] = useState<any>({});
  const [isloading, setIsloading] = useState(true);
  const [noRecord, setNoRecord] = useState(true);
  const [serviceData, setServiceData] = useState<any>([]);
  const [isOverlayOpened, setisOverlayOpen] = useState(false);
  const [activeBox, setactiveBox] = useState("Transactions");
  const [isnoRecord, setisnoRecord] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");
  const [isLoading, setisLoading] = useState(true);
  const [planData, setplanData] = useState([]);
  const [searchContent, setSearchContent] = useState<string | undefined>();
  const [isPlanLoading, setisPlanLoading] = useState(true);
  const { location } = useHistory();
  const [currentPriceTab, setcurrentPriceTab] = useState("Local");
  const [localPlans, setlocalPlans] = useState([]);
  const [intlPlans, setintlPlans] = useState([]);

  const routeDetails: any = location?.state;
  let businessname = routeDetails && routeDetails["businessName"];
  const serviceId = routeDetails && routeDetails["serviceId"];

  // const [{ data, error, setLocalData }, fetchData] = useApi.get(
  //   `/services/${serviceId}`
  // );

  const boxData = [
    {
      id: 2,
      icon: Icon2,
      label: "Transactions",
      value: 0,
      tabName: "transactions",
      isActive: true,
    },
    {
      id: 3,
      icon: Icon3,
      label: "Customers",
      tabName: "customers",
      value: 0,
      isActive: false,
    },
    {
      id: 4,
      icon: Icon4,
      label: "Total Settlement",
      tabName: "settlements",
      value: 0,
      isActive: false,
    },
    {
      id: 5,
      icon: Icon5,
      label: "Third Party Account",
      tabName: "thirdPartyAccounts",
      value: 0,
      isActive: false,
    },

    {
      id: 7,
      icon: Icon6,
      label: "Dispute Created",
      tabName: "disputesCreated",
      value: 0,
      isActive: false,
    },
  ];

  const boxSelected = (label: any) => {
    getData(label);
    setactiveBox(label);
  };

  const boxList = boxData.map((box) => (
    <Box
      key={box.id}
      isActive={activeBox === box.label ? true : false}
      onClick={() => boxSelected(box.label)}
    >
      <IconContainer isActive={activeBox === box.label ? true : false}>
        <img src={box.icon} alt="" />
      </IconContainer>
      <BoxTitle>{box.label}</BoxTitle>
      {/* {box.label === "Transactions" && (
        <p style={{ fontSize: "12px" }}>(Success)</p>
      )} */}
      {/* <BoxValue>{serviceData[box.tabName]}</BoxValue> */}
      <BoxValue>
        {box.label === "Transactions" && (
          <SplitLabels>
            <Single>
              <FontFigure size={28}>
                {serviceData[box.tabName]?.success}
              </FontFigure>
              <FontFigure>(Success)</FontFigure>
            </Single>
            <Single>
              <FontFigure size={28}>
                {serviceData[box.tabName]?.failed}
              </FontFigure>
              <FontFigure>(Failed)</FontFigure>
            </Single>
          </SplitLabels>
        )}
      </BoxValue>
    </Box>
  ));

  const getExtraData = async () => {
    const response = await businessOBJ.getServiceExtraData(serviceId);
    // console.log("service extra data", response);
    if (response) setServiceData(response);
  };

  const pricingtableHeader: any = [
    // {
    //   id: "sn",
    //   label: "#",
    // },
    {
      id: "pricingName",
      label: "Pricing Name",
    },
    {
      id: "pricingType",
      label: "Type",
    },
    {
      id: "pricingValue",
      label: "Value",
    },
    {
      id: "funcProps",
      label: "Status",
    },
  ];

  //Todo: Filter out the current plan from the returned list
  //====== desc: Get pricing plans for overlay
  const getplans = async () => {
    // setisLoading(true);
    setisPlanLoading(true);
    const response: any = await businessOBJ.getAllPricing(currentPage);
    if (response?.data) {
      //@desc: filter out the current plan from returned plan list
      const filteredPlans = response?.data.filter(
        (plan: any) => plan.uuid !== details?.pricing?.uuid
      );
      const local_plans = filteredPlans.filter(
        (plan: any) => plan?.currencyType === "LOCAL"
      );
      const intl_plans = filteredPlans.filter(
        (plan: any) => plan?.currencyType === "INTERNATIONAL"
      );
      setlocalPlans(local_plans);
      setintlPlans(intl_plans);
      setplanData(local_plans && local_plans);
      settotalPages(parseInt(response.totalPages));
      // setcurrentPage(parseInt(data?.payload?.currentPage));
      // consoleLog("plans list", filteredPlans);
      setisnoRecord(false);
      setisPlanLoading(false);
    } else {
      setisPlanLoading(false);
      setisnoRecord(true);
    }
  };
  // ========================

  //=== This function check and format pricing plan value field

  const displayValue = (priceType: any, priceValue: any) => {
    if (priceType === "PERCENTAGE") return `${priceValue?.percentage}%`;
    if (priceType === "FLAT") {
      if (currentPriceTab === "Intl") {
        return numberIntFormat(priceValue?.flat);
      } else {
        return numberFormat(priceValue?.flat);
      }
    }
    if (priceType === "DYNAMIC")
      if (currentPriceTab === "Intl") {
        return `${priceValue.percentage}% + ${numberIntFormat(
          priceValue?.flat
        )}`;
      } else {
        return `${priceValue.percentage}% + ${numberFormat(priceValue?.flat)}`;
      }
  };

  const handlePricingUpdate = async (pricingID: string) => {
    const response = await businessOBJ.updatePricing(serviceId, {
      pricing_id: pricingID,
      type: currentPriceTab === "Local" ? "LOCAL" : "INTERNATIONAL",
    });
    if (response?.status) {
      toast.success(response?.message);
      setisOverlayOpen(false);
    } else {
      toast.error("Pricing update failed!, try again");
      setisOverlayOpen(false);
    }
  };

  // ====== plan list ======
  const planList =
    planData.length &&
    planData?.map((plan: any, index: number) => ({
      id: "",
      sn: index + 1,
      pricingName: plan.name,
      pricingType: plan.type,
      pricingValue: displayValue(plan.type, plan.value),
      funcProps: (
        <TableActions>
          <PlanStatus>{plan.status}</PlanStatus>
          <GroupedBtn>
            {/* pass plan into handleEdit function */}
            <AttachBtn onClick={() => handlePricingUpdate(plan?.id)}>
              Attach to service
            </AttachBtn>
          </GroupedBtn>
        </TableActions>
      ),
    }));
  //  ===================

  // === filter
  const handleFilter = (searchData: any) => {
    setSearchContent(searchData);
    const newlist = planData.filter((plan: any) => {
      if (
        plan.name
          .toString()
          .toLowerCase()
          .indexOf(searchData.toString().toLowerCase()) > -1
      )
        return plan;
    });
    if (newlist.length) setplanData(newlist);

    // if (!searchData) setplanData(data.payload?.data);
  };
  // ====================

  const getServiceDetails = async () => {
    const data = await businessOBJ.getServiceDetails(serviceId);
    if (data && data.status) {
      consoleLog("service data =======>", data);
      setDetails(data?.payload);
      setNoRecord(false);
      setIsloading(false);
    }
  };

  useLayoutEffect(() => {
    getExtraData();
    if (serviceId) {
      getServiceDetails();
    }
  }, [serviceId]);

  useEffect(() => {
    getplans();
  }, [isOverlayOpened]);

  return (
    <>
      {/* ======== pricing plans modal============ */}
      <PageOverlay
        isToogled={isOverlayOpened}
        toogleOverlay={() => setisOverlayOpen(false)}
      >
        <>
          {isPlanLoading ? (
            <GradientLoader />
          ) : (
            <>
              {isnoRecord ? (
                <EmptyState />
              ) : (
                <>
                  <DashboardSubHeader
                    // count={totalBusinesses ? totalBusinesses : 0}
                    title={"Price List"}
                    btnTitle=""
                    withSearch={true}
                    placeholder="Pricing Name"
                    withFilter={false}
                    widthActionBtn={false}
                    filterOptions={["Name", "Date"]}
                    setSearchData={handleFilter}
                    callBackFn={() => ""}
                    searchContent={searchContent}
                  />

                  <PricingTabsCont>
                    <PricingTab
                      isActive={currentPriceTab === "Local" && true}
                      onClick={() => {
                        setcurrentPriceTab("Local");
                        setplanData(localPlans);
                      }}
                    >
                      Local plans
                    </PricingTab>
                    <PricingTab
                      isActive={currentPriceTab === "Intl" && true}
                      onClick={() => {
                        setcurrentPriceTab("Intl");
                        setplanData(intlPlans);
                      }}
                    >
                      International plans
                    </PricingTab>
                  </PricingTabsCont>
                  <Table
                    headData={pricingtableHeader}
                    bodyData={planList ? planList : []}
                    totalPages={totalPages}
                    currentpage={currentPage}
                    goToPage={(value: any) => {
                      setcurrentPage(value);
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      </PageOverlay>
      {/* ================================== */}

      <BusinesPageLayout>
        <DetailsContainer>
          <InfoContainer>
            <TopGrid>{boxList}</TopGrid>
          </InfoContainer>

          {noRecord ? (
            <GradientLoader />
          ) : (
            <>
              <InfoContainer isDetails={true}>
                <h3>Service Details</h3>
                <InfoGroup>
                  <p>Service Name:</p>
                  <p>{details?.name?.toUpperCase()}</p>
                </InfoGroup>
                <InfoGroup>
                  <p>Description:</p>
                  <p>{details?.description}</p>
                </InfoGroup>

                <InfoGroup>
                  <p>Service ID:</p>
                  <p>{details?.serviceId}</p>
                </InfoGroup>

                <InfoGroup>
                  <p>Service Mode</p>
                  <p>
                    <StatusButton mode={details?.mode}>
                      {details?.mode}
                    </StatusButton>
                  </p>
                </InfoGroup>

                <br />
                <InfoGroup>
                  <p>Pricing plan:</p>
                  <p
                    style={{
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    {!details?.pricing.length &&
                      <>
                        <span>Default Price</span>
                        <button
                        style={{
                          padding: "4px 8px",
                          background: "#333",
                          color: "#fff",
                          borderRadius: "2rem",
                          cursor: "pointer",
                          fontSize: "0.8rem",
                        }}
                        onClick={() => setisOverlayOpen(true)}
                      >
                        Change
                      </button>
                      </>
                    }
                    {details?.pricing &&
                      <>
                        {details?.pricing.map((price: any, ind: number)=>{
                          <div key={ind}>
                            <span>{price.name}</span>
                            <button
                            style={{
                              padding: "4px 8px",
                              background: "#333",
                              color: "#fff",
                              borderRadius: "2rem",
                              cursor: "pointer",
                              fontSize: "0.8rem",
                            }}
                            onClick={() => setisOverlayOpen(true)}
                          >
                            Change
                          </button>
                          </div>
                        })}
                        
                    </>
                    }
                    
                  </p>
                </InfoGroup>
                <Ribbon mode={details?.mode}></Ribbon>
              </InfoContainer>
            </>
          )}
        </DetailsContainer>

        {/*  */}
      </BusinesPageLayout>
    </>
  );
}
