import React, { Fragment, useState, useRef, useEffect, useCallback, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import useOnOutsideClick from 'shared/hooks/onOutsideClick';
import useOnEscapeKeyDown from 'shared/hooks/onEscapeKeyDown';

import { ScrollOverlay, ClickableOverlay, StyledModal, CloseIcon } from './Styles';

// const propTypes = {
//   className: PropTypes.string,
//   testid: PropTypes.string,
//   variant: PropTypes.oneOf(['center', 'aside']),
//   width: PropTypes.number,
//   withCloseIcon: PropTypes.bool,
//   isOpen: PropTypes.bool,
//   onClose: PropTypes.func,
//   renderLink: PropTypes.func,
//   renderContent: PropTypes.func.isRequired,
// };

type PropT = {
  className: string,
  testid: string,
  variant: 'center' | 'aside',
  width: number,
  withCloseIcon: boolean,
  isOpen: boolean,
  onClose: ()=>void,
  renderLink: ({})=>void,
  renderContent: any,
};

const defaultProps = {
  className: undefined,
  testid: 'modal',
  variant: 'center',
  width: 600,
  withCloseIcon: true,
  isOpen: undefined,
  onClose: () => {},
  renderLink: (x:any)=> {} ,
};

const Modal = ({
  className,
  testid,
  variant,
  width,
  withCloseIcon,
  isOpen: propsIsOpen,
  onClose: tellParentToClose,
  renderLink,
  renderContent,
} : PropT) => {
  const [stateIsOpen, setStateOpen] = useState(false);
  const isControlled = typeof propsIsOpen === 'boolean';
  const isOpen = isControlled ? propsIsOpen : stateIsOpen;

  const $modalRef = useRef();
  const $clickableOverlayRef = useRef();

  const closeModal = useCallback(() => {
    if (!isControlled) {
      setStateOpen(false);
    } else {
      tellParentToClose();
    }
  }, [isControlled, tellParentToClose]);

  useOnOutsideClick($modalRef, isOpen, closeModal, $clickableOverlayRef);
  useOnEscapeKeyDown(isOpen, closeModal);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isOpen]);

  return (
    <Fragment>
      <>
      {!isControlled && renderLink({ open: () => setStateOpen(true) })}

      {isOpen &&
        ReactDOM.createPortal(
          <ScrollOverlay>
            {/* @ts-ignore */}
            <ClickableOverlay variant={variant} ref={$clickableOverlayRef}>
              <StyledModal
                className={className}
                variant={variant}
                width={width}
                data-testid={testid}
                // @ts-ignore
                ref={$modalRef}
              >
                <>
                  {withCloseIcon && <CloseIcon type="close" variant={variant} onClick={closeModal} />}
                  {renderContent({ close: closeModal })}
                </>
              </StyledModal>
            </ClickableOverlay>
          </ScrollOverlay>,
          $root ,
        )}
      </>
    </Fragment>
  );
};

const $root : any = document.getElementById('root');

// Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
