import { useState } from "react";
import FirstSection from "./firstSection";
import { OverviewPageLayout } from "./Styles";

export default function Overview() {
  return (
    <OverviewPageLayout>
      <FirstSection />
    </OverviewPageLayout>
  );
}
