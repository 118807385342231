import React from "react";
import eyeopen from "./assets/eyeopen.svg";
import eyeclose from "./assets/eyeclosed.svg";
import { EyeClosed as Eyeclosed, EyeOpen as Eyeopen } from "./Styles";

type eyeOpenPropT = {
  height: string;
};

type eyeClosePropT = {
  size: string;
};

export function EyeOpen({ height }: eyeOpenPropT) {
  return <Eyeopen height={height} src={eyeopen} />;
}

export function EyeClosed({ size }: eyeClosePropT) {
  return <Eyeclosed size={size} src={eyeclose} />;
}
