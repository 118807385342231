import { LogActivityData } from "AppTypes";
import { useEffect, useRef, useState } from "react";
import Modal from "shared/quickComponents/modal";
import Filter from "shared/components/Filter";
import logger from "shared/utils/logger";
import { fieldSorter } from "shared/utils/url";
import Table from "shared/clientcomponents/Table";
import EmptyState from "../shared/components/EmptyState";
import AccountRow from "./AccountRow";
import {
  Section,
  ModalContentLeft,
  ModalContentRight,
  ModalContentRow,
  UserModal,
  ViewButton,
  TableContainer,
  PaginationContainer,
  PaginationButtonsContainer,
  PaginationNext,
  PaginationPrev,
} from "./Styles";
import { GradientLoader } from "shared/components";
import { is } from "cypress/types/bluebird";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { getDates } from "shared/utils/utility";
import { useEffectOnce } from "shared/hooks/useEffectOnce";

type usersPropT = {
  id: number;
  activityLog: string;
  description: string;
  merchant: string;
  time: String;
};

const filterOptions = [
  {
    name: "LOGIN",
    label: "Logins",
    checked: true,
  },
  {
    name: "merchant",
    label: "Merchants",
    checked: false,
  },
  {
    name: "REVIEW",
    label: "Reviews",
    checked: false,
  },
  {
    name: "execute",
    label: "Execute",
    checked: false,
  },
];

let dates = getDates();

export default function AdminLog() {
  const [filterBy, setFilterBy] = useState(filterOptions);
  const [logs, setLogs] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isloading, setisloading] = useState(true);
  const [totalPages, settotalPages] = useState<any>("");
  const [currentpage, setcurrentpage] = useState<number>(1);
  const [currentDates, setcurrentDates] = useState<any>(dates);
  const [currentAction, setcurrentAction] = useState<any[]>(["LOGIN"]);

  const history = useHistory();
  const divRef = useRef(null);

  const columnHeaders = [
    { id: "sn", label: "#" },
    { id: "date", label: "Date" },
    { id: "author", label: "Author" },
    { id: "ipaddress", label: "IP Address" },
    { id: "action", label: "Action" },
    { id: "detail", label: "Detail" },
    { id: "useragent", label: "Machine" },
    { id: "funcProps", label: "" },
  ];

  const handleLogData = (log: any) => {
    history.push("/system-logs/details/" + log.id);
  };

  const logList = logs.map((log, index) => ({
    id: index + 1,
    sn: index + 1,
    date: moment(log.createdAt).format("LLLL"),
    author: log.author,
    ipaddress: log.payload?.ipAddress,
    action: log.action,
    detail: log.payload?.data?.detail,
    useragent: log.payload?.userAgent,
    funcProps: <ViewButton onClick={() => handleLogData(log)}>View</ViewButton>,
  }));

  const handleFilter = (data: any) => {
    const filterData = data.filter((option: any) => option.checked);
    const sortDataBy = filterData.map((option: any) => option.name);
    const unSortDataBy = filterOptions.map((option: any) => option.name);
    const newDisputeData =
      sortDataBy.length > 0
        ? logs.sort(fieldSorter(sortDataBy))
        : logs.sort(fieldSorter(unSortDataBy));

    setLogs(newDisputeData);
    setFilterBy(data);
  };

  const handleFilterRemote = async (data: any) => {
    const { actions, date } = data;

    // console.log("query with dates", date, "Query actions: ", actions);
    if (date) {
      // console.log("in dates", date.startDate);
      await setcurrentDates({
        startDate: date.startDate,
        endDate: date.endDate,
      });
      // console.log("Current dates: ", currentDates)
    }
    // console.log("actions", actions);
    if (actions.length) {
      let actionsItems: any = actions.map((item: any) => {
        return item.name.toString().toUpperCase();
      });
      await setcurrentAction(actionsItems);
    } else {
      let actionsItems: any = filterOptions.map((item: any) => {
        return item.name.toString().toUpperCase();
      });
      await setcurrentAction(actionsItems);
    }
    // console.log("Current actions", currentAction);
  };

  const getAllLogs = async () => {
    setisloading(true);
    const payload: any = {};
    payload.resource = "admin";
    if (currentpage > 0) {
      payload.pagination = { page: currentpage };
    }

    if (currentDates) {
      // console.log("Current dates test:", currentDates);
      payload.range = {
        // start: new Date(currentDates[0]),
        // end: new Date(currentDates[1]),
        start: currentDates.startDate //Checking if state is ready or not to avoid undefined
          ? currentDates.startDate + "T00:00:00.077Z" //new Date(currentDates.startDate)
          : dates[0] + "T00:00:00.077Z", //new Date(dates[0])
        end: currentDates.endDate
          ? currentDates.endDate + "T23:59:00.077Z"
          : dates[1] + "T23:59:00.077Z", //new Date(dates[1])
      };
    }

    if (currentAction && currentAction.length) {
      payload.action = currentAction;
    }
    payload.sorting = { field: "action", order: "desc" };
    // console.log("Log payload: ", payload);
    const data: any = await logger.getLogs(payload);
    // console.log("Log data: ", data);
    if (data?.payload && data?.payload?.length) {
      settotalPages(data.meta.totalPages);
      setisloading(false);
      setLogs(data?.payload);
    } else {
      setLogs([]);
    }
    if (!data?.payload?.length) setisloading(false);
  };

  const nextPage = () => {
    if (currentpage < totalPages) {
      let nextItem = currentpage + 1;
      setcurrentpage(nextItem);
      getAllLogs();
    }
  };

  const prevPage = () => {
    if (currentpage > 1) {
      let prevItem = currentpage - 1;
      setcurrentpage(prevItem);
      getAllLogs();
    }
  };

  const getALLRecords = () => {
    setcurrentpage(0);
    getAllLogs();
  };

  useEffect(() => {
    getAllLogs();
  }, [currentpage, currentAction, currentDates]);

  // useEffectOnce(() => {
  //   setcurrentpage(1);
  //   //getAllLogs();
  // });

  /*useEffectOnce(() => {
    getAllLogs({
      pagination:{page: currentpage},sorting: {field: "action",order: "desc"},range:{start: new Date(currentDates[0]), end: new Date(currentDates[1])}, action:currentAction});
  }, []);*/

  if (!logs) {
    return <GradientLoader />;
  }

  return (
    <>
      <Filter
        onChange={handleFilterRemote}
        options={filterBy}
        title="Admin System logs"
        downloadable={{
          headers: columnHeaders,
          content: logList,
          title: "Admin System logs",
        }}
        tableName={document.getElementById("table-to-xls") as HTMLElement}
        itemRef={document.getElementById("printArea") as HTMLElement}
      />

      <Section>
        {isloading ? (
          <GradientLoader />
        ) : (
          <>
            <TableContainer id="printArea">
              {logs.length ? (
                <>
                  <Table
                    headData={columnHeaders}
                    bodyData={logList}
                    maxwidth="8rem"
                    border={true}
                  />
                </>
              ) : (
                <EmptyState stateTitle="No Logs" statePara="" stateBtnText="" />
              )}
            </TableContainer>

            {logs.length ? (
              <PaginationContainer>
                <div>
                  {currentpage} of {totalPages} pages
                </div>

                <PaginationButtonsContainer>
                  {currentpage > 1 && (
                    <PaginationPrev onClick={prevPage}>
                      {"< "}Prev
                    </PaginationPrev>
                  )}
                  {totalPages > 1 && (
                    <>
                      {currentpage < totalPages && (
                        <PaginationNext onClick={nextPage}>
                          Next {` >`}
                        </PaginationNext>
                      )}
                      <PaginationNext onClick={getALLRecords}>
                        All
                      </PaginationNext>
                    </>
                  )}
                </PaginationButtonsContainer>
              </PaginationContainer>
            ) : (
              ""
            )}
          </>
        )}
      </Section>
    </>
  );
}
