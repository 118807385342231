import { isDisabled } from "@testing-library/user-event/dist/utils";
import React from "react";
import { FormInput, InputLabel, FormDisabledInput } from "./Styles";

type PropT = {
  placeholder?: string;
  onChange: Function;
  label?: string;
  disabled?: boolean;
  inputType?: string;
};

type PropSharedInput = {
  placeholder?: string;
  label?: string;
  disabled: boolean;
};

export function SharedInput({
  placeholder,
  label,
  onChange,
  disabled,
  inputType,
}: PropT) {
  return (
    <>
      {label ? (
        <div style={{ marginBottom: "1.4rem", width: "100%" }}>
          <InputLabel>{label}</InputLabel>
          <FormInput
            onChange={() => onChange()}
            disabled={disabled}
            placeholder={placeholder}
            type={inputType ? inputType : "text"}
          />
        </div>
      ) : (
        <>
          <FormInput onChange={() => onChange()} disabled={disabled} />
        </>
      )}
    </>
  );
}

export function SharedDisbaledInput({
  placeholder,
  label,
  disabled,
}: PropSharedInput) {
  return (
    <div>
      <FormDisabledInput placeholder={placeholder} disabled={disabled} />
    </div>
  );
}
