import styled from "styled-components";
import { color } from "../../shared/utils/styles";
import {
  ContentHeaderContainer,
  ContentHeader,
  Content,
} from "shared/section_components/Styles";

type contentPropT = {
  align?: string;
};

type modalButtonPropT = {
  option?: boolean;
};

export const PageLayout = styled.div`
  margin-top: 1rem;
  width: 100%;
  padding: 2rem 0;
`;

export const PageHeader = styled.div`
  width: 100%;
  padding: 2rem 0;
  // border: 1px solid red;
`;

export const PageContent = styled.div`
  margin-top: 2rem;
  background-color: #fff;
  padding: 2rem 2rem;
  width: 100%;
  min-height: 10rem;
  border-radius: 20px;
`;

export const CHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${color.fountainLight};
  padding-right: 2rem;
`;

export const InviteContentHeader = styled.div<contentPropT>`
  width: 30%;
  padding: 1rem 1rem;
  color: ${color.fountain};
  font-size: 18px;
  font-weight: 900;
  display: flex;
  justify-content: ${(prop) => prop.align === "end" && "flex-end"};
`;
export const InviteContent = styled.div<contentPropT>`
  width: 30%;
  padding: 1rem 0rem;
  font-size: 16px;
  display: flex;
  justify-content: ${(prop) => prop.align === "end" && "flex-end"};
`;

export const ListContainer = styled.div`
  max-height: 50vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background-color: white;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${color.fountain};
    max-height: 20px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;
export const InviteContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0rem 2rem;
  margin-top: 0.4rem;
  margin-bottom: 0.2rem;
  border-bottom: 1px solid ${color.backgroundMedium};
  /* cursor: pointer; */
  &:hover {
    /* background-color: ${color.backgroundLight}; */
  }
`;

export const ViewAllButton = styled.button`
  background-color: #fff;
  color: ${color.fountain};
  padding: 0.8rem 2rem;
  border-radius: 0.4rem;
  border: 1px solid ${color.fountain};
  cursor: pointer;
  transition: all ease 0.8s;

  &:hover {
    background-color: ${color.fountain};
    color: #fff;
  }
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #760000;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  font-size: 14px;
`;

export const ResendContainer = styled.div`
  display: flex;
  color: #04b776;
  gap: 0.4rem;
  align-items: center;
  cursor: pointer;
`;

export const RevokeContainer = styled.div`
  display: flex;
  align-items: center;
  color: #ff0000;
  gap: 0.4rem;
  cursor: pointer;
`;

export const ActionIcon = styled.img`
  width: 1.2rem;
  height: 1rem;
  /* margin-top: 0.1rem; */
`;

export const ActionDIcon = styled.img`
  width: 1.4rem;
  height: 1.4rem;
  /* margin-top: 0.1rem; */
`;

export const ModalContent = styled.div`
  width: 90%;
  margin: auto;
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    padding: 0.4rem;
    text-align: center;
  }
`;

export const ModalButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
`;

export const ModalButton = styled.button<modalButtonPropT>`
  padding: 0.6rem 2rem;
  background-color: ${(prop) =>
    prop.option ? color.fountainLight : color.fountain};
  color: ${(prop) => (prop.option ? "#000" : "#fff")};
  border-radius: 0.4rem;
`;

export const ActionLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
