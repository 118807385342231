import React from "react";
import { TableEmpty } from "../Styles";
import {
  TableRowContainer,
  TableLinkRowContainer,
  TableLinkTdwContainer,
} from "./Style";

const TableRow = ({
  data,
  headData,
  hoverLink,
  maxwidth,
  border,
  ...props
}: any) => {
  const Container = hoverLink ? TableLinkRowContainer : TableRowContainer;
  const hoverClick = (elm: any) => {
    if (hoverLink) {
      hoverLink(elm);
    }
  };
  // console.log('table row ', props)
  return (
    <Container>
      <TableLinkTdwContainer>{props?.serialNumber}</TableLinkTdwContainer>
      {headData?.map((item: any, index: any) => {
        return (
          <TableLinkTdwContainer
            index={index + 1}
            key={item.id}
            onClick={() => hoverClick(data)}
            maxwidth={maxwidth}
            border={border}
            {...props}
          >
            {data[item.id]}
          </TableLinkTdwContainer>
        );
      })}
    </Container>
  );
};

export default TableRow;
