import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

type tooglePropT = {
  isActive: boolean;
};

export const TabsContainer = styled.div`
  width: 100%;
  min-height: 2rem;
`;

export const TabsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
  border-bottom: 1px solid ${color.borderLight};
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
    padding-top: 0px;
  }
`;

export const TabsTitle = styled.div`
  margin-bottom: 1rem;
  text-align: left;
  h2 {
    margin-bottom: 0.3rem;
  }
`;

export const TabButton = styled.li<tooglePropT>`
  background-color: ${(prop) => prop.isActive && "#fff"};
  border-left: ${(prop) => prop.isActive && `1px solid ${color.borderLight}`};
  border-right: ${(prop) => prop.isActive && `1px solid ${color.borderLight}`};
  border-top: ${(prop) => prop.isActive && `1px solid ${color.borderLight}`};
  border-bottom: ${(prop) => prop.isActive && `0px solid ${color.borderLight}`};
  align-items: center;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  cursor: pointer;
  &:hover {
    background-color: #fff;
  }
`;

export const TabsWrapper = styled.ul`
  display: inline-block;
  list-style: none;
  align-self: flex-end;
  align-content: flex-end;
  padding: 15px;
  position: relative;
  top: 1.4px;

  ${TabButton} {
    display: inline;
    height: 100%;
    padding: 1rem;
    margin: 12px;
    @media ${breakpoints.device.max.xs} {
      margin-left: 0;
    }
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    padding-left: 0;
  }
`;

export const TabsContent = styled.div`
  display: block;
  margin-top: 15px;
`;
