import { GradientLoader } from "shared/components";
import EmptyState from "shared/components/EmptyState";
import Table from "shared/clientcomponents/Table";
import { SectionContainer, TableContainer, ViewAllBtn } from "../Style";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import AgencyBankingObj from "AgencyBanking/class.agency";
import { numberFormat } from "shared/utils/utility";
import { useHistory, useParams } from "react-router-dom";

type PropT = {
  [x: string]: any;
};

export default ({ setPage }: any) => {
  const [data, setdata] = useState([]);
  const [content, setcontent] = useState<any>([]);
  const [isloading, setIsloading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const history = useHistory();

  const tableHeader = [
    { id: "agentID", label: "Agent ID" },
    { id: "agentName", label: "Agent Name" },
    { id: "numberOfAgents", label: "Number of Agents" },
    { id: "amountWithdraw", label: "Amount Withdraw" },
    { id: "transactions", label: "Transactions" },
  ];

  const getAgents = async () => {
    const response = await AgencyBankingObj.getAllAgents(currentPage);
    console.log("agents fetched", response);
    if (response) {
      setIsloading(false);
      setdata(response?.data);
      setcontent(response?.data);
      setIsloading(false);
    }
  };

  const agentsData = content?.map((el: any) => {
    return {
      agentID: el?.agentId,
      agentName: `${el?.user.firstName} ${el?.user.lastName}`,
      numberOfAgents: el?.numberOfAgents,
      amountWithdraw: numberFormat(el?.amountWithdraw),
      transactions: el?.numOfTransactions,
    };
  });

  const hoverLink = (elm: PropT) => {
    history.push(`/agencybanking/agent/${elm?.agentID}`);
  };

  useEffect(() => {
    getAgents();
    setIsloading(true);
  }, [currentPage]);
  return (
    <>
      <SectionContainer>
        {isloading ? (
          <GradientLoader />
        ) : (
          <>
            <TableContainer>
              <Table
                headData={tableHeader}
                bodyData={agentsData.slice(0, 4)}
                hoverLink={hoverLink}
              />
            </TableContainer>

            {agentsData?.length > 1 && (
              <>
                <br />
                <Link to="./all-agents">
                  <ViewAllBtn>View all</ViewAllBtn>
                </Link>
              </>
            )}
          </>
        )}
      </SectionContainer>
    </>
  );
};
