import { omniGetter, omniSaver } from "shared/utils/authToken";
import { ActionTypes } from "../constants/actionType";

let category=omniGetter("category");
category=category ? JSON.parse(category) : '';
const initialState = {
  category: category ? category?.payload : "Gateway",
};

export const consoleCategoryReducer = (
  state: any = initialState.category,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_CONSOLE_CATEGORY:
      // alert(JSON.stringify(action))
      omniSaver("category", JSON.stringify(action));
      return action.payload;
    default:
      // alert(state)
      return state;
  }
};
