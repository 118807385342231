import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import FirstSection from "./firstSection";
import SecondSection from "./secondSection";
import api from "shared/utils/api";
import useApi from "shared/hooks/api";
import show from "shared/utils/toast";
import { OverviewPageLayout } from "./Styles";
import { useEffectOnce } from "shared/hooks/useEffectOnce";
import { GradientLoader } from "shared/components";
import EmptyState from "shared/components/EmptyState";
import consoleLog from "shared/utils/consoleLog";
import overviewOBJ from "./class.overview";

export default function() {
  const [contents, setcontents] = useState<any>({});
  const [isLoading, setisLoading] = useState(false);
  const [NoRecord, setNoRecord] = useState(true);
  // const [{ data, error, setLocalData }, fetchData] = useApi.get(`/overview`);

  const contentRef = useRef<any>(null);
  const getOviewData = async () => {
    setisLoading(true);
    try {
      const response: any = await overviewOBJ.getOverviewData();
      setcontents(response?.payload);
      contentRef.current = response?.payload;
      //console.log("overview response", response);
    } catch (error) {
      setisLoading(false);
    } finally {
      setisLoading(false);
    }
  };

  // const getBusinessData = async () => {
  //   setisLoading(true);
  //   try {
  //     const res: any = await api.get("/business");
  //   } catch (error) {
  //     console.log("my err", error);
  //   } finally {
  //     setisLoading(false);
  //   }
  // };

  // useLayoutEffect(() => {
  //   if (!data) setisLoading(true);
  //   if (error) {
  //     setisLoading(false);
  //   }
  //   if (data) {
  //     consoleLog("network request on ====> overview", data?.payload);
  //     setcontents(data.payload);
  //     setisLoading(false);
  //   }
  // }, [data, error]);

  useLayoutEffect(() => {
    const controller = new AbortController();
    getOviewData();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <OverviewPageLayout>
        <FirstSection
          data={contentRef.current?.modules ?? {}}
          isLoading={isLoading}
        />
        <SecondSection data={contentRef.current?.transactionGraph ?? {}} />
      </OverviewPageLayout>
    </>
  );
}
