import { useState } from "react";
import { Checkbox, Input } from "shared/components";
import { FilterInputCont, FilterParamsCont, FilterSec, FilterText, InputHolder, TextDescription } from "./Styles";
import { PopUpSubHead } from "../DashboardSubHeader/Styles";

type PropT = {
    onProcessFilter: Function
}
const SubaccountFilter=({onProcessFilter}: PropT)=>{
    const [start, setStart] = useState<string>("");
    const [end, setEnd] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [successful, setSuccessful] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);
    const [terminated, setTerminated] = useState<boolean>(false);

    const [card, setCard] = useState<boolean>(false);
    const [qrpay, setQrpay] = useState<boolean>(false);
    const [internetBanking, setInternetBanking] = useState<boolean>(false);
    const [directDebit, setDirectDebit] = useState<boolean>(false);

    const onChangeTextHandler=()=>{
        onProcessFilter({date:{start, end}, search, status:{successful, failed, terminated}, channel:{card, qrpay, internetBanking, directDebit}})
    }
    return (
        <FilterSec>
            <PopUpSubHead>Search transaction</PopUpSubHead>
            <FilterText>
                <TextDescription>Search</TextDescription>
                <Input
                  name={"search"}
                  type="text"
                  value={search}
                  onChange={(e: any) => {setSearch(e.target.value); onChangeTextHandler();}}
                />
              </FilterText>

            <PopUpSubHead>Date range</PopUpSubHead>
            <FilterInputCont>
                <InputHolder>
                    <Input
                        name={"start"}
                        type={"date"}
                        value={start}
                        placeholder={"Start date"}
                        onChange={(e: any) =>{setStart(e.target.value); onChangeTextHandler();}}
                    />
                </InputHolder>

                <InputHolder>
                    <Input
                        name={"end"}
                        type={"date"}
                        value={end}
                        placeholder={"End date"}
                        onChange={(e: any) =>{setEnd(e.target.value); onChangeTextHandler();}}
                    />
                </InputHolder>
            </FilterInputCont>

            <PopUpSubHead>Payment channels</PopUpSubHead>
            <FilterParamsCont>
                <Checkbox
                    onChange={(e: any) => {setCard(!card); onChangeTextHandler();}}
                    label={"Card"}
                    value={card}
                    name={"card"}
                ></Checkbox>
                <Checkbox
                    onChange={(e: any) => {setQrpay(!qrpay); onChangeTextHandler();}}
                    label={"QR Pay"}
                    value={qrpay}
                    name={"qrpay"}
                ></Checkbox>
                <Checkbox
                    onChange={(e: any) => {setInternetBanking(!internetBanking); onChangeTextHandler();}}
                    label={"Internet Banking"}
                    value={internetBanking}
                    name={"internetBanking"}
                ></Checkbox>
                <Checkbox
                    onChange={(e: any) => {setDirectDebit(!directDebit); onChangeTextHandler();}}
                    label={"Direct debit"}
                    value={directDebit}
                    name={"directDebit"}
                ></Checkbox>
            </FilterParamsCont>

            <PopUpSubHead>Transaction status</PopUpSubHead>
            <FilterParamsCont>
                <Checkbox
                    onChange={(e: any) => {setSuccessful(!successful); onChangeTextHandler();}}
                    label={"Successful"}
                    value={successful}
                    name={"successful"}
                ></Checkbox>
                <Checkbox
                    onChange={(e: any) => {setFailed(!failed); onChangeTextHandler();}}
                    label={"Failed"}
                    value={failed}
                    name={"failed"}
                ></Checkbox>
                <Checkbox
                    onChange={(e: any) => {setTerminated(!terminated); onChangeTextHandler();}}
                    label={"Terminated"}
                    value={terminated}
                    name={"terminated"}
                ></Checkbox>
            </FilterParamsCont>
        </FilterSec>
    );
}

export default SubaccountFilter;