export const StepData = [
  { value: 1, label: "Merchant Details" },
  { value: 2, label: "Business Details" },
  { value: 3, label: "Account Details" },
  { value: 4, label: "Documents" },
//  { value: 5, label: "Merchant Email" }, 
];

export const RegTypeData = [
  { value: "RC", label: "RC" },
  { value: "BN", label: "BN" },
  { value: "TN", label: "TN" },
];

export const PersonalTypeData = [
  { value: "BVN", label: "BVN" },
  { value: "NINC", label: "NIMC" },
];
