import styled from "styled-components";

type RowT = {
  status: string;
};

export const RoleUserPageCont = styled.div`
  width: 100%;
  padding: 1rem 0;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

export const PageHeader = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    padding: 0.88rem 1rem;
    border-radius: 4px;
    color: #fff;
    background-color: #0e314c;
    cursor: pointer;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
`;

export const RowStatus = styled.span<RowT>`
  color: ${(prop) =>
    prop.status === "Active"
      ? "green"
      : prop.status === "Pending"
      ? "orange"
      : prop.status === "Inactive"
      ? "red"
      : "#000"};
`;

export const OptionItem = styled.div`
  padding: 0 0.88rem;
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-size: 0.8rem;
  }
`;

export const DisableSpan = styled.span`
  color: red;
`;
