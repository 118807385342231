import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import settlementOBJ from "Settlements/settlementClass";
import Table from "shared/clientcomponents/Table";
import { GradientLoader } from "shared/components";
import EmptyState from "shared/components/EmptyState";
import { numberFormat, numFormatter } from "shared/utils/utility";
import { PageTitle, TableCont } from "./Style";

export default function Single() {
  const history = useHistory();
  const { id: Id } = useParams<{
    id: string;
  }>();

  const [transactions, settransactions] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [isRecord, setisRecord] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");

  const TableHeader = [
    { id: "sn", label: "S/N", width: "2%" },
    { id: "transactionId", label: "Transaction ID", width: "14%" },
    { id: "amount", label: "Amount", width: "10%" },
    { id: "businessName", label: "Business Name", width: "50%" },
    { id: "time", label: "Date/Time", width: "10%" },
    { id: "functionProp", label: "", width: "2%" },
  ];

  const transactionList = transactions.map((obj: any, index: number) => ({
    Id: obj?.id,
    sn: ++index,
    amount: `${numberFormat(obj?.amount)}`,
    transactionId: obj?.transactionId,
    businessName: obj?.businessName,
    time: obj?.createdAt,
  }));

  const getTransactions = async () => {
    const response = await settlementOBJ.getTransactions(Id, currentPage);
    if (response?.status && response?.payload?.data.length) {
      // console.log("transactions ====>", response?.payload);
      setcurrentPage(response?.currentPage);
      setisRecord(true);
      setisloading(false);
      settransactions(response?.payload?.data);
    } else {
      setisRecord(false);
      setisloading(false);
    }
  };

  useLayoutEffect(() => {
    if (!Id) {
      history.push("/settlements/overview");
    }
  }, [Id]);

  useEffect(() => {
    console.log("Getting transactions");
    getTransactions();
  }, []);

  return (
    <div>
      <PageTitle>Transactions</PageTitle>
      <TableCont>
        {isloading ? (
          <GradientLoader />
        ) : (
          <>
            {isRecord ? (
              <Table
                headData={TableHeader}
                bodyData={transactionList}
                rowStyle={{ background: "#ffffff" }}
                totalPages={totalPages}
                currentpage={currentPage}
                goToPage={(value: any) => {
                  setcurrentPage(value);
                }}
              />
            ) : (
              <EmptyState
                stateTitle="No Transaction record"
                statePara=""
                stateBtnText=""
              />
            )}
          </>
        )}
      </TableCont>
    </div>
  );
}
