import { useCallback, useEffect, useState } from "react";
import { GraphAreaLayout, PanelTitle } from "./Style";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";
import reportObj from "Report/report-class";
import { channel } from "diagnostics_channel";
import { Spinner } from "shared/components";

const styles = {
  legend: {
    fontSize: "12px",
    display: "inline-block",
    align: "center",
  },
};

let data = [
  { channel: "Cards", value: 50 },
  { channel: "eBanking", value: 100 },
  { channel: "Bank", value: 200 },
  { channel: "Transfer", value: 300 },
];

const GraphArea = () => {
  const [totalValues, setTotalValues] = useState<any>(0);
  const [datas, setDatas] = useState<any>(data);
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isloading, setisloading] = useState(false);
  const [isDatafetched, setisDatafetched] = useState(false);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042" , "#ff4242" , "#2b1962", "#ff42d0"];
  const RADIAN = Math.PI / 180;

  const getStats = async () => {
    //setisloading(true);
    try{
      const response = await reportObj.getChannelStat();
    
      if (response?.length > 0) {
        // data = response.map(function(elem: any) {
        //   return {
        //     value: elem.value,
        //     channel: elem.channel,
        //   } 
        // });
        // // console.log("response: ", response)
        // data=response;
        setDatas(response);
        setTotalValues(arrSum(response));
        //setisloading(false);
        setisDatafetched(true);
      }
    }catch(error: any){
  
    }finally{
      //setisloading(false);
    }
    
  };
  
  
  

  const arrSum = (array: any) =>
    array.reduce(
      (sum: number, num: any) =>
        sum + (Array.isArray(num.value) ? arrSum(num.value) : num.value * 1),
      0
    );


  const onPieEnter = () => {};

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  const renderPies =()=> {
    const pies = datas instanceof Array &&datas.map((value: any, index: number) => (
      <Cell
          key={`cell-${index}`}
          fill={COLORS[index % COLORS.length]}
        />
    ));
    return pies;
  }
  
  const renderPie=(
    <ResponsiveContainer  height={height/2+40}>
        <PieChart width={500} height={100} onMouseEnter={onPieEnter}>
          <Pie
            data={datas}
            nameKey="channel"
            dataKey="value"
            
            cy={150}
            labelLine={true}
            label={({
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              value,
              index
            }) => {
              const RADIAN = Math.PI / 180;
              // eslint-disable-next-line
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              // eslint-disable-next-line
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              // eslint-disable-next-line
              const y = cy + radius * Math.sin(-midAngle * RADIAN);
              // console.log("Total value: ", totalValues)
              return (
                <text
                  x={x}
                  y={y}
                  fill={COLORS[index % COLORS.length]}
                  textAnchor={x > cx ? "start" : "end"}
                  fontSize={"15px"}
                  dominantBaseline="central"
                >
                  {(((value / totalValues) * 100).toFixed(2)+"%")}
                </text>
              );
            }}
            innerRadius="20%"
            outerRadius="40%"
            fill="#8884d8"
            paddingAngle={5}
            isAnimationActive={false}
          >
            {renderPies()}
          </Pie>
          
          <Legend wrapperStyle={styles.legend} />
        </PieChart>
      </ResponsiveContainer>
  );

  useEffect(() => {
    
    if (!isDatafetched) {
      getStats();
    }
  }, []);


  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <GraphAreaLayout>
      {renderPie}
    </GraphAreaLayout>
  );
};

export default GraphArea;
