import BreakPoints from "shared/utils/Breakpoint";
import styled from "styled-components";

export const PageCont = styled.div`
  margin-top: 1.2rem;
  @media (${BreakPoints.xs}) {
    position: relative;
    top: 0rem;
    margin-top: -2rem;
    padding: 0.2rem;
  }
`;
