import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import dayjs from "dayjs";
import {
  Datepicker,
  Timepicker,
  DateTimepicker,
  RangeDatepicker,
  IRangeDateValue
} from "bear-react-datepicker";

import "bear-react-grid/dist/index.css";
import "bear-react-datepicker/dist/index.css";
import { CalendaContainer, CloseIcon, Container, DateInput, DateInputContainer } from './Style';
import Icon from '../Icon';


type PropT ={
  onSelect:Function
}

const Calenda = ({onSelect}: PropT) => {
    const [myDateTime, setMyDateTime] = useState("");
    const [myDate, setMyDate] = useState("");
    const [myTime, setMyTime] = useState("");
    const [myRangeDate, setMyRangeDate] = useState<IRangeDateValue>({
        startDate: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
        endDate: new Date().toJSON().slice(0,10).replace(/-/g,'-')
    });
    const [openCalenda, setOpenCalenda] = useState(false);

    const getDateString=()=>{
        if(myRangeDate.startDate && myRangeDate.endDate){
            return `${myRangeDate.startDate ?? ""} ~ ${myRangeDate.endDate ?? ""}`
        }else{
            return "";
        }
    }

    useEffect(()=>{
        onSelect(myRangeDate)
    }, [myRangeDate])
    
    return (
        <CalendaContainer>
            <DateInputContainer>
                <DateInput
                    placeholder="yyyy-mm-dd ~ yyy-mm-dd"
                    value={getDateString()}
                    onClick={()=>{
                        setOpenCalenda(!openCalenda);
                    }}
                    onChange={(event: any) => {
                        const dateStr = event?.target?.value;
                        const dateObj = dateStr.split("~");
                        setMyRangeDate({ startDate: dateObj[0], endDate: dateObj[1] });
                    }}
                />
                <CloseIcon type="close" size={23} color="#ccc"
                    onClick={()=>{
                        setMyRangeDate({ startDate: "", endDate: "" });
                        setOpenCalenda(false);
                    }}
                />
                <CloseIcon type="calendar" size={23} color="#ccc" onClick={()=>{
                        setOpenCalenda(!openCalenda);
                    }}
                />
            </DateInputContainer>
            
            <Container isVisible={openCalenda}>
                <RangeDatepicker
                    value={myRangeDate}
                    onChange={setMyRangeDate}
                    isVisibleSetToday
                    locale="us-EN"
                    isDark
                    //minDate={dayjs().subtract(1, "month").format("YYYY-MM-DD")}
                    maxDate={dayjs().add(0, "day").format("YYYY-MM-DD")}
                />    
            </Container>

            
        </CalendaContainer>
    );
};

export default Calenda;
