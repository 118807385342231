import { toast } from "react-toastify";
import api from "shared/utils/api";

type pwdDataT = {
  password: string;
  confirm_password?: string;
  current_userPassword?: string;
  email?: string
};
class CreatePassword {
  async setPassword(id: any, data: pwdDataT) {
    try {
      const response = await api.post(`/set-password?id=${id}`, {
        password: data.password,
        confirmPassword: data.confirm_password,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async changePassword(data: pwdDataT) {
    try {
      const response = await api.post(`/change-password`, {
        oldPassword: data.current_userPassword,
        password: data.password,
        confirmPassword: data.confirm_password,
      });
      toast.success("Your password has been changed successfully.");
      // console.log("password response", response);
      return true;
    } catch (error) {
      toast.error("Error occured while trying to change password.");
      // console.log("error in set password", error);
      return false;
    }
  }
}

const passwordOBJ = new CreatePassword();
export default passwordOBJ;

