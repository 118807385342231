import React, { useState, useEffect } from "react";
import ConfirmModal from "../../shared/quickComponents/ConfirmModal";
import OtpModal from "../../shared/quickComponents/OtpModal";
import FileIcon from "../../Assets/fileIcon.png";
import DownloadIcon from "../../Assets/downloadIcon.png";
import DeleteIcon from "../../Assets/deleteIcon.png";
import {
  ActionsContainer,
  AddFileButton,
  FileField,
  FileFormContainer,
  FileGroup,
  FileInput,
  FileInputGroup,
  FilesContainer,
  FileSection,
  FileSectionContainer,
  FormFileGroup,
  InputField,
  SelectedFileList,
  SubmitButton,
  UploadButton,
  UploadWrapper,
} from "Kyc/Styles";
import Modal from "shared/quickComponents/modal";
import kycOBJ from "Kyc/kycClass";
// import toast from "shared/utils/toast";
import { toast } from "react-toastify";
export default function Index({ files, id, fetchData }: any) {
  const [isModalOpen, setisModalOpen] = useState(false);
  const [showIndicator, setshowIndicator] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [uploads, setuploads] = useState<any[]>([]);
  const [tagName, setTagName] = useState("");
  const [uploading, setuploading] = useState(false);
  const [poa, setpoa] = useState(files?.poa ?? "");
  const [cac, setcac] = useState(files?.cac ?? "");
  const [moa, setmoa] = useState(files?.moa ?? "");
  const [article, setarticle] = useState(files?.article ?? "");
  const [otherFiles, setothers] = useState<any>([]);
  const [btnText, setbtnText] = useState("Upload");
  const [action, setaction] = useState(false);
  const [fieldName, setfieldName] = useState("");
  const [otherFileSelected, setotherFileSelected] = useState("");

  const fileArr = [
    { id: 1, name: "cac", tag: "CAC Certificate", file: files?.cac },
    { id: 1, name: "article", tag: "Article", file: files?.article },
    {
      id: 3,
      name: "moa",
      tag: "Article of memorandum of Association",
      file: files?.moa,
    },
    { id: 4, name: "poa", tag: " Utility Bills", file: files?.poa },
  ];

  const tags = ["poa", "moa", "cac", "article", "others"];
  const tagChecker = (tag: string) => {
    if (tags.includes(tag)) {
      if (tag === "poa" && poa) return <></>;
      if (tag === "moa" && moa) return <></>;
      if (tag === "cac" && cac) return <></>;
      if (tag === "article" && cac) return <></>;
      return (
        <>
          <UploadButton>Select file</UploadButton>
          <FileField onChange={imageChange} />
          <span style={{ fontSize: "14px" }}>
            {uploading && "Processing..."}
          </span>
        </>
      );
    }
  };

  const handleAddToState = (file: any, filename: any) => {
    if (uploads.length) {
      setuploads([...uploads, { tagName, file, filename }]);
    } else {
      setuploads([{ tagName, file, filename }]);
    }
    setTagName("");
  };

  // Remove from state ===========================
  const removeFromState = (file: any, tagName: any) => {
    if (tagName === "poa") setpoa("");
    if (tagName === "moa") setmoa("");
    if (tagName === "cac") setcac("");
    if (tagName === "article") setarticle("");
    if (tagName === "others") {
      const newOthers = otherFiles.filter((obj: any) => obj != file);
      // console.log("new others", newOthers);
      setothers(newOthers);
    }
    const newUploads = uploads.filter((obj) => obj.file != file);
    setuploads(newUploads);
  };
  // ===========================================

  const imageChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileName = e.target.files[0].name;
      const fileSize = e.target.files[0].size;
      const allowedExtensions = /(\.pdf|\.docx|\.jpg|\.jpeg|\.png)$/i;

      if (!allowedExtensions.exec(fileName)) {
        console.log("error on type");
        toast.error("Invalid file type");
        return;
      } else if (fileSize > 5000000) {
        toast.error("File must not be greater than 5mb in size");
        console.log("selected file", e.target.files[0].size);
        return;
      } else {
        setuploading(true);
        const formData = new FormData();
        formData.append("certificate", e.target.files[0]);
        const response: any = await kycOBJ.uploadFile(formData);
        if (response?.status === 200) {
          setuploading(false);
          const cacUrl = response.data?.payload?.fileUrl;
          if (tagName === "poa") setpoa(cacUrl);
          if (tagName === "moa") setmoa(cacUrl);
          if (tagName === "cac") setcac(cacUrl);
          if (tagName === "article") setarticle(cacUrl);
          if (tagName === "others") setothers([...otherFiles, cacUrl]);
          handleAddToState(cacUrl, fileName);
        } else {
          setuploading(false);
        }
      }
    }
  };

  //===== show uploaded files in form ================
  const SelectedFiles = uploads.length
    ? uploads.map((obj: any, index: number) => (
        <SelectedFileList key={index}>
          {obj.tagName.toUpperCase()}: {obj.filename}
          <span onClick={() => removeFromState(obj.file, obj.filename)}>
            remove
          </span>
        </SelectedFileList>
      ))
    : "";
  //============================================

  //========= DOcument list from api ========
  const fileList = fileArr.map((flx: any, index: number) => {
    if (!flx?.file || String(flx?.file).trim() === "") return null;
    return (
      <FileGroup key={index}>
        <div>
          <span>{flx.tag}</span>
          <FileInputGroup>
            <FileInput>
              <img src={FileIcon} />
              {flx?.file.substring(0, 30)}...
            </FileInput>
            <ActionsContainer>
              <a href={flx?.file} target="_blank">
                <img src={DownloadIcon} />
              </a>
              <img
                src={DeleteIcon}
                onClick={() => {
                  setfieldName(flx.name); //set field name to delete
                  setConfirmModalOpen(true);
                }}
              />
            </ActionsContainer>
          </FileInputGroup>
        </div>
      </FileGroup>
    );
  });
  //=========================================================

  //========= Other document categories ================
  const others =
    files?.others &&
    files?.others instanceof Array &&
    files?.others.map((el: any, index: number) => {
      return (
        <FileGroup key={`${index}Othe`}>
          <div>
            <FileInputGroup>
              <FileInput>
                <img src={FileIcon} />
                {el.substring(0, 30)}...
              </FileInput>
              <ActionsContainer>
                <a href={el} target="_blank">
                  <img src={DownloadIcon} />
                </a>
                <img
                  src={DeleteIcon}
                  onClick={() => {
                    setfieldName("others");
                    setotherFileSelected(el);
                    setConfirmModalOpen(true);
                  }}
                />
              </ActionsContainer>
            </FileInputGroup>
          </div>
        </FileGroup>
      );
    });
  //=========================================================

  //======== Send file tags & url to backend ======================
  const handleUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    setbtnText("Uploading...");
    e.preventDefault();
    const uploadMeta = {
      cac,
      moa,
      poa,
      others: [...otherFiles],
    };
    const response = await kycOBJ.updateFiles({ uploadMeta: uploadMeta }, id);
    if (response.status) {
      toast.success(response.message);
      setisModalOpen(false);
      setbtnText("Upload");
      setuploads([]);
      fetchData();
    } else {
      toast.error(response.message);
      setisModalOpen(false);
    }
  };
  //==========================================================

  const handleDelete = async () => {
    setshowIndicator(true);
    let uploadMeta: any = {};
    if (fieldName === "others") {
      const filterOthers = otherFiles.filter(
        (file: any) => file !== otherFileSelected
      );
      uploadMeta = { cac, moa, poa, others: [...filterOthers] };
      console.log("upload meta", filterOthers);
    } else {
      uploadMeta = {
        cac: fieldName === "cac" ? "" : cac,
        moa: fieldName === "moa" ? "" : moa,
        poa: fieldName === "poa" ? "" : poa,
        others: [...files?.others],
      };
    }
    const response = await kycOBJ.updateFiles({ uploadMeta: uploadMeta }, id);
    if (response.status) {
      toast.success("File deleted successfully!");
      setshowIndicator(false);
      setConfirmModalOpen(false);
      fetchData();
    } else {
      toast.error("Oops, Can't delete file!");
      setshowIndicator(false);
      setConfirmModalOpen(false);
    }
    // console.log("field name to delete", fieldName);
  };

  return (
    <>
      <FileSection>
        <h3>UPLOADED DOCUMENTS</h3>
        <hr />
        <FileSectionContainer>
          <FilesContainer>{fileList}</FilesContainer>
          {others && (
            <>
              <div>Others</div>
              {others}
            </>
          )}
          <AddFileButton onClick={() => setisModalOpen(true)}>
            Click to Add Files
          </AddFileButton>
          {/* <Ribbon></Ribbon> */}
        </FileSectionContainer>
      </FileSection>

      {/*=========== upload modal=========== */}
      <Modal
        marginTop="1rem"
        onClose={() => setisModalOpen(false)}
        isOpen={isModalOpen}
        closeButton={true}
        width="40%"
      >
        <FileFormContainer>
          <form onSubmit={handleUpload}>
            <h2>Add new file</h2>

            <FormFileGroup>
              <label>Enter Tag</label>
              <InputField
                placeholder="poa, cac, moa or others"
                value={tagName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTagName(event.target.value);
                  setaction(true); //tracking component interaction
                }}
              />
            </FormFileGroup>

            <FormFileGroup>
              <label>Upload New Document</label>

              <UploadWrapper>{tagChecker(tagName)}</UploadWrapper>
            </FormFileGroup>
            <div>{SelectedFiles}</div>
            <FormFileGroup>
              <SubmitButton type="submit" disabled={!uploads.length && true}>
                {btnText}
              </SubmitButton>
            </FormFileGroup>
          </form>
        </FileFormContainer>
      </Modal>

      <ConfirmModal
        message="Are sure you want to delete this file?"
        handleYesModalButton={() => handleDelete()}
        handleNoModalButton={() => setConfirmModalOpen(!isConfirmModalOpen)}
        isModalOpen={isConfirmModalOpen}
        showIndicator={showIndicator}
        handleCloseModal={() => setConfirmModalOpen(!isConfirmModalOpen)}
      />
    </>
  );
}
