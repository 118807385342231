import styled from "styled-components";
import { color } from "../../../shared/utils/styles";
import breakpoints from "shared/utils/breakpoints";

type boxPropT = {
  background: boolean;
};

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  align-items: flex-start;
  @media ${breakpoints.device.max.md} {
    flex-direction: column;
   }
`;
export const TableContainer = styled.div`
  width: 80%;
  background-color: #fff;
  padding: 0rem 0.4rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
  margin-right: 25px;
  /* height: 28rem; */
  /* margin-bottom: 2rem; */
  @media ${breakpoints.device.max.md} {
    width: 100%;  
  }
`;

export const Container = styled.div`
  margin-bottom: "4rem";
  height: 20rem;
  overflow-y: scroll;
  margin-bottom: 2rem;
  &::-webkit-scrollbar {
    background-color: white;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${color.fountain};
    max-height: 20px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const TotalContainer = styled.div`
  width: 20%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;
export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
  /* background-color: #fff; */
`;
export const ViewAllBtn = styled.button`
  padding: 1rem 4rem;
  color: white;
  background-color: ${color.fountain};
  border-radius: 2rem;

  &:hover {
    cursor: pointer;
  }
`;

export const Box = styled.div<boxPropT>`
  background-color: ${(prop) =>
    prop.background ? color.fountain : "rgba(255, 255, 255, 0.8)"};
  color: ${(prop) => (prop.background ? "#fff" : "#000")};
  display: flex;
  width: 20%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.48rem 1.4rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all ease 0.4s;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    background-color: ${(prop) => (prop.background ? "white" : color.fountain)};
    color: ${(prop) => (prop.background ? color.fountain : "#fff")};
  }

  @media ${breakpoints.device.max.md} {
    width: 100%;  
    margin-top: 50px;
  }
`;

export const BoxIcon = styled.img`
  width: 2.8rem;
  height: 2.8rem;
`;

export const BoxTitle = styled.span`
  margin-top: 1rem;
  padding-top: 0.2rem;
  font-size: 0.95rem;
`;

export const BoxValue = styled.span`
  display: block;
  margin-top: 1.8rem;
  padding-bottom: 0.4rem;
  font-weight: 900;
`;
