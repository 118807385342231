import React from "react";
import { Box, GridContainer } from "./Styles";
import SingleBox from "./Box";
import icon1 from "../../assets/icon1.svg";
import icon2 from "../../assets/icon2.svg";
import icon3 from "../../assets/icon3.svg";
import icon4 from "../../assets/icon4.svg";
import { includes } from "lodash";
import { useHistory } from "react-router-dom";
import { currencyFormat, numberFormat } from "shared/utils/utility";

export default function FirstSection({ data, isLoading }: any) {
  const history = useHistory();
  const {
    business,
    services,
    subAccount,
    dispute,
    kyc,
    transaction,
    settlement,
  } = data;
  const boxData = [
    {
      id: 1,
      title: "Number of Business",
      value: business,
      icon: icon1,
      background: true,
      urlPath: "/",
      link: "/business/home",
    },
    {
      id: 2,
      title: "Number of Services",
      value: services,
      icon: icon2,
      background: false,
      urlPath: "/",
    },
    {
      id: 3,
      title: "Number of Sub Account",
      value: subAccount,
      icon: icon3,
      background: false,
      urlPath: "/",
    },

    {
      id: 4,
      title: "Dispute Created",
      value: dispute,
      icon: icon4,
      background: false,
      urlPath: "/",
    },

    {
      id: 5,
      title: "Amount Processed",
      value: numberFormat(parseInt(transaction?.processedTransAmount)) ?? 0,
      icon: icon4,
      background: false,
      urlPath: "/",
    },
    {
      id: 6,
      title: "Amount Settled",
      value: settlement,
      icon: icon2,
      background: false,
      urlPath: "/",
    },
    {
      id: 7,
      title: "KYC Pending",
      value: kyc,
      icon: icon3,
      background: false,
      urlPath: "/",
      link: "/kyc/home",
    },

    {
      id: 8,
      title: "Others",
      background: false,
      urlPath: "/",
    },
  ];

  const boxLists = boxData.map((box) => {
    let check = ![1, 7].includes(box?.id);
    return (
      <SingleBox
        noPointer={check}
        key={box.id}
        boxdata={box}
        onClick={() => box?.link && history.push(box?.link)}
        isLoading={isLoading}
      />
    );
  });

  return (
    <>
      <GridContainer>{boxLists}</GridContainer>
    </>
  );
}
