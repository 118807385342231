import api from "shared/utils/api";

class Pricing {
  async getAllCountry(currentPage = 1) {
    try {
      const response = await api.get(`/shared/get-countries`);
      //console.log("Response: ", response)
      if (response?.payload) return response?.payload;
    } catch (error) {
      //console.log("error in fetching country list ==>", error);
    }
  }

  async addPricing(data: any) {
    try {
      const response = await api.post(`/pricing`, data);

      return response;
    } catch (error) {
      console.log("error", error);
      return { status: false, message: `error adding new pricing ${error}` };
    }
  }

  async getAllPricing(currentPage = 1) {
    try {
      const response = await api.get(`/pricing?page=${currentPage}`);
      if (response?.payload) return response?.payload;
    } catch (error) {
      // console.log("error in fetching pricing list ==>", error);
    }
  }

  async getPlanDetails(planID: string) {
    try {
      const response = await api.get(`/pricing/${planID}`);
      if (response) return response;
    } catch (error) {
      // console.log("error getting a pricing details ==>", error);
    }
  }

  async sendOTP(data: any) {
    try {
      const response = await api.post("/send-pricing-otp", data);
      if (response?.payload) return response;
    } catch (error) {
      // console.log("error occur while initiating OTP ==>", error);
      return false;
    }
  }

  async confirmOTP(data: any) {
    console.log("OTP: ", data);
    try {
      const response = await api.post("/confirm-otp", data);
      if (response?.status) return response;
    } catch (error) {
      return error;
    }
  }

  async updatePricing(businessId: any, pricingId: string) {
    try {
      const response = api.update(`/business/${businessId}/pricing`, pricingId);
      // console.log("response from pricing plan update ==>", response);
    } catch (error) {
      // console.log("error from pricing plan update ==>", error);
    }
  }

  async updatePricingPlan(planID: any, data: any) {
    // console.log("edited data", data);
    try {
      const response = await api.put(`/pricing/${planID}`, data);
      // // console.log("response from pricing plan update ==>", response);
      if (response?.status) return response;
    } catch (error) {
      // console.log("error from pricing plan update ==>", error);
    }
  }
}

export const pricingOBJ = new Pricing();
