import styled from "styled-components";
import { color } from "../../shared/utils/styles";

export const PageLayout = styled.div`
  width: 100%;
  margin-top: 2rem;
  background-color: #fff;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;

export const TableContainer = styled.div`
  margin-top: 2rem;
  max-height: 60vh;
  overflow-y: scroll;
`;

export const AllBusinessTableContainer = styled.div`
  margin-top: 0rem;
  min-height: 14rem;
  /* max-height: 60vh; */
  overflow-y: scroll;
`;

export const TableHeader = styled.div`
  background-color: ${color.fountainLight};
  display: flex;
  width: 100%;
  padding: 1rem 0.2rem;
  justify-content: space-between;
  color: ${color.fountain};
  font-size: 14px;
`;

export const TableRow = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0.2rem;
  justify-content: space-between;
  color: ${color.fountain};
  font-size: 14px;
  border: 1px solid ${color.backgroundMedium};
  cursor: pointer;

  &:hover {
    background-color: ${color.backgroundLightest};
  }
`;

export const TableContent = styled.div`
  width: 15%;
  padding-left: 0.2rem;
  justify-content: center;
`;
export const TableContentMain = styled.div`
  width: 15%;
  padding-left: 0.2rem;
  justify-content: center;
  font-size: 13px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem auto;
  background: #fff;
  padding: 0 1rem;
  height: 4.3rem;
  width: 100%;
  position: relative;
  bottom: 0;
  border: 1px solid ${color.backgroundMedium};
`;

export const PaginationButtonsContainer = styled.div`
  display: flex;
  gap: 1.4rem;
`;

export const PaginationNext = styled.button`
  background-color: ${color.fountain};
  color: #fff;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 12px;
`;

export const PaginationPrev = styled.button`
  background-color: #bf7a79;
  color: #fff;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  font-size: 12px;
  cursor: pointer;
`;
