import styled from "styled-components";
import { color, font } from "shared/utils/styles";

export const OtpContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const Title = styled.h1`
  color: #292929;
  padding: 10px 0;
  ${font.size(32)}
`;

export const SubTitle = styled.p`
  color: #828282;
  ${font.size(16)}
`;
