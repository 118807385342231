import TablesSection from "./TablesSection";

const Users = () => {
  return (
    <div>
      <TablesSection />
    </div>
  );
};

export default Users;
