import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";

import NormalizeStyles from "./NormalizeStyles";
import BaseStyles from "./BaseStyles";
import Toast from "./Toast";
import Routes from "./Routes";
import { useHistory } from "react-router-dom";

import Preloader from "preloader";

// We're importing .css because @font-face in styled-components causes font files
// to be constantly re-requested from the server (which causes screen flicker)
// https://github.com/styled-components/styled-components/issues/1593
import "./fontStyles.css";
import { useEffectOnce } from "shared/hooks/useEffectOnce";

const App = () => {
  const [isloading, setisloading] = useState<boolean>(true);

  useEffectOnce(() => {
    setTimeout(() => {
      setisloading(false);
    }, 800);
  });

  return (
    <>
      {isloading ? (
        <Preloader />
      ) : (
        <Fragment>
          <NormalizeStyles />
          <BaseStyles />
          <Toast />
          <Routes />
        </Fragment>
      )}
    </>
  );
};

export default App;
