export const FinanceData = [
  {
    id: 1,
    transactionId: "1234455633457",
    amountPaid: "N234,9000",
    description: "Sent Money to X",
    type: "Payment",
    timeDate: "11:00 PM 20th JUNE 2022",
    activated: true,
  },

  {
    id: 2,
    transactionId: "1234455633458",
    amountPaid: "N234,9000",
    description: "Sent Money to X",
    type: "Payment",
    timeDate: "11:00 PM 20th JUNE 2022",
    activated: true,
  },

  {
    id: 3,
    transactionId: "1234455633459",
    amountPaid: "N234,9000",
    description: "Sent Money to X",
    type: "Payment",
    timeDate: "11:00 PM 20th JUNE 2022",
    activated: false,
  },

  {
    id: 4,
    transactionId: "1234455633460",
    amountPaid: "N234,9000",
    description: "Sent Money to X",
    type: "Payment",
    timeDate: "11:00 PM 20th JUNE 2022",
    activated: true,
  },

  {
    id: 5,
    transactionId: "1234455633451",
    amountPaid: "N234,9000",
    description: "Sent Money to X",
    type: "Payment",
    timeDate: "11:00 PM 20th JUNE 2022",
    activated: false,
  },
  {
    id: 6,
    transactionId: "1234455633452",
    amountPaid: "N234,9000",
    description: "Sent Money to X",
    type: "Payment",
    timeDate: "11:00 PM 20th JUNE 2022",
    activated: false,
  },
  {
    id: 7,
    transactionId: "1234455633453",
    amountPaid: "N234,9000",
    description: "Sent Money to X",
    type: "Payment",
    timeDate: "11:00 PM 20th JUNE 2022",
    activated: true,
  },
  {
    id: 8,
    transactionId: "1234455633454",
    amountPaid: "N234,9000",
    description: "Sent Money to X",
    type: "Payment",
    timeDate: "11:00 PM 20th JUNE 2022",
    activated: true,
  },
  {
    id: 9,
    transactionId: "1234455633455",
    amountPaid: "N234,9000",
    description: "Sent Money to X",
    type: "Payment",
    timeDate: "11:00 PM 20th JUNE 2022",
    activated: true,
  },
  {
    id: 10,
    transactionId: "1234455633456",
    amountPaid: "N234,9000",
    description: "Sent Money to X",
    type: "Payment",
    timeDate: "11:00 PM 20th JUNE 2022",
    activated: false,
  },
];
