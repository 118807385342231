import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, RoleUserPageCont } from "../Users/Styles";
import Table from "shared/clientcomponents/Table";
import Modal from "shared/quickComponents/modal";
import {
  Container,
  HeadingDiv,
  Title,
  ActionButton,
  Select,
  Input,
} from "./Style";

import { toast } from "react-toastify";
import roleApi from "RoleManagement/apiClass";
import Switch from "shared/components/Switch";
import consoleLog from "shared/utils/consoleLog";

export default function AccessGroup() {
  const [open, setOpen] = useState(false);
  const [formstate, setFormstate] = useState({} as any);
  const [departments, setDepartments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isApicall, setisApicall] = useState(false);

  const history = useHistory();
  const TableHeader = [
    // { id: "sn", label: "S/N", width: "2%" },
    { id: "name", label: "GROUP NAME", width: "10%" },
    { id: "parentName", label: "MAIN UNIT", width: "10%" },
    { id: "permission", label: "Permissions", width: "50%" },
    { id: "status", label: "STATUS", width: "10%" },
    { id: "functionProp", label: "", width: "2%" },
  ];

  let bodyData: any = tableData;

  const addAccess = () => {
    setOpen(true);
    setFormstate({});
  };

  const handleEdit = async (item: any) => {
    setFormstate(item);
    setOpen(true);
  };
  consoleLog(departments, "departments");
  const changeHandler = (val: any, name: string) =>
    setFormstate({ ...formstate, [name]: val });

  const changeStatus = async (id: string, status: string) => {
    //To prevent multiple calls
    if (isApicall) return;
    else {
      setisApicall(true);
      let obj = {
        id,
        status,
      };
      let data = await roleApi.addDept(obj, true);
      if (data) {
        await fetchDepartments();
        setOpen(false);
        setFormstate({});
        setisApicall(false);
      }
    }
  };

  const fetchDepartments = async () => {
    let data = await roleApi.fetchDepts();
    if (data) {
      let arrData = data.map(
        (el: any) => el.id && { value: el.id, label: el.name }
      );
      let newArr: any = [];

      for (let elm of arrData) {
        if (elm && !newArr.find((elmm: any) => elmm.value === elm.value)) {
          newArr.push(elm);
        }
      }

      setDepartments(newArr);

      let newArray: any = [];
      for (let item of data) {
        let status = String(item.status)
          .trim()
          .toUpperCase();
        let statusFlag = status === "ENABLED";
        newArray.push({
          ...item,
          permission: (
            <div
              onClick={() =>
                history.push(`/role-management/menu-manager/${item.id}`)
              }
              style={{ cursor: "pointer" }}
            >
              view Permissions
            </div>
          ),
          status: (
            <Switch
              id={`${item.id}`}
              status={statusFlag}
              toggleMethod={() =>
                changeStatus(item.id, statusFlag ? "DISABLED" : "ENABLED")
              }
              label={status}
              color={statusFlag ? "#760000" : "#333"}
            />
          ),
          functionProp: (
            <a href="#" onClick={() => handleEdit(item)}>
              Edit
            </a>
          ),
        });
      }
      setTableData(newArray);
    }
  };

  const handleSubmit = async () => {
    if (formstate?.name && String(formstate.name).trim() !== "") {
      let data = await roleApi.addDept(formstate);
      if (data) {
        await fetchDepartments();
        setOpen(false);
        setFormstate({});
      }
    } else {
      toast.error("Please enter a unit name");
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  return (
    <div>
      <RoleUserPageCont>
        <PageHeader>
          <h3>Access Group Manager</h3>
          {/* display modal */}
          <button onClick={addAccess}>Add Access</button>
        </PageHeader>
      </RoleUserPageCont>
      <Table
        headData={TableHeader}
        bodyData={bodyData}
        rowStyle={{ background: "#ffffff" }}
        // hoverLink={hoverLink}
      />
      <Modal
        isOpen={open}
        closeButton
        onClose={() => {
          setOpen(false);
          setFormstate({});
        }}
      >
        {open && (
          <Container>
            <HeadingDiv>Create Unit</HeadingDiv>
            <Title>Enter Unit Name</Title>
            <Input
              type="text"
              placeholder=""
              style={{ marginBottom: 20, padding: 10 }}
              onChange={(e) => changeHandler(e.target.value, "name")}
              value={formstate?.name}
            />
            <Title>Select Department</Title>
            <Select
              style={{ marginBottom: 10, padding: 10 }}
              value={formstate?.parentId}
              onChange={(e) => changeHandler(e.target.value, "parentId")}
            >
              <option value="">Select Department</option>
              {departments
                .filter((el: any) => el.value !== formstate.id)
                .map((el: any, idx: number) => (
                  <option key={`DO${idx}`} value={el?.value}>
                    {el?.label}
                  </option>
                ))}
            </Select>
            <ActionButton
              type="button"
              variant="primary"
              onClick={handleSubmit}
            >
              {formstate?.id ? "Update" : "Submit"}
            </ActionButton>
          </Container>
        )}
      </Modal>
    </div>
  );
}
