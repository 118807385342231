import React from "react";
import Layout from "Layout";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { ProtectedRoute } from "guards/ProtectedRoute";
import { SuperAdminGuard } from "guards/super-admin-guard";
import { AdminGuard } from "guards/admin-guard";
import { PageError } from "shared/components";
import Home from "./Overview";
import Single from "./Single";
import { PageCont } from "./Style";
import Transactions from "Transactions";
import AccountDetails from "./AccountDetails";

export default function Settlements() {
  const match = useRouteMatch();
  return (
    <Layout pageTitle="Settlement">
      <>
        <PageCont>
          <ProtectedRoute
            path={`${match.path}/overview`}
            guards={[SuperAdminGuard, AdminGuard]}
            component={Home}
            fallback={() => <PageError />}
          />

          <ProtectedRoute
            path={`${match.path}/single`}
            guards={[SuperAdminGuard, AdminGuard]}
            component={Single}
            fallback={() => <PageError />}
          />
          <ProtectedRoute
            path={`${match.path}/details/:id/account-details`}
            guards={[SuperAdminGuard, AdminGuard]}
            component={AccountDetails}
            fallback={() => <PageError />}
          />
          <ProtectedRoute
            path={`${match.path}/details/:id/transactions`}
            guards={[SuperAdminGuard, AdminGuard]}
            component={Transactions}
            fallback={() => <PageError />}
          />
          {match.isExact && <Redirect to={`${match.url}/overview`} />}
        </PageCont>
      </>
    </Layout>
  );
}
