import { PageLayout } from "shared/section_components/Styles";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection/SecondSection";

export default function Finance() {
  return (
    <>
      <PageLayout>
        <FirstSection />
        <SecondSection />
      </PageLayout>
    </>
  );
}
