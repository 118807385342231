import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { getCurrentUser } from "shared/utils/authToken";
import icon5 from "../../../Dashboard/assets/icon5.svg";
import Table from "shared/clientcomponents/Table";
import EmptyState from "shared/components/EmptyState";
import useApi from "shared/hooks/api";
import { DisputeTableHeader } from "Dispute/DisputeTableHeader";
import SingleBox from "./Box";
import moment from "moment";

import {
  BtnContainer,
  Container,
  FilterButton,
  FilterContainer,
  Section,
  TableContainer,
  ViewAllBtn,
} from "./Styles";
import { EmptyState2, GradientLoader } from "shared/components";
import { TableActions } from "Business/Single/Styles";
import { numberFormat, numFormatter } from "shared/utils/utility";
import { getClientDetails } from "shared/utils/location";
import logger from "shared/utils/logger";
import { useEffectOnce } from "shared/hooks/useEffectOnce";
import consoleLog from "shared/utils/consoleLog";

type PropT = {
  [x: string]: any;
};

export default function SecondSection() {
  const [disputes, setdisputes] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [NoRecord, setNoRecord] = useState(true);
  const [filterBtn, setfilterBtn] = useState("");

  const history = useHistory();
  const currentUser = getCurrentUser();

  const [{ data, error, setLocalData }, fetchData] = useApi.get(`/dispute`);

  const handleLog = async () => {
    //console.log("logging on Dispute");
    await logger.logActivities("remote", {
      date: new Date().toString(),
      author: currentUser.fullName,
      resource: "admin",
      action: "REVIEW",
      payload: {
        userAgent: getClientDetails(),
        data: {
          detail: `${currentUser.fullName} access the "Dispute" overview`,
        },
      },
    });
  };

  const disputeList = disputes.map((disputeData: any, index: number) => ({
    id: disputeData.id,
    sn: index + 1,
    ticketID: disputeData.ticketId,
    transactionID: disputeData.transactionId,
    status: disputeData.status,
    amountPAID: numberFormat(disputeData.amount),
    merchant: disputeData.merchant,
    email: disputeData.email,
    timeDate: moment(disputeData.createdAt).format("DD/MM/YYYY"),
  }));

  const hoverLink = (elm: PropT) => {
    history.push(`/disputes/single/${elm?.id}`);
  };

  const box = {
    id: 1,
    title: "Total Number of Dispute Raised",
    value: data?.payload?.length ?? 0,
    icon: icon5,
    background: false,
    urlPath: "/",
  };

  const handleFilter = (statusValue: string) => {
    if (data) {
      const newDispute = data?.payload?.filter(
        (dispute: any) => dispute.status === statusValue
      );
      setdisputes(newDispute.slice(0, 3));
    }
  };

  useLayoutEffect(() => {
    // if (!data) setisLoading(true);
    if (error) {
      setisLoading(false);
      setNoRecord(true);
    }
    // if (!data && !error) setNoRecord(true);
    if (data && data?.payload?.length) {
      consoleLog("disputes data ==>", data);
      setdisputes(data?.payload);
      setNoRecord(false);
      setisLoading(false);
      setfilterBtn("OPEN");
      handleFilter("OPEN");
    } else if (data && !data?.payload?.length) {
      consoleLog("disputes data", data);
      setisLoading(false);
      setNoRecord(true);
    }
    console.log("effect ran", data);
  }, [data, error]);

  useEffectOnce(() => {
    handleLog();
  });
  useEffect(() => {
    handleFilter(filterBtn);
  }, [filterBtn]);

  return (
    <Section>
      <TableContainer>
        {isLoading ? (
          <GradientLoader />
        ) : (
          <>
            {NoRecord ? (
              <EmptyState
                stateTitle="No Dispute"
                statePara=""
                stateBtnText=""
              />
            ) : (
              <>
                <Container>
                  <FilterContainer>
                    <FilterButton
                      onClick={() => setfilterBtn("OPEN")}
                      isclicked={filterBtn === "OPEN" ? true : false}
                    >
                      OPEN
                    </FilterButton>
                    <FilterButton
                      onClick={() => setfilterBtn("CLOSE")}
                      isclicked={filterBtn === "CLOSE" ? true : false}
                    >
                      CLOSED
                    </FilterButton>
                    <FilterButton
                      onClick={() => setfilterBtn("PENDING")}
                      isclicked={filterBtn === "PENDING" ? true : false}
                    >
                      PENDING
                    </FilterButton>
                  </FilterContainer>
                  <Table
                    headData={DisputeTableHeader}
                    bodyData={disputeList.slice(0, 3)}
                    hoverLink={hoverLink}
                  />
                </Container>
                {data?.payload.length > 3 && (
                  <BtnContainer>
                    <Link to={`../disputes/all`}>
                      <ViewAllBtn>View All</ViewAllBtn>
                    </Link>
                  </BtnContainer>
                )}
              </>
            )}
          </>
        )}
      </TableContainer>
      <SingleBox boxdata={box} />
    </Section>
  );
}
