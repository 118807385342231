import styled from "styled-components";
import { color } from "shared/utils/styles";

type StatusPropT = {
  status: string;
};
export const TableActions = styled.div`
  display: flex;
  gap: 2rem;
`;

export const ViewAllBtn = styled.span`
  background: ${color.fountain};
  cursor: pointer;
  color: #fff;
  border-radius: 0.4rem;
  padding: 0.6rem 1rem;
  font-size: 12px;
`;

// ======= transaction status ==========
export const TransStatus = styled.span<StatusPropT>`
  color: ${(prop) =>
    prop.status === "SUCCESSFUL"
      ? "green"
      : prop.status === "FAILED"
      ? "red"
      : "orange"};
    
    /* color: ${(prop) => prop.status === "SUCCESSFUL" && "green"};
    color: ${(prop) => prop.status === "FAILED" && "red"};
    color: ${(prop) => prop.status === "In Progress" && "orange"}; */
`;
