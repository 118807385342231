const colorChannelMixer = (colorChannelA: number, amountToMix: number) => {
  var channelA: number = colorChannelA + amountToMix;

  return channelA > 255 ? channelA - 200 : channelA;
};
//rgbA and rgbB are arrays, amountToMix ranges from 0.0 to 1.0
//example (red): rgbA = [255,0,0]
const colorMixer = (rgbA: any, amountToMix: number) => {
  rgbA = rgbA.trim();
  rgbA = rgbA.substring(4);
  rgbA = rgbA.substring(0, rgbA.length - 1).split(",");

  var r = colorChannelMixer(parseInt(rgbA[0]), amountToMix);
  var g = colorChannelMixer(parseInt(rgbA[1]), amountToMix);
  var b = colorChannelMixer(parseInt(rgbA[2]), amountToMix);
  return "rgb(" + r + "," + g + "," + b + ")";
};

export default colorMixer;
