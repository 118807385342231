import BreakPoints from "shared/utils/Breakpoint";
import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";

type BoxheightPropT = {
  isToday?: boolean;
};

export const SectionContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media ${breakpoints.device.max.xs} {
    display: block;
  }
`;

export const GraphContainer = styled.div`
  width: 60%;
  padding: 1.8rem 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const GraphHeader = styled.div`
  display: flex;
  justify-content: end;
`;

export const GraphContent = styled.div`
  margin-top: 1.4rem;
`;

export const StatContainer = styled.div`
  width: 38%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-bottom: 45px;
  }
`;

export const BoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.4rem;
  width: 48%;

  @media (${BreakPoints.xs}) {
    justify-content: flex-start;
  }
`;

export const BoxBig = styled.div<BoxheightPropT>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(prop) => (prop.isToday ? "14rem" : "12rem")};
  font-size: 2rem;
  padding: 1rem 0;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @media (${BreakPoints.xs}) {
    height: ${(prop) => (prop.isToday ? "12rem" : "10rem")};
    /* border: 2px solid red; */
  }
`;

export const BoxSmall = styled(BoxBig)`
  width: 90%;
  height: 8rem;
  padding: 1rem 0;
`;

export const BoxTitle = styled.span`
  margin-top: 1rem;
  padding-top: 0.2rem;
  font-size: 0.95rem;
`;

export const BoxValue = styled.span`
  display: block;
  margin-top: 1.6rem;
  padding-bottom: 0.4rem;
  font-weight: 900;
`;

export const SelectBoxCont = styled.div`
  width: 22%;

  @media (${BreakPoints.xs}) {
    width: 55%;
    /* border: 2px solid red; */
  }
`;
