import React, { useEffect, useState, useLayoutEffect } from "react";
import { getCurrentUser } from "shared/utils/authToken";
import logger from "shared/utils/logger";

import Table from "shared/clientcomponents/Table";
import {
  Box,
  BoxGrid,
  BoxTitle,
  BoxValue,
  Container,
  IconContainer,
  TableActions,
  TableContainer,
  ViewAllBtn,
  ViewBtn,
} from "Kyc/Styles";
import Kycicon1 from "../../../Assets/kycicon1.png";
import Kycicon2 from "../../../Assets/kycicon2.png";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { useHistory } from "react-router-dom";
import { getClientDetails } from "shared/utils/location";
import data from "shared/utils/data";
import { object } from "prop-types";
import StatusBadge from "shared/components/StatusBadge";
import { useEffectOnce } from "shared/hooks/useEffectOnce";
import kycOBJ from "Kyc/kycClass";
import { GradientLoader } from "shared/components";
import EmptyState from "shared/components/EmptyState";
import useApi from "shared/hooks/api";

type PropT = {
  [x: string]: any;
};

export default function index() {
  const [noRecord, setnoRecord] = useState(false);
  const [noValue, setnoValue] = useState(false);
  const [kycOviewData, setkycOviewData] = useState<any>({});
  const [kycData, setKycData] = useState<any>([]);
  const [isLoading, setisLoading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");
  const [pinBox, setPinBox] = useState(1);

  const currentUser = getCurrentUser();
  const history = useHistory();
  const [{ data, error, setLocalData }, fetchData] = useApi.get(
    `/kyc/total?type=AGENCY&page=${currentPage}`
  );

  const kycTableHeader = [
    { id: "sn", label: "S/N", width: "10%" },
    { id: "businessID", label: "Business ID", width: "15%" },
    { id: "merchantName", label: "Merchant Name", width: "25%" },
    { id: "email", label: "Email", width: "30%" },
    { id: "status", label: "Status", width: "10%" },
    { id: "functionProp", label: "", width: "20%" },
  ];

  const kycList = kycData?.map((business: any, index: number) => ({
    sn: ++index,
    businessID: business?.Business?.businessId,
    merchantName: `${business?.User?.firstName} ${business?.User?.lastName}`,
    email: business?.User?.email,
    status: (
      <StatusBadge
        // mode={business.status}
        mode={"TRANSPARENT"}
        title={business.status}
      />
    ),
    functionProp: (
      <ViewAllBtn
        onClick={() => {
          history.push(`/agencybanking/kyc/overview`, { data: business.uuid });
        }}
      >
        View
      </ViewAllBtn>
    ),
    ID: business.id,
  }));

  const hoverLink = (elm: PropT) => {
    history.push(`/kyc/overview/${elm?.ID}`);
  };

  const handleLog = async () => {
    // console.log("logging on Kyc");
    await logger.logActivities("remote", {
      date: new Date().toString(),
      author: currentUser.fullName,
      resource: "admin",
      action: "MERCHANT",
      payload: {
        userAgent: getClientDetails(),
        data: {
          detail: `${currentUser.fullName} access the "Kyc" overview`,
        },
      },
    });
  };

  const getKYCList = async () => {
    console.log("hello");
    setisLoading(true);
    try {
      const response = await kycOBJ.KycList(currentPage, "AGENCY");
      if (response.status && response.payload) {
        setisLoading(false);
        setKycData(response.payload?.data);
        console.log("kyc data ", response.payload?.data);
        setcurrentPage(response?.payload?.currentPage);
        settotalPages(response?.payload?.totalPages);
        setisLoading(false);
      }
    } catch (error) {
      setnoRecord(true);
    }
  };

  const getApproved = async () => {
    setisLoading(true);
    try {
      const response = await kycOBJ.getApprovedKyc("AGENCY");
      if (response.status && response.payload) {
        setisLoading(false);
        setKycData(response.payload?.data);
        // console.log('kyc data ', response.payload?.data)
        setcurrentPage(response?.payload?.currentPage);
        settotalPages(response?.payload?.totalPages);
        setisLoading(false);
      }
    } catch (error) {
      setnoRecord(true);
    }
  };

  const getPending = async () => {
    setisLoading(true);
    try {
      const response = await kycOBJ.getPendingKyc("AGENCY");
      if (response.status && response.payload) {
        setisLoading(false);
        setKycData(response.payload?.data);
        // console.log('kyc data ', response.payload?.data)
        setcurrentPage(response?.payload?.currentPage);
        settotalPages(response?.payload?.totalPages);
        setisLoading(false);
      }
    } catch (error) {
      setnoRecord(true);
    }
  };

  const getRejected = async () => {
    setisLoading(true);
    try {
      const response = await kycOBJ.getRejectedKyc("AGENCY");
      if (response.status && response.payload) {
        setisLoading(false);
        setKycData(response.payload?.data);
        // console.log("kyc data ", response.payload?.data);
        setcurrentPage(response?.payload?.currentPage);
        settotalPages(response?.payload?.totalPages);
        setisLoading(false);
      }
    } catch (error) {
      setnoRecord(true);
    }
  };

  const getKYCOverview = async () => {
    const response = await kycOBJ.overview();
    if (response?.status) setkycOviewData(response?.payload);
  };

  useEffect(() => {
    if (error) setisLoading(false);
    if (data && data?.payload?.data) {
      setKycData(data?.payload?.data);
      console.log("agency banking kyc", data);
      settotalPages(data?.payload?.totalPages);
      setisLoading(false);
      setnoRecord(false);
    }
  }, [data, error]);

  useEffect(() => {
    getKYCOverview();
  }, []);

  useEffectOnce(() => {
    handleLog();
  });

  useEffect(() => {
    // fetchData();
    // getKYCList();
  }, [currentPage]);

  return (
    <Container>
      <BoxGrid>
        <Box
          key={1}
          isActive={pinBox === 1 ? true : false}
          onClick={() => {
            setPinBox(1), getApproved();
          }}
        >
          <IconContainer isActive={true}>
            <img src={Kycicon2} alt="" />
          </IconContainer>
          <BoxTitle>KYC Completed</BoxTitle>
          <BoxValue>{kycOviewData?.approved}</BoxValue>
        </Box>

        <Box
          key={1}
          isActive={pinBox === 2 ? true : false}
          onClick={() => {
            setPinBox(2);
            getPending();
          }}
        >
          <IconContainer isActive={true}>
            <img src={Kycicon1} alt="" />
          </IconContainer>
          <BoxTitle>KYC Pending</BoxTitle>
          <BoxValue>{kycOviewData?.pending}</BoxValue>
        </Box>

        <Box
          key={1}
          isActive={pinBox === 3 ? true : false}
          onClick={() => {
            setPinBox(3);
            getRejected();
          }}
        >
          <IconContainer isActive={true}>
            <img src={Kycicon1} alt="" />
          </IconContainer>
          <BoxTitle>KYC Revoked</BoxTitle>
          <BoxValue>{kycOviewData.rejected}</BoxValue>
        </Box>
      </BoxGrid>
      <TableContainer>
        <DashboardSubHeader
          // count={0}
          title={"KYC LIST"}
          btnTitle=""
          withSearch={true}
          placeholder="Business ID"
          withFilter={false}
          widthActionBtn={false}
          filterOptions={["Name", "Date"]}
          setSearchData={() => ""}
          callBackFn={() => ""}
        />
        {isLoading ? (
          <GradientLoader />
        ) : (
          <>
            {noRecord ? (
              <EmptyState stateTitle="No Kyc" statePara="" stateBtnText="" />
            ) : (
              <>
                {isLoading ? (
                  <GradientLoader />
                ) : (
                  <Table
                    headData={kycTableHeader}
                    bodyData={[]}
                    rowStyle={{ background: "#ffffff" }}
                    totalPages={totalPages}
                    currentpage={currentPage}
                    goToPage={(value: any) => {
                      setcurrentPage(value);
                      // getKYCList();
                    }}
                    // hoverLink={hoverLink}
                  />
                )}
              </>
            )}
          </>
        )}
      </TableContainer>
    </Container>
  );
}
