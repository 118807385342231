import { TicketIDBadge } from "Dispute/AllDispute/Styles";
import { useState } from "react";
import { FinanceData } from "../../Data";
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
  TableContent,
  TableContentMain,
  TableHeader,
  TableRow,
} from "./Styles";

const Finance = ({ finance, handleSwitch }: any) => {
  return (
    <TableRow>
      <TableContentMain>
        <TicketIDBadge>{finance.transactionId}</TicketIDBadge>
      </TableContentMain>
      <TableContentMain>{finance.description}</TableContentMain>
      <TableContentMain>{finance.type}</TableContentMain>
      <TableContentMain>{finance.amountPaid}</TableContentMain>
      <TableContentMain>{finance.timeDate}</TableContentMain>
      <TableContentMain>
        <CheckBoxWrapper>
          <CheckBox
            id="checkbox"
            type="checkbox"
            checked={finance.activated}
            onChange={() => handleSwitch(finance.id)}
          />
          <CheckBoxLabel
            htmlFor="checkbox"
            onClick={() => handleSwitch(finance.id)}
          />
          <span onClick={() => handleSwitch(finance.id)}>Release</span>
        </CheckBoxWrapper>
      </TableContentMain>
    </TableRow>
  );
};

export default function TableSection() {
  const [finances, setFinances] = useState(FinanceData.slice(0, 7));

  const handleSwitch = (id: any) => {
    let newData = FinanceData.map((finance) => {
      if (finance.id == id) {
        finance.activated = !finance.activated;
      }
      return finance;
    });
    setFinances(newData.slice(0, 7));
  };

  const fianceList = finances.map((finance) => (
    <Finance key={finance.id} finance={finance} handleSwitch={handleSwitch} />
  ));

  return (
    <>
      <TableHeader>
        <TableContent>Transaction ID</TableContent>
        <TableContent>Transaction Description</TableContent>
        <TableContent>Transaction Type</TableContent>
        <TableContent>Amount</TableContent>
        <TableContent>Time/Date</TableContent>
        <TableContent>Release or Delay Settlement</TableContent>
      </TableHeader>

      {fianceList}
    </>
  );
}
