import { color } from "shared/utils/styles";
import styled from "styled-components";

export const PageContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  background-color: #fff;
`;

export const Section = styled.section`
  form {
    margin-top: 2rem;
    /* border: 1px solid ${color.fountain}; */
  }

  label {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.5rem;

    img{
        cursor: pointer;
    }
  }

  input {
    padding: 0.5rem;
    width: 100%;
    height: 46px;
    border: 1px solid rgba(215, 215, 215, 0.8);
    border-radius: 9px;
    font-size:14px;
  }

  select {
    padding: 0.5rem;
    width: 100%;
    height: 46px;
    border: 1px solid rgba(215, 215, 215, 0.8);
    border-radius: 9px;
    font-size:14px;
    color: #333;
    padding-right: 2rem;
  }

  button{
    padding: 0.8rem 1.8rem;
    border-radius: 5px;
    color: #fff;
    background-color: ${color.fountain};
    cursor: pointer;
    :disabled{
      background-color: #ccc;
      cursor:none
    }
  }
  option{
    font-size:14px;
  }
  margin-bottom: 4rem;
`;
export const SectionHeader = styled.div`
  width: 100%;
  padding: 1.2rem 0.6rem;
  border-bottom: 1px solid ${color.fountain};

  h3 {
    font-weight: 600;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  gap: 2rem;

  div {
    width: 100%;
  }
`;

export const EmailInputGroup = styled.div`
  display: block;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  gap: 2rem;

  div {
    width: 100%;
  }
  &>.error{
    color: red;
  }
`;

export const FormContainer = styled.div`
  width: 70%;
  margin-top: 2rem;
`;
