import React, { useState } from "react";
import { Icon } from "shared/components";
import {
  SearchInput,
  SearchInputContainer,
  FormContainer,
  SearchButton,
  IconContainer,
  SearchDropdown,
  SelectList,
} from "./Styles";
import { Select, Option } from "../../../shared/quickComponents/select";
type PropT = {
  [x: string]: any;
};

export default function HeaderSearch() {
  const [searchCategory, setsearchCategory] = useState<string>("Transaction");
  return (
    <>
      <form>
        <FormContainer>
          <SearchInputContainer>
            <IconContainer>
              <Icon type="search" size="24" color="gray" />
            </IconContainer>

            <SearchInput placeholder={`Enter ${searchCategory} id `} />
          </SearchInputContainer>
          <SearchDropdown>
            <Select value={searchCategory} bgColor="#760000">
              <Option onClick={() => setsearchCategory("Account")}>
                Account
              </Option>
              <Option onClick={() => setsearchCategory("Business")}>
                Business
              </Option>
              <Option onClick={() => setsearchCategory("Transaction")}>
                Transaction
              </Option>
            </Select>
          </SearchDropdown>
          <SearchButton>Search</SearchButton>
        </FormContainer>
      </form>
    </>
  );
}
