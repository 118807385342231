import React from "react";
import SharedCheckList from "shared/clientcomponents/SharedCheckList";
import {
  OptionLabel,
  PageCont,
  PolicyField,
  PolicyLabel,
  PolicyOption,
} from "./Styles";

export default function EmailPolicy() {
  return (
    <div>
      <PageCont>
        <PolicyField>
          <PolicyLabel>Email</PolicyLabel>
          <PolicyOption>
            <SharedCheckList
              checked={true}
              id="1"
              label=""
              onChange={() => ""}
            />
            <OptionLabel>
              Activate other mails different from fountainpay
            </OptionLabel>
          </PolicyOption>
        </PolicyField>
      </PageCont>
    </div>
  );
}
