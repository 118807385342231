import { ActionTypes } from "../constants/actionType";

const initialState = {
  title: "Dashboard",
};

export const pageTitleReducer = (
  state: any = initialState.title,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_PAGE_TITLE:
      return action.payload;
    default:
      return state;
  }
};
