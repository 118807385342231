import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const FormContainer = styled.div`
  margin-top: 5rem;
  width: 68%;
  padding-left: 2.8rem;
  @media ${breakpoints.device.max.xs} {
    width: 90%;
  }
`;

export const ResetForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputLabel = styled.label`
  text-align: right;
  width: 25%;
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
`;

export const Input = styled.input`
  width: 70%;
  padding: 1rem 0.4rem;
  border: 1px solid ${color.backgroundMedium};
  outline-style: none;
  border-radius: 0.4rem;
  @media ${breakpoints.device.max.sm} {
    width: 100%;
  }
`;

export const BtnCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const ResetBtn = styled.button`
  width: 70%;
  padding: 1rem;
  background: ${color.fountain};
  color: #fff;
  border-radius: 0.4rem;
  cursor: pointer;
`;
