import React, { useState } from "react";
import AccountRow from "./AccountRow";
import ServiceRow from "./ServiceRow";
import {
  Section,
  TableContainer,
  TableContent,
  TableHeader,
  TableRow,
} from "./Styles";

type usersPropT = {
  id: number;
  AccountName: string;
  phoneNumber: string;
  email: string;
  activated: boolean;
};

const data = [
  {
    id: 1,
    AccountName: "Adesare Adegbagi",
    phoneNumber: "+234234567890",
    email: "tunmisehassan@gmail.com",
    activated: true,
  },
  {
    id: 2,
    AccountName: "Kunle Dende",
    phoneNumber: "+2341234567890",
    email: "kunle@google.com",
    activated: false,
  },
  {
    id: 3,
    AccountName: "John Doe",
    phoneNumber: "+234234567890",
    email: "JohnDoen@google.com",
    activated: true,
  },
  {
    id: 4,
    AccountName: "Olabode Eto",
    phoneNumber: "+2347060601229",
    email: "Olabodeeto@google.com",
    activated: true,
  },
  {
    id: 5,
    AccountName: "Code Breed",
    phoneNumber: "+2347060601229",
    email: "Olabodeeto@google.com",
    activated: false,
  },
  {
    id: 6,
    AccountName: "Kunle Dende",
    phoneNumber: "+2341234567890",
    email: "kunle@google.com",
    activated: false,
  },
  {
    id: 7,
    AccountName: "John Doe",
    phoneNumber: "+234234567890",
    email: "JohnDoen@google.com",
    activated: true,
  },
];

const ServiceData = [
  {
    id: 1,
    ServiceName: "Mega Impact",
    AccountNumber: "+234234567890",
    BankName: "Megaimpact@gmail.com",
    activated: true,
  },
  {
    id: 2,
    ServiceName: "Portesoft Inc",
    AccountNumber: "+234234567890",
    BankName: "Portesoft@gmail.com",
    activated: true,
  },
];

export default function TablesSection() {
  const [accounts, setaccounts] = useState(data.slice(0, 7));
  const [services, setservices] = useState(ServiceData);

  const handleSwitch = (id: any) => {
    let newData = accounts.map((account) => {
      if (account.id == id) {
        account.activated = !account.activated;
      }
      return account;
    });
    setaccounts(newData.slice(0, 7));
  };

  const AccountList = accounts.map((account) => (
    <AccountRow
      key={account.id}
      Account={account}
      toogleswitch={handleSwitch}
    />
  ));

  const ServiceList = services.map((service) => (
    <ServiceRow
      key={service.id}
      Service={service}
      toogleswitch={handleSwitch}
    />
  ));

  return (
    <>
      <Section>
        <h2 style={{ marginBottom: "2.5rem" }}>Sub Accounts</h2>
        <TableContainer>
          <TableHeader>
            <TableContent>Sub Account Name</TableContent>
            <TableContent width="25%">Sub Account Phone Number</TableContent>
            <TableContent>Email</TableContent>
            <TableContent width="12%"></TableContent>
          </TableHeader>
          {AccountList}
        </TableContainer>
      </Section>
    </>
  );
}
