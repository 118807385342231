import BreakPoints from "shared/utils/Breakpoint";
import styled from "styled-components";

export const OverviewPageLayout = styled.div`
  margin-top: 2.4rem;
  width: 100%;

  @media (${BreakPoints.xs}) {
    margin-top: -2.6rem;
    /* border: 2px solid red; */
  }
`;
