import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { store } from "store";
// import store from "../AppStore/store";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import App from "./AppWrapper";
import Preloader from "../preloader";
import { ToastContainer } from "react-toastify";

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <App />
    </Provider>
  );
};

export default AppWrapper;
