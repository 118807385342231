import styled from "styled-components";
import { Button, Icon } from "shared/components";
import { color, font } from "shared/utils/styles";

export const Container = styled.div`
  padding: 20px;
  width: 100%;
`;
export const HeadingDiv = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #760000;
  margin-bottom: 47px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #292929;
`;

export const ActionButton = styled(Button)`
  margin: 2em 0;
  color: #fff;
  width: 100%;
  background-color: ${color.fountain};
`;

export const Input = styled.input`
  box-sizing: border-box;

  padding: 10px;

  width: 100%;
  height: 61px;

  background: #ffffff;
  /* Grey Border 1 */

  border: 1px solid rgba(215, 215, 215, 0.6);
  border-radius: 9px;
`;
export const Select = styled.select`
  box-sizing: border-box;

  paddong: 10px;

  width: 100%;
  height: 61px;

  /* Primary White */

  background: #ffffff;
  /* Grey Border 1 */

  border: 1px solid rgba(215, 215, 215, 0.6);
  border-radius: 9px;
`;
