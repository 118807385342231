import React from 'react';

import errorPage from './assets/error-page.png';

import { ErrorPage, ErrorPageInner, ErrorBox, StyledIcon, Title } from './Styles';

const PageError = () => (
  <ErrorPage>
    <ErrorPageInner>
      <ErrorBox>
        <StyledIcon>
          <img src={errorPage} />
        </StyledIcon>
       
        <Title>Looks like there is a glitch…</Title>
        <p>
          {'We’re not quite sure what went wrong. Please contact us for assistance '}
          <a href="https://support.fountainpay.ng">Help Center</a>
          {' if you need a hand.'}
        </p>
      </ErrorBox>
    </ErrorPageInner>
  </ErrorPage>
);

export default PageError;
