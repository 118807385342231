import axios from "axios";

import {
  getStoredAuthToken,
  // getStoredRefreshToken,
  storeAuthToken,
} from "shared/utils/authToken";
// import consoleLog from "shared/utils/consoleLog";

const ApiMultiPart = axios.create({
  baseURL: process.env.REACT_MULTI_API_URL || "https://api-5916234be.fountainpay.ng",//"https://staging-5714397be.fountainpay.ng",

  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    "x-api-client": "api",
  },
});

//Before making a request , do the following
ApiMultiPart.interceptors.request.use(
  (config: any) => {
    // consoleLog("Token", getStoredAuthToken());
    const token = getStoredAuthToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    // consoleLog(config, "config");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//When we get response
ApiMultiPart.interceptors.response.use(
  (res) => {
    // consoleLog(res, "res");
    return res;
  },
  async (error) => {
    const newConfig = error.config;

    if (error.response) {
      if ([403].includes(error.response.status) && !newConfig._retry) {
        newConfig._retry = true;

        try {
          const res = await ApiMultiPart.post("/authentication/refresh-token", {
            // refreshToken: getStoredRefreshToken(),
          });

          // consoleLog("PAYLOAD: ", res);
          const { access } = res.data;

          storeAuthToken(access);
          return ApiMultiPart(newConfig);
        } catch (error) {
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default ApiMultiPart;
