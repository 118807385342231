import React from "react";
import { Link } from "react-router-dom";
import { BusinessData } from "Dashboard/Business/Data";
import {
  TableContent,
  TableContentMain,
  TableHeader,
  TableRow,
} from "./Styles";

export default function TableSection() {
  const businessList = BusinessData.slice(0.7).map((biz) => (
    <div key={biz.id}>
      <Link to={`/agencybanking/business/overview/${biz.id}`}>
        <TableRow key={biz.id}>
          <TableContentMain>{biz.binessName}</TableContentMain>
          <TableContentMain>{biz.amountProcessed}</TableContentMain>
          <TableContentMain>{biz.numOfServices}</TableContentMain>
          <TableContentMain>{biz.amountSettled}</TableContentMain>
          <TableContentMain>{biz.numOfSubAcct}</TableContentMain>
        </TableRow>
      </Link>
    </div>
  ));

  return (
    <>
      <TableHeader>
        <TableContent>Agent Name</TableContent>
        <TableContent>Amount Processed</TableContent>
        <TableContent>Number of Services</TableContent>
        <TableContent>Amount Settled</TableContent>
        <TableContent>Number Of Subaccount</TableContent>
      </TableHeader>

      {businessList}
    </>
  );
}
