import React, { useState } from "react";
import "chart.js/auto";
import { Line, Doughnut, Pie } from "react-chartjs-2";
import { color } from "../../../../shared/utils/styles";
import { Select, Option } from "shared/quickComponents/select";
import { Data } from "./Data";
import {
  Box,
  ChartDetails,
  ChartLabelContainer,
  GraphContainer,
  GraphContent,
  GraphHeader,
} from "./Styles";

type ChartPropT = {
  id: number;
  month: string;
  transaction: number;
  userLost: number;
}[];

const businessData = [
  { id: 1, BusinessName: "Business 1", Value: 400 },
  { id: 2, BusinessName: "Business 2", Value: 200 },
  { id: 3, BusinessName: "Business 3", Value: 590 },
  { id: 4, BusinessName: "Business 4", Value: 200 },
];

function shuffleArray(array: any) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const colorsArr = [
  "#0D2535",
  "rgb(255, 99, 132)",
  "rgb(54, 162, 235)",
  "rgb(255, 205, 86)",
];

const businessDataSort = businessData.sort((a, b) => b.Value - a.Value);
const chartValues = businessData.map((data) => data.Value);

export default function PieChart() {
  const [userData, setUserData] = useState({
    labels: ["A Business", "B Business", "C Business", "D Business"],
    datasets: [
      {
        label: "My First Dataset",
        data: chartValues, //feeding the chart with business data values array
        backgroundColor: colorsArr,
        hoverOffset: 4,
      },
    ],
  });

  function kFormatter(num: any) {
    let exp = (Math.sign(num) * Math.abs(num)) / 1000;
    return Math.abs(num) > 999
      ? ` ${exp.toFixed(1)}k`
      : Math.sign(num) * Math.abs(num);
  }

  const options = {
    plugins: { legend: { display: false } },
  };

  const detailsList = businessData.map((business, i) => (
    <ChartDetails key={business.id}>
      <div style={{ display: "flex", gap: "0.4rem" }}>
        <Box bgColor={colorsArr[i]}></Box>
        <span>{business.BusinessName}</span>
      </div>
      <span
        style={{
          display: "block",
          width: "100%",
          textAlign: "center",
          fontSize: "1rem",
        }}
      >
        {business.Value}
      </span>
    </ChartDetails>
  ));
  return (
    <>
      <GraphHeader>
        <h4>Five best businesses</h4>
      </GraphHeader>
      <GraphContent>
        <Pie data={userData} options={options} />
      </GraphContent>
      <ChartLabelContainer>{detailsList}</ChartLabelContainer>
    </>
  );
}
