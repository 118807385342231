import React, { useEffect, useState } from "react";
import {
  ContentHeader,
  ContentRow,
  InnerPanelLayout,
  PanelTitle,
  ViewAll,
} from "./Style";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import consoleLog from "shared/utils/consoleLog";
import reportObj from "Report/report-class";
import { Icon, Spinner } from "shared/components";
import { getStatusColor } from "shared/utils/statusColor";

const data = [
  { date: "27/07/2023", value: 1000 },
  { date: "27/07/2023", value: 1000 },
  { date: "27/07/2023", value: 1000 },
  { date: "27/07/2023", value: 1000 },
  { date: "27/07/2023", value: 1000 },
];

export default function RecentTransactions() {
  const [datas, setDatas] = useState<any>(data);
  const [loading, setLoading] = useState(false);
  const history=useHistory();

  const getTransactions = async () => {
    setLoading(true)
    try {
      const response = await reportObj.getTransactions();
      //console.log("Transactions: ", response)
      
      if (
        response instanceof Array
      ) {
        setDatas(response);
      } else {
        setDatas([]);
      }

      //consoleLog(response, "responseServ");
    } catch (err) {
      //consoleLog(err, "error");
      setDatas([]);
      // @ts-ignore
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  const truncate=(item: string)=> {
    if(!item){
      return "";
    }

    const str: string=item.length > 15? item.substring(0, 15) + "...": item;

    return(
      <>
        {str}
      </>
    )
  }
  
  const linkUrlPath = () => {
    history.push(`${history?.location?.pathname}/details/transactions`);
  };
  // console.log("Transactions: ", datas)
  const arrayDataItems = datas instanceof Array && datas.map((item: any, id: number) => (
    <ContentRow key={id}>
      <span>{truncate(item?.businessName)}</span>
      {/* <Icon type={"arrow-down"} size={20} color={item?.status.toString().toLowerCase().includes("init")?getStatusColor("terminated"):getStatusColor(item?.status)} /> */}
      <span>
        {item?.paymentCurrency}
        {parseFloat(item?.amount).toFixed(2)}
      </span>
    </ContentRow>
  ));

  useEffect(()=>{
    getTransactions();
  }, []);
  return (
    <InnerPanelLayout>
      <PanelTitle>Recent Transactions</PanelTitle>
      <ContentHeader>
        <span>Business</span>
        <span>Total volume</span>
      </ContentHeader>
      {loading && <Spinner size={30} />}
      {!loading && datas && arrayDataItems}
      
      <ViewAll onClick={()=>linkUrlPath()}>View all</ViewAll>
    </InnerPanelLayout>
  );
}
