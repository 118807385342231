import React, { useState } from "react";
import { BtnCont, BusinessForm, FormBtn, FormTitle, LoaderCont } from "./Styles";
import SelectField from "./SelectField";
import InputField from "./InputField";
import { PersonalTypeData } from "./StepsData";
import api from "shared/utils/api";
import Loaders from 'shared/components/Loaders/index'
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";
import { omniSaver } from "shared/utils/ls";

type PropT = {
  setcurrentStep: Function;
  setdoneSteps: Function;
  doneSteps: any[];
};

export default function PersonalKyc({
  setcurrentStep,
  setdoneSteps,
  doneSteps,
}: PropT) {
  const [merchantEmail, setmerchantEmail] = useState("");
  const [RegNo, setRegNo] = useState("");
  const [RegType, setRegType] = useState("BVN");
  const [isLoading, setIsLoading] = useState(false)
  const [isValidating, setIsValidating] = useState(false)

  const handleStepOne = (e: any) => {
    e.preventDefault();
    setcurrentStep(2);
    setdoneSteps([...doneSteps, 1]);
  };

  const getUserData = async(value:string) => {
    if(value.length === 11 && merchantEmail !== '') {
        const params:any = {
            isBvn: RegType === 'BVN' ? true : false,
            is_bvn: RegType === 'BVN' ? true : false,
            number: value
        }

        setIsValidating(true)

        try {
            const res:any = await api.post(
                "/kyc-validate",
                {...params}
            )
            const {payload} = res
            const personalKyc = {
              email: merchantEmail || payload.email,
              firstName: payload.firstName,
              lastName: payload.lastName,
              phone: payload.phoneNumber,
              address: payload.address,
              bvn: payload.bvn,
              nimc: '',
              dob: payload.dateOfBirth
            }
            toast.success(`${res.message}`);
            omniSaver('personalKyc', personalKyc)
        } catch(err:any) {
            console.log('get user err', err)
        } finally{
          setIsValidating(false)
        }


    } else if (value.length === 11 && merchantEmail === '') {
      toast.error('Merchant email required !');
    } else if (merchantEmail !== '' && value.length < 11) {
      toast.error(`Valid ${RegType} required !`);
    } 
  }


  return (
    <div>
      <FormTitle>Enter Merchant Details</FormTitle>
      <BusinessForm onSubmit={handleStepOne}>
        {
          <>
            <InputField
              value={merchantEmail}
              label="Email Address"
              inputType="email"
              handleChange={(value: any) => setmerchantEmail(value)}
            />
            <SelectField
              value={RegType}
              setValue={(value: any) => setRegType(value)}
              label="Registration Type"
              data={PersonalTypeData}
              />
            <InputField
                value={RegNo}
                label={isValidating ? "Validating..." : RegType === 'BVN' ? "Bank Verification Number" : "National Identification Number"}
                disabled={isValidating}
                inputType="Number"
                handleChange={(value: any) => {
                    setRegNo(value)
                    getUserData(value)
                }}
            />
          </>
        }
        
        {/* <InputField
          value={businessName}
          label="Business Name"
          handleChange={(value: any) => setbusinessName(value)}
        /> */}

        
        

        <BtnCont>
          <FormBtn 
            disabled={isLoading} 
            type="submit"
          >
            Save and Continue
          </FormBtn>
        </BtnCont>
      </BusinessForm>
    </div>
  );
}
