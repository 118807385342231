import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color } from "../../../shared/utils/styles";

export const PageLayout = styled.div`
  width: 100%;
  margin-top: 2rem;
  background-color: #fff;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  @media ${breakpoints.device.max.md} {
    margin-top: 0rem;
  }
`;

export const TableContainer = styled.div`
  margin-top: 2rem;
  height: 60vh;
  overflow-y: scroll;
`;

export const TableHeader = styled.div`
  background-color: ${color.fountainLight};
  display: flex;
  width: 100%;
  padding: 1rem 0.2rem;
  justify-content: space-between;
  color: ${color.fountain};
  font-size: 14px;
`;

export const TableRow = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0.2rem;
  justify-content: space-between;
  color: ${color.fountain};
  font-size: 14px;
  border: 1px solid ${color.backgroundMedium};
  cursor: pointer;

  &:hover {
    background-color: ${color.backgroundLightest};
  }
`;

export const TableContent = styled.div`
  width: 15%;
  padding-left: 0.2rem;
  justify-content: center;
`;
export const TableContentMain = styled.div`
  width: 15%;
  padding-left: 0.2rem;
  justify-content: center;
  font-size: 13px;
`;
