import styled from "styled-components";

import { AnimationDuration, FadeAnimation } from "shared/animations";
import breakpoints from "shared/utils/breakpoints";
import { color, sizes } from "shared/utils/styles";

const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

type nav = {
  navOpened: boolean;
};

export const Layouts = styled.div`

//mobile styles goes here
  /* padding: 25px 20px 50px ${paddingLeft - 20}px; */
  max-width: 100vw;
  overflow-x: hidden;
 overflow-x:hidden;


@media only screen and (min-width: 768px) {
  padding: 25px 32px 50px ${paddingLeft - 10}px;
  background: ${color.backgroundLightest};
  height: 100vh;
  margin-bottom: -20px;

}
 
  @media (max-width: 1100px) {
    /* padding: 25px 20px 50px ${paddingLeft - 20}px; */
  }
  @media (max-width: 999px) {
    /* padding-left: ${paddingLeft - 20 - sizes.secondarySideBarWidth}px; */
  }
`;

export const NavWrapper = styled.div<nav>`
  position: absolute;
  /* display: none; */
  z-index: 10000;
  /* width: 0px; */
  left: ${(props) => (props.navOpened ? 0 : `-284px`)};
  /* right: 0; */
  top: 0;
  /* height: 100%; */
  height: 100vh;
  width: 284px;
  /* width: 100%; */
  -webkit-transition: left 0.2s ease-in-out;
  -moz-transition: left 0.2s ease-in-out;
  -o-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
  background-color: red;
`;

export const NavLeftMenu = styled.div`
  display: none;
  @media ${breakpoints.device.max.sm} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 30px;
    width: 30px;
    padding-top: 3px;
    top: 1.88rem;
    border-radius: 50%;
    z-index: 10000000;
    right: 10px;
    background-color: #760000;
  }
`;

export const ContentArea = styled.div`
  //mobile styles heere
  max-width: 100%;
  margin-top: 6rem;
  animation-name: ${FadeAnimation};
  animation-duration: ${AnimationDuration};

  @media only screen and (min-width: 768px) {
    margin-top: ${sizes.appNavBarLeftWidth - 35}px;
    margin-left: 1rem;
    padding: 28px 0px;
    height: auto;
  }

  @media ${breakpoints.device.max.xs} {
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 40px 0px;
  }
`;
