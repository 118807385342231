import React from "react";
import Layout from "Layout";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { ProtectedRoute } from "guards/ProtectedRoute";
import { SuperAdminGuard } from "guards/super-admin-guard";
import { AdminGuard } from "guards/admin-guard";
import { PageError } from "shared/components";
import Home from "./Home";
import Single from "./Single";
import AllKyc from "./AllKyc";

export default function index() {
  const match = useRouteMatch();
  return (
    <Layout pageTitle="KYC">
      <>
        <ProtectedRoute
          path={`${match.path}/home`}
          guards={[SuperAdminGuard, AdminGuard]}
          component={Home}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/overview`}
          guards={[SuperAdminGuard, AdminGuard]}
          component={Single}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/overview/:id`}
          guards={[SuperAdminGuard, AdminGuard]}
          component={Single}
          fallback={() => <PageError />}
        />

        <ProtectedRoute
          path={`${match.path}/all`}
          guards={[SuperAdminGuard, AdminGuard]}
          component={AllKyc}
          fallback={() => <PageError />}
        />

        {match.isExact && <Redirect to={`${match.url}/home`} />}
      </>
    </Layout>
  );
}
