import React, { useState } from "react";
import disputOBJ from "Dispute/disputeClass";
import { PageLayout } from "shared/section_components/Styles";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection/SecondSection";
import useApi from "shared/hooks/api";

export default function DisputeHome() {
  // const [{ data, error, setLocalData }, fetchData] = useApi.get(`/dispute`);
  return (
    <>
      <PageLayout>
        <FirstSection />
        <SecondSection />
      </PageLayout>
    </>
  );
}
