import React, { useState, useEffect } from "react";
import logo from "Assets/logo.svg";
import loginBanner from "Assets/loginbanner.svg";
import Spinner from "shared/components/Spinner";

import {
  BannerContainer,
  Container,
  FormContainer,
  InputContainer,
  InputLabel,
  LoginButton,
  LoginForm,
  Textarea,
  PageContainer,
  Theform,
  WelcomeText,
  ErrorText,
  ErrorContainer,
} from "./Styles";
import { Button } from "shared/components";
import { encryptData } from "shared/utils/security";

type userType = {
  email: string;
  fullname: string;
  role: string;
};

type ErrorPropT = {
  status: boolean;
  errors: any;
  message: string;
  meta: any;
  payload: any;
};

const GenerateComponent = () => {
  // const USER = useAppSelector((state) => state.USER);
  // const dispatch = useAppDispatch();

  const [content, setContent] = useState<string>("");
  const [result, setResult] = useState<string>("");
  const [isBtnActive, setisBtnActive] = useState<boolean>(false);
  const [showActivityIndicator, setshowActivityIndicator] = useState(false);

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setshowActivityIndicator(true);
    const data: any=encryptData(content, false)
    setResult(JSON.stringify(data));
    setshowActivityIndicator(false);
  };

  const handleChange=(event: any)=> {
    console.log(event.target.value)
	  setContent(event.target.value)
  }

  useEffect(() => {
    if (content.length) {
      setisBtnActive(true);
    } else {
      setisBtnActive(false);
    }
  }, [content]);


  return (
    <div>
      <PageContainer>
        <Container>
          <FormContainer>

            <LoginForm>
				<WelcomeText>Generate an encrypted tokens</WelcomeText>
				
				<Theform onSubmit={handleLogin} autoComplete="off">
					<div style={{ width: "100%", marginTop: "4rem" }}>
						<Textarea
							value={content}
							onChange={handleChange}
							/>
							
					</div>
					<div style={{ width: "100%", marginTop: "4rem" }}>
						<LoginButton isActive={isBtnActive} type="submit">
							{showActivityIndicator ? <Spinner color="#fff" /> : "Login"}
						</LoginButton>
					</div>
					<div style={{ width: "100%", marginTop: "4rem" }}>
						<Textarea
							value={result}
							/>
							
					</div>
				</Theform>
            </LoginForm>
          </FormContainer>
        </Container>
      </PageContainer>
    </div>
  );
};

export default GenerateComponent;
