import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getCurrentUser } from "shared/utils/authToken";
import { toast } from "react-toastify";

import useApi from "shared/hooks/api";
import { PageLayout } from "shared/section_components/Styles";

import {
  PersonalFormCont,
  FormGroup,
  Span,
  FormInputContainer,
  FormInput,
  InputLabelContainer,
} from "./Styles";
import { Divider } from "shared/section_components/Styles";
import { SharedInput } from "shared/section_components/ShareInput";
import { GhostButton } from "shared/section_components/Styles";
import Layout from "Layout";
import OtpModal from "shared/quickComponents/OtpModal";
import ConfirmModal from "shared/quickComponents/ConfirmModal";
import profileOBJ from "./profileClass";

const BusinessSettings = () => {
  const settings = {};
  const users = {};

  const currentUser = getCurrentUser();
  const history = useHistory();

  const handleToogle = () => {
    setagencyBankingForm(!agencyBankingForm);
    setgatewayForm(!gatewayForm);
  };

  const handlePassword = () => {
    history.push("/change-password");
  };

  const [{ isUpdating }, updateBusiness] = useApi.put("/business");

  // =================== Local State and functions ====================
  const [businessImage, setbusinessImage] = useState<string>();
  const [agencyBankingForm, setagencyBankingForm] = useState<boolean>(false);
  const [gatewayForm, setgatewayForm] = useState<boolean>(true);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [phoneBtn, setPhoneBtn] = useState("Edit");
  const [phoneNumber, setphoneNumber] = useState("");
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showIndicator, setshowIndicator] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");

  const [tempId, settempId] = useState("");

  // =================================================================

  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const objectUrl: string = URL.createObjectURL(e.target.files[0]);
      setbusinessImage(objectUrl);
    }
  };

  const sendOTP = async () => {
    setshowIndicator(true);
    const adminEmail = currentUser.email;
    const requestedEmail = currentUser.email;
    const response = await profileOBJ.SendOTP({
      adminEmail,
      requestedEmail,
      phoneNumber,
    });
    if (response.status) {
      settempId(response?.payload?.temp_id);
      toast.success(response?.message);
      setshowIndicator(false);
      setConfirmModalOpen(false);
      setOtpModalOpen(true);
      console.log("send otp response", response);
    } else {
      toast.error(response?.message);
      setshowIndicator(false);
      setConfirmModalOpen(false);
    }
  };
  const handleOTP = async (otp: string) => {
    setOtp(otp);
    if (otp.length === 6) {
      const OTPpayload = { otp, tempId, action: "UPDATE_USER_PHONE_NUMBER" };
      const response = await profileOBJ.ConfirmOTP(OTPpayload);
      if (response?.status) {
        toast.success(response?.message);
        setOtpModalOpen(false);
      } else {
        toast.error(response);
      }
    }
  };

  useEffect(() => {
    let [first, ...second] = currentUser.fullName.split(" ");
    second = second.join(" ");
    setfirstName(first);
    setlastName(second);
  }, []);

  return (
    <Layout PageTitle="Profile">
      {/* {!business && <PageLoader /> } */}
      {/* {error && <PageError /> } */}
      <PageLayout>
        <div>
          <h2 style={{ marginTop: "2rem" }}>Personal Information</h2>
          <Divider />
          <PersonalFormCont>
            <FormGroup>
              <SharedInput
                onChange={() => ""}
                placeholder={firstName}
                disabled={true}
                label="First name"
              />
              <SharedInput
                onChange={() => ""}
                placeholder={lastName}
                disabled={true}
                label="Last name"
              />
            </FormGroup>
            <SharedInput
              onChange={() => ""}
              placeholder={currentUser.email}
              disabled={true}
              label="Email address"
            />

            <Divider />
            <div style={{ marginTop: "1rem" }}>
              <Span>Password</Span>
              <div
                style={{
                  marginTop: "0.4rem",
                  width: "48%",
                }}
              >
                <GhostButton onClick={handlePassword}>
                  Change Password
                </GhostButton>
              </div>
            </div>
          </PersonalFormCont>
        </div>

        <br />
        <div
          style={{
            marginTop: "8rem",
            marginBottom: "8rem",
          }}
        >
          <h2 style={{ marginTop: "2rem" }}>Phone Number</h2>
          <Divider />
          <div style={{ marginTop: "0.4rem" }}>
            <FormInputContainer>
              <InputLabelContainer>
                <span>Phone Number</span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (phoneBtn === "Edit") {
                      setPhoneBtn("Update");
                    } else {
                      if (
                        phoneNumber.length === 11 &&
                        phoneNumber !== currentUser.phoneNumber
                      ) {
                        setConfirmModalOpen(true);
                      } else {
                        toast.error("Invalid phone number entry");
                      }
                    }
                  }}
                >
                  {phoneBtn}
                </span>
              </InputLabelContainer>
              <FormInput
                value={phoneNumber}
                placeholder={currentUser.phoneNumber}
                disabled={phoneBtn === "Edit" ? true : false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setphoneNumber(event.target.value);
                }}
              />
            </FormInputContainer>
          </div>
        </div>

        <ConfirmModal
          message="Are sure you want to change this phone number?"
          handleYesModalButton={sendOTP}
          handleNoModalButton={() => setConfirmModalOpen(!isConfirmModalOpen)}
          isModalOpen={isConfirmModalOpen}
          showIndicator={showIndicator}
          handleCloseModal={() => setConfirmModalOpen(!isConfirmModalOpen)}
        />
        <OtpModal
          onChangeOTP={handleOTP}
          onClose={() => setOtpModalOpen(false)}
          isOpen={otpModalOpen}
        />
      </PageLayout>
    </Layout>
  );
};

export default BusinessSettings;
