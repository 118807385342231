import styled from "styled-components";
import { color } from "../../../../shared/utils/styles";

export const TableHeader = styled.div`
  background-color: ${color.fountainLight};
  text-align: left;
  display: flex;
  width: 100%;
  padding: 1rem 0.2rem;
  justify-content: space-between;
  color: ${color.fountain};
  font-size: 14px;
`;

export const TableRow = styled.div`
  display: flex;
  text-align: left;
  width: 100%;
  padding: 1rem 0.2rem;
  justify-content: space-between;
  color: ${color.fountain};
  font-size: 14px;
  border: 1px solid ${color.backgroundMedium};
  /* cursor: pointer; */
`;

export const TableContent = styled.div`
  width: 15%;
  padding-left: 0.2rem;
  justify-content: center;
`;
export const TableContentMain = styled.div`
  width: 15%;
  padding-left: 0.2rem;
  justify-content: center;
  font-size: 13px;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  & span {
    cursor: pointer;
  }
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  margin-right: 10px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  margin-right: 10px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #760000;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const TransactionModal = styled.div`
  padding: 45px 35px;
`;

export const ModalContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const ModalContentLeft = styled.div`
  width: 30%;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`;

export const ModalContentRight = styled.div`
  width: 70%;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
`;
