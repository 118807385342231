import React, { useEffect, useState } from "react";
import {
  CHeaderContainer,
  PageContent,
  PageHeader,
  PageLayout,
  InviteContent,
  InviteContentHeader,
  InviteContentRow,
  ListContainer,
  ViewAllButton,
  ResendContainer,
  ActionIcon,
  RevokeContainer,
  ActionDIcon,
  ActionLayout,
  ModalContent,
  ModalButtonsContainer,
  ModalButton,
} from "./Styles";

import PageForm from "./PageForm";
import UserRow from "./UserRow";
import useApi from "shared/hooks/api";
import { GradientLoader } from "shared/components";
import api from "shared/utils/api";
import moment from "moment";
import closeIcon from "./assets/close.png";
import reshareIcon from "./assets/reshare.png";
import Table from "shared/clientcomponents/Table";
import Modal from "shared/quickComponents/modal";
import { toast } from "react-toastify";
import { getCurrentUser } from "shared/utils/authToken";
import useLogger from "shared/hooks/useLogger";
import logger from "shared/utils/logger";
import { getClientDetails, getIpAddress } from "shared/utils/location";
import EmptyState from "shared/components/EmptyState";

type usersPropT = {
  id: number;
  email: string;
  activated: boolean;
};

const InviteUsers = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [isloading, setisloading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState<any>([]);
  const [resend, setresend] = useState<string>("Resend");
  const [revoke, setrevoke] = useState<string>("Revoke");
  const [isLoading, setisLoading] = useState(false);

  const currentUser = getCurrentUser();

  const [Logger] = useLogger();

  const [{ data, error, setLocalData }, fetchData] = useApi.get(`/invitations`);

  const columnHeaders = [
    { id: "sn", label: "#" },
    { id: "date", label: "Date" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "funcProps", label: "" },
  ];

  const handleViewlAll = () => {
    let newdata = data.slice(0, data.length);
    setUsers([...users, ...newdata]);
  };

  const handleResend = async (me: any) => {
    const resendResult = await api.post(`/invitations/${me.id}/resend`);

    if (resendResult) {
      console.log("resend response ===>", resendResult);
      toast.success("Invitation request revoked successfully");
      await logger.logActivities("remote", {
        date: new Date().toString(),
        author: currentUser.fullName,
        resource: "admin",
        action: "LOGIN",
        payload: {
          userAgent: getClientDetails(),
          data: {
            detail: `${currentUser.fullName} revoked ${me.fullName} invitation`,
          },
        },
      });
      await fetchData();
      setresend("Invite link sent !");
      setTimeout(() => {
        setresend("Resend");
      }, 2000);
    }
  };

  const handleRevoked = async () => {
    setShowModal(!showModal);
    await api.post(`/invitations/${currentId.id}/revoke`);

    toast.success("Invitation request revoked successfully");

    await logger.logActivities("remote", {
      date: new Date().toString(),
      author: currentUser.fullName,
      resource: "admin",
      action: "LOGIN",
      payload: {
        userAgent: getClientDetails(),
        data: {
          detail: `${currentUser.fullName} revokes ${currentId.fullName} access request invitation.`,
        },
      },
    });
    await fetchData();
    setrevoke("Invite revoked!");
    setTimeout(() => {
      setrevoke("Revoke");
    }, 2000);
  };

  const handleModal = (id: any) => {
    setCurrentId(id);
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (!data) {
      setisloading(true);
    }

    if (error) {
      setisloading(false);
    }

    if (data) {
      // console.log(data)
      setUsers(data.payload);
      setisloading(false);
    }
  }, [data, error]);

  const userList = users.map((user: any, index: number) => ({
    sn: index + 1,
    date: moment(user.createdAt).format("LLLL"),
    name: user.fullName,
    email: user.email,
    phone: user.phoneNumber,
    funcProps: (
      <ActionLayout>
        <ResendContainer onClick={() => handleResend(user)}>
          <ActionIcon src={reshareIcon} />
          <span>{resend}</span>
        </ResendContainer>

        <RevokeContainer onClick={() => handleModal(user)}>
          <ActionDIcon src={closeIcon} />
          <span>{revoke}</span>
        </RevokeContainer>
      </ActionLayout>
    ),
  }));

  return (
    <>
      <PageLayout>
        <PageHeader>
          <PageForm fetchData={fetchData} />
        </PageHeader>
        <PageContent>
          {isloading ? (
            <GradientLoader />
          ) : (
            <>
              {userList.length ? (
                <Table
                  headData={columnHeaders}
                  bodyData={userList}
                  maxwidth="8rem"
                  border={true}
                />
              ) : (
                <EmptyState
                  stateTitle="No invite"
                  statePara=""
                  stateBtnText=""
                />
              )}
            </>
          )}
        </PageContent>
      </PageLayout>

      <Modal
        closeButton={false}
        isOpen={showModal}
        onClose={() => console.log("")}
      >
        <ModalContent>
          <p>Do you want to revoke this Invite?</p>

          <ModalButtonsContainer>
            <ModalButton onClick={handleRevoked}>Yes</ModalButton>
            <ModalButton onClick={() => setShowModal(!showModal)} option={true}>
              No
            </ModalButton>
          </ModalButtonsContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InviteUsers;
