import { useEffect, useState } from "react";
import {
  ContentHeader,
  ContentRow,
  InnerPanelLayout,
  PanelTitle,
} from "./Style";
import reportObj from "Report/report-class";
import { currencyFormatter } from "shared/utils/utility";
import { Spinner } from "shared/components";

const Channels = () => {
  const [channelData, setchannelData] = useState<any[]>([]);
  const [isloading, setisloading] = useState(false);
  const [isDatafetched, setisDatafetched] = useState(false);

  const renderChannels = (arr: any[]) => {
    console.log("Array: ", arr)
    if (arr.length > 0) {
      return arr.map((obj, index: number) => (
        <ContentRow key={index}>
          <label>{obj?.channel}</label>
          <span>{currencyFormatter(obj?.value)} </span>
        </ContentRow>
      ));
    }
  };

  const getStats = async () => {
    const response = await reportObj.getChannelStat();
    if (response?.length > 0) {
      setchannelData(response);
      setisloading(false);
      setisDatafetched(true);
    }
  };

  useEffect(() => {
    if (!isDatafetched) {
      setisloading(true);
      getStats();
    }
  }, []);

  return (
    <InnerPanelLayout>
      <>
        <PanelTitle>Channels</PanelTitle>
        <ContentHeader>
          <span>Name</span>
          <span>Total volume</span>
        </ContentHeader>
        {isloading && <Spinner size={30} />}
        {!isloading && channelData && renderChannels(channelData)}
      </>
    </InnerPanelLayout>
  );
};

export default Channels;
