import React from "react";
import { RoundBtn } from "./Styles";
type PropT = {
  setcurrentStep: Function;
  setdoneSteps: Function;
  doneSteps: any[];
  currentStep: number;
};
export default function BackBtn({
  setcurrentStep,
  setdoneSteps,
  doneSteps,
  currentStep,
}: PropT) {
  const handleBack = () => {
    const filteredSteps = doneSteps.filter((step) => step !== currentStep);
    setdoneSteps([...filteredSteps]);
    setcurrentStep(currentStep - 1);
  };
  return (
    <RoundBtn onClick={handleBack}>
      <svg
        width="24"
        height="14"
        viewBox="0 0 24 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.875973 9.0881L4.75497 12.9671C4.9425 13.1546 5.19681 13.2599 5.46197 13.2599C5.72714 13.2599 5.98145 13.1546 6.16897 12.9671C6.35644 12.7796 6.46176 12.5253 6.46176 12.2601C6.46176 11.9949 6.35644 11.7406 6.16897 11.5531L2.61397 8.0001H23C23.2652 8.0001 23.5195 7.89475 23.7071 7.70721C23.8946 7.51967 24 7.26532 24 7.0001C24 6.73489 23.8946 6.48053 23.7071 6.293C23.5195 6.10546 23.2652 6.0001 23 6.0001H2.55297L6.16897 2.3811C6.35644 2.19358 6.46176 1.93927 6.46176 1.6741C6.46176 1.40894 6.35644 1.15463 6.16897 0.967103C5.98145 0.779632 5.72714 0.674316 5.46197 0.674316C5.19681 0.674316 4.9425 0.779632 4.75497 0.967103L0.874973 4.8461C0.314401 5.40972 -0.000187311 6.17238 8.36725e-08 6.96731C0.000187478 7.76224 0.315135 8.52475 0.875973 9.0881Z"
          fill="#828282"
        />
      </svg>
    </RoundBtn>
  );
}
