import { useEffect, useState } from "react";
import { Input } from "shared/components";
import {
  FilterInputCont,
  FilterParamsCont,
  FilterSec,
  FilterText,
  TextDescription,
} from "./Styles";
import Daterange from "./Daterange";
import Checkbox from "@mui/material/Checkbox";
import {
  ActionButton,
  PopUpFooter,
  PopUpSubHead,
  ResetButton,
} from "../DashboardSubHeader/Styles";
import { FormControlLabel } from "@mui/material";

type PropT = {
  onProcessFilter: Function;
};

const TransactionFilter = ({ onProcessFilter }: PropT) => {
  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();
  // we will display the date as DD-MM-YYYY
  let currentDate = `${currentDay}/${currentMonth}/${currentYear}`;
  const [start, setStart] = useState<string | any>(currentDate);
  const [end, setEnd] = useState<string | any>(currentDate);
  const [search, setSearch] = useState<string | any>("");
  const [successful, setSuccessful] = useState<boolean | undefined>(true);
  const [failed, setFailed] = useState<boolean | undefined>(true);
  const [terminated, setTerminated] = useState<boolean | undefined>(true);

  const [card, setCard] = useState<boolean | undefined>(true);
  const [qrpay, setQrpay] = useState<boolean | undefined>(true);
  const [internetBanking, setInternetBanking] = useState<boolean | undefined>(
    true
  );
  const [transfer, setTransfer] = useState<boolean | undefined>(true);
  const [directDebit, setDirectDebit] = useState<boolean | undefined>(true);
  const [filterPayload, setFIlterPayload] = useState<any>({});

  const onChangeTextHandler = () => {
    //onProcessFilter({date:{start, end}, search, status:{successful, failed, terminated}, channel:{card, qrpay, internetBanking, directDebit}})
    onProcessFilter({
      start,
      end,
      search,
      successful,
      failed,
      terminated,
      card,
      qrpay,
      internetBanking,
      directDebit,
      transfer
    });
  };

  const handleSelection = (fromDate: string, toDate: string) => {
    setStart(fromDate);
    setEnd(toDate);
    // console.log(`testing calendar ${fromDate}, ${toDate}`);
  };

  useEffect(() => {
    setFIlterPayload({
      start: start,
      end: end,
      search,
      successful: true,
      failed: true,
      terminated: true,
      card: true,
      qrpay: true,
      internetBanking: true,
      directDebit: true,
      transfer: true
    });
  }, [start, end, card, directDebit, internetBanking, qrpay, transfer]);

  return (
    <>
      <FilterSec>
        <PopUpSubHead>Search transaction</PopUpSubHead>
        <FilterText>
          {/* <TextDescription style={{ marginBottom: "0.66rem" }}>
            Search
          </TextDescription> */}
          <Input
            name={"search"}
            type="text"
            value={search}
            onChange={(value: any) => {
              setSearch(value);
              // onChangeTextHandler();
            }}
            placeholder="Search"
          />
        </FilterText>

        <PopUpSubHead>Date range</PopUpSubHead>
        <FilterInputCont>
          <Daterange handleSelection={handleSelection} />
        </FilterInputCont>

        <PopUpSubHead>Payment channels</PopUpSubHead>
        <FilterParamsCont>
          <FormControlLabel
            control={
              <Checkbox checked={card} onChange={() => setCard(!card)} />
            }
            label="Card"
            style={{ fontSize: "0.88rem" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "#760000",
                marginRight: "-5px",
              },
              "& .MuiTypography-root": {
                fontSize: 14,
              },
            }}
          />

          <FormControlLabel
            control={
              <Checkbox checked={qrpay} onChange={() => setQrpay(!qrpay)} />
            }
            label="QR Pay"
            style={{ fontSize: "0.88rem" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "#760000",
                marginRight: "-5px",
              },
              "& .MuiTypography-root": {
                fontSize: 14,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={transfer}
                onChange={() => setTransfer(!transfer)}
              />
            }
            label="Transfer"
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "#760000",
                marginRight: "-5px",
              },
              "& .MuiTypography-root": {
                fontSize: 14,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={internetBanking}
                onChange={() => setInternetBanking(!internetBanking)}
              />
            }
            label="Internet Banking"
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "#760000",
                marginRight: "-5px",
              },
              "& .MuiTypography-root": {
                fontSize: 14,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={directDebit}
                onChange={() => setDirectDebit(!directDebit)}
              />
            }
            label="Direct debit"
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "#760000",
                marginRight: "-5px",
              },
              "& .MuiTypography-root": {
                fontSize: 14,
              },
            }}
          />
        </FilterParamsCont>

        <PopUpSubHead>Transaction status</PopUpSubHead>
        <FilterParamsCont>
          <FormControlLabel
            control={
              <Checkbox
                checked={successful}
                onChange={() => setSuccessful(!successful)}
              />
            }
            label="Successful"
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "#760000",
                marginRight: "-5px",
              },
              "& .MuiTypography-root": {
                fontSize: 14,
              },
            }}
          />

          <FormControlLabel
            control={
              <Checkbox checked={failed} onChange={() => setFailed(!failed)} />
            }
            label="Failed"
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "#760000",
                marginRight: "-5px",
              },
              "& .MuiTypography-root": {
                fontSize: 14,
              },
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={terminated}
                onChange={() => setTerminated(!terminated)}
              />
            }
            label="Terminated"
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "#760000",
                marginRight: "-5px",
              },
              "& .MuiTypography-root": {
                fontSize: 14,
              },
            }}
          />
        </FilterParamsCont>
      </FilterSec>

      <PopUpFooter>
        <ResetButton onClick={() => ""}>Reset</ResetButton>
        <ActionButton
          onClick={() => {
            // onFilterProcess();
            onChangeTextHandler();
            // setOpenPopUp(false);
          }}
        >
          Apply
        </ActionButton>
      </PopUpFooter>
    </>
  );
};

export default TransactionFilter;
