import styled from "styled-components";

export const Container = styled.div`
  display: relative;
`;
export const DivText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding-bottom: 10px;
  cursor: pointer;

  img {
    margin-top: 14px;
    width: 4px;
  }
`;

export const OptionDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  /* border: 1px solid #ccc; */
  border-radius: 5px;
  /* margin-left: 10px; */
  min-height: 20px;
  padding: 0.3em 0.4em;
  position: absolute;
  background-color: white;
  position: absolute;
  right: 40px;
  box-shadow: 2px 6px 14px 0px rgba(0, 0, 0, 0.1);
`;

export const OptionItem = styled.div`
  cursor: pointer;
  padding: 10px 0em;
  width: 100%;
  min-width: 90px;
  &:hover {
    background-color: #eaeaea;
  }
`;
