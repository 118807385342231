import {
  ContainerFinder,
  Finders_headButton,
  Finders_pageheader,
  Finders_tablecont,
} from "Finders/Styles";
import React, { useState } from "react";
import Table from "shared/clientcomponents/Table";

export default function FindersHome() {
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState<any>("");
  const findersTableHeader = [
    { id: "finderName", label: "Finders Name", width: "14%" },
    { id: "businessName", label: "Business Name", width: "38%" },
    { id: "email", label: "Email", width: "20%" },
    { id: "accountNumber", label: "Account Number", width: "20%" },
    { id: "bankName", label: "Bank Name", width: "20%" },
    { id: "amountEarned", label: "Amount Earned", width: "20%" },
  ];

  return (
    <ContainerFinder>
      <Finders_pageheader>
        <h2>Finder’s Fee</h2>
        <Finders_headButton>Add Finder’s Fee</Finders_headButton>
      </Finders_pageheader>
      <Finders_tablecont>
        <Table
          headData={findersTableHeader}
          bodyData={[]}
          rowStyle={{ background: "#ffffff" }}
          totalPages={totalPages}
          currentpage={currentPage}
          goToPage={(value: any) => {
            setcurrentPage(value);
            // getKYCList(value);
          }}
          //   hoverLink={hoverLink}
        />
      </Finders_tablecont>
    </ContainerFinder>
  );
}
