import styled from "styled-components";

import { zIndexValues, mixin } from "shared/utils/styles";

type PropT = {
  width: number;
};

export const StyledTooltip = styled.div<PropT>`
  /* z-index: ${zIndexValues.modal + 1}; */
  z-index: 80000;
  position: fixed;
  width: ${(props) => props.width}px;
  border-radius: 3px;
  background: #fff;
  ${mixin.hardwareAccelerate}
  ${mixin.boxShadowDropdown}
`;
