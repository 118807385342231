import Layout from "Layout";
import { useEffect, useLayoutEffect, useState } from "react";
import { Redirect, Route, useHistory, useRouteMatch } from "react-router-dom";
import { GradientLoader } from "shared/components";
import Business from "./Business";
import Overview from "./Overview";
import Kyc from "./Kyc";
import AllAgents from "./AllAgents/AllAgents";
import { AgencyBankingPage } from "./Styles";
import Single from "./Single";

import Financial from "./Financial";
import AllFinance from "./Financial/AllFinance";
import SystemStatus from "./SystemStatus";
import { useEffectOnce } from "shared/hooks/useEffectOnce";

type menuPropT = {
  id: number;
  title: string;
  url: string;
  icon: string;
  type: boolean;
}[];

export default function AgencyBanking() {
  const menu: menuPropT = [
    {
      id: 1,
      title: "Overview",
      url: "/agencybanking",
      icon: "home",
      type: false,
    },
    {
      id: 2,
      title: "Business",
      url: "/agencybanking/business",
      icon: "stack",
      type: false,
    },
    {
      id: 3,
      title: "Financial",
      url: "/agencybanking/financial",
      icon: "board",
      type: false,
    },
    {
      id: 4,
      title: "System Status",
      url: "/agencybanking/system-status",
      icon: "settings",
      type: false,
    },
  ];
  // const dispatch = useAppDispatch();

  const [isLoggedIn, setisLoggedIn] = useState(true);
  const [data, setdata] = useState<boolean>(true);
  const match = useRouteMatch();
  const history = useHistory();
  // const USER = useAppSelector((state) => state.USER);

  useLayoutEffect(() => {
    //reset the sidebar menu state to default before
    //mounting component
  }, []);

  useEffectOnce(() => {
    // dispatch(setSidebar(menu)); //Set sidebar menus
  });
  return (
    <Layout>
      {!data ? (
        <GradientLoader />
      ) : (
        <AgencyBankingPage>
          <Route path={`${match.path}/over-view`} render={() => <Overview />} />
          <Route path={`${match.path}/agent/:id`} render={() => <Single />} />
          <Route
            path={`${match.path}/all-agents`}
            render={() => <AllAgents />}
          />

          <Route path={`${match.path}/business`} render={() => <Business />} />

          <Route
            exact
            path={`${match.path}/financial`}
            render={() => <Financial />}
          />
          <Route
            path={`${match.path}/financial/all`}
            render={() => <AllFinance />}
          />
          <Route
            path={`${match.path}/system-status`}
            render={() => <SystemStatus />}
          />

          <Route path={`${match.path}/kyc`} render={() => <Kyc />} />

          {match.isExact && <Redirect to={`${match.url}/over-view`} />}
        </AgencyBankingPage>
      )}
    </Layout>
  );
}
