import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, NavLink, useHistory } from "react-router-dom";
// import { useAppSelector, useAppDispatch } from "AppStore/hooks";
import { Icon, AboutTooltip, LogoutTooltip } from "shared/components";
import { MENUS } from "shared/utils/sitemap";
import { useAppDispatch, useAppSelector } from "store/hook";
import user from "App/assets/images/user.png";

import {
  NavLeft,
  LogoLink,
  ImageAvatar,
  LogoText,
  Bottom,
  Item,
  ItemText,
} from "./Styles";
import { ROLES } from "shared/utils/sitemap";
import { getCurrentUser, removeAll } from "shared/utils/authToken";
import { setSidebar } from "store/actions/settingAction";
import { setConsoleCategory } from "store/actions/consoleCategory";
import { setPageTitle } from "store/actions/pageTitle";
import { checkIfUrlExist, validateMenu } from "shared/utils/menurecords";

type PropT = {
  [x: string]: any;
};

type menus = {
  menu: {
    id: number;
    title: string;
    url: string;
    icon: string;
  }[];
};

const NavbarLeft = () => {
  const history = useHistory();
  const currentUser: any = getCurrentUser();
  const dispatch = useAppDispatch();

  const navbarMenus = [
    {
      id: 1,
      title: "Gateway",
      url: "/dashboard",
      icon: "earth",
      category: "Gateway",
    },
    {
      id: 2,
      title: "Agency banking",
      url: "/agencybanking",
      icon: "page",
      category: "Agency",
    },

    {
      id: 3,
      title: "Report",
      url: "/gateway/report",
      icon: "reports",
      category: "Reports",
    },

    {
      id: 4,
      title: "Role Mgmt",
      url: "/role-management/cusers",
      icon: "users",
      category: "Role Management",
    },

    {
      id: 5,
      title: "System Monitor",
      url: "/system-status",
      icon: "issues",
      category: "System Status",
    },
  ];
  const navbarIcon = () => {};
  const [menus, setmenus] = useState(navbarMenus);

  const businesses = "";

  const addBusinesses = () => history.push("/business/add");
  const openGatewaySection = () => history.push("/dashboard");
  const openBusiness = ({ business }: any) => {
    window.location.reload();
  };

  // =============== Create jsx menu tab list using data from redux store ==========
  ///...CHECK MENU HERE =============

  const menuList = menus.map((item) => (
    <Item
      key={item.id}
      onClick={() => {
        dispatch(setConsoleCategory(item.category));
        dispatch(setPageTitle(item.title));
        history.push(item.url);
      }}
    >
      <Icon type={item.icon} size={22} top={1} left={3} />
      <ItemText>{item.title}</ItemText>
    </Item>
  ));
  //================================================================================
  const openSettings = () => {
    history.push("/profile");
  };

  const getUserRole = () => {
    const filter = ROLES.filter((role) => role.code === currentUser.type);
    if (filter.length > -1) {
      return filter[0].name;
    }
  };

  //  enabled after full implementation
  //validate Parent Menu
  const getExactMenu = () => {
    let permissions = currentUser?.Role?.permissions;
    let arr: any[] = [];
    if (permissions && permissions[1]) {
      let permittedMenus = permissions[1];
      for (let el of navbarMenus) {
        if (permissions[0].allOpen || validateMenu(el.url, permittedMenus)) {
          arr.push(el);
        }
      }
      setmenus(arr);
    }
  };

  useEffect(() => {
    getExactMenu();
  }, []);

  return (
    <NavLeft isClicked={false}>
      <LogoLink to="/dashboard">
        <ImageAvatar>
          <img
            src={
              currentUser && currentUser?.passport
                ? currentUser?.passport
                : user
            }
            width="50"
            alt={currentUser.fullName}
          />
        </ImageAvatar>
        <LogoText>
          {currentUser?.fullName}
          <p>({getUserRole()})</p>
        </LogoText>
      </LogoLink>

      {businesses && currentUser.currentUser && (
        <>
          {Object.keys(businesses).map((business: any, index: number) => {
            <Item key={index} onClick={() => openBusiness(business)}>
              <Icon type="plus" size={27} />
              <ItemText>Create Issue</ItemText>
            </Item>;
          })}
        </>
      )}
      
      {/* <span>{USER.fullname}</span> */}

      {/* ======== set menu tabs ================== */}
      <div style={{ marginTop: "2rem" }}>
        {menuList}
      </div>
      {/* ============================================ */}

      <Bottom>
        <Item onClick={openSettings}>
          <Icon type="user" size={20} />
          <ItemText>Profile</ItemText>
        </Item>

        <AboutTooltip
          placement="right"
          offset={{ top: -218 }}
          renderLink={(linkProps: PropT) => (
            <Item {...linkProps}>
              <Icon type="help" size={25} />
              <ItemText>About</ItemText>
            </Item>
          )}
        />

        <LogoutTooltip
          placement="right"
          offset={{ top: -218 }}
          renderLink={(linkProps: PropT) => (
            <Item {...linkProps}>
              <Icon type="log_out_outlined_logout_sign" size={25} />
              <ItemText>Logout</ItemText>
            </Item>
          )}
        />
      </Bottom>
    </NavLeft>
  );
};

export default NavbarLeft;
