import styled, { css } from "styled-components";

import { color, sizes } from "shared/utils/styles";
import breakpoints from "shared/utils/breakpoints";
type Propt = {
  isTabActive?: boolean;
  alignEnd?: boolean;
  width?: string;
  isMapVisible?: boolean;
};

const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

export const AuditTrailPage = styled.div`
  padding: 0rem 32px 50px ${paddingLeft - sizes.secondarySideBarWidth - 110}px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1100px) {
    padding: -25px 20px 50px ${paddingLeft - 20}px;
  }
  @media (max-width: 999px) {
    /* padding-left: ${paddingLeft - 20 - sizes.secondarySideBarWidth}px; */
		padding: 0px 15px;
  }
`;

export const LeftPanel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const TabList = styled.div<Propt>`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  &:hover {
    background-color: #fff;
  }
  ${(props) =>
    props.isTabActive
      ? `background-color: #fff;`
      : "background-color: transparent;"}
`;

export const ContentArea = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  margin-top: -20px;
  width: 100%;
  margin-left: 9px;
`;

export const TopPanel = styled.div`
  width: 100%;
  height: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 22px !important;
  margin-bottom: 2px;
  margin-left: 10px;
`;

export const Items = styled.div`
  display: block;
  margin-right: 10px;
  width: 100%;
  background-color: green;
  margin-top: -20px;
`;

export const SearchDropdown = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.fountain};
  color: #fff;
  width: 100%;
  cursor: pointer;
`;

/***For Client Logs */
export const Section = styled.section`
  margin-top: 1rem;
  margin-bottom: 5rem;
  background: #fff;
  padding: 1.4rem 1rem 0 1rem;
  border-radius: 1rem;
  min-height: 34rem;
  min-height: 10rem;
  position: relative;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

export const TableContainer = styled.div`
  width: 100%;
  padding: 0rem 0.2rem 4rem 0.2rem;
  overflow-y: auto;
  min-height: 10rem;
  overflow: auto;
  border-bottom: 1px solid ${color.backgroundMedium};
`;

export const TableHeader = styled.div`
  display: flex;
  background-color: ${color.fountainLight};
  color: ${color.fountain};
  justify-content: space-between;
  padding: 0 1rem;
  gap: 2rem;
`;

export const TableRow = styled.div`
  display: flex;
  background-color: #fff;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  border: 1px solid ${color.fountainLight};
`;
export const TableContent = styled.div<Propt>`
  width: ${(prop) => (prop.width ? prop.width : "24%")};
  padding: 0.8rem 0;
  display: flex;
  justify-content: ${(prop) => (prop.alignEnd ? "end" : "start")};
  @media ${breakpoints.device.max.xs} {
    min-width: ${(prop) => (prop.width ? prop.width : "24%")};
    padding: 0.8rem 10px;
  }
`;

export const ModalContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  @media ${breakpoints.device.max.xs} {
    display: block;
  }
`;

export const ModalContentLeft = styled.div`
  width: 30%;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const ModalContentRight = styled.div`
  width: 70%;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const ViewBtn = styled.button`
  padding: 0.5rem 1rem;
  color: white;
  background-color: ${color.fountain};
  border-radius: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const UserModal = styled.div`
  padding: 45px 35px;
  @media ${breakpoints.device.max.xs} {
    padding: 15px;
  }
`;

export const ViewButton = styled.div`
  padding: 0.5rem 0.5rem;
  background: #760000;
  color: #fff;
  text-align: center;
  border-radius: 0.6rem;
  cursor: pointer;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  background: ${color.light};
  padding: 0 1rem;
  height: 4.3rem;
  position: relative;
`;

export const PaginationButtonsContainer = styled.div`
  display: flex;
  gap: 1.4rem;
`;

export const PaginationNext = styled.button`
  background-color: ${color.fountain};
  color: #fff;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 12px;
`;

export const PaginationPrev = styled.button`
  background-color: #bf7a79;
  color: #fff;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  font-size: 12px;
  cursor: pointer;
`;

export const LogDescription = styled.div`
  width: 40%;
`;

export const LogMap = styled.div<Propt>`
  position: relative;
  width: 60%;
  background-color: #fff;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  ${(props) =>
    props.isMapVisible &&
    css`
    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 4px;
      background-color: rgba(0,0,0,0.3);
      display: 'block'};
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  `};

  button {
    display: ${(props) => (props.isMapVisible ? "none" : "block")};
    position: absolute;
    top: 44.5%;
    left: 45.5%;
    z-index: 999999;
    background-color: #760000;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: #fff;
    cursor: pointer;
  }
`;

export const LogItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0 1px 0;
  gap: 20px;
`;
