import { omit } from "lodash";
import queryString from "query-string";

export const queryStringToObject = (str: string, options = {}) =>
  queryString.parse(str, {
    arrayFormat: "bracket",
    ...options,
  });

export const objectToQueryString = (obj: {} | any[], options = {}) =>
  queryString.stringify(obj, {
    arrayFormat: "bracket",
    ...options,
  });

export const omitFromQueryString = (str: string, keys: string[]) =>
  objectToQueryString(omit(queryStringToObject(str), keys));

export const addToQueryString = (str: string, fields: {}) =>
  objectToQueryString({
    ...queryStringToObject(str),
    ...fields,
  });

export const fieldSorter = (fields: any) => (a: any, b: any) =>
  fields
    .map((o: any) => {
      let dir = 1;
      if (o[0] === "-") {
        dir = -1;
        o = o.substring(1);
      }
      return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
    })
    .reduce((p: any, n: any) => (p ? p : n), 0);
