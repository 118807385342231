import React, { useState, useEffect } from "react";
import {
  DomianEmailErr,
  FormContainer,
  FormInput,
  InputField,
  InputLabel,
  InviteButton,
  InviteFormGroup,
  InviteModalButton,
  ModalFormContainer,
  ModalFormGroup,
  Modaltitle,
  StyledForm,
} from "./Styles";
import { ToastContainer, toast } from "react-toastify";
import Modal from "shared/quickComponents/modal";
import { ModalContent } from "./Styles";
import { Option, Select } from "shared/quickComponents/select";
import api from "shared/utils/api";
import useLogger from "shared/hooks/useLogger";
import { getCurrentUser } from "shared/utils/authToken";
import { ROLES } from "shared/utils/sitemap";
import logger from "shared/utils/logger";
import { getClientDetails, getIpAddress } from "shared/utils/location";
import { decryptData } from "../../../shared/utils/security";

type pageformT = {
  fetchData: Function;
};

export default function PageForm({ fetchData }: pageformT) {
  const [userEmail, setuserEmail] = useState<string>("");
  const [userFullname, setuserFullname] = useState("");
  const [userPhonenumber, setuserPhonenumber] = useState("");
  const [usertype, setusertype] = useState<any>([]);
  const [emailDomainError, setemailDomainError] = useState(false);
  const [ismodalOpen, setismodalOpen] = useState(false);
  const [modalBtnText, setmodalBtnText] = useState("Send Invite");

  const currentUser = getCurrentUser();

  const [Logger] = useLogger();

  const openModal = () => {
    setismodalOpen(!ismodalOpen);
  };
  const clearFileds = () => {
    setuserEmail("");
    setuserFullname("");
    setuserPhonenumber("");
    setusertype(ROLES[ROLES.length - 1]);
    setmodalBtnText("Send Invite");
    setismodalOpen(false);
  };

  const handleInviteForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var emailDomain = userEmail.substring(userEmail.lastIndexOf("@") + 1);

    openModal();
  };

  const handleSelect = (e: any) => {
    // console.log(e);
  };

  const sendInvite = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setmodalBtnText("Sending...");

    // console.log(userEmail, usertype.code, userFullname, userPhonenumber);
    try {
      // console.log("Invite data: ", {
      //   email: userEmail,
      //   userType: usertype.code,
      //   fullName: userFullname,
      //   phoneNumber: userPhonenumber,
      // })

      const data = await api.post("/invite", {

        email: userEmail,
        user_type: usertype.code,
        full_name: userFullname,
        phone_number: userPhonenumber,
      });
      // console.log("response", data);
      await logger.logActivities("remote", {
        date: new Date().toString(),
        author: currentUser.fullName,
        resource: "admin",
        action: "LOGIN",
        payload: {
          userAgent: getClientDetails(),
          data: {
            detail: `${currentUser.fullName} sent invites to ${userFullname}`,
          },
        },
      });
      toast.success(data.message);
      fetchData();
      setmodalBtnText("Send Invite");
      clearFileds();
      openModal();
    } catch (err) {
      // console.log("Error data: ",err)
      await logger.logActivities("remote", {
        date: new Date().toString(),
        author: currentUser.fullName,
        resource: "admin",
        action: "LOGIN",
        payload: {
          userAgent: getClientDetails(),
          data: {
            detail: `Error {${err}} while ${currentUser.fullName} was inviting ${userFullname} for access as ${usertype.code}`,
          },
        },
      });
      toast.error(`${err}`);
      setmodalBtnText("Send Invite");
    }
  };

  useEffect(() => {
    // checkRole();
  }, [usertype]);

  return (
    <>
      {/* ================== Modal ============== */}
      <Modal
        marginTop="2.5rem"
        width="50%"
        isOpen={ismodalOpen}
        closeButton={true}
        onClose={clearFileds}
      >
        <ModalContent>
          <Modaltitle>Invite User</Modaltitle>
          <ModalFormContainer>
            <form onSubmit={sendInvite}>
              <ModalFormGroup>
                <InputLabel>Full name</InputLabel>
                <FormInput
                  placeholder="Full Name"
                  value={userFullname}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setuserFullname(event.target.value)
                  }
                  required
                />
              </ModalFormGroup>

              <ModalFormGroup>
                <InputLabel>Phone number</InputLabel>
                <FormInput
                  placeholder="Phonenumber"
                  value={userPhonenumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setuserPhonenumber(event.target.value)
                  }
                  required
                />
              </ModalFormGroup>

              <ModalFormGroup>
                <InputLabel>User category</InputLabel>
                <Select value={usertype.name} variant="primary" bgColor="#fff">
                  {ROLES.map((role: any, index: number) => {
                    return (
                      <Option
                        key={index}
                        variant="primary"
                        onClick={() => setusertype(role)}
                      >
                        {role.name}
                      </Option>
                    );
                  })}
                </Select>

                <InviteModalButton type="submit">
                  {modalBtnText}
                </InviteModalButton>
              </ModalFormGroup>
            </form>
          </ModalFormContainer>
        </ModalContent>
      </Modal>
      {/* ======================================= */}
      <StyledForm onSubmit={handleInviteForm}>
        <FormContainer>
          <InviteFormGroup>
            <InputField
              placeholder="Email address"
              value={userEmail}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setuserEmail(event.target.value)
              }
              required
            />
          </InviteFormGroup>

          <InviteButton type="submit" onSubmit={() => handleInviteForm}>
            Invite
          </InviteButton>
        </FormContainer>
      </StyledForm>
    </>
  );
}
