export const DisputeTableHeader: any = [
  { id: "sn", label: "#" },
  {
    id: "ticketID",
    label: "Ticket ID",
  },
  {
    id: "transactionID",
    label: "Transaction ID",
  },
  {
    id: "amountPAID",
    label: "Amount",
  },
  {
    id: "merchant",
    label: "Merchant",
  },
  {
    id: "status",
    label: "Status",
  },
  { id: "email", label: "Email" },
  {
    id: "timeDate",
    label: "Time/Date",
  },
];
