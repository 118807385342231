import React, { useEffect, useState } from "react";
import QuestionMarkIcon from "../../Assets/QuestionMarkIcon.svg";
import {
  FormContainer,
  InputGroup,
  PageContainer,
  Section,
  SectionHeader,
  EmailInputGroup,
} from "./Styles";
import roleApi from "RoleManagement/apiClass";
import api from "shared/utils/api";
import { toast } from "react-toastify";
import { removeDuplicateObjects } from "shared/utils/utility";
import consoleLog from "shared/utils/consoleLog";
import { useHistory, useParams } from "react-router-dom";

export default function AddUser() {
  const history = useHistory();
  const { id: userId }: any = useParams();

  const [departList, setdepartList] = useState<[] | any>([]);
  const [departChildrenList, setdepartChildrenList] = useState<[] | any>([]);
  const [allRoles, setallRoles] = useState<any>([]);
  const [deptRoles, setdeptRoles] = useState([]);

  const [selectedDepartment, setselectedDepartment] = useState<any>();
  const [selectedUnit, setselectedUnit] = useState<any>("");
  const [designation, setdesignation] = useState("");
  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [staffID, setstaffID] = useState("");
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false);

  const [btnTest, setbtnTest] = useState(
    userId ? "Update User" : "Create User"
  );
  const [isFormValid, setisFormValid] = useState(false);

  const [title, settitle] = useState("");

  const getDepartments = async (unitId = "", roles = []) => {
    const data: any = await roleApi.fetchDeptsChildren();
    if (data) {
      //getting only the departments
      // const filList = response.filter(
      //   (obj: any) => obj.parentName !== "MAIN UNIT" && obj.parentName !== null
      // );
      //construst depts array
      const deptsArr = data.map((obj: any) => {
        return {
          label: obj?.name,
          id: obj?.id,
        };
      });
      //remover duplicates from deptsArr
      // const cleanDepts: any = removeDuplicateObjects(deptsArr);
      // console.log("filtered depts", cleanDepts);
      if (unitId === "") {
        setselectedDepartment(deptsArr[0]?.id);
      } else {
        setselectedDepartment(roleApi.findParentUnit(roles, unitId));
      }
      setdepartList(deptsArr);
      setdepartChildrenList(data);
    }
  };

  const getRoles = async () => {
    const response = await roleApi.fetchRoles();
    consoleLog(response, "responseRoles");
    setallRoles(response);
    return response;
  };

  const getUserRecords = async (roles: any[]) => {
    const data: any = await roleApi.fetchUser(userId);
    consoleLog(data, "UserRecords", roles);
    if (data) {
      let unitId =
        roles.find((role: any) => role.id === data.roleId)?.unitId ?? "";
      setselectedUnit(unitId);
      setdesignation(data.designation ?? "");
      setfullname(data.fullName ?? "");
      setemail(data.email ?? "");
      setphone(data.phoneNumber ?? "");
      setstaffID(data.staffId ?? "");
      settitle(data.title ?? "");
      return unitId;
    }
  };

  const initialRun = async () => {
    let roles = await getRoles();
    if (userId) {
      let unitId = await getUserRecords(roles);

      await getDepartments(unitId, roles);
    } else {
      await getDepartments();
    }
  };

  useEffect(() => {
    initialRun();
  }, []);

  useEffect(() => {
    const roles = allRoles.filter(
      (role: any) => role?.unitId === selectedDepartment
    );

    setdeptRoles(roles);
    setselectedUnit(roles[0]?.unitId);
  }, [selectedDepartment]);

  const validateForm = () => {
    setisFormValid(
      fullname.length !== 0 &&
        phone.length === 11 &&
        email.length > 5 &&
        !emailInvalid &&
        selectedUnit &&
        selectedDepartment &&
        title &&
        designation &&
        staffID.length > 4
    );
  };

  const confirmEmail = (value: string) => {
    const emails = value.split("@");
    const validEmail = emails.length > 1 && emails[1] == "fountainpay.ng";
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setemail(value);
    if (validEmail && value.match(validRegex)) {
      setEmailInvalid(false);
    } else {
      setEmailInvalid(true);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let roleId: any = allRoles.find(
      (roles: any) => roles?.unitId === selectedUnit
    )?.id;

    if (!roleId) return;
    const payload = {
      email: email,
      userType: "ADMIN",
      fullName: fullname,
      phoneNumber: phone,
      designation,
      roleId,
      role_id: roleId,
      staffId: staffID,
      staff_id: staffID,
      title,
    };
    setbtnTest("Processing...");
    consoleLog(payload, "Payload...");
    try {
      const response = userId
        ? await api.put(`/users/${userId}`, payload)
        : await api.post("/invite", payload);
      if (response && response.status) {
        toast.success(
          userId ? "User updated successfully" : "Invite sent successfully!"
        );
        history.push("/role-management/cusers");
        setbtnTest("Create User");
      } else {
        toast.error("Opps! Something went wrong");
        setbtnTest("Create User");
        console.log("invite user", response);
      }
    } catch (error) {
      toast.error("Opps! Something went wrong");
      setbtnTest("Create User");
      // console.log("invite user", error);
    } finally {
    }
  };

  useEffect(() => {
    validateForm();
  }, [
    fullname,
    designation,
    title,
    selectedUnit,
    selectedDepartment,
    phone,
    email,
    staffID,
  ]);

  let selectedDeptObj =
    departChildrenList &&
    departChildrenList.find((el: any) => el.id === selectedDepartment);

  return (
    <div>
      <PageContainer>
        <Section>
          <SectionHeader>
            <h3>Personal Information</h3>
          </SectionHeader>

          <form onSubmit={handleSubmit}>
            <FormContainer>
              <InputGroup>
                <div>
                  <label>Title</label>
                  <select
                    value={title}
                    onChange={(e) => {
                      settitle(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select Title
                    </option>
                    <option value="DR">Dr</option>
                    <option value="MR">Mr</option>
                    <option value="MRS">Mrs</option>
                    <option value="MISS">Ms</option>
                  </select>
                </div>

                <div>
                  <label>Staff ID</label>
                  <input
                    value={staffID}
                    type="text"
                    minLength={4}
                    required
                    placeholder="Staff ID"
                    onChange={(e) => setstaffID(e.target.value)}
                  />
                </div>
              </InputGroup>
              <InputGroup>
                <div>
                  <label>Name</label>
                  <input
                    value={fullname}
                    required
                    type="text"
                    placeholder="Full name"
                    onChange={(e) => setfullname(e.target.value)}
                  />
                </div>
              </InputGroup>

              <EmailInputGroup>
                <div>
                  <label>Email</label>
                  <input
                    value={email}
                    type="email"
                    required
                    placeholder="Email address"
                    onChange={(e) => confirmEmail(e.target.value)}
                  />
                </div>
                {email && emailInvalid && (
                  <div className="error">Invalid email</div>
                )}
              </EmailInputGroup>

              <InputGroup>
                <div>
                  <label>Phone Number</label>
                  <input
                    value={phone}
                    minLength={11}
                    maxLength={11}
                    type="text"
                    placeholder="e.g 090374473937"
                    onChange={(e) => setphone(e.target.value)}
                  />
                </div>

                <div>
                  <label>Designation</label>
                  <select
                    value={designation}
                    required
                    onChange={(e) => {
                      setdesignation(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select Designation
                    </option>
                    <option value="MEMBER">Member</option>
                    <option value="MANAGER">Manager</option>
                    <option value="SUPERVISOR">Supervisor</option>
                  </select>
                </div>
              </InputGroup>
            </FormContainer>

            {/* ===================================================================== */}
            <SectionHeader>
              <h3>Access Level</h3>
            </SectionHeader>

            <FormContainer>
              <InputGroup>
                <div>
                  <label>
                    Select Department
                    <img src={QuestionMarkIcon} alt="" />
                  </label>
                  <select
                    value={selectedDepartment}
                    onChange={(e) => {
                      setselectedDepartment(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select Department
                    </option>
                    {departList.map((dept: any, index: number) => (
                      <option key={index} value={dept?.id}>
                        {dept?.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label>
                    Access Role <img src={QuestionMarkIcon} alt="" />
                  </label>
                  <select
                    value={selectedUnit}
                    onChange={(e) => {
                      setselectedUnit(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select Access Role
                    </option>

                    {selectedDeptObj?.children.map(
                      (elm: any, index: number) => (
                        <option key={index} value={elm?.id}>
                          {elm.name}
                        </option>
                      )
                    )}
                    {selectedDeptObj && (
                      <option value={selectedDeptObj?.id}>
                        {selectedDeptObj.name}
                      </option>
                    )}
                  </select>
                </div>
              </InputGroup>
            </FormContainer>
            <button disabled={isFormValid ? false : true}>{btnTest}</button>
          </form>
        </Section>

        {/* ================================================================= */}
      </PageContainer>
    </div>
  );
}
