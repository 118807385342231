import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";

export const OverviewPageLayout = styled.div`
  margin-top: 2.4rem;
  width: 100%;
  flex-direction: row;
  display: flex;
  // border: 1px solid black;
  @media ${breakpoints.device.max.sm} {
    margin-top: 0rem;
  }
`;
